import { createSlice } from '@reduxjs/toolkit';
import { HomeMyViewInitialState } from './homeMyViewInitialState';

const homeMyViewSlice = createSlice({
  name: 'homeMyView',
  initialState: HomeMyViewInitialState,
  reducers: {
    //used set  all focus accounts when user uncheck the focus_account checkbox
    setUserFocusAccounts: (state, action) => {
      state.userFocusAccounts = action.payload;
    },
    setAllAccountOptions: (state, action) => {
      state.allAccountOptions = action.payload;
    },
    setEcosystemOptions: (state, action) => {
      state.ecosystemOptions = action.payload;
    },
    setProductOptions: (state, action) => {
      state.productOptions = action.payload;
    },
    setAllProductOptions: (state, action) => {
      state.allProductOptions = action.payload;
    },
    setSquadOptions: (state, action) => {
      state.squadOptions = action.payload;
    },
    setTimePeriodOptions: (state, action) => {
      state.timePeriodOptions = action.payload;
    },
    setTimePeriods: (state, action) => {
      state.timePeriod = action.payload;
    },
    setSelectedAccount: (state, action) => {
      state.selectedAccount = action.payload;
    },
    setSelectedEcosystem: (state, action) => {
      state.selectedEcosystem = action.payload;
    },
    setSelectedProduct: (state, action) => {
      state.selectedProduct = action.payload;
    },
    setSelectedSquad: (state, action) => {
      state.selectedSquad = action.payload;
    },
    setMarketingFilters: (state, action) => {
      state.marketingFilters = action.payload;
    },
    setChannelFilter: (state, action) => {
      state.marketingFilters.Channel = action.payload;
    },
    setDiscussionTopicFilter: (state, action) => {
      state.marketingFilters.DiscussionTopic = action.payload;
    },
    setStakeholderTypeFilter: (state, action) => {
      state.marketingFilters.PayerStakeholderType = action.payload;
    },
    setTemplateNameFilter: (state, action) => {
      state.marketingFilters.TemplateName = action.payload;
    },
    setSurveyTypeFilter: (state, action) => {
      state.marketingFilters.SurveyType = action.payload;
    },
    setPresentationNameFilter: (state, action) => {
      state.marketingFilters.PresentationName = action.payload;
    },
    setKeyMessageFilter: (state, action) => {
      state.marketingFilters.KeyMessage = action.payload;
    },
    setSelectedTimePeriod: (state, action) => {
      state.selectedTimePeriod = action.payload;
    },
    setFocusAccountChecked: (state, action) => {
      state.focusAccountChecked = action.payload;
    },
    setNoFocusedAccount: (state, action) => {
      state.noFocusedAccount = action.payload;
    },
    setIsAccountAvailable: (state, action) => {
      state.isAccountAvailable = action.payload;
    },
    setViewName: (state, action) => {
      state.viewName = action.payload;
    },
    setKpiId: (state, action) => {
      state.kpiId = action.payload;
    },
    setKpiName: (state, action) => {
      state.kpiName = action.payload;
    },
    setDimensionId: (state, action) => {
      state.dimesnionId = action.payload;
    },
    setDimensionName: (state, action) => {
      state.dimensionName = action.payload;
    },
    setChartData: (state, action) => {
      state.chartData = action.payload;
    },
    setChartType: (state, action) => {
      state.chartType = action.payload;
    },
    setOldChartType: (state, action) => {
      state.oldChartType = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
    setCards: (state, action) => {
      state.cards = action.payload;
    },
    setDefaultCard: (state, action) => {
      state.defaultCards = action.payload;
    },
    setTimespan: (state, action) => {
      state.timespan = action.payload;
    },
    setKpiandDimensions: (state, action) => {
      state.kpiAndDimensions = action.payload;
    },
    setSelectedKPI: (state, action) => {
      state.selectedKPI = action.payload;
    },
    setKPIclickedStatus: (state, action) => {
      state.KPIselectedStatus = action.payload;
    },
    setExpression: (state, action) => {
      state.expression = action.payload;
    },
    setNoDimensionValue: (state, action) => {
      state.noDimensionValue = action.payload;
    },
    setBenchmarkNoDimensionValue: (state, action) => {
      state.benchmarkNoDimensionValue = action.payload;
    },
    setSelectedKpiId: (state, action) => {
      state.selectedKpiId = action.payload;
    },
    setSelectedKpiName: (state, action) => {
      state.selectedKpiName = action.payload;
    },
    setSelectedAppId: (state, action) => {
      state.selectedAppId = action.payload;
    },
    setShowSuccess: (state, action) => {
      state.showSuccess = action.payload;
    },
    setSelectedAppLink: (state, action) => {
      state.selectedAppLink = action.payload;
    },
    setSelectedCardView: (state, action) => {
      state.selectedCardView = action.payload;
    },
    setAdvanceButtonStatus: (state, action) => {
      state.advanceButtonValue = action.payload;
    },
    setAdvanceOptions: (state, action) => {
      state.advanceOptions = action.payload;
    },
    setAdvanceDropdownOptions: (state, action) => {
      state.advanceDropdownOptions = action.payload;
    },
    setSystemAlertsData: (state, action) => {
      state.systemAlertsData = action.payload;
    },
    setNotificationssData: (state, action) => {
      state.notificationsData = action.payload;
    },
    setWhatsNewDatas: (state, action) => {
      state.whatsNewData = action.payload;
    },
  },
});

export const {
  setUserFocusAccounts,
  setAllAccountOptions,
  setEcosystemOptions,
  setTimePeriodOption,
  setSelectedAccount,
  setSelectedEcosystem,
  setSelectedProduct,
  setSelectedTimePeriod,
  setFocusAccountChecked,
  setIsAccountAvailable,
  setViewName,
  setKpiId,
  setKpiName,
  setDimensionId,
  setDimensionName,
  setChartType,
  setOldChartType,
  setChartData,
  setTableData,
  setCards,
  setDefaultCard,
  setSelectedSquad,
  setSquadOptions,
  setProductOptions,
  setAllProductOptions,
  setTimePeriods,
  setTimePeriodOptions,
  setKpiandDimensions,
  setSelectedKpiId,
  setSelectedKpiName,
  setSelectedKPI,
  setExpression,
  setNoDimensionValue,
  setBenchmarkNoDimensionValue,
  setShowSuccess,
  setSelectedAppId,
  setTimespan,
  setNoFocusedAccount,
  setSelectedAppLink,
  setKeyMessageFilter,
  setPresentationNameFilter,
  setSurveyTypeFilter,
  setTemplateNameFilter,
  setStakeholderTypeFilter,
  setDiscussionTopicFilter,
  setChannelFilter,
  setMarketingFilters,
  setSelectedCardView,
  setAdvanceButtonStatus,
  setKPIclickedStatus,
  setAdvanceOptions,
  setAdvanceDropdownOptions,
  setSystemAlertsData,
  setNotificationssData,
  setWhatsNewDatas,
} = homeMyViewSlice.actions;

export const homeMyViewSliceActions = homeMyViewSlice.actions;

export default homeMyViewSlice.reducer;
