/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { Input } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';
import { searchData } from 'utils/utils';

import classes from './View.module.scss';

import { InsightsBoard } from './InsightsBoard';

import {
  ACCOUNT_TYPE,
  CUSTOMER_VIEW_UPDATE_TO_INSIGHTS,
} from 'constants/constants';

import {
  setCustomerViewButtonText,
  setSelectedAccountId,
  setSelectedAccount,
  setCustomerInsightBoardId,
  setCards,
  setFocusAccountChecked,
  setCustomerViewButtonDisabled,
  setSeletedView,
  setPayerMixCustomColumns,
  setInsightType,
  setAccountPayerCustomColumn,
  setFilterInit,
} from 'store/customer/customerViewSlice';
import {
  getAllInsightBoards,
  getFocusAccountData,
} from 'store/customer/customer-view-actions';
import { areas } from 'constants/constants';
import { Spinner } from 'components/shared/loader/Spinner';

import AccountTypeToggle from 'components/shared/account-type-toggle/AccountTypeToggle';
import { SampleInsightsBoard } from './SampleInsightBoard';
import { setEngagementDateInit } from 'store/customer/filter/filter-slice';

const ViewList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const insightsBoards = useSelector(
    (state) => state?.CustomerView?.insightBoards
  );

  const componentAccessList = useSelector(
    (state) => state?.Auth?.componentAccessList
  );

  const allAccountOptions = useSelector(
    (state) => state?.CustomerView?.allAccountOptions
  );

  // const allSiteOptions = useSelector(
  //   (state) => state?.CustomerView?.allSiteOptions
  // );

  const [searchText, setSearchText] = useState('');
  const [searchedData, setSearchedData] = useState(insightsBoards);
  const { Search } = Input;

  const { unixId, roleId } = useSelector(
    (state) => state?.Auth?.userClaimObject
  );
  const [selectOptions, setSelectedOptions] = useState('account');
  const handleSearch = (value) => {
    const filteredData = searchData(insightsBoards, value);
    setSearchText(value);
    setSearchedData(filteredData);
  };

  const handleReset = () => {
    setSearchedData(insightsBoards);
    setSearchText('');
  };

  const itemClickHandler = (insight) => {
    const view = insight?.label?.toLowerCase();
    dispatch(setSeletedView(view));
    dispatch(setCards([]));
    dispatch(
      setPayerMixCustomColumns(['ParentPayer', 'Payer', 'BookOfBusiness'])
    );
    dispatch(
      setAccountPayerCustomColumn([
        'Parent_Payer',
        'Payer_Name',
        'Book_of_Business',
        'Benefit_Type',
      ])
    );
    dispatch(setCustomerViewButtonText(CUSTOMER_VIEW_UPDATE_TO_INSIGHTS));
    dispatch(
      setSelectedAccount({
        value: insight.accountId,
        label: insight.accountName,
        zip: insight.zip,
        veevaId: insight?.veevaId,
        flag: insight?.flag,
      })
    );
    let isPriorityAccount = insight?.isPriorityAccount === 'Y' ? true : false;
    dispatch(setSelectedAccountId(insight.accountId));
    dispatch(setCustomerInsightBoardId(insight.id));
    dispatch(setInsightType(insight.insightType));
    dispatch(setCustomerViewButtonDisabled(true));
    dispatch(setFocusAccountChecked(isPriorityAccount));
  };

  const onChangeHandler = (selected) => {
    dispatch(setSeletedView(selectOptions));
    dispatch(setSelectedAccount(selected));
    navigate(`${selected?.label}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setFilterInit());
    dispatch(setEngagementDateInit());
    dispatch(getAllInsightBoards(unixId, roleId));
    dispatch(setSelectedAccount([]));
    dispatch(setInsightType('general'));
    // dispatch(getFocusAccountData(unixId, roleId, ACCOUNT_TYPE.site));
  }, []);

  useEffect(() => {
    setSearchedData(insightsBoards);
  }, [insightsBoards]);

  const handleOptionChange = async (event) => {
    setSelectedOptions(event.target.value);
  };

  useEffect(() => {
    dispatch(getFocusAccountData(unixId, roleId, selectOptions));
  }, [selectOptions]);

  const sampleInsights = searchedData.filter(
    (ib) => ib.insightType === 'sample'
  );
  const generalInsights = searchedData.filter(
    (ib) => ib.insightType === 'general'
  );

  return (
    <>
      <Row className={classes['title-container']}>
        <Col
          md={12}
          xs={9}>
          <Fade>
            <p className='title__bold title__medium me-3 animate__animated animate__fadeIn'>
              <i className='icon icon__customer-view--title'></i>
              Customer View
            </p>
          </Fade>
          {/* account type */}
          <div className={`${classes['account-dropdown']}`}>
            <AccountTypeToggle
              accountChecked={selectOptions === ACCOUNT_TYPE.account}
              siteChecked={selectOptions === ACCOUNT_TYPE.site}
              handleOptionChange={handleOptionChange}
              isInline={true}
              showPriorityAccount={true}
              showPrioritySite={true}
              onChangeHandler={onChangeHandler}
              isMulti={false}
            />
            {/* configure priority link */}
            {componentAccessList?.includes(
              'Customer-View-Configure-Priority'
            ) && (
              <Link
                to='/configure-priority'
                className='d-flex align-items-center ms-3'>
                <i className='icon icon__configure'></i>{' '}
                <span
                  className={`text__bold text__small ${classes['configure-label']}`}>
                  {selectOptions === ACCOUNT_TYPE.account
                    ? 'Configure Priority Account'
                    : 'Configure Priority Site'}
                </span>
              </Link>
            )}
          </div>
        </Col>
      </Row>

      <Fade>
        <Row className={`${classes['insights-header-container']}`}>
          <Col className='animate__animated animate__fadeIn'>
            {/* insights board Title */}
            <p className='title__bold title__medium mb-4'>
              <i className='icon icon__insight-board-title'></i>
              Insight Boards
            </p>
          </Col>
          <Col className={classes['searchbar-container']}>
            {/* Search Insights */}
            <Search
              placeholder='Search'
              onSearch={handleSearch}
              allowClear
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onReset={handleReset}
              className={classes['search-bar']}
            />
          </Col>
        </Row>
      </Fade>

      {sampleInsights?.map((item, index) => (
        <Fade
          key={index}
          cascade
          style={{ transitionDelay: `${index * 100}ms` }}>
          <span>
            <SampleInsightsBoard
              insight={item}
              key={index}
              onItemClickHandler={itemClickHandler}
            />
          </span>
        </Fade>
      ))}

      {generalInsights?.map((item, index) => (
        <Fade
          key={index}
          cascade
          style={{ transitionDelay: `${index * 100}ms` }}>
          <span>
            {item.insightType === 'general' && (
              <InsightsBoard
                insight={item}
                key={index}
                onItemClickHandler={itemClickHandler}
              />
            )}
            <Spinner area={areas.customerViewList} />
          </span>
        </Fade>
      ))}
    </>
  );
};

export default ViewList;
