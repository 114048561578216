/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch } from 'react-redux';
import capitalize from 'lodash/capitalize';

import classes from './InfoSiteLayout.module.scss';

import {
  ACCOUNT_TYPE,
  AFFILIATION_INFO_EXPRESSIONS,
  REPLACE_STRING,
  SITE_INFO_AFFILIATIONS_COLUMNS,
} from 'constants/constants';

import LinkButton from 'components/shared/button/LinkButton';
import SiteAffiliations from 'components/customer/affiliations/SiteAffiliation';
import GNEModal from 'components/shared/modal/Modal';
import SiteInfoFilters from '../../filter/SiteInfoFilters';

import {
  setHCPCity,
  setHCPName,
  setHCPSpeciality,
  setHCPStakeholderType,
  setHCPState,
  setHCPZip,
  setSelectedAccount,
  setSeletedView,
} from 'store/customer/customerViewSlice';
import { getQlikSenseData } from 'services/qlik.service';
import { useEffect } from 'react';
import { getAffiliationsDataSite } from 'store/customer/customer-view-actions';

import { SiteInformation } from './SiteInformation';

const InfoSitesLayout = (props) => {
  const dispatch = useDispatch();
  const [hcpCount, setHcpCount] = useState('');
  const [affiliationContact, setAffiliationContact] = useState('');
  const [engagementsCount, setEngagementsCount] = useState('');
  const [grossSalesR12, setGrossSalesR12] = useState('');
  const qlikToken = localStorage.getItem('qlikToken');
  const qlikCookie = localStorage.getItem('qlikCookie');

  const [hcpCountLoader, setHcpCountLoader] = useState(false);
  const [affiliationContactLoader, setAffiliationContactLoader] =
    useState(false);
  const [engagementsCountLoader, setEngagementsCountLoader] = useState(false);
  const [grossSalesR12Loader, setGrossSalesR12Loader] = useState(false);
  const TABS = {
    info: 'info',
    affiliations: 'affiliations',
    comments: 'comments',
  };

  const [showFilter, setShowFilter] = useState(false);
  const [resetFilters, setResetFilters] = useState(true);
  const [key, setKey] = useState(TABS.info);
  const AFFL_HCP_EXP = `flag_contact_affiliation={0}, ${
    props.corpParent ? 'Account_ID' : 'Site_ID'
  } ={"${props.data.id}"}`;
  const AFFL_CONTACT = `flag_contact_affiliation={1}, ${
    props.corpParent ? 'Account_ID' : 'Site_ID'
  }={"${props.data.id}"}`;
  const ENGAGEMENT_COUNT = `Count({<Date ={">=$(=Date(Today()-90, 'MM/DD/YYYY'))"},  ${
    props.corpParent ? 'Account_ID' : 'Site_ID'
  }={"vReplaceString"}>} distinct ID)`;
  const GROSS_SALES = `sum({<R12={"C"},brand_flag={1}, ${
    props.corpParent ? 'Account_ID' : 'Site_ID'
  }={"vReplaceString"}>}[GNE 867 Dollar Sales])`;

  const site = {
    label: props.data.name,
    value: props.data.id,
    flag: props.data.flag,
  };
  const [filterExp, setFilterExp] = useState('');

  useEffect(() => {
    if (key === TABS.affiliations) {
      const filterExp = `{${site.value}}`;
      handleFilterApply(filterExp);
      resetAffiliationOptions();
    } else {
    }
  }, [key]);

  useEffect(() => {
    getAffilicationHcp();
    getAffilicationContact();
    getEngagements();
    getGrossSalesR12();
  }, []);

  const resetAffiliationOptions = () => {
    try {
      dispatch(setHCPName([]));
      dispatch(setHCPCity([]));
      dispatch(setHCPSpeciality([]));
      dispatch(setHCPStakeholderType([]));
      dispatch(setHCPState([]));
      dispatch(setHCPZip([]));
      setResetFilters(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilterApply = async (filterExp) => {
    setFilterExp(filterExp);
    dispatch(
      getAffiliationsDataSite(
        props.account.value,
        0,
        null,
        qlikCookie,
        qlikToken,
        null,
        null,
        filterExp,
        props.corpParent
      )
    );
  };

  const handleViewSite = () => {
    const site = {
      label: props?.data?.name,
      value: props.data?.id,
      flag: props.data?.flag,
      zip: props?.data?.address?.substring(
        props?.data?.address?.length - 5,
        props?.data?.address?.length
      ),
    };
    dispatch(setSeletedView(ACCOUNT_TYPE.site));
    dispatch(setSelectedAccount(site));
    window.open(`/customerView/list/${props?.data?.name}`, '_blank');
  };

  const handleViewAccount = () => {
    const account = {
      label: props?.account?.label,
      value: props.account.value,
    };
    dispatch(setSeletedView(ACCOUNT_TYPE.account));
    dispatch(setSelectedAccount(account));
    window.open(`/customerView/list/${props?.account?.label}`, '_blank');
  };

  const getAffilicationHcp = async () => {
    setHcpCountLoader(true);
    let response = await getQlikSenseData(
      AFFILIATION_INFO_EXPRESSIONS.expression.replace(
        REPLACE_STRING,
        AFFL_HCP_EXP
      ),
      null,
      process.env.REACT_APP_AFFILIATION_APP_ID
    );

    setHcpCount(response?.data?.data?.category[0]?.replaceAll("'", ' '));
    setHcpCountLoader(false);
  };

  const getAffilicationContact = async () => {
    setAffiliationContactLoader(true);
    let response = await getQlikSenseData(
      AFFILIATION_INFO_EXPRESSIONS.expression.replace(
        REPLACE_STRING,
        AFFL_CONTACT
      ),
      null,
      process.env.REACT_APP_AFFILIATION_APP_ID
    );

    setAffiliationContact(
      response?.data?.data?.category[0]?.replaceAll("'", ' ')
    );
    setAffiliationContactLoader(false);
  };

  const getEngagements = async () => {
    setEngagementsCountLoader(true);
    let response = await getQlikSenseData(
      ENGAGEMENT_COUNT.replace(REPLACE_STRING, props?.data?.id),
      null,
      process.env.REACT_APP_CRM_APP_ID
    );

    setEngagementsCount(
      response?.data?.data?.category[0]?.replaceAll("'", ' ')
    );
    setEngagementsCountLoader(false);
  };

  const getGrossSalesR12 = async () => {
    setGrossSalesR12Loader(true);
    let response = await getQlikSenseData(
      GROSS_SALES.replace(REPLACE_STRING, props?.data?.id),
      null,
      process.env.REACT_APP_EABI_APP_ID
    );

    setGrossSalesR12(response?.data?.data?.category[0]?.replaceAll("'", ' '));
    setGrossSalesR12Loader(false);
  };

  // html modal title
  const title = (
    <p className='title__large title__bold d-flex align-items-center'>
      <i
        className='icon icon__info-big'
        title='Information'
      />{' '}
      Information
    </p>
  );

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const content = (
    <Tabs
      id='customeHierarchyActions'
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className='mb-3'>
      <Tab
        eventKey={TABS.info}
        title={<span className='d-flex'>Info</span>}>
        <SiteInformation
          data={props.data}
          affiliationContact={affiliationContact}
          hcpCount={hcpCount}
          engagementsCount={engagementsCount}
          grossSalesR12={grossSalesR12}
          account={props.account}
          onSiteNameClick={handleViewSite}
          onAccountClick={handleViewAccount}
          hcpCountLoader={hcpCountLoader}
          affiliationContactLoader={affiliationContactLoader}
          grossSalesR12Loader={grossSalesR12Loader}
          engagementsCountLoader={engagementsCountLoader}
        />
      </Tab>

      <Tab
        eventKey={TABS.affiliations}
        title={
          <span
            className='d-flex ms-3'
            title={`${capitalize(TABS.affiliations)}`}>
            Affiliations
          </span>
        }>
        <Row>
          <Col
            lg={12}
            md={12}
            className='ps-0'>
            <Button
              variant={`btn btn-gne btn-secondary float-right ${
                showFilter ? 'active' : ''
              } ${classes.filter}`}
              onClick={handleFilter}>
              {/* show filter icon if no filter is selected */}
              <i
                className={`icon ${
                  showFilter ? 'icon__filter-active' : 'icon__filter'
                }`}></i>
              Filters
            </Button>
            {showFilter && (
              <SiteInfoFilters
                account={props.account}
                site={site}
                onFilterApply={(filterExp) => handleFilterApply(filterExp)}
                isFilterReset={resetFilters}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col
            lg={12}
            md={12}>
            {/* TODO refactor to use its own configurable table */}
            <SiteAffiliations
              columns={SITE_INFO_AFFILIATIONS_COLUMNS}
              filterExp={filterExp}
              enableTitle={false}
              corpParent={props.corpParent}
              tableType={'info'}
            />
          </Col>
        </Row>
      </Tab>
    </Tabs>
  );

  const footer = (
    <div className='d-flex justify-content-end w-100'>
      <LinkButton
        className='text text__small'
        onClick={props.onHide}>
        Close
      </LinkButton>
    </div>
  );

  return (
    <GNEModal
      titlecontent={title}
      show={props.show}
      onHide={props.onHide}
      content={content}
      footer={footer}
      backdrop='static'
      size='xl'
    />
  );
};

export default InfoSitesLayout;
