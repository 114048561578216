import { useState } from 'react';
import classes from './Comments.module.scss';
import { Drawer, List, Spin, Typography } from 'antd';
import LinkButton from 'components/shared/button/LinkButton';
import { useSelector } from 'react-redux';
import {
  DATE_FORMAT,
  LOGTYPE,
  CHANGEACTIONTYPE,
  CHANGEVALUETYPE,
} from 'constants/constants';
import moment from 'moment';
import ContextPopover from 'components/shared/context-menu/ContextPopover';
import { deleteComment } from 'services/comments.service';
import EditComment from './EditComment';
import { addLogs } from 'services/org/org.service';
const { Paragraph } = Typography;

const Comments = ({ comments, isLoading, onDeleteComment, onEditComment }) => {
  const { unixId } = useSelector((state) => state.Auth.userClaimObject);
  const [expandedComments, setExpandedComments] = useState([]);
  const [commentsData, setCommentsData] = useState(comments);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCommentId, setSelectedCommentId] = useState(null);
  const [selectedCommentDetails, setSelectedCommentDetails] = useState(null);
  const [editDrawerOpen, setEditDrawerOpen] = useState(false);

  const selectedAccount = useSelector(
    (state) => state?.CustomerView?.selectedAccount
  );
  const addActionLog = async () => {
    const payload = [
      {
        LogType: LOGTYPE.userlog,
        ViewChangeType: CHANGEACTIONTYPE.hierarchy,
        ImpactedMdmId: selectedCommentId,
        ValueType: CHANGEVALUETYPE.parent,
        OldValue: selectedCommentId,
        NewValue: '',
        Action: 'comments',
        Flag: '',
        isActive: 'Y',
        ActionDetails: `removed comment from ${selectedCommentDetails?.name}`,
        unixId: unixId,
        customerId: selectedAccount?.value,
      },
    ];
    await addLogs(payload);
  };

  const onDelete = async () => {
    try {
      const response = await deleteComment(selectedCommentId, unixId);
      let updatedComments = [];
      setAnchorEl(null);
      if (response) {
        onDeleteComment();
        updatedComments = commentsData.filter(
          (item) => item.id !== selectedCommentId
        );
        setCommentsData(updatedComments);
        addActionLog();
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const toggleExpand = (commentId) => {
    if (expandedComments.includes(commentId)) {
      setExpandedComments(expandedComments.filter((id) => id !== commentId));
    } else {
      setExpandedComments([...expandedComments, commentId]);
    }
  };

  const contextContent = (
    <>
      <div
        className='menu-item'
        onClick={() => openEditCommentDrawer()}>
        <i className={`icon icon__edit-metric ${classes['edit-icon']}`}></i>
        <LinkButton className={classes['context-menu-button']}>Edit</LinkButton>
      </div>
      <div
        className='menu-item'
        onClick={() => onDelete()}>
        <i className='icon icon__delete'></i>
        <LinkButton className={classes['context-menu-button']}>
          Delete
        </LinkButton>
      </div>
    </>
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleContextClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedCommentId(item.id);
    setSelectedCommentDetails(item);
  };

  const openEditCommentDrawer = () => {
    setAnchorEl(null);
    setEditDrawerOpen(true);
  };

  const onCloseEditDrawer = () => {
    setEditDrawerOpen(false);
  };

  return (
    <>
      <Spin spinning={isLoading}>
        <List
          itemLayout='vertical'
          size='large'
          dataSource={comments}
          renderItem={(item, index) => (
            <List.Item
              key={item?.name}
              className={`classes['comment-list-item']`}
              title={item?.name}
              description={item?.comment}>
              <div className={`${classes.commentListTitle}`}>
                {' '}
                {item?.name}{' '}
                <div className={`${classes.commentType}`}>
                  <span className='title__xx-small'>{item?.type}</span>
                </div>
                {unixId.toLowerCase() === item?.unixId.toLowerCase() && (
                  <div
                    className={`ms-auto ${classes['icon-wrap']}`}
                    onClick={(e) => handleContextClick(e, item)}>
                    <i className='icon icon__card-toggle text-end'></i>
                  </div>
                )}
                <ContextPopover
                  anchor={anchorEl}
                  onHandleClose={handleClose}
                  content={contextContent}
                />
              </div>
              <div style={{ overflow: 'hidden' }}>
                {item?.comment?.length > 100 ? (
                  <>
                    <div>
                      <Paragraph
                        ellipsis={
                          expandedComments.includes(item.id)
                            ? false
                            : { rows: 3 }
                        }>
                        {expandedComments.includes(item.id)
                          ? item?.comment
                          : item?.comment.slice(0, 100) + '...'}
                      </Paragraph>
                      {item?.comment?.length > 100 && (
                        <LinkButton
                          className={`${classes.viewMoreLink} text text__small`}
                          onClick={() => toggleExpand(item.id)}>
                          {expandedComments.includes(item.id)
                            ? 'View Less'
                            : 'View More'}
                        </LinkButton>
                      )}
                    </div>
                  </>
                ) : (
                  <span> {item?.comment}</span>
                )}

                <div className={`${classes.commentFooter}`}>
                  <span>{moment(item?.updateDate).format(DATE_FORMAT)}</span>
                  <span className={`${classes.commentAuthor}`}>
                    {item?.userName}
                  </span>
                </div>
              </div>
            </List.Item>
          )}
        />
      </Spin>

      <Drawer
        title='Edit Comment'
        placement='right'
        onClose={onCloseEditDrawer}
        // headerStyle={headerStyle}
        width={480}
        className={`${classes.addListDrawer}`}
        open={editDrawerOpen}>
        <EditComment
          closeDrawer={onCloseEditDrawer}
          selectedComment={selectedCommentDetails}
          onEditComment={onEditComment}
          editDrawerOpen={editDrawerOpen}
        />
      </Drawer>
    </>
  );
};

export default Comments;
