/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  menuClasses,
  ProSidebarProvider,
} from 'react-pro-sidebar';

import SearchBar from '../search-bar/SearchBar';
import classes from './KPISideBar.module.scss';
import { CONFIGURE_VIEW } from 'constants/constants';
import { Spin } from 'antd';

const KPISideBar = ({
  kpiLibData,
  handleFilter,
  filteredData,
  selectedKpi,
  handleClick,
  handleDimensionClick,
  dimensionName,
  page,
}) => {
  const isExtra =
    selectedKpi?.filterEnable?.channelNameEnable === 'Y' ||
    selectedKpi?.filterEnable?.templateNameEnable === 'Y' ||
    selectedKpi?.filterEnable?.surveyTypeEnable === 'Y' ||
    selectedKpi?.filterEnable?.keyMessageEnable === 'Y';

  const [kpiContainerHeight, setKpiContainerHeight] = useState(0);
  const advanceButtonValue = useSelector(
    (state) => state?.HomeMyView?.advanceButtonValue
  );

  let myViewHeight = isExtra ? 575 : 505;
  let insightViewHeight = isExtra ? 580 : 520;
  let viewHeight =
    page === CONFIGURE_VIEW.myView ? myViewHeight : insightViewHeight;
  const chartData = useSelector((state) => state?.HomeMyView?.chartData);

  const KPIselectedStatus = useSelector(
    (state) => state?.HomeMyView?.KPIselectedStatus
  );

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [advanceButtonValue, selectedKpi]);

  const handleResize = () => {
    const height = window.innerHeight;
    let containerHeight = 0;

    if (advanceButtonValue) {
      containerHeight = 100 * ((viewHeight + 71) / height);
    } else {
      containerHeight = 100 * (viewHeight / height);
    }
    setKpiContainerHeight(containerHeight);
  };

  return (
    <>
      <Spin spinning={KPIselectedStatus}>
        <ProSidebarProvider>
          <div className='kpi-sidebar'>
            <Sidebar
              backgroundColor='var(--color-light-brown) !important'
              rootStyles={{
                color: 'var(--color-black)',
                fontFamily: 'var(--font-gene-sans)',
                height: '100%',
                fontSize: 'var(--font-size-small)',
                transition: 'all 0.5s ease-out',
              }}>
              <Menu
                renderExpandIcon={({ open }) => {
                  return (
                    true && (
                      <>
                        {open ? (
                          <i
                            className={`${classes['nav-link-icon']} icon icon__kpi-collapse`}></i>
                        ) : (
                          <i
                            className={`${classes['nav-link-icon']} icon icon__kpi-expand`}></i>
                        )}
                      </>
                    )
                  );
                }}>
                <MenuItem
                  key='searchBar'
                  rootStyles={{
                    ['.' + menuClasses.button]: {
                      backgroundColor: 'var(--color-light-brown) !important',
                      paddingRight: '10px !important',
                      fontWeight: 'bold !important',
                      transition: 'all 0.5s ease-out',
                    },
                  }}>
                  <SearchBar
                    data={kpiLibData}
                    filterKey='KpiName'
                    onSearch={handleFilter}
                  />
                </MenuItem>

                <div
                  className={classes['library-container']}
                  style={{ height: kpiContainerHeight + 'vh' }}>
                  {filteredData?.map((item, index) => {
                    const kpiItem = item;
                    return !item?.dimensions ? (
                      <MenuItem
                        active={item?.KpiId === selectedKpi?.KpiId}
                        onClick={() => handleClick(item)}
                        key={index}
                        rootStyles={{
                          ['.' + menuClasses.button]: {
                            backgroundColor: 'var(--color-light-brown)',
                            paddingRight: '10px !important',
                            fontWeight: 'bold !important',
                            '&:hover': {
                              backgroundColor:
                                'var(--color-light-brown-2) !important',
                            },
                          },
                          ['.' + menuClasses.label]: {
                            fontWeight: 'bold !important',
                          },
                        }}>
                        {item?.KpiName}
                      </MenuItem>
                    ) : (
                      <SubMenu
                        onClick={() => handleClick(item)}
                        defaultOpen={
                          selectedKpi?.KpiId !== undefined
                            ? item?.KpiId === selectedKpi?.KpiId
                            : index === 0
                        }
                        label={item?.KpiName}
                        key={item?.KpiName + index}
                        rootStyles={{
                          ['& > .' + menuClasses.button]: {
                            paddingRight: '10px !important',
                            backgroundColor:
                              'var(--color-light-brown) !important',
                            fontWeight: 'bold !important',
                            '&:hover': {
                              backgroundColor:
                                'var(--color-tertiary-blue) !important',
                              color: 'var(--color-white) !important',
                              transition: 'all 0.5s ease-out',
                            },
                          },
                          ['.' + menuClasses.label]: {
                            textOverflow: 'initial',
                          },
                          ['.' + menuClasses.subMenuContent]: {
                            backgroundColor:
                              'var(--color-light-brown) !important',
                            color: 'var(--color-black) !important',
                            transition: 'all 1s ease-out',
                          },
                          ['.' + menuClasses.button]: {
                            color: 'var(--color-black) !important',
                          },
                          ['& > .' + menuClasses.icon]: {
                            backgroundImage:
                              "url('./icon_menu_engagement.png') !important",
                            width: '26px',
                            height: ' 26px',
                          },
                        }}
                        component='div'>
                        {item?.dimensions &&
                          item?.dimensions?.map((item, index) => {
                            return (
                              <MenuItem
                                key={kpiItem?.KpiId + index}
                                onClick={() =>
                                  handleDimensionClick(
                                    kpiItem,
                                    item?.dimensionName
                                  )
                                }
                                active={
                                  kpiItem?.KpiId === selectedKpi?.KpiId &&
                                  item?.dimensionName === dimensionName
                                }
                                rootStyles={{
                                  ['.' + menuClasses.button]: {
                                    backgroundColor: 'var(--color-gray-2)',
                                    '&:hover': {
                                      backgroundColor:
                                        'var(--color-tertiary-blue) !important',
                                      color: 'var(--color-white) !important',
                                      transition: 'all 0.5s ease-out',
                                    },
                                  },
                                  ['.' + menuClasses.active]: {
                                    backgroundColor:
                                      'var(--color-tertiary-blue) !important',
                                    color: 'var(--color-white) !important',
                                    transition: 'all 0.5s ease-out',
                                  },
                                }}>
                                {item?.dimensionName}
                              </MenuItem>
                            );
                          })}
                      </SubMenu>
                    );
                  })}
                </div>
              </Menu>
            </Sidebar>
          </div>
        </ProSidebarProvider>
      </Spin>
    </>
  );
};

export default KPISideBar;
