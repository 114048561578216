import React, { useState } from 'react';
import GNEModal from 'components/shared/modal/Modal';
import classes from './accountmodal.module.scss';
import { addPlanData } from 'store/customer/customer-view-actions';
import { toast } from 'react-toastify';
import { Button, Col, Form } from 'react-bootstrap';
import LinkButton from 'components/shared/button/LinkButton';

const AddAccount = (props) => {
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const [titleError, setTitleError] = useState('');
  const [urlError, setUrlError] = useState('');
  const [validated, setValidated] = useState(false);

  const onCancel = (e) => {
    e.preventDefault();
    setValidated(false);
    props.onHide();
  };

  const handleTitleChange = (e) => {
    const value = e.target.value;
    setTitle(value);
    if (value?.trim().length === 0) {
      setTitleError('Please provide a title.');
    } else {
      setTitleError('');
    }
  };

  const handleUrlChange = (e) => {
    const value = e.target.value;
    setUrlError(!validateUrl(value) ? 'Please provide a valid url.' : '');
    setUrl(value);
  };

  const validateUrl = (value) => {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value
    );
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    if (form.checkValidity() === false || !validateUrl(url)) {
      if (title.trim().length === 0) {
        setTitleError('Please provide a title.');
      }

      if (url.trim().length === 0 || !validateUrl(url)) {
        setUrlError('Please provide a valid url.');
      }
      setValidated(true);
    } else {
      let response = await addPlanData(
        props.customerId,
        url,
        title,
        props.userName
      );
      if (response === 200) {
        toast.success(
          `${props.view ? 'Account' : 'Site'} plan link successfully added`
        );
        props.onSave();
      } else {
        toast.error('Operation failed.');
      }

      setTitle('');
      setUrl('');
      setTitleError('');
      setUrlError('');
      setValidated(false);
      props.onHide();
    }
  };

  const modalTitle = (
    <p className='d-flex align-items-center gap-1 ml-5'>
      <i className='icon icon__addTitle me-1 mb-3' />
      <p className='text text__text-medium text__bold'>
        Add {props.view === true ? 'Account' : 'Site'} Plan
      </p>
    </p>
  );

  const modalContent = (
    <Form
      className='ms-3'
      noValidate
      onSubmit={handleSubmit}>
      <div>
        <Form.Group
          as={Col}
          controlId='validationCustom01'>
          <Form.Label className='text text__small'>Title</Form.Label>
          <Form.Control
            required
            type='text'
            placeholder='Title'
            value={title}
            onChange={handleTitleChange}
            autoComplete='off'
            isInvalid={titleError.length > 0}
            isValid={title.length > 0 && titleError.length === 0}
          />
          <Form.Control.Feedback
            className='animate__animated animate__pulse'
            type='invalid'>
            {titleError}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group
          as={Col}
          controlId='validationCustom03'
          className='mt-4'>
          <Form.Label className='text text__small'>Url</Form.Label>
          <Form.Control
            type='text'
            placeholder='https://example.com'
            required
            value={url}
            onChange={handleUrlChange}
            isInvalid={urlError.length > 0}
            isValid={url.length > 0 && urlError.length === 0}
            autoComplete='off'
          />
          <Form.Control.Feedback
            className='animate__animated animate__pulse'
            type='invalid'>
            {urlError}
          </Form.Control.Feedback>
        </Form.Group>
      </div>
      <div className={`mt-4 ${classes['text-content']}`}>
        <p className='d-flex align-items-center mt-2 text text__small ms-2 me-1'>
          <i className='icon icon__info-icon-yellow ' />
          Prior to uploading this {props.view ? 'account' : 'site'} plan, please
          ensure all compliance guidelines have been followed and the document
          permissions are set to "View" access for anyone at Roche in the file's
          settings.
         </p>
      </div>
      <div className='d-flex justify-content-end mt-4 mb-2'>
        <LinkButton
          onClick={onCancel}
          className='text text__small  me-2'>
          Cancel
        </LinkButton>
        <Button
          variant='btn btn-gne btn-primary'
          type='submit'
          className={` ${classes['btn-add']}`}>
          Add
        </Button>
      </div>
    </Form>
  );

  return (
    <GNEModal
      show={props.show}
      onHide={() => {
        setValidated(false);
        props.onHide();
      }}
      titlecontent={modalTitle}
      content={modalContent}
      css={`size-medium`}
      backdrop='static'
    />
  );
};

export default AddAccount;
