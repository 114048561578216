import React, { useEffect, useState } from 'react';
import GNEModal from 'components/shared/modal/Modal';
import classes from './accountmodal.module.scss';
import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { Table } from 'antd';
import { Popconfirm } from 'antd';
import LinkButton from 'components/shared/button/LinkButton';
import { DATE_FORMAT } from 'constants/constants';
import moment from 'moment';
import {
  deletePlanData,
  updatePlanData,
} from 'store/customer/customer-view-actions';
import { toast } from 'react-toastify';
import { Fade } from 'react-awesome-reveal';

const AccountDetails = (props) => {
  const [currentData, setCurrentData] = useState(props.data?.currentPlan);
  const [previousData, setPreviousData] = useState(props.data?.previousPlan);
  const [editableText, setEditableText] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  const [ACCOUNTDETAILS_COLUMN, setACCOUNTDETAILS_COLUMN] = useState([]);
  const COLUMN = [
    {
      id: 1,
      title: 'Name',
      key: 'name',
      dataIndex: 'docName',
      render: (_, record) => (
        <OverlayTrigger
          key={`overlay-${record.docName}`}
          placement='bottom'
          overlay={
            <Tooltip id={`tooltip-${record.docName}`}>{record.docName}</Tooltip>
          }>
          <span
            className='text text__small text__light-blue'
            style={{
              display: 'block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              cursor: 'pointer',
            }}
            onClick={() => openUrl(record.docUrl)}>
            {record.docName}
          </span>
        </OverlayTrigger>
      ),
    },
    {
      id: 2,
      title: 'Uploaded Date',
      dataIndex: 'updatedDate',
      key: 'updated',
      render: (text) => <span> {moment(text).format(DATE_FORMAT)}</span>,
      sorter: (a, b) => moment(a.updatedDate) - moment(b.updatedDate),
      defaultSortOrder: 'descend',
    },
    {
      id: 3,
      title: 'Uploaded By',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      sorter: (a, b) => a.updatedBy.localeCompare(b.updatedBy),
    },
    {
      id: 4,
      key: 'delete',
      title: '',
      dataIndex: 'delete',
      width: '8%',
      render: (_, record) => (
        <Popconfirm
          disabled={props.disableAdd}
          title='Confirm Deletion?'
          style={{ marginRight: '50px' }}
          okText='Yes'
          onConfirm={() => handleDelete(record.docId)}
          cancelText='No'>
          <i className={`icon icon__delete`} />
        </Popconfirm>
      ),
    },
  ];
  useEffect(() => {
    setCurrentData(props.data?.currentPlan);
    setPreviousData(props.data?.previousPlan);
    if (props.disableAdd) {
      setACCOUNTDETAILS_COLUMN(COLUMN.filter((val) => val.key !== 'delete'));
    } else {
      setACCOUNTDETAILS_COLUMN(COLUMN);
    }
  }, [props]);

  const scrollConfig = { y: 200 };
  const handleDelete = async (record) => {
    let response = await deletePlanData(record);
    if (response === 200) {
      let previousPlan = previousData.filter((item) => item.docId !== record);
      setPreviousData(previousPlan);
      props.onDelete({ currentPlan: currentData, previousPlan });
      toast.success(
        `${props.view ? 'Account' : 'Site'} plan successfully deleted`
      );
    } else {
      toast.error(`Operation failed`);
    }
  };
  const openModal = () => {
    props.open();
    setIsEditing(false);
    props.onHide();
  };
  const modalTitle = (
    <p className='title title__large title__bold d-flex align-items-center'>
      <i
        className='icon icon__link-title '
        title='Account Plan'
      />
      {props.view === true ? 'Account' : 'Site'} Plan
      {!props.disableAdd && (
        <Button
          disabled={props.disableAdd}
          variant='btn btn-gne btn-outline-primary btn-gne__outline btn-gne__outline--add ms-2'
          onClick={openModal}>
          Add New
        </Button>
      )}
    </p>
  );

  const handleInputChange = (e) => {
    setEditableText(e.target.value);
  };
  const saveData = async () => {
    let response = await updatePlanData(
      currentData[0]?.docId,
      props.userName,
      editableText
    );
    if (response === 200) {
      toast.success(
        `${props.view ? 'Account' : 'Site'} plan title successfully updated`
      );
      let datatable = { ...currentData };
      datatable[0].docName = editableText;
      setCurrentData(datatable);
    } else {
      toast.error('Operation failed');
    }
  };

  const handleSaveClick = async () => {
    setIsEditing(false);
    setEditableText(editableText);
    saveData();
  };
  const hide = () => {
    props.onHide();
    setIsEditing(false);
  };
  const handleedite = () => {
    setIsEditing(true);
    setEditableText(currentData[0]?.docName);
  };
  const openUrl = (url) => {
    window.open(url, '_blank');
  };

  const modalContent = (
    <>
      <Row className={`${classes['text-content']}  mt-3`}>
        <Col
          md={3}
          className={`d-flex justify-content-center align-items-center text text__bold text__normal p-0`}>
          Current Plan
        </Col>

        {isEditing ? (
          <Col
            md={9}
            className='p-0 mt-3'>
            <div
              className={`d-flex justify-content-center  ${classes['input-custom']} p-0 `}>
              <Form.Control
                type='text'
                value={editableText}
                placeholder={editableText}
                className={`text text__normal ${classes['form-control']} w-65 `}
                onChange={(e) => handleInputChange(e)}
              />
              <LinkButton
                onClick={() => {
                  setIsEditing(false);
                }}
                className={classes['reset-icon']}>
                <i className='icon icon__reset-disabled-account-plan' />
              </LinkButton>
              <LinkButton
                onClick={handleSaveClick}
                disabled={editableText.length === 0}
                className='d-inline-block p-0 '>
                {editableText.length === 0 ? (
                  <i className='icon icon__configure-checkbackground-account-plan-disabled' />
                ) : (
                  <i className='icon icon__configure-checkbackground-account-plan' />
                )}
              </LinkButton>
            </div>
            <p className={`ps-2 text  text__x-small me-2 text__grey `}>
              {currentData[0]?.docUrl}
            </p>
          </Col>
        ) : (
          <Col md={9}>
            <span
              className={`title title__normal title__blue ${classes['url-content']}`}
              onClick={() => openUrl([currentData[0]?.docUrl])}>
              {currentData[0]?.docName}
            </span>
            {!props.disableAdd && (
              <i
                className='ms-1 icon icon__configureEditTile '
                onClick={handleedite}
              />
            )}
          </Col>
        )}
      </Row>
      <div className='mt-1 d-inline-block'>
        <span className='text text__bold text__x-small text__black '>
          Uploaded Date:
        </span>
        <span className='ps-1 text  text__x-small me-2 text__black'>
          {moment(currentData[0]?.updatedDate).format(DATE_FORMAT)}
        </span>
        <span className='text text__bold text__x-small text__black'>
          Uploaded By:
        </span>
        <span className='ps-1 text  text__x-small text__black'>
          {currentData[0]?.updatedBy}
        </span>
      </div>

      <div className='mt-4'>
        <p className='title title__normal title__bold'>Previous Plan</p>
        <Fade cascade>
          <Table
            columns={ACCOUNTDETAILS_COLUMN}
            dataSource={previousData}
            pagination={false}
            scroll={scrollConfig}
            className='custom-table gne-table'
          />
        </Fade>
      </div>

      <span
        className='d-flex mt-4 justify-content-end'
        title='footer'>
        <LinkButton
          className='text text__small'
          onClick={() => {
            props.onHide();
            setIsEditing(false);
          }}>
          Close
        </LinkButton>
      </span>
    </>
  );

  return (
    <GNEModal
      show={props.show}
      onHide={hide}
      titlecontent={modalTitle}
      content={modalContent}
      css='size-medium'
      backdrop='static'
    />
  );
};

export default AccountDetails;
