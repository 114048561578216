/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { wrapMenuList } from 'react-select-async-paginate';

import {
  ACCOUNT_TYPE,
  ALL,
  INSIGHTS_BOARD_ACCOUNT_PLACEHOLDER_TEXT,
  VIEW,
} from 'constants/constants';

import { setFocusAccountChecked } from 'store/customer/customerViewSlice';

import { setFocusAccountChecked as setTrendsFocusedChecked } from 'store/home/homeMyViewSlice';

import {
  formatOptionLabel,
  getArrayValues,
  ValueContainer,
  dropdownOptions,
} from 'utils/utils';

import classes from './Account.module.scss';
import GNECheckbox from 'components/shared/checkbox/Checkbox';
import MultiSelectDropdown from 'components/home/configure-my-view/MultiSelectDropdown';
import { loadOptions } from './utils';
import GNEAsyncPagination from 'components/shared/dropdown-with-pagination/GNEAsyncPagination';
import GNESingleSelect from 'components/shared/dropdown/GNESingleSelect';

const Account = ({
  cssClass,
  onAccountSelected,
  showPriorityAccount,
  isMyPriority,
  priorityTabView,
  customerView,
  viewName,
  isFocusedChecked,
  isMulti,
  isDisabled,
  isClearable,
  clearDependencies,
  viewMode,
  selected,
}) => {
  const dispatch = useDispatch();
  const { unixId } = useSelector((state) => state.Auth.userClaimObject);

  const [options, setOptions] = useState([]);
  const [searchOptions, setsearchOptions] = useState([]);

  const [prevEcosystem, setPrevEcosystem] = useState([]);
  const [offset, setOffset] = useState(100);
  const shouldLoadMore = useRef(false);
  const firstLoading = useRef(true);
  const selectedAccount = useSelector(
    (state) => state?.CustomerView?.selectedAccount
  );
  const selectedTrendsAccount = useSelector(
    (state) => state?.HomeMyView?.selectedAccount
  );
  const allAccountOptions = useSelector(
    (state) => state?.CustomerView?.allAccountOptions
  );

  const accountOptions = useSelector(
    (state) => state?.HomeMyView?.allAccountOptions
  );

  const focusAccountChecked = useSelector(
    (state) => state?.CustomerView?.focusAccountChecked
  );

  const focusAccountCheckedTrends = useSelector(
    (state) => state?.HomeMyView?.focusAccountChecked
  );

  const selectedEcosystem = useSelector(
    (state) => state?.HomeMyView?.selectedEcosystem
  );

  const selectedEcosystemLabels = JSON.stringify(
    getArrayValues(selectedEcosystem).split(',')
  );

  useEffect(() => {
    if (customerView || isFocusedChecked) {
      return;
    }

    // we are using 2 different stores, customer view and home view
    // so we need to determine which store to update
    if (viewName === VIEW.trends && viewMode !== 'edit') {
      if (accountOptions) {
        dispatch(setTrendsFocusedChecked(accountOptions?.length !== 0));
      }
    } else if (viewName !== VIEW.trends) {
      dispatch(setFocusAccountChecked(allAccountOptions?.length !== 0));
    }
  }, [allAccountOptions]);

  const shouldLoadMoreOptions = (scrollHeight, clientHeight, scrollTop) => {
    if (firstLoading.current) {
      firstLoading.current = false;
      return true;
    }
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    const loadMore = bottomBorder < scrollTop;
    shouldLoadMore.current = loadMore;

    return loadMore;
  };

  const [dropdownAction, setDropdownAction] = useState('');
  const loadDropdownOptions = async (searchQuery, loadedOptions, firstLoad) => {
    try {
      const ecosystem = getEcosystem();
      const selected = getSelected();

      const dataOffset =
        firstLoad === true
          ? 0
          : firstLoading.current
          ? 0
          : !shouldLoadMore.current
          ? 0
          : offset;

      const { data, hasMore, currentListSize } = await loadOptions(
        dataOffset,
        options,
        selected === 'All' ? [] : selected,
        ACCOUNT_TYPE.account,
        ecosystem,
        prevEcosystem,
        searchQuery,
        shouldLoadMore.current,
        unixId,
        isMyPriority,
        dropdownAction,
        searchOptions
      );

      // we need to keep track of the change so we can
      // determine if we need to fetch new data
      if (prevEcosystem.toString() !== ecosystem.toString()) {
        setPrevEcosystem(ecosystem);
      }
      //Set Regular Options
      if (!searchQuery) {
        setOptions(data);
      } else {
        //Set Searched Data Options
        setsearchOptions(data);
      }

      if (hasMore) {
        setOffset((prevValue) => dataOffset + currentListSize);
      }

      return {
        options: data,
        hasMore: hasMore,
      };
    } catch (error) {
      console.log('error', error);
    }
  };

  const accountFocusAction = (value) => {
    setPriorityAccount(value?.target?.checked);
  };

  const setPriorityAccount = (value) => {
    dispatch(setFocusAccountChecked(value));
  };

  const getFocus = () => {
    return viewName === VIEW.trends
      ? focusAccountCheckedTrends
      : focusAccountChecked;
  };

  const getSelected = () => {
    return viewName === VIEW.trends
      ? selectedTrendsAccount
      : selected
      ? selected
      : selectedAccount || {};
  };

  const getEcosystem = () => {
    return viewName === VIEW.trends && selectedEcosystem?.length > 0
      ? selectedEcosystemLabels
      : [];
  };

  const getOptions = () => {
    return viewName === VIEW.trends ||
      (priorityTabView === VIEW.teamPOC && allAccountOptions?.length === 0)
      ? accountOptions
      : allAccountOptions || [];
  };

  const configPriority = priorityTabView === VIEW.teamPOC ? true : false;
  return (
    <>
      {/* account dropdown here */}
      <div
        className={viewName !== VIEW.trends ? 'd-flex align-items-center' : ''}>
        {(getFocus() && !isMyPriority) || configPriority ? (
          <>
            {viewName !== VIEW.trends || configPriority ? (
              <GNESingleSelect
                value={getSelected()}
                onChange={onAccountSelected}
                options={getOptions()}
                placeholder={INSIGHTS_BOARD_ACCOUNT_PLACEHOLDER_TEXT}
                className={`single-select ${classes['account-dropdown']} ${
                  cssClass ? cssClass : ''
                }`}
                formatOptionLabel={(data, { context }) =>
                  formatOptionLabel(data, { context })
                }
              />
            ) : (
              <MultiSelectDropdown
                options={!accountOptions ? [] : accountOptions}
                selected={
                  selectedTrendsAccount === 'All' ? [] : selectedTrendsAccount
                }
                setSelected={onAccountSelected}
                hasSelectAll={false}
                page={viewName}
              />
            )}
          </>
        ) : (
          <>
            <GNEAsyncPagination
              value={getSelected()}
              isDisabled={isDisabled}
              defaultOptions
              loadOptions={loadDropdownOptions}
              getOptionValue={(option) => option?.value}
              getOptionLabel={(option) => option?.label}
              formatOptionLabel={(data, { context }) =>
                formatOptionLabel(data, { context })
              }
              onChange={onAccountSelected}
              isMulti={isMulti}
              components={
                isMulti && {
                  ValueContainer: ValueContainer,
                  Option: wrapMenuList(dropdownOptions),
                }
              }
              placeholder={
                viewName === VIEW.trends
                  ? isMulti
                    ? ALL
                    : 'Select Account'
                  : INSIGHTS_BOARD_ACCOUNT_PLACEHOLDER_TEXT
              }
              containerCss={cssClass}
              className={`single-select ${classes['account-dropdown']} ${cssClass}`}
              closeMenuOnSelect={!isMulti}
              isClearable={isClearable}
              cacheUniqs={[getSelected(), clearDependencies]}
              shouldLoadMore={shouldLoadMoreOptions}
              onDropdownAction={(action) => {
                setDropdownAction(action);
              }}
            />
          </>
        )}

        {showPriorityAccount && (
          <div className={classes['priority-checkbox']}>
            <GNECheckbox
              checked={
                viewName === VIEW.trends
                  ? focusAccountCheckedTrends
                  : focusAccountChecked
              }
              onChange={accountFocusAction}
              disabled={allAccountOptions?.length === 0 && !focusAccountChecked}
              label='Priority Accounts'
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Account;
