/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, memo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ListManager } from 'react-beautiful-dnd-grid';

import Tile from './Tile';
import classes from './Tiles.module.scss';
import DefaultMessage from '../message/DefaultMessage';
import {
  getCardsData,
  getDefultViewCard,
  updateCardPositions,
} from 'store/home/home-my-view-actions';
import { setCards } from 'store/home/homeMyViewSlice';
import {
  CARD_POISTIONS_ERROR_MESSAGE,
  CONFIGURE_VIEW,
  areas,
} from 'constants/constants';
import { Spinner } from 'components/shared/loader/Spinner';
import { toast } from 'react-toastify';
import { Fade } from 'react-awesome-reveal';
import { isEmpty } from 'lodash';

const TileContainer = memo(({ page, activeTab = '' }) => {
  const dispatch = useDispatch();
  const { unixId } = useSelector((state) => state.Auth.userClaimObject);
  const { roleId } = useSelector((state) => state.Auth.userClaimObject);
  const cards = useSelector((state) => state?.HomeMyView?.cards);
  const defaultCards = useSelector((state) => state?.HomeMyView?.defaultCards);
  const [showViewMore, setViewMore] = useState(false);
  const [showViewMoreDefault, setShowViewMoreDefault] = useState(false);
  const shouldLoad = useRef(true);
  const hasCards = useRef(true);
  const hasDefaultCard = useRef(true);
  const shouldLoadDefault = useRef(true);
  const userRestrictedRole = useSelector(
    (state) => state?.Auth?.userRestriction
  );
  const userRestrictedEcosystemt = useSelector(
    (state) => state?.Auth?.userRestrictedEcosystemt
  );
  const restrictedProductSquad = useSelector(
    (state) => state?.Auth?.restrictedProductSquad
  );
  useEffect(() => {
    if (shouldLoad.current && activeTab !== 'myDefault' && isEmpty(cards)) {
      dispatch(
        getCardsData(
          unixId,
          roleId,
          userRestrictedRole,
          userRestrictedEcosystemt,
          restrictedProductSquad
        )
      );
      shouldLoad.current = false;

      setTimeout(() => {
        hasCards.current = false;
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (activeTab === 'myDefault' && isEmpty(defaultCards)) {
      dispatch(
        getDefultViewCard(
          unixId,
          userRestrictedRole,
          userRestrictedEcosystemt,
          restrictedProductSquad,
          page === CONFIGURE_VIEW.myDefault ? roleId : ''
        )
      );
      shouldLoadDefault.current = false;

      setTimeout(() => {
        hasDefaultCard.current = false;
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (cards?.length > 6 && !showViewMore) {
      setViewMore(true);
    } else {
      setViewMore(false);
    }
  }, [cards]);

  useEffect(() => {
    if (defaultCards?.length > 6 && !showViewMoreDefault) {
      setShowViewMoreDefault(true);
    } else {
      setShowViewMoreDefault(false);
    }
  }, [defaultCards]);

  const getCards = () => {
    return !showViewMore
      ? cards
      : cards.slice(0, cards?.length >= 6 ? 6 : cards?.length);
  };

  const getDefaultCard = () => {
    return !showViewMoreDefault
      ? defaultCards
      : defaultCards.slice(
          0,
          defaultCards?.length >= 6 ? 6 : defaultCards?.length
        );
  };

  const sortList = async (list, payload) => {
    const updatedCards = list
      .slice()
      .sort((first, second) => first.positionIndex - second.positionIndex);

    try {
      const response = await updateCardPositions(payload);
      dispatch(setCards(updatedCards));
      toast.success(response.message);
    } catch (err) {
      toast.error(CARD_POISTIONS_ERROR_MESSAGE);
    }
  };

  const reorderList = (sourceIndex, destinationIndex) => {
    if (destinationIndex === sourceIndex || page === CONFIGURE_VIEW.myDefault) {
      return;
    }

    let cardData = [...cards];
    let list = [];
    const payload = {
      cards: [
        {
          cardId: cards[sourceIndex].cardId,
          positionId: cards[destinationIndex].positionIndex,
        },
        {
          cardId: cards[destinationIndex].cardId,
          positionId: cards[sourceIndex].positionIndex,
        },
      ],
    };
    for (const card of cardData) {
      if (cardData.indexOf(card) === sourceIndex) {
        list.push({
          ...card,
          positionIndex: cards[destinationIndex].positionIndex,
        });
      } else if (cardData.indexOf(card) === destinationIndex) {
        list.push({ ...card, positionIndex: cards[sourceIndex].positionIndex });
      } else {
        list.push({ ...card });
      }
    }
    sortList(list, payload);
  };

  const ListElement = ({ item: card, area }) => {
    return (
      <Col
        sm={12}
        md={12}>
        <Tile
          card={card}
          key={card.cardId}
          page={page}
          area={area}
        />
      </Col>
    );
  };

  return (
    <>
      {((page === CONFIGURE_VIEW.myView && cards?.length > 0) ||
        (page === CONFIGURE_VIEW.myDefault && defaultCards?.length > 0)) && (
        <div className={classes['tile-container-drag-drop-container']}>
          <Row className='list-manager'>
            <ListManager
              items={
                page === CONFIGURE_VIEW.myDefault
                  ? getDefaultCard()
                  : getCards()
              }
              direction='horizontal'
              maxItems={2}
              render={(item) => (
                <ListElement
                  item={item}
                  area={
                    page === CONFIGURE_VIEW.myView
                      ? areas.home
                      : areas.defaultView
                  }
                />
              )}
              onDragEnd={reorderList}
            />
          </Row>
        </div>
      )}
      {page === CONFIGURE_VIEW.myDefault && showViewMoreDefault ? (
        <Row>
          <Col>
            <div
              className={classes.viewMoreButton}
              onClick={() => setShowViewMoreDefault(false)}>
              View More <i className='icon icon__view-more'></i>
            </div>
          </Col>
        </Row>
      ) : showViewMore && page !== CONFIGURE_VIEW.myDefault ? (
        <Row>
          <Col>
            <div
              className={classes.viewMoreButton}
              onClick={() => setViewMore(false)}>
              View More <i className='icon icon__view-more'></i>
            </div>
          </Col>
        </Row>
      ) : (
        ''
      )}

      {hasCards.current && <Spinner area={areas.home} />}
      {cards?.length === 0 && page !== CONFIGURE_VIEW.myDefault && (
        <Fade>
          <DefaultMessage />
        </Fade>
      )}
      {page !== CONFIGURE_VIEW.myDefault ? (
        <div className={classes['disclaimer-text']}>
          Disclaimer:
          <em>
            This information is shared for transparency and coordination. Not
            all information is necessary or appropriate for all roles; focus on
            information that is critical for your role and only use data
            consistent with your role description and within the HCO policies,
            procedures, and business guidance applicable to your role.
          </em>
        </div>
      ) : (
        ''
      )}
    </>
  );
});

export default TileContainer;
