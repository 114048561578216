import React, { useState } from 'react';
import classes from './RoleSelection.module.scss';
import { Button, Col, Row } from 'react-bootstrap';
import Radio from '@mui/material/Radio';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  setUserClaimObject,
  setWrapperObj,
  setFavReportObj,
  setComponentAccessList,
  setRestrictedEcosystem,
  setUserRestriction,
  setRestrictedProductSquad,
} from 'store/auth/Authslice';
import {
  setDefaultCard,
  setCards,
  setEcosystemOptions,
} from 'store/home/homeMyViewSlice';
import { fetchQlikToken } from 'store/auth/auth-actions';
import { getTableRestrictions } from 'store/customer/customer-view-actions';
import { Spin } from 'antd';
import {
  getSystemAlertsData,
  getFavoriteData,
} from 'store/home/home-my-view-actions';
import { setSystemAlertsData } from 'store/home/homeMyViewSlice';

const RoleSelection = () => {
  let userClaimObject = useSelector((state) => state.Auth.userClaimObject);
  const roles = useSelector((state) => state?.Auth?.roles);

  const [selectedRole, setSelectedRole] = useState(userClaimObject?.roleId);
  const [errorMsg, setErrorMsg] = useState('');
  const [btnDisabled, setBtnDisabled] = useState(false);
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const handleRoleSelection = (roleCode) => {
    setSelectedRole(roleCode);
    setErrorMsg('');
  };

  const getSystemAlerts = async (role) => {
    let data = await getSystemAlertsData(role);
    dispatch(setSystemAlertsData(data));
    data?.length ? navigation('/system-alerts') : navigation('/home');
  };

  const favoritesData = async (unixId, roleId) => {
    let favData = await getFavoriteData(unixId, roleId);
    dispatch(setFavReportObj(favData));
  };

  const continueClickHandler = () => {
    if (selectedRole === '') {
      setErrorMsg('Please select one role');
    } else {
      dispatch(setDefaultCard([]));
      dispatch(setCards([]));
      setBtnDisabled(true);
      let res = roles.filter((role) => role.roleCode === selectedRole);
      let roleData = res[0];
      userClaimObject = { ...userClaimObject, roleId: selectedRole };
      dispatch(setEcosystemOptions([]));

      dispatch(setRestrictedEcosystem(roleData?.restrictedEcosystem));
      dispatch(setUserRestriction(roleData?.roleRestriction));
      dispatch(setRestrictedProductSquad(roleData?.restrictionsDetails));
      dispatch(setUserClaimObject(userClaimObject));
      dispatch(setComponentAccessList(roleData?.componentAccessList));
      dispatch(setWrapperObj(roleData?.wrapperObj));
      favoritesData(userClaimObject?.unixId, userClaimObject?.roleId);
      dispatch(getTableRestrictions(selectedRole));

      if (process.env.REACT_APP_MAINTENANCE_IN_PROGRESS === 'true') {
        localStorage.clear();
        navigation('/maintenance');
      } else {
        // giving the dispatch time to get qlik token and store it
        // before we navigate to home
        setTimeout(() => {
          if (process.env.REACT_APP_SHOW_NOTIFICATION === 'true') {
            navigation('/notification');
          } else {
            getSystemAlerts(userClaimObject?.roleId);
          }
        }, 3000);
      }
    }
  };

  const generateRadioDiv = (roleCode, selectedRole, handleRoleSelection) => {
    return (
      <Col
        lg={12}
        key={roleCode}
        className={
          selectedRole === roleCode
            ? `${classes.role_div} ${classes.selected_role_div}`
            : `${classes.role_div}`
        }
        onClick={() => handleRoleSelection(roleCode)}>
        <Radio
          name='role'
          value={roleCode}
          checked={selectedRole === roleCode}
          onChange={() => handleRoleSelection(roleCode)}
          className='gne-radio'
        />
        <label className={`radio-label`}>{roleCode}</label>
      </Col>
    );
  };

  return (
    <div className={`d-flex flex-column align-items-center bg-white`}>
      <div className={`border-lightgrey border-2`}>
        <i
          className={`icon icon__logo animate__animated animate__rubberBand ${classes.img_width} `}></i>
      </div>

      <Row className={`justify-content-center mt-4 ${classes.row_width}`}>
        <Col
          lg={12}
          className={`mb-3 ${classes.hdr}`}>
          Choose Your Role:
        </Col>

        {roles.map((role) =>
          generateRadioDiv(role.roleCode, selectedRole, handleRoleSelection)
        )}

        {errorMsg && (
          <Col
            lg={12}
            className={`${classes.errorMsg}`}>
            {errorMsg}
          </Col>
        )}

        <Col
          lg={12}
          className={`mt-1 mb-4 text text__x-small ${classes.notes}`}>
          <span className={`text text__bold`}>Note:</span> You can switch back
          to your other role from the
          <br />
          user menu located at the top right of the application.
        </Col>

        <Col
          lg={12}
          className={`text-center ${classes.position_rel}`}>
          <Spin
            spinning={btnDisabled}
            className={`${classes.spin}`}
          />
          <Button
            variant={`primary btn-gne btn-gne__outline w-50`}
            onClick={continueClickHandler}
            disabled={btnDisabled}>
            <span>Continue</span>
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default RoleSelection;
