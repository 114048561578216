import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Container } from 'react-bootstrap';

import classes from './Insights.module.scss';

import ConfigureMyView from 'components/home/configure-my-view/ConfigureMyView';
import TileContainer from '../insights/tiles/TileContainer';
import {
  getAllKpiAndDimension,
  getProductData,
} from 'store/customer/customer-view-actions';
import { CONFIGURE_VIEW } from 'constants/constants';

const Insights = () => {
  const dispatch = useDispatch();
  const { roleId } = useSelector((state) => state.Auth.userClaimObject);

  const selectedView = useSelector(
    (state) => state?.CustomerView?.selectedView
  );

  const selectedAccount = useSelector(
    (state) => state?.CustomerView?.selectedAccount
  );

  const field = selectedView === 'site' ? 'Account' : null;
  useEffect(() => {
    if (!Array.isArray(selectedAccount)) {
      dispatch(getProductData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classes['insights-container']}
      id='Insights'>
      <div className={`no-overflow ${classes['insights-content']}`}>
        <ConfigureMyView page={CONFIGURE_VIEW.insights} />
        <DndProvider backend={HTML5Backend}>
          <TileContainer page={CONFIGURE_VIEW.insights} />
        </DndProvider>
      </div>
    </div>
  );
};
export default Insights;
