import React, { useEffect, useState, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import GNESingleSelect from 'components/shared/dropdown/GNESingleSelect';
import classes from './AdvanceFilter.module.scss';
import { isArray } from 'utils/utils';
import {
  ADVANCE_FILTER,
  COMPARE_BENCHMARK,
  COMPARE_KPI,
  COMPARE_TIME_PERIOD,
  DIMENSION,
} from 'constants/constants';
import MultiSelectDropdown from 'components/home/configure-my-view/MultiSelectDropdown';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAdvanceOptions,
  setSelectedCardView,
} from 'store/home/homeMyViewSlice';
import {
  setAdvanceOptions as setInsightAdvanceOptions,
  setSelectedCardView as setCardView,
} from 'store/customer/customerViewSlice';
import { loadDropdownOptions } from './chart.service';

const AdvanceFilter = ({ reducerName }) => {
  const [placeholder, setPlaceholder] = useState('Add Dimension');

  const [multiKpiOptions, setMultiKpiOptions] = useState([]);
  const firstLoad = useRef(true);

  const selectedDropdownValue = useSelector(
    (state) => state?.[reducerName]?.advanceOptions?.selectDropdownOption
  );
  const compareByType = useSelector(
    (state) => state?.[reducerName]?.advanceOptions?.compareBy
  );

  const [loadInitialCompareOpts, setLoadInitialCompareOpts] = useState(true);

  const selectedAdvanceOptions = useSelector(
    (state) => state?.[reducerName]?.advanceOptions
  );
  const selectedKPI = useSelector((state) => state?.[reducerName]?.selectedKPI);
  const selectedTimePeriod = useSelector(
    (state) => state?.[reducerName]?.selectedTimePeriod
  );
  const dimensionName = useSelector(
    (state) => state?.[reducerName]?.dimensionName
  );
  const advanceDropdownOptions = useSelector(
    (state) => state?.[reducerName]?.advanceDropdownOptions
  );
  const KPIselectedStatus = useSelector(
    (state) => state?.HomeMyView?.KPIselectedStatus
  );

  let isMyView = reducerName === 'HomeMyView' ? true : false;

  const dispatch = useDispatch();
  //fisrt load
  useEffect(() => {
    if (loadInitialCompareOpts) {
      if (!selectedAdvanceOptions) {
        dispatch(
          isMyView
            ? setAdvanceOptions({ compareBy: DIMENSION })
            : setInsightAdvanceOptions({ compareBy: DIMENSION })
        );
        loadDropdownOptions(
          DIMENSION,
          dispatch,
          selectedKPI,
          selectedTimePeriod,
          dimensionName,
          reducerName
        );
      } else {
        loadDropdownOptions(
          selectedAdvanceOptions?.compareBy,
          dispatch,
          selectedKPI,
          selectedTimePeriod,
          dimensionName,
          reducerName,
          selectedAdvanceOptions
        );
      }
      setLoadInitialCompareOpts(false);
    }
  }, []);

  useEffect(() => {
    setMultiKpiOptions([]);
  }, [selectedTimePeriod?.label]);

  const setPlaceholderValue = (value) => {
    switch (value) {
      case COMPARE_TIME_PERIOD:
        setPlaceholder('Select Time Period');
        break;
      case DIMENSION:
        setPlaceholder('Select Dimension');
        break;
      case COMPARE_BENCHMARK:
        setPlaceholder('Select Benchmark');
        break;
      case COMPARE_KPI:
        setPlaceholder('Select KPI');
        break;
      default: {
        setPlaceholder('Select Option');
      }
    }
  };
  //on change of advance dropdown option
  useEffect(() => {
    if (selectedAdvanceOptions?.selectDropdownOption === '')
      setPlaceholderValue(selectedAdvanceOptions?.compareBy);
  }, [selectedAdvanceOptions?.selectDropdownOption]);

  const onChangeAdvanceCompareOption = (value) => {
    loadDropdownOptions(
      value,
      dispatch,
      selectedKPI,
      selectedTimePeriod,
      dimensionName,
      reducerName
    );
    setPlaceholderValue(value);
    let setOnTypeChange = {
      compareBy: value,
      selectDropdownOption: '',
    };

    dispatch(
      isMyView
        ? setAdvanceOptions(setOnTypeChange)
        : setInsightAdvanceOptions(setOnTypeChange)
    );
  };

  const handleDropdownSelection = (selected) => {
    let setOnDropdownOptChange = {
      ...selectedAdvanceOptions,
      selectDropdownOption: selected,
    };

    dispatch(
      isMyView
        ? setAdvanceOptions(setOnDropdownOptChange)
        : setInsightAdvanceOptions(setOnDropdownOptChange)
    );

    if (compareByType === COMPARE_KPI) {
      if (selected.length === 0) {
        setMultiKpiOptions([]);
      }
      if (selected.length >= 4) {
        firstLoad.current = false;
        setMultiKpiOptions(
          advanceDropdownOptions?.map((item) => ({
            ...item,
            disabled:
              selected.filter((sel) => sel.label === item.label)?.length > 0
                ? false
                : true,
          }))
        );
      } else {
        let isEditView =
          setOnDropdownOptChange?.view === 'edit' && firstLoad.current === true;

        let newOpts = [];

        if (isEditView) {
          newOpts = advanceDropdownOptions.map((val) => ({
            ...val,
            disabled: false,
          }));
        }

        if (!firstLoad.current) {
          newOpts = multiKpiOptions.map((val) => ({
            ...val,
            disabled: false,
          }));
          firstLoad.current = true;
        }
        if (newOpts?.length > 0) setMultiKpiOptions(newOpts);
      }
    }
  };

  const setTileView = (view) => {
    setMultiKpiOptions([]);
    dispatch(
      reducerName === 'HomeMyView'
        ? setSelectedCardView(view)
        : setCardView(view)
    );
  };

  return (
    <>
      <div className={`${classes['filter-container']} `}>
        <Row className='title__x-small'>
          <Col
            md={8}
            className={`title__x-small d-flex justify-content-space-between mt-2 ${classes['advance-radio-btn-ipad-align']}`}>
            <label
              className={`d-flex justify-content-start ${classes['Advance_radio_button']}`}>
              <input
                type='radio'
                name='advance'
                value={compareByType}
                className={classes['radio_trend']}
                onChange={() => {
                  onChangeAdvanceCompareOption(DIMENSION);
                  setTileView('chart');
                }}
                checked={compareByType === DIMENSION ? true : false}
                placeholder='Add Dimension'
              />{' '}
              <span className='ms-1 title__x-small'>Add Dimension</span>
            </label>

            <label
              className={`d-flex justify-content-start ${classes['Advance_radio_button']}`}>
              <input
                type='radio'
                name='advance'
                value={compareByType}
                className={classes['radio_trend']}
                onChange={() => {
                  onChangeAdvanceCompareOption(COMPARE_KPI);
                  setTileView('table');
                }}
                checked={compareByType === COMPARE_KPI ? true : false}
                placeholder='Select KPI'
              />{' '}
              <span className='ms-1 title__x-small'>Add KPI</span>
            </label>

            <label
              className={`d-flex justify-content-start ${classes['Advance_radio_button']}`}>
              <input
                type='radio'
                name='advance'
                value={compareByType}
                className={classes['radio_trend']}
                onChange={() => {
                  onChangeAdvanceCompareOption(COMPARE_BENCHMARK);
                  setTileView('chart');
                }}
                checked={compareByType === COMPARE_BENCHMARK ? true : false}
                placeholder='Select Benchmark'
              />
              <span className='ms-1 title__x-small'>Compare Benchmark</span>
            </label>

            <label
              className={`d-flex justify-content-start ${classes['Advance_radio_button']}`}>
              <input
                type='radio'
                name='advance'
                value={compareByType}
                checked={compareByType === COMPARE_TIME_PERIOD ? true : false}
                onChange={() => {
                  onChangeAdvanceCompareOption(COMPARE_TIME_PERIOD);
                  setTileView('chart');
                }}
                className={classes['radio_trend']}
              />{' '}
              <span className=' ms-1 title__x-small'>Compare Time Period</span>
            </label>
          </Col>
          <Col
            md={4}
            className={`mt-2 ${classes['advance-kpi-dropdown-ipad-align']}`}>
            {compareByType === COMPARE_KPI ? (
              <MultiSelectDropdown
                options={
                  multiKpiOptions.length > 0
                    ? multiKpiOptions
                    : advanceDropdownOptions
                }
                selected={
                  selectedDropdownValue?.[0] === undefined &&
                  !isArray(selectedDropdownValue?.label)
                    ? []
                    : isArray(selectedDropdownValue?.label)
                    ? selectedDropdownValue?.label
                    : selectedDropdownValue
                }
                setSelected={handleDropdownSelection}
                hasSelectAll={false}
                className=''
                page={ADVANCE_FILTER}
                loading={KPIselectedStatus}
              />
            ) : (
              <GNESingleSelect
                value={selectedDropdownValue}
                onChange={handleDropdownSelection}
                options={advanceDropdownOptions}
                placeholder={placeholder}
                containerCss={
                  'd-flex justify-content-right align-content-center'
                }
                className={`single-select ${classes.actionsDropdown}`}
              />
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AdvanceFilter;
