/* eslint-disable react-hooks/exhaustive-deps */
import { Menu } from 'antd';
import classes from './SectionMenu.module.scss';
import LinkButton from 'components/shared/button/LinkButton';
import { useEffect, useState, useRef } from 'react';
import DataModal from '../banner/DataModal';
import AccountDetails from '../account-plan/AccountDetails';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDarwinReports,
  getPlanData,
} from 'store/customer/customer-view-actions';
import AddAccount from '../account-plan/AddAccount';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toLower } from 'lodash';
import { setDarwinReportsData } from 'store/customer/customerViewSlice';

const SectionMenu = (props) => {
  const selections = props?.data;
  const dispatch = useDispatch();
  const [showAddPlan, setShowAddPlan] = useState(false);
  const [darwinReport, setDarwinReport] = useState(false);
  const selectedAccount = useSelector(
    (state) => state?.CustomerView?.selectedAccount
  );
  const darwinReportsData = useSelector(
    (state) => state?.CustomerView?.darwinReportsData
  );
  const componentAccessList = useSelector(
    (state) => state?.Auth?.componentAccessList
  );
  const { unixId } = useSelector((state) => state.Auth.userClaimObject);

  const contacts = useSelector((state) => state?.CustomerView?.contacts);

  const [showModal, setShowModal] = useState(false);
  const [showAccModal, setShowAccModal] = useState(false);
  const [planData, setPlanData] = useState([]);

  const qlikToken = localStorage.getItem('qlikToken');
  const qlikCookie = localStorage.getItem('qlikCookie');
  const scrollref = useRef(null);
  const [showLeftButton, setshowLeftButton] = useState(true);
  const [showRightButton, setshowRightButton] = useState(true);

  useEffect(() => {
    if (!Array.isArray(selectedAccount)) {
      getPlanDataDetails(selectedAccount?.value);
      setShowAddPlan([]);
    }
    dispatch(setDarwinReportsData([]));
  }, [selectedAccount]);
  useEffect(() => {
    setShowAddPlan(
      contacts
        ? contacts?.filter(
            (value) =>
              value.primayContactFlag === 'Y' &&
              toLower(value.unixId) === toLower(unixId)
          )
        : []
    );
  }, [contacts]);
  useEffect(() => {
    updateScrollButtons();
    const currentReff = scrollref.current;
    if (currentReff) {
      currentReff.addEventListener('scroll', updateScrollButtons);
    }
    return () => {
      if (currentReff) {
        currentReff.removeEventListener('scroll', updateScrollButtons);
      }
    };
  }, []);

  const scrollfun = (dir) => {
    if (scrollref.current) {
      const scrollAmount = dir === 'left' ? -200 : 200;
      scrollref.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const updateScrollButtons = () => {
    const { scrollLeft, scrollWidth, clientWidth } = scrollref.current;
    if (scrollref.current) {
      setshowLeftButton(scrollLeft > 0);
      setshowRightButton(scrollLeft < scrollWidth - clientWidth - 1.2);
    }
  };

  const getPlanDataDetails = async (customerId) => {
    let data = await getPlanData(customerId);
    let currentPlan = data.filter((plan) => {
      return plan?.isActive === 'Y';
    });
    let previousPlan = data.filter((plan) => plan?.isActive !== 'Y');
    setPlanData({ currentPlan, previousPlan });
  };
  const onDelete = (planDetails) => {
    setPlanData(planDetails);
  };
  const tooltip = () => {
    return (
      <Tooltip bsclass='gne-tooltip tooltip--left'>
        <span className='text text__x-small'>
          A plan for this
          {selections === 'account' ? ' account ' : ' site '}
          has not been added to date. If you are the primary point of contact
          for this
          {selections === 'account' ? ' account ' : ' site '}
          and would like to upload a plan, you must first designate yourself as
          the primary point of contact for this
          {selections === 'account' ? ' account ' : ' site '}
        </span>
      </Tooltip>
    );
  };
  const menuItems = [
    {
      key: 'insights',
      className: classes.menuItem,
      label: (
        <a
          className={`title title__bold title__small ${classes['item-link']}`}
          href='#Insights'>
          Insights
        </a>
      ),
    },
    {
      key: 'engagements',
      className: classes.menuItem,
      label: (
        <a
          className={`title title__bold title__small ${classes['item-link']}`}
          href='#Engagements'>
          Engagements
        </a>
      ),
    },
    ...(selections === 'account' &&
    componentAccessList?.includes('Site-Performance')
      ? [
          {
            key: 'sitePerformance',
            className: classes.menuItem,
            label: (
              <a
                className={`title title__bold title__small ${classes['item-link']}`}
                href='#SitePerformance'>
                Site Performance
              </a>
            ),
          },
        ]
      : []),
    ...(selections === 'site' && componentAccessList?.includes('Product-Mix')
      ? [
          {
            key: 'productMix',
            className: classes.menuItem,
            label: (
              <a
                className={`title title__bold title__small ${classes['item-link']}`}
                href='#ProductMix'>
                Product Mix
              </a>
            ),
          },
        ]
      : []),
    ...((selections === 'site' && componentAccessList?.includes('Payer-Mix')) ||
    (selections === 'account' &&
      componentAccessList?.includes('Payer-Mix-Account'))
      ? [
          {
            key: 'payerMix',
            className: classes.menuItem,
            label: (
              <a
                className={`title title__bold title__small ${classes['item-link']}`}
                href='#PayerMix'>
                Payer Mix
              </a>
            ),
          },
        ]
      : []),
    ...(componentAccessList?.includes('Site-Utilization')
      ? [
          {
            key: 'siteUtilization',
            className: classes.menuItem,
            label: (
              <a
                className={`title title__bold title__small ${classes['item-link']}`}
                href='#SiteUtilization'>
                Site Utilization & Demographics
              </a>
            ),
          },
        ]
      : []),
    ...(selections === 'account'
      ? [
          {
            key: 'affiliations',
            className: classes.menuItem,
            label: (
              <a
                className={`title title__bold title__small ${classes['item-link']}`}
                href='#Affiliations'>
                Affiliations
              </a>
            ),
          },
        ]
      : []),
    ...(selections === 'site'
      ? [
          {
            key: 'SiteAffiliations',
            className: classes.menuItem,
            label: (
              <a
                className={`title title__bold title__small ${classes['item-link']}`}
                href='#SiteAffiliations'>
                Affiliations
              </a>
            ),
          },
        ]
      : []),
    ...(componentAccessList?.includes('Copay-FRM')
      ? [
          {
            key: 'copayFRM',
            className: classes.menuItem,
            label: (
              <a
                className={`title title__bold title__small ${classes['item-link']}`}
                href='#copayFRMView'>
                Copay Analytics
              </a>
            ),
          },
        ]
      : []),
    ...(componentAccessList?.includes('Copay-HD')
      ? [
          {
            key: 'copayHD',
            className: classes.menuItem,
            label: (
              <a
                className={`title title__bold title__small ${classes['item-link']}`}
                href='#copayHDView'>
                Copay Analytics
              </a>
            ),
          },
        ]
      : []),
  ];
  return (
    <>
      <div className='section-container'>
        {showLeftButton && (
          <i
            className='icon icon__chevron-left-blue left-arrow'
            onClick={() => scrollfun('left')}></i>
        )}
        <div
          ref={scrollref}
          className='scroll-container'>
          <Menu
            mode='horizontal'
            className={`${classes['menuContainer']} left-sections animate__animated animate__fadeIn`}
            defaultSelectedKeys='insights'
            disabledOverflow={true}
            items={menuItems}></Menu>
        </div>
        {showRightButton && (
          <i
            className='icon icon__chevron-right-blue left-arrow mb-1'
            onClick={() => scrollfun('right')}></i>
        )}
        <div
          mode='horizontal'
          className={`${classes['menuContainer']} animate__animated animate__fadeIn right-sections mt-2 d-flex align-item-center justify-content-center`}>
          {componentAccessList?.includes('Account-Plan') &&
            planData?.currentPlan?.length > 0 && (
              <div
                className='ms-auto'
                key='AccountPlan'>
                <LinkButton
                  className='me-0'
                  onClick={() => setShowModal(true)}>
                  <i className=' ms-1 mb-4 ml-1 mt-2  icon icon__link_icon'></i>
                  <p className='title__bold title__small'>
                    {selections === 'account' ? 'Account' : 'Site'} Plan
                  </p>
                </LinkButton>
              </div>
            )}
          {componentAccessList?.includes('Account-Plan') &&
            planData?.currentPlan?.length === 0 && (
              <div
                className='ms-auto'
                key='AddAccountPlan'>
                <LinkButton
                  className={`me-0 ${
                    showAddPlan.length === 0 ? 'disabled' : ''
                  }`}
                  disabled={showAddPlan.length === 0}
                  onClick={() => setShowAccModal(true)}>
                  {showAddPlan.length === 0 ? (
                    <OverlayTrigger
                      key={`overlay-${selections}`}
                      placement='bottom'
                      overlay={tooltip()}>
                      <p className='title__bold title__small'>
                        <i
                          className={` ms-1  mt-2  icon  ${
                            showAddPlan.length === 0
                              ? 'icon__add-black'
                              : 'icon__add-blue'
                          }`}
                        />
                        Add {selections === 'account' ? 'Account' : 'Site'} Plan
                      </p>
                    </OverlayTrigger>
                  ) : (
                    <p className='title__bold title__small'>
                      <i
                        className={` ms-1  mt-3  icon  ${
                          showAddPlan.length === 0
                            ? 'icon__add-black'
                            : 'icon__add-blue'
                        }`}
                      />
                      Add {selections === 'account' ? 'Account' : 'Site'} Plan
                    </p>
                  )}
                </LinkButton>
              </div>
            )}

          <div
            key='darwin'
            className={
              !componentAccessList?.includes('Account-Plan') ? 'ms-auto' : ''
            }>
            <LinkButton
              className='me-0'
              onClick={() => {
                darwinReportsData.length === 0 &&
                  dispatch(
                    getDarwinReports(
                      selectedAccount?.value,
                      qlikCookie,
                      qlikToken
                    )
                  );
                setDarwinReport(true);
              }}>
              <i className=' ms-1 mb-4 ml-1 mt-1  icon icon__darwinReports'></i>
              <p className='title__bold title__small'>Darwin Reports</p>
            </LinkButton>
          </div>
        </div>
      </div>
      <DataModal
        data={darwinReportsData}
        show={darwinReport}
        onHide={() => setDarwinReport(false)}
      />
      {planData?.currentPlan?.length > 0 && (
        <AccountDetails
          show={showModal}
          open={() => setShowAccModal(true)}
          onHide={() => setShowModal(false)}
          data={planData}
          userName={showAddPlan[0]?.name}
          disableAdd={showAddPlan.length === 0 ? true : false}
          view={selections === 'account'}
          onDelete={onDelete}
        />
      )}
      <AddAccount
        show={showAccModal}
        onHide={() => setShowAccModal(false)}
        customerId={selectedAccount?.value}
        userName={showAddPlan[0]?.name}
        onSave={() => getPlanDataDetails(selectedAccount?.value)}
        view={selections === 'account'}
      />
    </>
  );
};

export default SectionMenu;
