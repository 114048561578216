import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedKPI,
  setDimensionName,
  setSelectedKpiId,
  setSelectedAppId,
  setChartType,
  setSelectedAppLink,
  setTimePeriods,
  setTimePeriodOptions,
  setSelectedTimePeriod,
  setDimensionId,
  setOldChartType,
  setKPIclickedStatus,
} from 'store/home/homeMyViewSlice';

import KPISideBar from 'components/shared/KPISidebar/KPISideBar';
import {
  getDimensionsTimePeriodOptions,
  getTimePeriodOptions,
  getTimePeriods,
} from 'services/trends.service';

const KPILibrary = ({ page }) => {
  const dispatch = useDispatch();
  let timePeriods = useSelector((state) => state?.HomeMyView?.timePeriod);

  const kpiLibData = useSelector(
    (state) => state?.HomeMyView?.kpiAndDimensions
  );

  const [filteredData, setFilteredData] = useState(kpiLibData);
  const selectedKpi = useSelector((state) => state?.HomeMyView?.selectedKPI);

  const dimensionName = useSelector(
    (state) => state?.HomeMyView?.dimensionName
  );

  useEffect(() => {
    setFilteredData(kpiLibData);
  }, [kpiLibData]);

  const handleFilter = (filteredData) => {
    setFilteredData(filteredData);
  };

  const handleClick = async (item) => {
    await handleKPIChanged(item);
    dispatch(setDimensionName(item.dimensions[0].dimensionName));
    dispatch(
      setDimensionId(
        item?.dimensions?.filter((onedim) => {
          if (onedim.dimensionName === dimensionName) return onedim.id;
        })
      )
    );
  };

  const loadTimePeriodFilter = async (item, dimensionName) => {
    let dimensionId = item?.dimensions.filter(
      (item) => item.dimensionName === dimensionName
    )?.[0]?.id;
    let isDimensionLevelExpression =
      item?.filterEnable?.EnableDimensionLevelExpression === 'Y';
    const timePeriodOptions = isDimensionLevelExpression
      ? await getDimensionsTimePeriodOptions(timePeriods, dimensionId)
      : await getTimePeriodOptions(timePeriods);
    dispatch(setTimePeriodOptions(timePeriodOptions));

    let exp = timePeriods.filter(
      (tp) => tp.uXDefaultValue === 'Y' && tp.dimensionId === dimensionId
    )[0];

    dispatch(
      setSelectedTimePeriod({
        label: exp.timePeriodName,
        value: exp.timePeriodId,
      })
    );
  };

  const handleDimensionClick = async (item, dimensionName) => {
    await handleKPIChanged(item, dimensionName);
    if (item?.filterEnable?.EnableDimensionLevelExpression === 'Y') {
      await loadTimePeriodFilter(item, dimensionName);
    }
    dispatch(
      setChartType(getChartTypeByDimension(item?.dimensions, dimensionName))
    );
    dispatch(
      setOldChartType(getChartTypeByDimension(item?.dimensions, dimensionName))
    );
    dispatch(setDimensionName(dimensionName));
    dispatch(
      setDimensionId(
        item?.dimensions?.filter((onedim) => {
          if (onedim.dimensionName === dimensionName) return onedim.id;
        })
      )
    );
  };

  const getChartTypeByDimension = (dimensions, dimensionName) => {
    const matchedDimension = dimensions.find(
      (dimension) => dimension.dimensionName === dimensionName
    );

    const barChart =
      matchedDimension.chartType === 'Bar Chart'
        ? 'bar'
        : matchedDimension.chartType === 'Line'
        ? 'line'
        : null;
    return barChart;
  };

  const handleKPIChanged = async (item) => {
    dispatch(setSelectedKPI(item));
    dispatch(setSelectedAppId(item.AppId));
    dispatch(setSelectedKpiId(item.KpiId));
    dispatch(setSelectedAppLink(item.appLink));
    if (item.KpiId !== selectedKpi.KpiId) {
      let dimensionId = item?.dimensions?.[0]?.id;
      let isDimensionLevelExpression =
        item?.filterEnable?.EnableDimensionLevelExpression === 'Y';

      timePeriods = await getTimePeriods(item.KpiId);
      dispatch(setTimePeriods(timePeriods));

      const timePeriodOptions = isDimensionLevelExpression
        ? await getDimensionsTimePeriodOptions(timePeriods, dimensionId)
        : await getTimePeriodOptions(timePeriods);

      dispatch(setTimePeriodOptions(timePeriodOptions));

      let exp = isDimensionLevelExpression
        ? timePeriods.filter(
            (tp) => tp.uXDefaultValue === 'Y' && tp.dimensionId === dimensionId
          )[0]
        : timePeriods.filter((tp) => tp.uXDefaultValue === 'Y')[0];

      dispatch(
        setSelectedTimePeriod({
          label: exp.timePeriodName,
          value: exp.timePeriodId,
        })
      );
    }
  };

  return (
    <KPISideBar
      kpiLibData={kpiLibData}
      handleFilter={handleFilter}
      filteredData={filteredData}
      selectedKpi={selectedKpi}
      handleClick={handleClick}
      handleDimensionClick={handleDimensionClick}
      dimensionName={dimensionName}
      page={page}
    />
  );
};

export default KPILibrary;
