import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import Chart from 'components/charts/Echart';
import moment from 'moment';
import LinkButton from 'components/shared/button/LinkButton';
import {
  formatLabel,
  getArrayLabelValues,
  getRestrictedProducts,
  getRestrictedSquads,
} from '../../../utils/utils';
import classes from './Tiles.module.scss';
import {
  CONFIGURE_VIEW,
  DATE_FORMAT_COMMA,
  ACCOUNT_TYPE,
} from 'constants/constants';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NoDimension from '../no-dimension/NoDimensions';
import TableView from 'components/charts/TableView';

const ExpandMyView = ({ card, page, ...props }) => {
  const { filter: filterData, chartData } = card;
  const {
    kpiName = '',
    dimensionName = '',
    selectedEcosystem = {},
    selectedTimePeriod = {},
    selectedAccount,
    selectedProduct,
    chartType = 'bar',
    focusAccountChecked,
    accountableProduct,
    viewType,
    selectedSquad = {},
    selectedSiteName,
    selectedKPI,
    selectedChannel,
    selectedDiscussionTopic,
    selectedStakeholderType,
    selectedTemplateName,
    selectedSurveyType,
    selectedPresentationName,
    selectedKeyMessage,
  } = filterData;

  const noDimensionValue =
    card?.chartData?.values?.length > 0 && !card?.chartData?.category[0]
      ? card?.chartData?.values[0]?.data?.[0]
      : card?.chartData?.category?.[0];
  const benchmarkNoDimensionValue =
    card?.chartData?.values?.length > 0 &&
    card?.chartData?.values[1]?.data?.[0] !== undefined
      ? card?.chartData?.values[1]?.data?.[0]
      : '';
  const userRestrictedRole = useSelector(
    (state) => state?.Auth?.userRestriction
  );
  const userRestrictedEcosystem = useSelector(
    (state) => state?.Auth?.userRestrictedEcosystemt
  );
  const restrictedProductSquad = useSelector(
    (state) => state?.Auth?.restrictedProductSquad
  );

  const isMyView = page === CONFIGURE_VIEW.myView ? true : false;

  let siteName = '';
  if (page === CONFIGURE_VIEW.insights) {
    let checkLength = selectedSiteName?.length;
    let checkValue = selectedSiteName === undefined;

    if (checkLength === 0 || checkValue) {
      siteName = 'All Sites';
    } else if (checkLength > 0) {
      siteName = getArrayLabelValues(selectedSiteName);
    }
  }

  let ecosystemName = '';
  if (
    isMyView &&
    userRestrictedRole?.isNational === 'N' &&
    selectedEcosystem?.length === 0 &&
    !focusAccountChecked
  ) {
    ecosystemName = getArrayLabelValues(userRestrictedEcosystem);
  } else {
    ecosystemName =
      focusAccountChecked ||
      (selectedKPI?.filterEnable?.accountEnable === 'N' &&
        selectedEcosystem?.length === 0)
        ? 'All Ecosystems'
        : selectedEcosystem?.length > 0
        ? getArrayLabelValues(selectedEcosystem)
        : 'All Ecosystems';
  }

  let squadNames = '';
  if (
    (isMyView || page === CONFIGURE_VIEW.myDefault) &&
    userRestrictedRole?.squadRestriction === 'Y' &&
    selectedSquad?.length === 0
  ) {
    squadNames = getRestrictedSquads(
      restrictedProductSquad,
      accountableProduct
    );
  } else {
    squadNames =
      selectedSquad?.length > 0
        ? getArrayLabelValues(selectedSquad)
        : 'All Squads';
  }

  let selectedProductNames = '';
  if (
    (isMyView || page === CONFIGURE_VIEW.myDefault) &&
    userRestrictedRole?.productRestrictions === 'Y' &&
    selectedProduct?.length === 0
  ) {
    selectedProductNames = getRestrictedProducts(
      restrictedProductSquad,
      accountableProduct
    );
  } else {
    selectedProductNames =
      selectedProduct?.length === 0 && accountableProduct
        ? 'All Accountable Products'
        : selectedProduct?.length === 0 && !accountableProduct
        ? 'All Products'
        : getArrayLabelValues(selectedProduct);
  }
  // eslint-disable-next-line  no-unused-vars
  const viewName =
    dimensionName === 'No Dimension'
      ? kpiName
      : `${kpiName} by ${dimensionName}`;
  const timePeriod = selectedTimePeriod?.label;

  const selectedAccountNames =
    page === CONFIGURE_VIEW.insights
      ? selectedAccount?.label
      : focusAccountChecked === true
      ? selectedAccount?.length === 0
        ? `All Priority ${viewType === 'account' ? 'Accounts' : 'Sites'}`
        : getArrayLabelValues(selectedAccount)
        ? getArrayLabelValues(selectedAccount)
        : `All Priority ${viewType === 'account' ? 'Accounts' : 'Sites'}`
      : selectedAccount?.length === 0
      ? 'All'
      : getArrayLabelValues(selectedAccount)
      ? getArrayLabelValues(selectedAccount)
      : 'All';

  const isValue = noDimensionValue > 0;
  const noData = chartData?.values?.length === 0 && !noDimensionValue;
  const isColor =
    kpiName?.slice(-3) === '(%)' || kpiName?.toLowerCase().includes('change');
  const showChart = dimensionName !== 'No Dimension' && chartData && !noData;

  return (
    <>
      <Modal
        {...props}
        size='xl'
        className={`title gne-modal ${props.css}`}>
        <div className={classes.expandMyViewModal}>
          <Modal.Header
            closeButton
            className={`modal-header border-0`}></Modal.Header>
          <Modal.Body className='show-grid p-0 m-0'>
            <Row className='mb-4'>
              <Col md={8}>
                <p className={classes.expandMyViewSidebarTitle}>{card.title}</p>
                <p className='sub-title sub-title__x-small text__grey'>
                  {card.timespan}
                </p>
                {dimensionName === 'No Dimension' && !noData && (
                  <div className={classes['no-dimension-container']}>
                    <NoDimension
                      isColor={isColor}
                      noDimensionValue={noDimensionValue}
                      benchmarkNoDimensionValue={benchmarkNoDimensionValue}
                      kpiName={kpiName}
                      isValue={isValue}
                    />
                  </div>
                )}

                {card.cardView !== 'table' && showChart && (
                  <Chart
                    chartData={chartData}
                    kpiName={kpiName}
                    chartType={chartType}
                    hashView={card.cardView === 'hash'}
                  />
                )}

                {card.cardView === 'table' && showChart && (
                  <div className={classes['scrollable-table-container']}>
                    <TableView
                      data={chartData}
                      kpiName={kpiName}
                      dimensionName={dimensionName}
                      page={'expand'}
                    />
                  </div>
                )}

                {noData && (
                  <div className={classes.iconalign}>
                    <i className='icon icon__no-data center-align-icon'></i>
                  </div>
                )}
              </Col>
              <Col md={4}>
                <div className={classes.expandMyViewSidebar}>
                  <h1 className={classes.expandMyViewSidebarHeading}>
                    Chart Details
                  </h1>
                  <div className={classes.expandMyViewSidebarScrollableDiv}>
                    <div className={classes.expandMyViewSidebarDiv}>
                      <p className={classes.expandMyViewSidebarLabel}>
                        {viewType === 'account' ? 'Account' : 'Site'}
                      </p>
                      <p className={classes.expandMyViewSidebarValue}>
                        {selectedAccountNames}
                      </p>
                    </div>
                    {page === CONFIGURE_VIEW.insights &&
                      viewType === ACCOUNT_TYPE.account && (
                        <div className={classes.expandMyViewSidebarDiv}>
                          <p className={classes.expandMyViewSidebarLabel}>
                            Site
                          </p>
                          <p className={classes.expandMyViewSidebarValue}>
                            {siteName}
                          </p>
                        </div>
                      )}
                    <div className={classes.expandMyViewSidebarDiv}>
                      <p className={classes.expandMyViewSidebarLabel}>
                        Ecosystem
                      </p>
                      <p className={classes.expandMyViewSidebarValue}>
                        {ecosystemName}
                      </p>
                    </div>

                    <div className={classes.expandMyViewSidebarDiv}>
                      <p className={classes.expandMyViewSidebarLabel}>
                        Product
                      </p>
                      <p className={classes.expandMyViewSidebarValue}>
                        {selectedProductNames}
                      </p>
                    </div>
                    <div className={classes.expandMyViewSidebarDiv}>
                      <p className={classes.expandMyViewSidebarLabel}>Squad</p>
                      <p className={classes.expandMyViewSidebarValue}>
                        {squadNames}
                      </p>
                    </div>

                    <div className={classes.expandMyViewSidebarDiv}>
                      <p className={classes.expandMyViewSidebarLabel}>
                        Time Period
                      </p>
                      <p className={classes.expandMyViewSidebarValue}>
                        {timePeriod}
                      </p>
                    </div>
                    {selectedKPI?.filterEnable?.channelNameEnable === 'Y' && (
                      <div className={classes.expandMyViewSidebarDiv}>
                        <p className={classes.expandMyViewSidebarLabel}>
                          Channel Name
                        </p>
                        <p className={classes.expandMyViewSidebarValue}>
                          {selectedChannel?.length > 0
                            ? getArrayLabelValues(selectedChannel)
                            : 'All Channels'}
                        </p>
                      </div>
                    )}
                    {selectedKPI?.filterEnable?.discussionTopicEnable ===
                      'Y' && (
                      <div className={classes.expandMyViewSidebarDiv}>
                        <p className={classes.expandMyViewSidebarLabel}>
                          Discussion Topic
                        </p>
                        <p className={classes.expandMyViewSidebarValue}>
                          {selectedDiscussionTopic?.length > 0
                            ? getArrayLabelValues(selectedDiscussionTopic)
                            : 'All Discussion Topics'}
                        </p>
                      </div>
                    )}
                    {selectedKPI?.filterEnable?.stakeholderTypeEnable ===
                      'Y' && (
                      <div className={classes.expandMyViewSidebarDiv}>
                        <p className={classes.expandMyViewSidebarLabel}>
                          Stakeholder Type
                        </p>
                        <p className={classes.expandMyViewSidebarValue}>
                          {selectedStakeholderType?.length > 0
                            ? getArrayLabelValues(selectedStakeholderType)
                            : 'All Stakeholder Types'}
                        </p>
                      </div>
                    )}
                    {selectedKPI?.filterEnable?.templateNameEnable === 'Y' && (
                      <div className={classes.expandMyViewSidebarDiv}>
                        <p className={classes.expandMyViewSidebarLabel}>
                          Template Name
                        </p>
                        <p className={classes.expandMyViewSidebarValue}>
                          {selectedTemplateName?.length > 0
                            ? getArrayLabelValues(selectedTemplateName)
                            : 'All Stakeholder Types'}
                        </p>
                      </div>
                    )}
                    {selectedKPI?.filterEnable?.surveyTypeEnable === 'Y' && (
                      <div className={classes.expandMyViewSidebarDiv}>
                        <p className={classes.expandMyViewSidebarLabel}>
                          Survey Type
                        </p>
                        <p className={classes.expandMyViewSidebarValue}>
                          {selectedSurveyType?.length > 0
                            ? getArrayLabelValues(selectedSurveyType)
                            : 'All Survey Types'}
                        </p>
                      </div>
                    )}
                    {selectedKPI?.filterEnable?.presentationNameEnable ===
                      'Y' && (
                      <div className={classes.expandMyViewSidebarDiv}>
                        <p className={classes.expandMyViewSidebarLabel}>
                          Presentation Name
                        </p>
                        <p className={classes.expandMyViewSidebarValue}>
                          {selectedPresentationName?.length > 0
                            ? getArrayLabelValues(selectedPresentationName)
                            : 'All Presentation Names'}
                        </p>
                      </div>
                    )}
                    {selectedKPI?.filterEnable?.keyMessageEnable === 'Y' && (
                      <div className={classes.expandMyViewSidebarDiv}>
                        <p className={classes.expandMyViewSidebarLabel}>
                          Key Message
                        </p>
                        <p className={classes.expandMyViewSidebarValue}>
                          {selectedKeyMessage?.length > 0
                            ? getArrayLabelValues(selectedKeyMessage)
                            : 'All Key Messages'}
                        </p>
                      </div>
                    )}

                    <div className={classes.expandMyViewSidebarDiv}>
                      <p className={classes.expandMyViewSidebarLabel}>
                        Created Date
                      </p>
                      <p className={classes.expandMyViewSidebarValue}>
                        {moment(card.createdDate).format(DATE_FORMAT_COMMA)}
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <hr />
          </Modal.Body>
          <Modal.Footer className={`modal-header border-0`}>
            <Link
              to={card.selectedAppLink}
              target='_blank'
              className='title title__small'>
              <i className='icon icon__external-blue' />
              Go To Dashboard
            </Link>
            <LinkButton
              onClick={props.onHide}
              className='title title__small'>
              Close
            </LinkButton>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};
export default ExpandMyView;
