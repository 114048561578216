export const customerViewInitialState = {
  selectedView: 'account',
  allAccountOptions: [],
  allSiteOptions: [],
  ecosystemOptions: [],
  productOptions: [],
  allProductOptions: [],
  squadOptions: [],
  allSquadOptions: [],
  timePeriodOptions: [],
  timePeriods: [],
  accountPayerLoader: false,
  sitePayerLoader: false,
  copayLoader: false,
  copayData: {},
  selectedAccount: [],
  selectedEcosystem: [],
  selectedProduct: [],
  selectedSquad: [],
  selectedTimePeriod: [],

  selectedSiteView: [],

  timePeriodName: 'Oct 21-Sep 22',
  focusAccountChecked: true,
  focusSiteChecked: true,
  isAccountAvailable: true,
  viewName: 'Gross Sales by Account',
  kpiId: 1,
  kpiName: 'Gross Sales',
  dimesnionId: 2,
  dimensionName: '',
  noDimensionValue: '',
  benchmarkNoDimensionValue: '',
  chartType: 'linechart',
  oldChartType: '',
  chartData: [],
  tableData: [],
  cards: [],
  kpiAndDimensions: [],
  selectedKpiId: 1,
  selectedKpiName: '',
  selectedKpi: {},
  selectedAppLink: '',
  expression: '',
  showSuccess: false,
  custInsightsBrdId: '',
  insightType: '',
  customerViewButtonText: 'Add to Insight Boards',
  customerViewButtonDisabled: true,
  accountDetails: {},
  insightBoards: [],
  contacts: [],
  crmScores: {},
  crmPercent: {
    nps: 0,
    sat: 0,
    partner: 0,
  },
  beamChart: {},
  beamChartSite: {},
  payerChart: {},
  surveyTotal: {
    nps: '',
    sat: '',
    partner: '',
  },
  uses: null,
  crmQuarter: '',
  affiliated: '',
  selectedAccountId: 0,
  selectedAppId: 1,
  sitePerformanceFilters: {
    Site_Performance_SiteName_Address: {
      options: [],
      selectedValue: [],
    },
    Product: {
      options: [],
      selectedValue: [],
    },
    Ecosystem: {
      options: [],
      selectedValue: [],
    },
    contract_segment_Filter: {
      options: [],
      selectedValue: [],
    },
    SiteSquad: {
      options: [],
      selectedValue: [],
    },
    IsAccountableProduct: true,
  },
  siteUtilizationFilters: {
    SiteUtilization_Ecosystem: {
      options: [],
      selectedValue: [],
    },
    SiteUtilization_SiteName_Address: {
      options: [],
      selectedValue: [],
    },
    SiteUtilization_SiteCity: {
      options: [],
      selectedValue: [],
    },
    SiteUtilization_SiteState: {
      options: [],
      selectedValue: [],
    },
    SiteUtilization_Site_Address: {
      options: [],
      selectedValue: [],
    },
  },
  configureInsightsFilters: {
    Product: {
      options: [],
      selectedValue: [],
    },
    SiteSquad: {
      options: [],
      selectedValue: [],
    },
    Ecosystem: {
      options: [],
      selectedValue: [],
    },
    SiteName_Address: {
      options: [],
      selectedValue: [],
    },
    IsAccountableProduct: true,
  },

  siteInfoFilters: {
    HCPName: {
      options: [],
      selectedValue: [],
    },
    HCPStakeholderType: {
      options: [],
      selectedValue: [],
    },
    Speciality: {
      options: [],
      selectedValue: [],
    },
    City: {
      options: [],
      selectedValue: [],
    },
    State: {
      options: [],
      selectedValue: [],
    },
    Zip: {
      options: [],
      selectedValue: [],
    },
  },

  affiliationsFilters: {
    SiteName_Address: {
      options: [],
      selectedValue: [],
    },
    SiteState: {
      options: [],
      selectedValue: [],
    },
    SiteHIN: {
      options: [],
      selectedValue: [],
    },
    SiteAddress: {
      options: [],
      selectedValue: [],
    },
    HCPStakeholderTypes: {
      options: [],
      selectedValue: [],
    },
    HCPNames: {
      options: [],
      selectedValue: [],
    },
    HCPAddress: {
      options: [],
      selectedValue: [],
    },
    SiteSpeciality: {
      options: [],
      selectedValue: [],
    },
  },

  productMixFilters: {
    Product: {
      options: [],
      selectedValue: [],
    },
    SiteSquad: {
      options: [],
      selectedValue: [],
    },
    IsAccountableProduct: true,
  },

  payerMixFilters: {
    ParentPayer: {
      options: [],
      selectedValue: [],
    },
    Payer: {
      options: [],
      selectedValue: [],
    },
    BookOfBusiness: {
      options: [],
      selectedValue: [],
    },
    Benifit: '',
    Distribution: '',
  },
  AccountPayerMixFilters: {
    ParentPayer: {
      options: [],
      selectedValue: [],
    },
    Ecosystem: {
      options: [],
      selectedValue: [],
    },
    Product: {
      options: [],
      selectedValue: [],
    },
    Payer: {
      options: [],
      selectedValue: [],
    },
    BookOfBusiness: {
      options: [],
      selectedValue: [],
    },
    EstimatedPayerSale: {
      options: [],
      selectedValue: [],
    },
    EstimatedContributionToAccount: {
      options: [],
      selectedValue: [],
    },
    BenefitType: {
      options: [],
      selectedValue: [],
    },
    Benifit: '',
    Distribution: '',
  },
  copayAnalyticsFilters: {
    copayEcosystem: {
      options: [],
      selectedValue: [],
    },
    copaySiteAddressName: {
      options: [],
      selectedValue: [],
    },
    copayQuarter: {
      options: [],
      selectedValue: [],
    },
    copayProduct: {
      options: [],
      selectedValue: [],
    },
    copaySquad: {
      options: [],
      selectedValue: [],
    },
  },

  engagementFilters: {
    StakeholderName: {
      options: [],
      selectedValue: [],
    },
    StakeholderType: {
      options: [],
      selectedValue: [],
    },
    Engagement: {
      options: [],
      selectedValue: [],
    },
    Brand: {
      options: [],
      selectedValue: [],
    },
    Squad: {
      options: [],
      selectedValue: [],
    },
    Indication: {
      options: [],
      selectedValue: [],
    },
    GNE_User_Name: {
      options: [],
      selectedValue: [],
    },
    GENE_User_Role: {
      options: [],
      selectedValue: [],
    },
    EngagementEcosystem: {
      options: [],
      selectedValue: [],
    },
  },
  marketingFilters: {
    Channel: {
      options: [],
      selectedValue: [],
    },
    DiscussionTopic: {
      options: [],
      selectedValue: [],
    },
    PayerStakeholderType: {
      options: [],
      selectedValue: [],
    },

    TemplateName: {
      options: [],
      selectedValue: [],
    },
    SurveyType: {
      options: [],
      selectedValue: [],
    },
    PresentationName: {
      options: [],
      selectedValue: [],
    },
    KeyMessage: {
      options: [],
      selectedValue: [],
    },
  },
  columnsRestiction: {},
  excelColumnsRestiction: [],
  payerCustomColumns: ['ParentPayer', 'Payer', 'BookOfBusiness'],
  accountPayerCustomColumn: [
    'Parent_Payer',
    'Payer_Name',
    'Book_of_Business',
    'Benefit_Type',
  ],
  copayCustomColumn: ['Product', 'Site_ID', 'site_zip'],
  affiliationsData: {},
  affiliationsSiteData: {},
  sitePerformanceData: {},
  copayAnalyticsData: {},
  payerMiXData: {},
  accountMiXData: {},
  productMixData: {},
  timespan: '',
  hcpFlag: false,
  darwinReportsData: [],
  exportSiteUtilizaztion: false,
  exportAccountpayermix: false,
  exportCopayAnalytics: false,
  exportAffiliation: false,
  exportSite: false,
  payerExpression: '',
  copayExpression: '',
  appUsers: [],
  payertimeSpan: '',
  notificationEmailStatus: false,
  notifyUsersByEmailList: [],
  advanceOptions: '',
  advanceDropdownOptions: [],
};
