import React from 'react';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { Col, Container, Row } from 'react-bootstrap';

import Layout from 'components/layout/Layout';
import TrendsContainer from './TrendsContainer';

const Trends = () => {
  return (
    <ProSidebarProvider>
      <Layout showRighSideBar={false}>
        <Container className='gne-container customer-container overflow-hidden'>
          <Row>
            <Col
              md={4}
              className='mb-2'>
              <i className='icon icon__trends--black'></i>
              <span className='title__bold title__medium'>Trends</span>
            </Col>
          </Row>
          <TrendsContainer
            buttonText={'Add to My View'}
            view='add'
          />
        </Container>
      </Layout>
    </ProSidebarProvider>
  );
};

export default Trends;
