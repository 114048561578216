import React from 'react';
import { useSelector } from 'react-redux';

import styles from './UserAvatar.module.scss';

function UserAvatar() {
  const { name } = useSelector((state) => state.Auth.userClaimObject);

  // const signOut = () => {
  //   console.log('Ready to Integrate');
  // };

  return (
    <div className={styles.userInfo}>
      <div className={styles.userName}>
        <i className='icon icon__user_avatar'></i>
        {name}

        {/* <Dropdown drop='start'>
          <Dropdown.Toggle
            variant='success'
            style={{
              background: 'none',
              boxShadow: 'none',
              border: 'none',
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 0,
              paddingBottom: '5px',
            }}
            className={styles.dropdownToggle}>
            <i className='icon icon__nav-user-dropdown'></i>
          </Dropdown.Toggle>
          <Dropdown.Menu className={styles.dropdownMenu}>
            <Dropdown.Item onClick={() => signOut()}>
              <i className='icon icon__expand_view'></i>Sign out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
      </div>
    </div>
  );
}

export default UserAvatar;
