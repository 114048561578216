/* eslint-disable react-hooks/exhaustive-deps */
import classes from './Engagements.module.scss';
import EngagementFilters from './EngagementFilters';
import { useLayoutEffect, Suspense, lazy, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Fade } from 'react-awesome-reveal';
import uuid from 'react-native-uuid';

import EngagementPill from './EngagementPill';

import {
  REPLACE_STRING,
  CRM_APP_ID,
  ENGAGEMENT_EXPRESSIONS,
} from 'constants/constants';
import { getEngagementMetrics } from 'store/customer/timeline/timeline-view-actions';
import { buildEngagementExpression } from 'utils/utils';
import { Spinner } from 'components/shared/loader/Spinner';
import { setSelectedEngagement } from 'store/customer/customerViewSlice';

const Timeline = lazy(() => import('../engagements/Timeline'));

const Engagements = () => {
  const dispatch = useDispatch();

  const selectedAccount = useSelector(
    (state) => state?.CustomerView?.selectedAccount
  );
  const selectedView = useSelector(
    (state) => state?.CustomerView?.selectedView
  );
  const selectedEcosystem = useSelector(
    (state) => state?.CustomerView?.engagementFilters?.EngagementEcosystem
  );
  const selectedStakeholderName = useSelector(
    (state) => state?.CustomerView?.engagementFilters?.StakeholderName
  );
  const selectedStakeholderType = useSelector(
    (state) => state?.CustomerView?.engagementFilters?.StakeholderType
  );
  const selectedBrand = useSelector(
    (state) => state?.CustomerView?.engagementFilters?.Brand
  );
  const selectedIndication = useSelector(
    (state) => state?.CustomerView?.engagementFilters?.Indication
  );
  const selectedUser = useSelector(
    (state) => state?.CustomerView?.engagementFilters?.GNE_User_Name
  );
  const selectedRole = useSelector(
    (state) => state?.CustomerView?.engagementFilters?.GENE_User_Role
  );
  const selectedSquad = useSelector(
    (state) => state?.CustomerView?.engagementFilters?.Squad
  );

  const selectedEngagements = useSelector(
    (state) => state?.CustomerView?.engagementFilters?.Engagement?.selectedValue
  );
  const selectedStartDate = useSelector(
    (state) => state?.EngagementFilters?.startDate
  );

  const selectedEndDate = useSelector(
    (state) => state?.EngagementFilters?.endDate
  );

  const metrics = useSelector((state) => state?.TimelineView?.metrics);

  const [engagementMetrics, setEngagementMetrics] = useState(metrics);

  useLayoutEffect(() => {
    if (
      !selectedStartDate &&
      !selectedEndDate &&
      !Array.isArray(selectedAccount)
    ) {
      return;
    }
    const replaceWith = buildExpression();
    getMetrics(replaceWith);
  }, [
    selectedBrand,
    selectedIndication,
    selectedStakeholderName,
    selectedStakeholderType,
    selectedSquad,
    selectedEcosystem,
    selectedStartDate,
    selectedEndDate,
    selectedUser,
    selectedRole,
  ]);

  useLayoutEffect(() => {
    filterMetrics();
  }, [selectedEngagements, metrics]);

  const filterMetrics = () => {
    if (selectedEngagements?.length === 0) {
      setEngagementMetrics(metrics);
      return;
    }

    const filtered = [];

    for (const engagement of selectedEngagements) {
      const newFilter = metrics.filter(
        (metric) => metric.engagement === engagement.label
      );

      if (newFilter) {
        if (newFilter?.length > 0) {
          filtered.push(newFilter[0]);
        }
      }
    }
    setEngagementMetrics(filtered);
  };

  const buildExpression = () => {
    return buildEngagementExpression(
      selectedEngagements,
      selectedSquad,
      selectedStakeholderName,
      selectedStakeholderType,
      selectedBrand,
      selectedIndication,
      selectedStartDate,
      selectedEndDate,
      selectedAccount,
      selectedView,
      selectedUser,
      selectedRole,
      selectedEcosystem,
    );
  };

  const handleClose = (engagement) => {
    // remove the closed engagement from the selection
    const filtered = selectedEngagements.filter((e) => e.label !== engagement);

    // update the selected engagements store
    dispatch(setSelectedEngagement(filtered));
  };

  const getMetrics = async (replaceWith) => {
    const expression = ENGAGEMENT_EXPRESSIONS.metrics.expression.replace(
      REPLACE_STRING,
      replaceWith
    );
    const dimension = ENGAGEMENT_EXPRESSIONS.metrics.dimension;

    dispatch(getEngagementMetrics(CRM_APP_ID, expression, dimension, 20));
  };

  return (
    <div
      className={classes['engagements-container']}
      id='Engagements'>
      {/* title */}
      <div className={classes['engagements-header']}>
        <span className='title title__bold title__large'> Engagements </span>
      </div>

      {/* filters */}
      <div className={classes['engagements-tab-container']}>
        <EngagementFilters />
      </div>

      <Fade>
        <div className='gne-pill'>
          {engagementMetrics?.map((item) => (
            <EngagementPill
              item={item}
              key={uuid.v4()}
              handleClose={handleClose}
            />
          ))}
        </div>
      </Fade>

      {/* timeline and engagement table*/}
      <Suspense fallback={<Spinner />}>
        <Timeline />
      </Suspense>
    </div>
  );
};

export default Engagements;
