/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import LinkButton from 'components/shared/button/LinkButton';
import MyPriority from './MyPriority';
import TeamPOC from './TeamPOC';
import { setSelectedAccount } from 'store/customer/customerViewSlice';
import { useSelector, useDispatch } from 'react-redux';
import { getFocusAccountData } from 'store/customer/customer-view-actions';

const ConfigurePriority = () => {
  const dispatch = useDispatch();

  const { unixId, roleId } = useSelector((state) => state.Auth.userClaimObject);
  const navigation = useNavigate();
  const [key, setKey] = useState('myPriority');

  const navigateToCustomerView = () => {
    navigation('/customerView/list');
  };

  const teamPocHandler = () => {
    dispatch(setSelectedAccount([]));
    dispatch(getFocusAccountData(unixId, roleId, 'account'));
  };

  useEffect(() => {
    if (key === 'teamPOC') {
      teamPocHandler();
    }
  }, [key]);

  return (
    <>
      {/* link to go back to Customer View */}
      <LinkButton
        className='d-flex align-items-center p-0'
        onClick={navigateToCustomerView}>
        <i className='icon icon__back'></i>{' '}
        <span className='text text__small'>Back to Customer View</span>
      </LinkButton>
      {/* title */}
      <div className='d-flex align-items-center mt-2 mb-4'>
        <i className='icon icon__configureTitle'></i>{' '}
        <span className='title__medium text__bold'>Configure Priority</span>
      </div>

      {/* tabs */}
      <Tabs
        id='myPriority'
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className='mb-3'>
        {/* my priority */}
        <Tab
          eventKey='myPriority'
          title={
            <span className='d-flex text__bold text__small'>
              My Priority List
            </span>
          }>
          <MyPriority tabView={key} />
        </Tab>
        {/* team POC */}
        <Tab
          eventKey='teamPOC'
          title={
            <span className='d-flex text__bold text__small'>Team Setup</span>
          }>
          <TeamPOC tabView={key} />
        </Tab>
      </Tabs>
    </>
  );
};

export default ConfigurePriority;
