/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from 'antd';
import uuid from 'react-native-uuid';
import {
  Button,
  Col,
  Row,
  Alert,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';

import { Drawer, Input } from 'antd';

import { toast } from 'react-toastify';

import {
  ACTION_TYPE,
  DEFAULT_MESSAGE,
  DEFAULT_MESSAGE_SITE,
  FILTERSCREEN,
  FILTER_MESSAGE,
  ORG_ACTIONS,
  optionsInitialState,
} from 'constants/constants';

import classes from './CustomerHierarchy.module.scss';

import Comments from './comments/Comments';
import { getFocusAccountData } from 'store/customer/customer-view-actions';
import Account from 'components/customer/view/Account';
import Filters from './filter/Filters';
import { OrganizationalChart } from './org-chart/OrganizationalChart';
import {
  setSelectedAccount,
  setSeletedView,
} from 'store/customer/customerViewSlice';
import AddComments from './comments/AddComments';
import GNESingleSelect from 'components/shared/dropdown/GNESingleSelect';
import AddSitesLayout from './actions/AddSiteLayout';
import RemoveSitesLayout from './actions/RemoveSiteLayout';
import CreateLabelsLayout from './actions/CreateLabelsLayout';
import AssignLabelsLayout from './actions/AssignLabelsLayout';
import ViewLogs from 'components/customer/hierarchy/view-log/ViewLogs';
import {
  getActionDropdownList,
  getSavedFilterView,
  getSites,
  getUserLogs,
  saveFilterView,
} from 'services/org/org.service';
import { getLabels } from 'services/org/label.service';
import { getComments, getCommentUserList } from 'services/comments.service';
import LinkButton from 'components/shared/button/LinkButton';
import Edit340BLayout from './actions/Edit340BLayout';
import { filterSelectionRefactoring } from 'services/org/org.service';
import { capitalize, startCase, concat, toLower } from 'lodash';
import Site from '../view/Site';
import OrgHierarchyListView, {
  ORG_HIERARCHY_LIST_VIEW_COLUMNS,
} from './list-view/OrgHierarchyListView';
import { exportToExcel, IconformatOptionLabel } from 'utils/utils';
const CustomerHierarchy = () => {
  const dispatch = useDispatch();
  const [selectedFilter, setSelectedFilters] = useState({});
  const [siteName, setSiteName] = useState();
  const [ecoSystem, setEcoSystem] = useState();
  const [label, setLabel] = useState();
  const NODE_LIMIT = 15;

  const TABS = {
    hierarchy: 'hierarchy',
    orgChart: 'org',
    log: 'viewLogs',
    listView: 'listView',
  };

  const commentsTypeOptions = [
    { label: 'All Comments', value: 1, type: null },
    { label: 'Account Comments', value: 2, type: 'account' },
    { label: 'Site Comments', value: 3, type: 'site' },
  ];

  const legendDetails = [
    { color: '#6666CC', label: 'Account' },
    { color: '#a2c1d9', label: 'Parent Site' },
    { color: '#66CC66', label: 'Child Site' },
  ];

  const colorCodes = [
    '#12C0CE',
    '#74AA50',
    '#C8102E',
    '#8C4799',
    '#13294B',
    '#004677',
    '#4B80B1',
    '#DB930B',
    '#671E75',
    '#AF1685',
  ];

  const { Search } = Input;

  const headerStyle = {
    height: '200px',
  };

  const [key, setKey] = useState(TABS.orgChart);
  const [showView, setShowView] = useState(false);

  const [open, setOpen] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedAction, setSelectedAction] = useState([]);
  const [filterCount, setFilterCount] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowRemove, setModalShowRemove] = useState(false);
  const [modalShowEdit340, setModalShowEdit340] = useState(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [assignModalVisible, setAssignModalVisible] = useState(false);
  const [labelsData, setLabelsData] = useState([]);
  const accountId = useRef('');
  const accountName = useRef('');
  const [sites, setSites] = useState({});
  const [loading, setLoading] = useState(false);
  const [addDrawerOpen, setAddDrawerOpen] = useState(false);
  const { unixId, roleId } = useSelector(
    (state) => state?.Auth?.userClaimObject
  );
  const [selectedCommentsType, setSelectedCommentsType] = useState({
    label: 'All Comments',
    value: 1,
  });
  const [selectedUser, setSelectedUser] = useState({
    label: 'All',
    value: 'all',
    unixId: 'all',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [commentsData, setCommentsData] = useState([]);
  const [userlogs, setUserlogs] = useState([]);
  const [actionList, setActionList] = useState([]);
  const [masterActionList, setMasterActionList] = useState([]);
  const [dataFromTreeCard, setDataFromTreeCard] = useState(null);
  const [initialFilterValue, setInitialFilterValue] =
    useState(optionsInitialState);
  const [logsGridSize, setLogsGridSize] = useState(0);
  const [zoomIn, setZoomIn] = useState(null);
  const [zoomOut, setZoomOut] = useState(null);
  const [collapseAll, setCollapseAll] = useState(null);
  const [expandAll, setExpandAll] = useState(null);
  const [exportPDF, setExportPDF] = useState(null);
  const [exportSVG, setExportSVG] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [selectedOption, setSelectedOption] = useState('account');
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [savedFilterViewValue, setSavedFilterViewValue] = useState(null);
  const [showSavedFilterValue, setShowSavedFilterValue] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [localSelectedAccount, setLocalSelectedAccount] = useState(null);
  const [intSavedFilter, setInitSavedFilter] = useState();
  const [userOptions, setUserOptions] = useState([]);

  const focusAccountChecked = useSelector(
    (state) => state.CustomerView?.focusAccountChecked
  );

  const [selectOptions, setSelectedOptions] = useState('account');
  useEffect(() => {
    // account drop has a dependency on this
    dispatch(getFocusAccountData(unixId, roleId, selectOptions));
    dispatch(setSelectedAccount([]));
  }, [selectOptions]);

  useEffect(() => {
    if (key === TABS.log) {
      getUserLog(localSelectedAccount?.value);
    }
  }, [key]);

  const getCommentUsers = async () => {
    const data = await getCommentUserList(accountId?.current);
    const tranformedData = concat(
      { label: 'All', value: 'all', unixId: 'all' },
      data?.data?.result?.map((item) => ({
        label: startCase(capitalize(item.userName)),
        value: toLower(item.userName),
        unixId: item.unixId,
      }))
    );
    setUserOptions(tranformedData);
  };

  useEffect(() => {
    if (accountId?.current !== '') {
      getCommentUsers();
    }
  }, [accountId?.current]);

  useEffect(() => {
    if (!addDrawerOpen && localSelectedAccount?.value) {
      updateComments();
    }
  }, [selectedUser?.value]);

  useEffect(() => {
    if (!addDrawerOpen && localSelectedAccount?.value) {
      updateComments();
    }
    setSelectedFilters({});
    setSiteName({});
    setEcoSystem({});
    getSavedFilter(false);
    setIsFilterApplied(false);
    setSavedFilterViewValue(null);
  }, [localSelectedAccount?.value, unixId, selectedCommentsType?.type]);

  useEffect(() => {
    if (!isFilterApplied) {
      setFilterCount([]);
    }
    setInitialFilterValue(initialFilterValue);
  }, [showFilter]);

  const handleOptionChange = (event) => {
    setSelectedOptions(event.target.value);
    dispatch(setSelectedAccount([]));
    setLocalSelectedAccount(null);
    setShowView(false);
  };

  const getUserLog = async (customerId) => {
    const data = await getUserLogs('User Log', 'all', 'desc', 0, customerId);
    setUserlogs(data?.values);
    setLogsGridSize(data?.currentListSize);
  };

  const toggleCommentsDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setSelectedAction([]);
    setOpen(false);
    setSelectedCommentsType({
      label: 'All Comments',
      value: 1,
    });
    setSelectedUser({
      label: 'All',
      value: 'all',
      unixId: 'all',
    });
  };

  const hideModal = () => {
    setSelectedAction([]);
    setModalShow(false);
  };

  const hideModalRemove = () => {
    setSelectedAction([]);
    setModalShowRemove(false);
  };

  const hideModalEdit340 = () => {
    setSelectedAction([]);
    setModalShowEdit340(false);
  };

  const onAccountSelected = async (selected) => {
    if (!showView) {
      setShowView(true);
    }
    setSearchText(null);

    accountName.current = selected?.label;
    accountId.current = selected?.value;

    dispatch(setSelectedAccount(selected));
    dispatch(setSeletedView(selectOptions));

    if (selected?.value) {
      setLocalSelectedAccount(selected);
      await getActionDropdown(selected.value, unixId);
      await getOrgSites(selected.value, selected.flag);
      await getlabelsdata(selected.value);
      getUserLog(selected?.value);
      setInitialFilterValue(optionsInitialState);
      setShowFilter(false);
    }

    getlabelsdata(selected?.value);
    setFilterCount([]);
  };

  const getOrgSites = async (accountId, classType = '', filters) => {
    setLoading(true);
    const response = await getSites(accountId, classType, filters);
    setSites(response);
    setShowAlert(response?.length > NODE_LIMIT);
    setLoading(false);
  };

  const onCallOrgChartApi = (labelapage) => {
    getOrgSites(localSelectedAccount?.value, localSelectedAccount?.flag);

    if (labelapage === 'create') {
      getlabelsdata(localSelectedAccount?.value);
    }

    onCallReset();
  };

  const getActionDropdown = async (customerId, unixId) => {
    const actionList = await getActionDropdownList(customerId, unixId);
    setActionList(actionList);
    setMasterActionList(
      actionList.filter((oneAction) => oneAction?.actionLabel === 'GLOBAL')
    );
  };

  const checkLabelAccess = () => {
    const action = actionList.filter((item) => item.label === 'View Label');
    return action.length > 0;
  };

  const checkSiteLabelsAccess = () => {
    const action = actionList.filter((item) => item.label === 'View Label');
    return action.length > 0;
  };

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleFilterSelect = (selectedFilter, action) => {
    // if we are clearing the item remove it from the array
    // otherwise add it
    if (action === 'clear') {
      const filtered = filterCount.filter((item) => item !== selectedFilter);
      setFilterCount(filtered);
    } else {
      if (!filterCount.includes(selectedFilter)) {
        setFilterCount((current) => [...current, selectedFilter]);
      }
    }
  };

  const onCallReset = () => {
    setFilterCount([]);
    setInitialFilterValue(optionsInitialState);
    setIsFilterApplied(false);
  };

  const setAction = (selected) => {
    setSelectedAction(selected);
    initiateAction(selected);
  };

  const initiateAction = (selected) => {
    switch (selected?.value) {
      case ORG_ACTIONS.add:
        setModalShow(true);
        break;
      case ORG_ACTIONS.remove:
        setModalShowRemove(true);
        break;
      case ORG_ACTIONS.create:
        setCreateModalVisible(true);
        break;
      case ORG_ACTIONS.assign:
        setAssignModalVisible(true);
        break;
      case ORG_ACTIONS.comments:
        toggleCommentsDrawer();
        break;
      case ORG_ACTIONS.edit340:
        setModalShowEdit340(true);
        break;
      default:
        break;
    }
  };

  const setCommentsType = (selected) => {
    setSelectedCommentsType(selected);
  };

  const onFilterUser = (event) => {
    setIsLoading(true);
    setSelectedUser(event);
    setIsLoading(false);
  };

  const openAddCommentDrawer = () => {
    setAddDrawerOpen(true);
    setSelectedOption('account');
  };

  const openAddCommentDrawerForSite = () => {
    setAddDrawerOpen(true);
    setSelectedOption('site');
  };

  const onCloseAddCommentDrawer = () => {
    setAddDrawerOpen(false);
  };

  const handleFilterApply = async (filters, selectedFilters) => {
    setInitialFilterValue(selectedFilters);
    await getOrgSites(
      localSelectedAccount?.value,
      localSelectedAccount?.flag,
      filters
    );
    setIsFilterApplied(true);
    setShowFilter(!showFilter);
  };
  const handelSaveFilter = async (filters) => {
    await saveFilterView(accountId, filters, unixId, accountName);
    toast.success('Org chart filter view saved successfully!');
    setShowSavedFilterValue(true);
    getSavedFilter(false);
  };

  const getSavedFilter = async (applyValue) => {
    const result = await getSavedFilterView(accountId.current, unixId);
    if (applyValue === true) {
      setInitSavedFilter(result?.data?.data[0]);
      const updatedFilterCount = Object.entries(
        result?.data?.data[0]?.FilterValue
      )
        ?.filter((item) => item[1]?.length > 0)
        .map((row) => {
          return row[0];
        });
      setFilterCount(updatedFilterCount);
    } else {
      if (result?.data?.data[0]) {
        setShowSavedFilterValue(true);
        setInitSavedFilter(result?.data?.data[0]);
        let filterValue = result?.data?.data[0]?.FilterValue;
        if (filterValue.siteName.length > 0) {
          let label = [];
          await filterValue?.siteName.forEach((i) => label.push(i['label']));
          setSiteName(label);
        }
        if (filterValue?.ecosystem.length > 0) {
          let label = [];
          await filterValue?.ecosystem.forEach((i) => label.push(i['label']));
          setEcoSystem(label);
        }
        if (filterValue?.label.length > 0) {
          let label = [];
          await filterValue?.label.forEach((i) => label.push(i['label']));
          setLabel(label);
        }
      } else {
        setShowSavedFilterValue(false);
      }
    }
  };
  const applyFilter = async () => {
    const updatedFilterCount = Object.entries(intSavedFilter?.FilterValue)
      ?.filter((item) => item[1]?.length > 0)
      .map((row) => {
        return row[0];
      });
    if (intSavedFilter?.FilterValue.siteName.length > 0) {
      let label = [];
      await intSavedFilter?.FilterValue?.siteName.forEach((i) =>
        label.push(i['label'])
      );
      setSiteName(label);
    }
    if (intSavedFilter?.FilterValue?.ecosystem.length > 0) {
      let label = [];
      await intSavedFilter?.FilterValue?.ecosystem.forEach((i) =>
        label.push(i['label'])
      );
      setEcoSystem(label);
    }
    if (intSavedFilter?.FilterValue?.label.length > 0) {
      let label = [];
      await intSavedFilter?.FilterValue?.label.forEach((i) =>
        label.push(i['label'])
      );
      setLabel(label);
    }
    setFilterCount(updatedFilterCount);
    let option = filterSelectionRefactoring(intSavedFilter?.FilterValue);
    setSelectedFilters(option);
    await handleFilterApply(option, intSavedFilter?.FilterValue);
    setShowFilter(false);
  };
  const getlabelsdata = async (accountId) => {
    const data = await getLabels(accountId);
    setLabelsData(data);
  };

  const updateComments = () => {
    (async () => {
      // get the comments
      setIsLoading(true);
      const result = await getComments(
        selectedUser?.value !== 'all' ? selectedUser?.unixId : '',
        localSelectedAccount?.value,
        selectedCommentsType.type
      );
      setCommentsData(result);
      setIsLoading(false);
      getCommentUsers();
    })();
  };

  const handleNodeData = (data) => {
    setDataFromTreeCard(data);
  };

  const handleZoomIn = () => {
    if (zoomIn) {
      setZoomIn(!zoomIn);
    } else {
      setZoomIn(true);
    }
  };

  const handleZoomOut = () => {
    if (zoomOut) {
      setZoomOut(!zoomOut);
    } else {
      setZoomOut(true);
    }
  };

  const handleCollapseAll = () => {
    if (collapseAll) {
      setCollapseAll(!collapseAll);
    } else {
      setCollapseAll(true);
    }
  };

  const handleExpandAll = () => {
    if (expandAll) {
      setExpandAll(!collapseAll);
    } else {
      setExpandAll(true);
    }
  };

  const handleExportToPDF = () => {
    if (exportPDF) {
      setExportPDF(!exportPDF);
    } else {
      setExportPDF(true);
    }
  };

  const handleExportToSVG = () => {
    if (exportSVG) {
      setExportSVG(!exportSVG);
    } else {
      setExportSVG(true);
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleReset = () => {
    setSearchText('');
  };

  const exportTooltip = (
    <Tooltip bsclass='gne-tooltip tooltip--left'>Send to Excel</Tooltip>
  );

  const downloadData = () => {
    const sitesData = sites.map((item) => {
      const labels = item.labels?.map((item) => item.labelName)?.join(',');
      return {
        name: item.name,
        immediateParentName: item?.immediateParentName ?? '',
        corporateParentName: item?.corporateParentName ?? '',
        address: item.address,
        ecosystemName: item.ecosystemName,
        class: item.class,
        classificationCategory: item.classificationCategory,
        classification: item.classification,
        id: item.siteId,
        iqviaId: item.iqviaId,
        hin: item.hin,
        dea: item.dea,
        grossSalesR12: item.grossSalesR12,
        engagementsR6M: '',
        hcps: item.StakeholderCount ?? '',
        contacts: '',
        shared: item.shared,
        labels: labels ?? '',
      };
    });

    return exportToExcel(
      sitesData,
      ORG_HIERARCHY_LIST_VIEW_COLUMNS,
      'OrgHierarchy'
    );
  };

  const tooltip = () => {
    let showData = [];
    return (
      <Tooltip bsclass='gne-tooltip tooltip--left'>
        <p className='text text__x-small mb-1'>
          {selectedFilter &&
            Object.keys(selectedFilter).forEach(function (key) {
              showData.push(
                <div key={key}>
                  {key === 'siteName' ? (
                    <>
                      <strong className={classes['capitalize']}>{key}: </strong>
                      {siteName['label']
                        ? siteName['label'].join(', ')
                        : siteName
                        ? siteName.join(', ')
                        : ''}
                    </>
                  ) : key === 'ecosystem' ? (
                    <>
                      <strong className={classes['capitalize']}>{key}: </strong>
                      {ecoSystem['label']
                        ? ecoSystem['label'].join(', ')
                        : ecoSystem
                        ? ecoSystem.join(', ')
                        : ''}
                    </>
                  ) : key === 'label' ? (
                    <>
                      <strong className={classes['capitalize']}>{key}: </strong>
                      {label['label']
                        ? label['label'].join(', ')
                        : label
                        ? label.join(', ')
                        : ''}
                    </>
                  ) : key === 'grossSales' ? (
                    <>
                      <strong className={classes['capitalize']}>{key}: </strong>
                      ${JSON.stringify(selectedFilter[key], null, 2)}
                    </>
                  ) : (
                    <>
                      <strong style={{ textTransform: 'capitalize' }}>
                        {key}:{' '}
                      </strong>
                      {selectedFilter[key].constructor.name === 'Array' ? (
                        <>{selectedFilter[key].join(', ')}</>
                      ) : (
                        <>{JSON.stringify(selectedFilter[key], null, 2)}</>
                      )}
                    </>
                  )}
                </div>
              );
            })}
          {showData}
        </p>
      </Tooltip>
    );
  };
  const filters = (
    <>
      {/* filters */}
      <Row className='mb-3'>
        {/* ctas and search */}
        <Col
          className='d-flex ps-0'
          md={8}>
          {key === TABS.orgChart && (
            <>
              <Search
                placeholder='Search'
                onSearch={(e) => {
                  handleSearch(e ?? e?.target?.value);
                }}
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
                allowClear
                onReset={handleReset}
                className='gne-search w-25 me-3'
                value={searchText}
              />

              <div className={classes.toolbar}>
                {/* zoom */}
                <LinkButton onClick={handleZoomIn}>
                  <i
                    className='icon icon__zoom-in'
                    title='Zoom In'></i>
                </LinkButton>
                <LinkButton onClick={handleZoomOut}>
                  <i
                    className='icon icon__zoom-out'
                    title='Zoom Out'></i>
                </LinkButton>

                {/* collapse / expand */}
                <LinkButton onClick={handleExpandAll}>
                  <i
                    className='icon icon__expand-all'
                    title='Expand All'></i>
                </LinkButton>

                <LinkButton onClick={handleCollapseAll}>
                  <i
                    className='icon icon__collapse-all'
                    title='Collapse All'></i>
                </LinkButton>

                {/* export */}
                <LinkButton onClick={handleExportToPDF}>
                  <i
                    className='icon icon__file-download'
                    title='Export to PDF'></i>
                </LinkButton>

                {/* svg */}
                {/* <LinkButton onClick={handleExportToSVG}>
              <i
                className='icon icon__export-svg'
                title='Export to SVG'></i>
            </LinkButton> */}
              </div>
            </>
          )}
        </Col>

        <Col className='d-flex justify-content-end p-0'>
          {key === TABS.listView && (
            <div className={`${classes.toolbar}`}>
              <OverlayTrigger
                placement='top'
                overlay={exportTooltip}>
                <i
                  onClick={downloadData}
                  className={`icon icon__affiliations_export_excel1`}
                />
              </OverlayTrigger>
            </div>
          )}
          <LinkButton
            className={`text text__small float-left me-1 ${
              !showSavedFilterValue ? 'disabled' : ''
            }`}
            onClick={applyFilter}
            disabled={!showSavedFilterValue}>
            <i
              className={
                !showSavedFilterValue
                  ? 'icon icon__org-saved-view-disabled'
                  : 'icon icon__org-saved-view'
              }></i>
            Saved View
          </LinkButton>
          {isFilterApplied && filterCount?.length > 0 ? (
            <OverlayTrigger
              overlay={tooltip()}
              placement='bottom'>
              <Button
                variant={`btn btn-gne btn-secondary float-right ${
                  showFilter ? 'active' : ''
                } ${classes.filter}`}
                onClick={handleFilter}>
                {/* show count if a filter is selected */}
                {filterCount?.length > 0 && (
                  <span
                    className={`${classes.count} ${
                      showFilter ? classes.active : ''
                    }`}>
                    {filterCount?.length}
                  </span>
                )}
                {/* show filter icon if no filter is selected */}
                {filterCount?.length === 0 && (
                  <i
                    className={`icon ${
                      showFilter ? 'icon__filter-active' : 'icon__filter'
                    }`}></i>
                )}
                Filters
              </Button>
            </OverlayTrigger>
          ) : (
            <Button
              variant={`btn btn-gne btn-secondary float-right ${
                showFilter ? 'active' : ''
              } ${classes.filter}`}
              onClick={handleFilter}>
              {/* show count if a filter is selected */}
              {filterCount?.length > 0 && (
                <span
                  className={`${classes.count} ${
                    showFilter ? classes.active : ''
                  }`}>
                  {filterCount?.length}
                </span>
              )}
              {/* show filter icon if no filter is selected */}
              {filterCount?.length === 0 && (
                <i
                  className={`icon ${
                    showFilter ? 'icon__filter-active' : 'icon__filter'
                  }`}></i>
              )}
              Filters
            </Button>
          )}
        </Col>
      </Row>

      {showFilter && (
        <Filters
          accountId={accountId.current}
          onFilterSelected={(selectedFilter, action) =>
            handleFilterSelect(selectedFilter, action)
          }
          labelsData={labelsData}
          labelAccess={checkLabelAccess()}
          filterSection={FILTERSCREEN.main}
          onFilterApply={(filters, selectedFilters) =>
            handleFilterApply(filters, selectedFilters)
          }
          onCallReset={onCallReset}
          initialFilterValue={initialFilterValue}
          onSaveFilter={(filters) => handelSaveFilter(filters)}
          savedFilterView={savedFilterViewValue}
          action={ORG_ACTIONS.filter}
          page={ACTION_TYPE.orgFilter}
          showInfo={(filters, siteName, ecoSystem, label) => {
            setSelectedFilters(filters);
            siteName && setSiteName(siteName);
            ecoSystem && setEcoSystem(ecoSystem);
            label && setLabel(label);
          }}
          classType={localSelectedAccount?.flag}
        />
      )}
      {showAlert && (
        <Alert
          key='warning'
          variant='warning'
          show={false}
          onClose={() => setShowAlert(false)}
          dismissible>
          {FILTER_MESSAGE}
        </Alert>
      )}
    </>
  );

  return (
    <>
      {/* top section */}
      <div
        className={`mb-4 d-flex align-items-center ${classes['top-filter-panel']}`}>
        {/* page title */}
        <div className='d-inline-block'>
          <i className='icon icon__customer-hierarchy-view'></i>
          <span className={`title title__bold ${classes['tab-title']}`}>
            Customer Hierarchy
          </span>
        </div>

        <div className={`title__small ${classes['radio_selection']}`}>
          <label className={classes['radio_button']}>
            <input
              type='radio'
              name='option'
              value='account'
              className={classes['radio_label']}
              checked={selectOptions === 'account'}
              onChange={handleOptionChange}
            />
            Account
          </label>
          <label className={classes['radio_button']}>
            <input
              type='radio'
              name='option'
              className={classes['radio_label']}
              value='site'
              checked={selectOptions === 'site'}
              onChange={handleOptionChange}
            />
            Site
          </label>
        </div>

        <div>
          {selectOptions === 'account' ? (
            <Account
              cssClass={`d-inline-block ${classes.wide}`}
              onAccountSelected={onAccountSelected}
              showPriorityAccount={true}
              selected={localSelectedAccount}
              isClearable={false}
            />
          ) : (
            <Site
              cssClass={`d-inline-block ${classes.wide}`}
              onSiteSelected={onAccountSelected}
              showPrioritySite={true}
              isFocusedChecked={focusAccountChecked}
              isClearable={false}
            />
          )}
        </div>

        {showView && masterActionList.length > 0 && (
          <div className='d-inline-block'>
            <GNESingleSelect
              value={selectedAction}
              onChange={setAction}
              options={masterActionList}
              placeholder='Bulk Actions'
              icons={true}
              isClearable={true}
              className={`single-select ${classes.actionsDropdown}`}
              formatOptionLabel={IconformatOptionLabel}
            />
          </div>
        )}
      </div>

      {/* default message */}
      {!showView && (
        <p className={classes['default-message']}>
          {selectOptions === 'site' ? DEFAULT_MESSAGE_SITE : DEFAULT_MESSAGE}
        </p>
      )}

      {/* tab section */}
      {showView && (
        <div className={classes.org}>
          {/* tabs */}
          <Tabs
            id='customerHierarchy'
            activeKey={key}
            onChange={(k) => setKey(k)}
            className={classes['tab-custom-class']}
            tabBarExtraContent={
              key === TABS.orgChart ? (
                <div
                  style={{ display: 'flex' }}
                  className='text__small text'>
                  {legendDetails?.map((item, index) => (
                    <div
                      key={uuid.v4() + index}
                      style={{ display: 'flex' }}>
                      <span
                        className={classes['legends-div']}
                        style={{ backgroundColor: item?.color }}
                      />
                      <span className={classes['legend-display']}>
                        {' '}
                        {item?.label}{' '}
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )
            }
            items={[
              {
                key: TABS.orgChart,
                label: <span className='d-flex'>Org Chart</span>,
                children: (
                  <>
                    {filters}

                    {/* org chart */}
                    <OrganizationalChart
                      data={sites}
                      account={localSelectedAccount}
                      loading={loading}
                      labelsData={labelsData}
                      comments={openAddCommentDrawerForSite}
                      onCallOrgChartApi={onCallOrgChartApi}
                      nodeActionList={actionList.filter(
                        (oneAction) => oneAction?.actionLabel === 'NODE'
                      )}
                      handleNodeData={handleNodeData}
                      labelAccess={checkSiteLabelsAccess()}
                      onZoomIn={zoomIn}
                      onZoomOut={zoomOut}
                      onExpandAll={expandAll}
                      onCollapseAll={collapseAll}
                      onExportPDF={exportPDF}
                      onExportSVG={exportSVG}
                      searchText={searchText}
                      selectOptions={selectOptions}
                    />
                  </>
                ),
              },
              {
                key: TABS.listView,
                label: <span className='d-flex'>List View</span>,
                children: (
                  <>
                    {filters}
                    <OrgHierarchyListView
                      data={sites}
                      account={localSelectedAccount}
                      loading={loading}
                      labelsData={labelsData}
                      onCallOrgChartApi={onCallOrgChartApi}
                      labelAccess={checkSiteLabelsAccess()}
                      nodeActionList={actionList.filter(
                        (oneAction) => oneAction?.actionLabel === 'NODE'
                      )}
                    />
                  </>
                ),
              },
              {
                key: TABS.log,
                label: <span className='d-flex'>View Log</span>,
                children: (
                  <ViewLogs
                    logs={userlogs}
                    id={accountId?.current}
                    logsGridSize={logsGridSize}
                  />
                ),
              },
            ]}
          />

          {/* comments */}
          <Drawer
            width={470}
            title={
              <>
                <div className={classes['drawer-title-container']}>
                  <div className='d-inline-block mr-5'>
                    <i className={`icon icon__comments-title mr-0`}></i>{' '}
                    <span className={classes.commentsTitle}>Comments</span>
                  </div>
                </div>
                <div className='d-flex'>
                  <GNESingleSelect
                    value={selectedCommentsType}
                    onChange={setCommentsType}
                    options={commentsTypeOptions}
                    className={`single-select ${classes['comments-dropdown']}`}
                    isClearable={false}
                    formatOptionLabel={IconformatOptionLabel}
                  />
                  <GNESingleSelect
                    value={selectedUser}
                    onChange={onFilterUser}
                    options={userOptions}
                    className={`single-select ${classes['comments-dropdown']}`}
                    isClearable={false}
                    formatOptionLabel={IconformatOptionLabel}
                  />
                  <Button
                    onClick={openAddCommentDrawer}
                    className={classes['add-btn-class']}
                    variant={`btn btn-gne btn-outline-primary btn-gne__outline btn-gne__outline--add`}>
                    Add New
                  </Button>
                </div>
              </>
            }
            placement='right'
            onClose={onClose}
            open={open}
            className={`${classes.addListDrawer}`}>
            <Comments
              accountType={selectedCommentsType?.type}
              comments={commentsData}
              isLoading={isLoading}
              onDeleteComment={updateComments}
              onEditComment={updateComments}
            />
          </Drawer>

          {/* add comments */}
          <Drawer
            width={470}
            title={
              <span>
                <i className='icon icon__add-big' />
                Add New Comment
              </span>
            }
            placement='right'
            onClose={onCloseAddCommentDrawer}
            headerStyle={headerStyle}
            className={`${classes.addListDrawer}`}
            open={addDrawerOpen}
            zIndex={1001}>
            <AddComments
              onSaveComment={updateComments}
              selectedNodeData={dataFromTreeCard}
              onClose={() => setAddDrawerOpen(false)}
              selectedOption={selectedOption}
              addDrawerOpen={addDrawerOpen}
            />
          </Drawer>
        </div>
      )}

      {/* add sites */}
      {modalShow && (
        <AddSitesLayout
          show={modalShow}
          page={'actions'}
          view={ORG_ACTIONS.add}
          onHide={() => hideModal()}
          accountId={accountId?.current}
          accountName={accountName?.current}
          labelsData={labelsData}
          labelAccess={checkLabelAccess()}
          onCallOrgChartApi={onCallOrgChartApi}
        />
      )}

      {/* remove sites */}
      {modalShowRemove && (
        <RemoveSitesLayout
          show={modalShowRemove}
          page={'actions'}
          view={ORG_ACTIONS.remove}
          onHide={() => hideModalRemove()}
          accountId={accountId?.current}
          accountName={accountName?.current}
          labelsData={labelsData}
          labelAccess={checkLabelAccess()}
          onCallOrgChartApi={onCallOrgChartApi}
        />
      )}

      {/* create labels */}
      {createModalVisible && (
        <CreateLabelsLayout
          showModal={createModalVisible}
          setShowModal={setCreateModalVisible}
          labelData={labelsData}
          dropdownColorOptions={colorCodes}
          accountId={accountId?.current}
          onCallOrgChartApi={onCallOrgChartApi}
          setSelectedAction={setSelectedAction}
        />
      )}

      {assignModalVisible && (
        <AssignLabelsLayout
          showModal={assignModalVisible}
          setShowModal={setAssignModalVisible}
          labelData={labelsData}
          accountId={accountId?.current}
          labelsData={labelsData}
          onCallOrgChartApi={onCallOrgChartApi}
          setSelectedAction={setSelectedAction}
          labelAccess={checkLabelAccess()}
        />
      )}

      {modalShowEdit340 && (
        <Edit340BLayout
          show={modalShowEdit340}
          page={'actions'}
          view={ORG_ACTIONS.edit340}
          onHide={() => hideModalEdit340()}
          accountId={accountId?.current}
          accountName={accountName?.current}
          labelsData={labelsData}
          labelAccess={checkLabelAccess()}
          onCallOrgChartApi={onCallOrgChartApi}
        />
      )}
    </>
  );
};

export default CustomerHierarchy;
