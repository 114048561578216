/* eslint-disable react-hooks/exhaustive-deps */
import KPILibrary from '../kpi-library/KPILibrary';
import { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { usePromiseTracker } from 'react-promise-tracker';
import { wrapMenuList } from 'react-select-async-paginate';

import {
  Form,
  Button,
  Row,
  Col,
  Container,
  OverlayTrigger,
  Tooltip,
  Popover,
} from 'react-bootstrap';

import {
  setSelectedProduct,
  setSelectedTimePeriod,
  setExpression,
  setCards,
  setCustomerViewButtonDisabled,
  setSelectedKPI,
  setSelectedKpiId,
  setSelectedAppId,
  setSelectedKpiName,
  setDimensionName,
  setSelectedSquad,
  setConfigureInsightProducts,
  setConfigureInsightSquad,
  setConfigureInsightEcosystem,
  setIsAccountableProductConfigure,
  setTimePeriods,
  setTimePeriodOptions,
  setKpiandDimensions,
  setChartType,
  setNoDimensionValue,
  setChartData,
  setOldChartType,
  setSelectedEcosystem,
  setEcosystemOptions,
  setChannelFilters,
  setDiscussionTopicFilters,
  setStakeholderTypeFilters,
  setTemplateNameFilters,
  setSurveyTypeFilters,
  setPresentationNameFilters,
  setKeyMessageFilters,
  setConfigInsightSiteFilter,
  setBenchmarkNoDimensionValue,
  setSelectedCardView,
  setMarketingFilters,
  setSelectedAppLink,
  setDimensionId,
  setAdvanceDropdownOptions,
} from 'store/customer/customerViewSlice';

import {
  getAllKpiAndDimension,
  getMultiDimensionChartData,
  getTimespan,
} from 'store/customer/customer-view-actions';

import classes from './ConfigureInsights.module.scss';

import {
  CHART_ADD_MESSAGE,
  CHART_UPDATE_MESSAGE,
  CONFIGURE_VIEW,
  EXPRESSION_PLACEHOLDER_TEXT,
  INSIGHTS_CHART_LIMIT_ERROR,
  ALL,
  CHECKBOXCLASS,
  VIEW,
  updatedKpiNameLength,
  kpiTitleLimitExceedMessage,
  FILTER_PLACE_HOLDER,
  COMPARE_BENCHMARK,
  BENCHMARK_LEGEND_FIRST,
  COMPARE_BENCHMARK_NO_DIMENSION_CHARTTYPE,
  COMPARE_KPI,
} from 'constants/constants';

import {
  ValueContainer,
  createExpression,
  advanceExpressionToSave,
  buildAdvancePropsObject,
  dropdownOptions,
  getAccountExpression,
  getAdvanceFilterValue,
  getArrayLabelValuesWithoutSpace,
  getChartTypeByDimension,
  isAllselected,
  isArray,
  moveSelected,
  replaceUndefinedWithEmptyArray,
} from 'utils/utils';
import LinkButton from 'components/shared/button/LinkButton';
import ChartAndTableView from 'components/charts/ChartAndTableView';

import axiosDBClient from 'axiosDBClient';

import {
  getDimensionsTimePeriodOptions,
  getTimePeriodOptions,
  getTimePeriods,
} from 'services/trends.service';
import ValidationMessage from 'components/shared/tiles/ValidationMessage';
import {
  dimensionDetails,
  getSelectedDimensionId,
} from 'components/customer/view/utils';
import MultiSelectDropdown from 'components/home/configure-my-view/MultiSelectDropdown';
import MarketingFilter from 'components/shared/custom-filter/MarketingFilter';
import { setAdvanceOptions } from 'store/customer/customerViewSlice';
import { customerViewInitialState } from 'store/customer/customerViewInitialState';
import SelectWithPagination from 'components/shared/dropdown-with-pagination/SelectWithPagination';
import { setKPIclickedStatus } from 'store/home/homeMyViewSlice';
import GNESingleSelect from 'components/shared/dropdown/GNESingleSelect';
import GNEAsyncPagination from 'components/shared/dropdown-with-pagination/GNEAsyncPagination';

export default function ConfigureInsights({
  buttonText,
  data,
  view,
  setKpiTitleUpdateMessage,
}) {
  const dispatch = useDispatch();
  const { promiseInProgress } = usePromiseTracker();
  const selectedCardView = useSelector(
    (state) => state?.CustomerView?.selectedCardView
  );

  const kpiId = useSelector((state) => state?.CustomerView?.selectedKpiId);
  const appLink = useSelector((state) => state?.CustomerView?.selectedAppLink);
  const expression = useSelector((state) => state?.CustomerView?.expression);
  const timespan = useSelector((state) => state?.CustomerView?.timespan);
  const { roleId } = useSelector((state) => state.Auth.userClaimObject);
  const oldChartType = useSelector(
    (state) => state?.CustomerView?.oldChartType
  );

  // eslint-disable-next-line no-unused-vars
  const [filters, setFilters] = useState([]);
  const [firstLoad, setFirstLoad] = useState(false);

  const dimensionName = useSelector(
    (state) => state?.CustomerView?.dimensionName
  );
  const dimesnionId = useSelector((state) => state?.CustomerView?.dimesnionId);
  const selectedView = useSelector(
    (state) => state?.CustomerView?.selectedView
  );
  const selectedAccount = useSelector(
    (state) => state?.CustomerView?.selectedAccount
  );
  const selectedSquad = useSelector(
    (state) => state?.CustomerView?.selectedSquad
  );
  const selectedChannel = useSelector(
    (state) => state?.CustomerView?.marketingFilters?.Channel
  );
  const selectedDiscussionTopic = useSelector(
    (state) => state?.CustomerView?.marketingFilters?.DiscussionTopic
  );
  const selectedStakeholderType = useSelector(
    (state) => state?.CustomerView?.marketingFilters?.PayerStakeholderType
  );
  const selectedTemplateName = useSelector(
    (state) => state?.CustomerView?.marketingFilters?.TemplateName
  );
  const selectedSurveyType = useSelector(
    (state) => state?.CustomerView?.marketingFilters?.SurveyType
  );
  const selectedPresentationName = useSelector(
    (state) => state?.CustomerView?.marketingFilters?.PresentationName
  );
  const selectedKeyMessage = useSelector(
    (state) => state?.CustomerView?.marketingFilters?.KeyMessage
  );

  const squadOptions = useSelector(
    (state) => state?.CustomerView?.squadOptions
  );

  const ecosystemOptions = useSelector(
    (state) => state?.CustomerView?.ecosystemOptions
  );

  const selectedEcosystem = useSelector(
    (state) => state?.CustomerView?.selectedEcosystem
  );
  const selectedProduct = useSelector(
    (state) => state?.CustomerView?.selectedProduct
  );
  const timePeriodOptions = useSelector(
    (state) => state?.CustomerView?.timePeriodOptions
  );

  const timePeriods = useSelector((state) => state?.CustomerView?.timePeriod);

  const selectedTimePeriod = useSelector(
    (state) => state?.CustomerView?.selectedTimePeriod
  );

  const cards = useSelector((state) => state?.CustomerView?.cards);
  const chartData = useSelector((state) => state?.CustomerView?.chartData);

  const selectedKPI = useSelector((state) => state?.CustomerView?.selectedKPI);

  const selectedDimension = useSelector(
    (state) => state?.CustomerView?.dimensionName
  );

  const noDimensionValue = useSelector(
    (state) => state?.CustomerView?.noDimensionValue
  );

  const showDimensionName = selectedDimension === 'No Dimension' ? false : true;

  const selectedAppId = useSelector(
    (state) => state?.CustomerView?.selectedAppId
  );

  const chartType = useSelector((state) => state?.CustomerView?.chartType);

  const allProductOptions = useSelector(
    (state) => state?.CustomerView?.productOptions
  );

  const selectedSite = useSelector(
    (state) => state?.CustomerView?.configureInsightsFilters?.SiteName_Address
  );

  const configureInsightsFilters = useSelector(
    (state) => state?.CustomerView?.configureInsightsFilters
  );

  const [accountableProductsChecked, setAccountableProductsChecked] =
    useState(true);
  const [disableKPI, setDisableKPI] = useState(false);
  const accountableProductOptions = allProductOptions.filter(
    (product) => product.priorityFlag === 'Y'
  );

  const isExtra =
    selectedKPI?.filterEnable?.channelNameEnable === 'Y' ||
    selectedKPI?.filterEnable?.templateNameEnable === 'Y' ||
    selectedKPI?.filterEnable?.surveyTypeEnable === 'Y' ||
    selectedKPI?.filterEnable?.keyMessageEnable === 'Y';
  const timespanExpression = useRef('');
  const kpiTriggered = useRef(false);
  const isInitializing = useRef(false);
  const localSelectedSquad = useRef([]);
  const localSelectedChannel = useRef([]);
  const localSelectedDiscussionTopic = useRef([]);
  const localSelectedStakeholderType = useRef([]);
  const localSelectedTemplateName = useRef([]);
  const localSelectedSurveyType = useRef([]);
  const localSelectedPresentationName = useRef([]);
  const localSelectedKeyMessage = useRef([]);
  const vReplaceString = useRef(EXPRESSION_PLACEHOLDER_TEXT);

  const localSelectedEcosystem = useRef([]);
  const localSelectedProduct = useRef([]);

  const [productOpts, setProductOpts] = useState([]);
  const [enableApplyDisableAddtoButton, setEnableApplyDisableAddtoButton] =
    useState(true);

  const productOptions = accountableProductsChecked
    ? accountableProductOptions
    : allProductOptions;

  let kpiNameValue = showDimensionName
    ? selectedKPI?.KpiName + ` by ${selectedDimension}`
    : selectedKPI['KpiName'];

  const [showKpiName, setshowKpiName] = useState(false);

  const [insightKpiValue, setInsightKpiValue] = useState(kpiNameValue);
  const [updatedKpiName, setUpdatedKpiName] = useState(insightKpiValue);
  const [filterValidation, setFilterValidation] = useState(true);
  const filterExpression = useRef('');
  const advanceComparePropsRef = useRef('');
  const [menuIsOpen, setMenuIsOpen] = useState({
    SiteName: false,
  });
  const onMenuOpen = useCallback((filterName, value) => {
    let menuIsOpenLocal = menuIsOpen;
    if (filterName !== '' && value) {
      menuIsOpenLocal = Object.keys(menuIsOpen).reduce((acc, key) => {
        return { ...acc, [key]: filterName === key };
      }, {});
    } else if ((filterName !== '' && !value) || filterName === '') {
      menuIsOpenLocal = Object.keys(menuIsOpen).reduce((acc, key) => {
        return { ...acc, [key]: false };
      }, {});
    }
    setMenuIsOpen({ ...menuIsOpenLocal });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const APP_ID = process.env.REACT_APP_AFFILIATION_APP_ID;
  const [fieldExpression, setFieldExpression] = useState('');

  const selectedAdvanceOptions = useSelector(
    (state) => state?.CustomerView?.advanceOptions
  );

  const handleEdit = () => {
    setshowKpiName(!showKpiName);
  };

  const handleKPINameChange = (val) => {
    setInsightKpiValue(val);
  };

  const updateKpiName = () => {
    if (insightKpiValue.length > updatedKpiNameLength) {
      toast.warning(kpiTitleLimitExceedMessage);
      setInsightKpiValue(updatedKpiName);
    } else if (
      insightKpiValue !== updatedKpiName &&
      insightKpiValue.length > 0
    ) {
      setUpdatedKpiName(insightKpiValue);
    } else {
      setInsightKpiValue(updatedKpiName);
    }

    setshowKpiName(false);
  };

  useEffect(() => {
    setEnableApplyDisableAddtoButton(false);
  }, [
    selectedProduct,
    selectedSquad,
    selectedEcosystem,
    selectedTimePeriod,
    selectedChannel,
    selectedDiscussionTopic,
    selectedStakeholderType,
    selectedTemplateName,
    selectedSurveyType,
    selectedPresentationName,
    selectedKeyMessage,
    accountableProductsChecked,
    selectedSite,
  ]);

  useEffect(() => {
    isInitializing.current = true;
    const loadEcosystemOptions = async (value) => {
      setFirstLoad(true);

      const response = await axiosDBClient.get('filter/ecosystem', {
        params: {
          customerId: selectedAccount?.value,
          searchToken: value,
        },
      });
      const ecosystems = response.data.result;
      let ecosystemOptions = [];

      if (ecosystems?.length > 0) {
        ecosystemOptions = ecosystems.map((ecosystem) => {
          return {
            label: ecosystem.ecosystemName,
            value: ecosystem.ecosystemId,
          };
        });
      }
      return ecosystemOptions;
    };
    //Edit Flow
    if (view === 'edit') {
      setDisableKPI(false);
      setKpiTitleUpdateMessage(false);
      const {
        filter,
        advanceEnabled,
        advanceFilterValue,
        advanceType,
        advanceExpression,
      } = data;
      const {
        selectedAccount,
        selectedProduct,
        selectedSquad,
        selectedTimePeriod,
        accountableProduct,
        selectedKPI,
        timespanExpression,
        dimensionName,
      } = filter;

      let {
        selectedSiteName,
        selectedEcosystem,
        selectedChannel,
        selectedDiscussionTopic,
        selectedStakeholderType,
        selectedTemplateName,
        selectedSurveyType,
        selectedPresentationName,
        selectedKeyMessage,
        chartType,
      } = filter;
      selectedChannel = replaceUndefinedWithEmptyArray(selectedChannel);
      selectedDiscussionTopic = replaceUndefinedWithEmptyArray(
        selectedDiscussionTopic
      );
      selectedStakeholderType = replaceUndefinedWithEmptyArray(
        selectedStakeholderType
      );
      selectedTemplateName =
        replaceUndefinedWithEmptyArray(selectedTemplateName);
      selectedSurveyType = replaceUndefinedWithEmptyArray(selectedSurveyType);
      selectedPresentationName = replaceUndefinedWithEmptyArray(
        selectedPresentationName
      );
      selectedKeyMessage = replaceUndefinedWithEmptyArray(selectedKeyMessage);
      selectedSiteName = replaceUndefinedWithEmptyArray(selectedSiteName);
      selectedEcosystem = replaceUndefinedWithEmptyArray(selectedEcosystem);

      dispatch(
        setOldChartType(
          getChartTypeByDimension(selectedKPI?.dimensions, dimensionName)
        )
      );

      dispatch(setSelectedAppId(selectedKPI?.AppId));
      dispatch(
        setConfigInsightSiteFilter({
          ...selectedSite,
          selectedValue: selectedSiteName,
        })
      );

      if (advanceEnabled === 'Y') {
        advanceComparePropsRef.current = buildAdvancePropsObject(
          advanceFilterValue,
          advanceType,
          advanceExpression,
          selectedDimension,
          chartType
        );
      } else {
        advanceComparePropsRef.current = '';
      }
      dispatch(setAdvanceOptions(advanceComparePropsRef.current));

      const loadTimePeriods = async () => {
        //load Edit flow tp
        const timePeriods = await getTimePeriods(selectedKPI.KpiId);
        dispatch(setTimePeriods(timePeriods));

        let dimensionId = getSelectedDimensionId(selectedKPI, dimensionName);

        let isDimensionLevelExpression =
          selectedKPI?.filterEnable?.EnableDimensionLevelExpression === 'Y';

        const timePeriodOptions = isDimensionLevelExpression
          ? await getDimensionsTimePeriodOptions(timePeriods, dimensionId)
          : await getTimePeriodOptions(timePeriods);

        dispatch(setTimePeriodOptions(timePeriodOptions));
        dispatch(setSelectedTimePeriod(selectedTimePeriod));

        return timePeriods;
      };

      const getData = async () => {
        const kpiDimensions = await getAllKpiAndDimension(
          roleId,
          selectedView === 'site' ? 'Account' : null
        );
        dispatch(setKpiandDimensions(kpiDimensions?.result));

        const timePeriods = await loadTimePeriods();

        setAccountableProductsChecked(accountableProduct);

        dispatch(setEcosystemOptions(await loadEcosystemOptions()));
        await loadSquadOptions();

        await loadProductOptions();

        let checkedProducts =
          selectedProduct === 'All'
            ? accountableProduct
              ? accountableProductOptions
              : allProductOptions
            : selectedProduct;

        dispatch(setSelectedProduct(checkedProducts));

        let checkedSquads =
          selectedSquad === 'All' ? squadOptions : selectedSquad;

        dispatch(setSelectedSquad(checkedSquads));

        dispatch(
          setChannelFilters({
            ...selectedChannel,
            selectedValue: selectedChannel,
          })
        );

        dispatch(
          setDiscussionTopicFilters({
            ...selectedDiscussionTopic,
            selectedValue: selectedDiscussionTopic,
          })
        );

        dispatch(
          setStakeholderTypeFilters({
            ...selectedStakeholderType,
            selectedValue: selectedStakeholderType,
          })
        );

        dispatch(
          setTemplateNameFilters({
            ...selectedTemplateName,
            selectedValue: selectedTemplateName,
          })
        );

        dispatch(
          setSurveyTypeFilters({
            ...selectedSurveyType,
            selectedValue: selectedSurveyType,
          })
        );

        dispatch(
          setPresentationNameFilters({
            ...selectedPresentationName,
            selectedValue: selectedPresentationName,
          })
        );

        dispatch(
          setKeyMessageFilters({
            ...selectedKeyMessage,
            selectedValue: selectedKeyMessage,
          })
        );
        // localSelectedChannel.current = selectedChannel?.selectedValue;

        let checkedEcosystem =
          selectedEcosystem === 'All' ? ecosystemOptions : selectedEcosystem;
        dispatch(setSelectedEcosystem(checkedEcosystem));

        if (accountableProduct && checkedSquads?.length === 0) {
          setProductOpts(accountableProductOptions);
        }
        dispatch(setDimensionName(dimensionName));
        // pass all variable that build need
        // because state isn't updated in time for use to use the data

        editBuildExpression(
          timePeriods,
          timespanExpression,
          selectedProduct,
          selectedSquad,
          selectedEcosystem,
          selectedTimePeriod,
          selectedAccount,
          selectedView,
          accountableProductsChecked,
          selectedKPI.AppId,
          dimensionName,

          advanceComparePropsRef.current,
          selectedChannel,
          selectedDiscussionTopic,
          selectedStakeholderType,
          selectedTemplateName,
          selectedSurveyType,
          selectedPresentationName,
          selectedKeyMessage,
          selectedSiteName,
          selectedKPI
        );

        isInitializing.current = false;
      };

      getData();
      setDisableKPI(true);
    } else {
      // Add flow
      dispatch(setSelectedCardView('chart'));
      advanceComparePropsRef.current = '';
      dispatch(setAdvanceOptions(''));

      const getData = async () => {
        const { result: kpDimensions } = await getAllKpiAndDimension(
          roleId,
          selectedView
        );

        let getDimensionId = getSelectedDimensionId(
          selectedKPI,
          selectedDimension
        );

        let isDimensionLevelExpression =
          selectedKPI?.filterEnable?.EnableDimensionLevelExpression === 'Y';

        const timePeriods = await getTimePeriods(kpDimensions?.[0]?.KpiId);

        dispatch(setTimePeriods(timePeriods));

        const timePeriodOptions = isDimensionLevelExpression
          ? await getDimensionsTimePeriodOptions(timePeriods, getDimensionId)
          : await getTimePeriodOptions(timePeriods);

        dispatch(setTimePeriodOptions(timePeriodOptions));

        let exp = timePeriods.filter((tp) => tp.uXDefaultValue === 'Y')[0];

        const selectedTimePeriod = {
          label: exp.timePeriodName,
          value: exp.timePeriodId,
        };

        dispatch(setEcosystemOptions(await loadEcosystemOptions()));

        dispatch(setSelectedTimePeriod(selectedTimePeriod));

        // clear the data that populates the kpi's
        dispatch(setKpiandDimensions([]));

        // wait for the view to update
        // so we can repopulate with data
        // this fixes the retaining of the previously opened kpi
        setTimeout(() => {
          dispatch(setKpiandDimensions(kpDimensions));
          dispatch(setSelectedKPI(kpDimensions?.[0]));
          dispatch(setSelectedKpiId(kpDimensions?.[0]?.KpiId));
          dispatch(setSelectedAppId(kpDimensions?.[0]?.AppId));
          dispatch(setSelectedKpiName(kpDimensions?.[0]?.KpiName));

          dispatch(
            setDimensionName(kpDimensions?.[0]?.dimensions[0]?.dimensionName)
          );

          dispatch(setSelectedSquad([]));
          dispatch(
            setMarketingFilters(customerViewInitialState.marketingFilters)
          );

          localSelectedChannel.current = [];
          localSelectedDiscussionTopic.current = [];
          localSelectedStakeholderType.current = [];
          localSelectedTemplateName.current = [];
          localSelectedSurveyType.current = [];
          localSelectedPresentationName.current = [];
          localSelectedKeyMessage.current = [];

          dispatch(setSelectedProduct([]));
          dispatch(setSelectedEcosystem([]));
          dispatch(
            setConfigInsightSiteFilter({
              options: [],
              selectedValue: [],
            })
          );
          editBuildExpression(
            timePeriods,
            exp.timespanExpression,
            [],
            [],
            [],
            selectedTimePeriod,
            selectedAccount,
            selectedView,
            accountableProductsChecked,
            kpDimensions?.[0]?.AppId,
            kpDimensions?.[0]?.dimensions[0]?.dimensionName,
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            kpDimensions?.[0]
          );

          isInitializing.current = false;
        }, 100);
      };
      getData();
      setEnableApplyDisableAddtoButton(true);
    }
  }, []);

  useEffect(() => {
    if (!isInitializing.current) {
      if (view === VIEW.edit) {
        if (firstLoad) {
          advanceComparePropsRef.current = '';
          dispatch(setAdvanceOptions(''));
        }
      } else {
        advanceComparePropsRef.current = '';
        dispatch(setAdvanceOptions(''));
      }
      if (!kpiTriggered.current || view === VIEW.edit) {
        buildExpression();
      }
    }
  }, [dimesnionId]);

  // useEffect(() => {
  //   if (!isInitializing.current) {
  //     kpiTriggered.current = false;
  //   }
  // }, [selectedKPI]);

  // useEffect(() => {
  //   if (kpiTriggered.current) {
  //     buildExpression();
  //     kpiTriggered.current = false;
  //     isInitializing.current = false;
  //   }
  // }, [selectedTimePeriod]);

  //'Cleanup is in progress'
  useEffect(() => {
    return () => {
      dispatch(setSelectedAppId(null));
      // dispatch(setKpiandDimensions(null));
      dispatch(setSelectedKPI(null));
      dispatch(setSelectedKpiName(null));
      dispatch(setDimensionName(null));
      dispatch(setSelectedAppLink(null));
      dispatch(setSelectedKpiId(null));
      dispatch(setDimensionId(null));
      dispatch(setOldChartType('KPI'));
      dispatch(setChartType('KPI'));
      dispatch(setAdvanceOptions(''));
      dispatch(setAdvanceDropdownOptions([]));
      dispatch(setSelectedTimePeriod([]));
    };
  }, []);

  useEffect(() => {
    localSelectedSquad.current = selectedSquad;
  }, [selectedSquad]);

  useEffect(() => {
    localSelectedChannel.current = selectedChannel;
    localSelectedDiscussionTopic.current = selectedDiscussionTopic;
    localSelectedStakeholderType.current = selectedStakeholderType;
    localSelectedTemplateName.current = selectedTemplateName;
    localSelectedSurveyType.current = selectedSurveyType;
    localSelectedPresentationName.current = selectedPresentationName;
    localSelectedKeyMessage.current = selectedKeyMessage;
  }, [
    selectedChannel,
    selectedDiscussionTopic,
    selectedStakeholderType,
    selectedTemplateName,
    selectedSurveyType,
    selectedPresentationName,
    selectedKeyMessage,
  ]);

  useEffect(() => {
    localSelectedProduct.current = selectedProduct;
  }, [selectedProduct]);

  const handleProductType = (value) => {
    let updateValue = value?.target?.checked;
    setAccountableProductsChecked(updateValue);
    doAccountableProductActions(updateValue);
    dispatch(setIsAccountableProductConfigure(value?.target?.checked));
    dispatch(setConfigureInsightProducts({ options: [], selectedValue: [] }));
    dispatch(setConfigureInsightSquad({ options: [], selectedValue: [] }));
    dispatch(setConfigureInsightEcosystem({ options: [], selectedValue: [] }));
  };

  const doAccountableProductActions = (isChecked) => {
    const allProducts = isChecked
      ? accountableProductOptions
      : allProductOptions;

    dispatch(setSelectedProduct([]));
    setProductOpts(allProducts);
  };

  const onProductChange = (value) => {
    value = ConvertSingleMultiSelectValue(value);
    dispatch(setSelectedProduct(value));
  };

  const onSquadChange = (value) => {
    value = ConvertSingleMultiSelectValue(value);
    dispatch(setSelectedSquad(value));
  };
  const onEcosystemChange = (value) => {
    value = ConvertSingleMultiSelectValue(value);
    dispatch(setSelectedEcosystem(value));
  };
  const ConvertSingleMultiSelectValue = (value) => {
    if (!Array.isArray(value)) {
      value = value === undefined || value === null ? [] : [value];
    }
    return value;
  };
  const loadProductOptions = async (val, value) => {
    setFirstLoad(true);
    const response = await axiosDBClient.get('filter/products', {
      params: {
        squads:
          selectedSquad?.length > 0
            ? JSON.stringify(
                getArrayLabelValuesWithoutSpace(selectedSquad).split(',')
              )
            : [],
        searchToken: val,
      },
    });

    if (selectedProduct.length === 0 && view !== 'edit') {
      dispatch(setSelectedProduct([]));
    }
    const accountableProductOptions = response?.data?.result?.filter(
      (product) => product?.priorityFlag === 'Y'
    );

    const allProductOptions = response?.data?.result;

    if (
      accountableProductsChecked &&
      localSelectedProduct?.current?.length > 0
    ) {
      const selectedAccountableProducts = localSelectedProduct.current.filter(
        (selection) =>
          accountableProductOptions.find(
            (item) => item.label === selection.label
          )
      );

      dispatch(setSelectedProduct(selectedAccountableProducts));
    }

    const allProducts = accountableProductsChecked
      ? accountableProductOptions
      : allProductOptions;

    setProductOpts(allProducts);
    return {
      options: allProducts,
    };
  };

  const loadSquadOptions = async (value) => {
    setFirstLoad(true);

    const response = await axiosDBClient.get('filter/squads', {
      params: {
        products:
          selectedProduct?.length > 0
            ? JSON.stringify(
                getArrayLabelValuesWithoutSpace(selectedProduct).split(',')
              )
            : [],
        searchToken: value,
      },
    });

    if (selectedSquad?.length === 0 && view !== 'edit') {
      dispatch(setSelectedSquad([]));
    }
    return {
      options: response.data.result,
    };
  };

  useEffect(() => {
    const sortedOptions = moveSelected(selectedProduct, productOptions);

    if (sortedOptions !== undefined) {
      setProductOpts(sortedOptions);
    }
  }, [selectedProduct]);

  const setTimePeriod = (value) => {
    dispatch(setSelectedTimePeriod(value));
  };

  const buildExpression = () => {
    const isAllProd =
      isArray(productOptions) &&
      (productOptions?.length === selectedProduct?.length ||
        selectedProduct?.length === 0);

    const isAllSquad =
      squadOptions !== undefined &&
      Array.isArray(squadOptions) &&
      selectedSquad?.length === 0;

    const isAllEcosystem =
      ecosystemOptions !== undefined &&
      Array.isArray(ecosystemOptions) &&
      selectedEcosystem?.length === 0;

    const timePeriodName = selectedTimePeriod?.label;

    const products = isAllProd ? 'Product=' : selectedProduct;
    const squad = isAllSquad ? 'Squad=' : selectedSquad;

    const ecosystem = isAllEcosystem ? 'Ecosystem=' : selectedEcosystem;
    const site =
      selectedSite?.selectedValue?.length > 0
        ? selectedSite?.selectedValue
        : undefined;

    // build account expression
    const accountExp = getAccountExpression(
      selectedAccount,
      false,
      selectedView
    );

    // build product expression
    const productExp = isArray(products)
      ? 'Product={"' + products.map((a) => a.label).join('","') + '"}'
      : products;

    const suqadExp = isArray(squad)
      ? 'Squad={"' + squad.map((a) => a.label).join('","') + '"}'
      : squad;

    const channelExp = createExpression(
      selectedKPI,
      selectedChannel?.selectedValue,
      'channelName',
      'Channel'
    );
    const discussionTopicExp = createExpression(
      selectedKPI,
      selectedDiscussionTopic?.selectedValue,
      'discussionTopic',
      'Discussion Topic'
    );
    const stakeholderTypeExp = createExpression(
      selectedKPI,
      selectedStakeholderType?.selectedValue,
      'stakeholderType',
      'Stakeholder Type'
    );
    const templateNameExp = createExpression(
      selectedKPI,
      selectedTemplateName?.selectedValue,
      'templateName',
      'Template Name'
    );
    const surveyTypeExp = createExpression(
      selectedKPI,
      selectedSurveyType?.selectedValue,
      'surveyType',
      'Survey Type'
    );
    const presentationNameExp = createExpression(
      selectedKPI,
      selectedPresentationName?.selectedValue,
      'presentationName',
      'Presentation Name'
    );
    const keyMessageExp = createExpression(
      selectedKPI,
      selectedKeyMessage?.selectedValue,
      'keyMessage',
      'Key Message'
    );

    const finalExp = [
      channelExp,
      discussionTopicExp,
      stakeholderTypeExp,
      templateNameExp,
      surveyTypeExp,
      presentationNameExp,
      keyMessageExp,
    ]
      .filter(Boolean)
      .join(',');

    const ecosystemExp = isArray(ecosystem)
      ? 'Ecosystem={"' + ecosystem.map((a) => a.label).join('","') + '"}'
      : ecosystem;

    const siteExp = isArray(site)
      ? 'Site_ID={"' + site.map((a) => a.value).join('","') + '"}'
      : site;
    // const MarketingExp = buildMarketingExp();
    //accountable products expression
    const isAccountableProduct =
      accountableProductsChecked && `isAccountableProduct={"Y"}`;

    // combine all expression
    let filterExp = accountableProductsChecked
      ? `${accountExp}, ${productExp}, ${suqadExp},${ecosystemExp}, ${isAccountableProduct},${
          finalExp !== '' ? `${finalExp}` : ''
        }`
      : `${accountExp},${productExp}, ${suqadExp},${ecosystemExp},${
          finalExp !== '' ? `${finalExp}` : ''
        }`;
    filterExp =
      filterExp.charAt(filterExp?.length - 1) === ','
        ? filterExp?.slice(0, -1)
        : filterExp;
    if (siteExp !== undefined) filterExp = `${filterExp},${siteExp}`;

    filterExpression.current = filterExp;
    // put it together and store it
    const filters = [{ filterExp, time: timePeriodName }];
    setFilters([...filters]);

    // we want the expression. It is not stored in timePeriods
    // so filter to get the expression for the time period
    // that is selected

    let isDimensionLevelExpression =
      selectedKPI?.filterEnable?.EnableDimensionLevelExpression === 'Y';

    let getDimensionId = getSelectedDimensionId(selectedKPI, selectedDimension);

    const timePeriod = timePeriods?.filter((t) =>
      isDimensionLevelExpression
        ? t.dimensionId === getDimensionId &&
          t.timePeriodName === filters[0].time
        : t.timePeriodName === filters[0].time
    );

    // replace the placeholder string with our filters
    const expression = timePeriod?.[0]?.expression.replaceAll(
      EXPRESSION_PLACEHOLDER_TEXT,
      filters?.[0]?.filterExp ? filters?.[0]?.filterExp : 'test'
    );
    dispatch(setExpression(expression));
    if (checkSingleSelectEligibility()) {
      generateMultiDimensionObject(
        advanceComparePropsRef.current,
        expression,
        oldChartType
      );
      dispatch(getTimespan(timePeriod?.[0]?.timespanExpression, selectedAppId));
    }
    timespanExpression.current = timePeriod?.[0]?.timespanExpression;
    setEnableApplyDisableAddtoButton(true);

    if (view === 'edit') setKpiTitleUpdateMessage(true);
  };

  //onselection of advance dropdown value
  useEffect(() => {
    if (!isInitializing.current) {
      advanceComparePropsRef.current = selectedAdvanceOptions;
      if (
        selectedAdvanceOptions &&
        selectedAdvanceOptions?.selectDropdownOption !== ''
      ) {
        buildExpression();
      }
    }
  }, [selectedAdvanceOptions?.selectDropdownOption]);

  //onchange of advance compareType load default view
  useEffect(() => {
    if (selectedAdvanceOptions?.selectDropdownOption === '') {
      buildExpression();
    }
  }, [selectedAdvanceOptions?.compareBy]);

  const generateMultiDimensionObject = async (
    advanceCompareProps,
    firstExp,
    oldChartType,
    editAppId,
    editFlowSelectedTimePeriod,
    editFlowDimensionName
  ) => {
    dispatch(setKPIclickedStatus(true));

    if (advanceCompareProps === undefined) {
      advanceComparePropsRef.current = '';
      dispatch(setAdvanceOptions(advanceCompareProps));
    }

    let dimensionObjOne = {
      dimesnionType: 'Cateogry',
      dimesnionName: editFlowDimensionName
        ? editFlowDimensionName
        : selectedDimension,
    };
    let dimensionObjTwo;
    if (
      advanceCompareProps &&
      advanceCompareProps?.compareBy === 'dimension' &&
      advanceCompareProps?.selectDropdownOption?.PossibleDimensionName &&
      advanceCompareProps?.selectDropdownOption?.PossibleDimensionName !==
        'undefined' &&
      advanceCompareProps?.selectDropdownOption?.PossibleDimensionName !==
        'null'
    ) {
      dimensionObjTwo = {
        dimesnionType: 'Advance',
        dimesnionName:
          advanceCompareProps?.selectDropdownOption?.PossibleDimensionName,
      };
    }
    dispatch(setChartData([]));
    dispatch(
      setChartType(
        advanceCompareProps?.selectDropdownOption?.chartType
          ? advanceCompareProps?.selectDropdownOption?.chartType
          : oldChartType
          ? oldChartType
          : chartType
      )
    );
    let dimension = [];

    dimension.push(JSON.stringify(dimensionObjOne));
    if (dimensionObjTwo) {
      dimension.push(JSON.stringify(dimensionObjTwo));
    }
    let measure = [];
    let measureObjOne = {
      timesPeriod:
        advanceCompareProps?.compareBy === COMPARE_BENCHMARK &&
        advanceCompareProps?.selectDropdownOption !== ''
          ? BENCHMARK_LEGEND_FIRST
          : advanceCompareProps?.compareBy === COMPARE_KPI &&
            advanceCompareProps?.selectDropdownOption !== ''
          ? selectedKPI?.KpiName
          : editFlowSelectedTimePeriod?.label
          ? editFlowSelectedTimePeriod?.label
          : selectedTimePeriod?.label,
      index: 1,
      expression: firstExp || expression,
    };
    measure.push(JSON.stringify(measureObjOne));
    let measureObjTwo;
    if (
      advanceCompareProps?.compareBy === 'time-period' &&
      advanceCompareProps?.selectDropdownOption?.possibleTimePeriod
    ) {
      measureObjTwo = {
        timesPeriod:
          advanceCompareProps?.selectDropdownOption?.possibleTimePeriod,
        index: 2,
        expression:
          view === 'add'
            ? advanceCompareProps?.selectDropdownOption?.advTimeperiodExp?.replaceAll(
                EXPRESSION_PLACEHOLDER_TEXT,
                filterExpression.current ? filterExpression.current : 'test'
              )
            : replaceFilterInExpression(
                advanceCompareProps,
                filterExpression
              ).replace(/(^"|"$)/g, ''),
      };
      measure.push(JSON.stringify(measureObjTwo));

      //SETTING UP THE DIMESNION NAME TO MONTH_N IF PRIMARY DIMESNION IS MONTH AND COMPARE BY IS TIME PERIOD.

      if (selectedDimension === 'Month') {
        let dimensionObjOne = {
          dimesnionType: 'Cateogry',
          dimesnionName: 'Month_N',
        };
        dimension = [JSON.stringify(dimensionObjOne)];
      }
    }
    if (
      advanceCompareProps?.compareBy === COMPARE_BENCHMARK &&
      advanceCompareProps?.selectDropdownOption !== ''
    ) {
      measureObjTwo = {
        timesPeriod: advanceCompareProps?.selectDropdownOption?.label,
        index: 2,
        expression:
          view === 'add'
            ? advanceCompareProps?.selectDropdownOption?.advTimeperiodExp?.replaceAll(
                EXPRESSION_PLACEHOLDER_TEXT,
                filterExpression.current ? filterExpression?.current : 'test'
              )
            : replaceFilterInExpression(advanceCompareProps, filterExpression),
      };
      measure.push(JSON.stringify(measureObjTwo));

      //SETTING UP THE DIMESNION NAME TO MONTH_N IF PRIMARY DIMESNION IS MONTH AND COMPARE BY IS TIME PERIOD.
      if (selectedDimension === 'Month') {
        let dimensionObjOne = {
          dimesnionType: 'Cateogry',
          dimesnionName: 'Month_N',
        };

        dimension = [JSON.stringify(dimensionObjOne)];
      }
    }
    if (
      advanceCompareProps?.compareBy === COMPARE_KPI &&
      advanceCompareProps?.selectDropdownOption !== ''
    ) {
      dispatch(setSelectedCardView('table'));
      let exp = advanceCompareProps?.selectDropdownOption?.advTimeperiodExp
        ? advanceCompareProps?.selectDropdownOption?.advTimeperiodExp
        : advanceCompareProps?.selectDropdownOption;
      exp.map((oneKpi) => {
        measureObjTwo = {
          timesPeriod: oneKpi?.label || oneKpi?.timesPeriod,
          index: measure.length + 1,
          expression:
            view === 'add'
              ? oneKpi?.advTimeperiodExp?.replaceAll(
                  EXPRESSION_PLACEHOLDER_TEXT,
                  filterExpression.current ? filterExpression?.current : 'test'
                )
              : replaceFilterInExpression(oneKpi, filterExpression),
        };
        measure.push(JSON.stringify(measureObjTwo));
      });
      //SETTING UP THE DIMESNION NAME TO MONTH_N IF PRIMARY DIMESNION IS MONTH AND COMPARE BY IS TIME PERIOD.
      if (selectedDimension === 'Month') {
        let dimensionObjOne = {
          dimesnionType: 'Cateogry',
          dimesnionName: 'Month_N',
        };

        dimension = [JSON.stringify(dimensionObjOne)];
      }
    }

    let limit =
      advanceCompareProps?.selectDropdownOption?.rowsLimit?.toString();
    await getMultiDimensionChartData(
      measure,
      dimension,
      editAppId !== undefined ? editAppId : selectedAppId,
      advanceCompareProps?.selectDropdownOption?.chartType
        ? advanceCompareProps?.selectDropdownOption?.chartType
        : oldChartType
        ? oldChartType
        : chartType,
      limit
    )
      .then((res) => {
        if (!res.data?.values[0]) {
          if (
            advanceCompareProps?.selectDropdownOption?.chartType ===
              COMPARE_BENCHMARK_NO_DIMENSION_CHARTTYPE ||
            res?.data[0]?.values[0]?.name === BENCHMARK_LEGEND_FIRST
          ) {
            dispatch(setNoDimensionValue(res?.data[0]?.values[0]?.data[0]));
            dispatch(
              setBenchmarkNoDimensionValue(res?.data[0]?.values[1]?.data[0])
            );
          } else {
            dispatch(setNoDimensionValue(res?.data[0]?.category[0]));
            dispatch(setBenchmarkNoDimensionValue(''));
          }
        }
        dispatch(setChartData(res.data[0] ? res.data[0] : []));
        dispatch(setKPIclickedStatus(false));
      })
      .catch((err) => {
        dispatch(setKPIclickedStatus(false));
      });
  };

  const editBuildExpression = (
    timePeriods,
    selectedTimespanExpression,
    selectedProduct,
    selectedSquad,
    selectedEcosystem,
    selectedTimePeriod,
    selectedAccount,
    selectedView,
    accountableProductsChecked,
    selectedAppId,
    selectedDimension,
    savedAdvanceComparePropsRef,
    selectedChannel,
    selectedDiscussionTopic,
    selectedStakeholderType,
    selectedTemplateName,
    selectedSurveyType,
    selectedPresentationName,
    selectedKeyMessage,
    selectedSiteName,
    selectedKPI
  ) => {
    const isAllProd =
      productOptions?.length === selectedProduct?.length ||
      selectedProduct?.length === 0;

    const isAllSquad =
      squadOptions?.length === selectedSquad?.length ||
      selectedSquad?.length === 0;

    const isAllEcosystem =
      ecosystemOptions?.length === selectedEcosystem?.length ||
      selectedEcosystem?.length === 0;

    const timePeriodName = selectedTimePeriod?.label;

    const products = isAllProd ? 'Product=' : selectedProduct;
    const squad = isAllSquad ? 'Squad=' : selectedSquad;
    const ecosystem = isAllEcosystem ? 'Ecosystem=' : selectedEcosystem;
    const site = selectedSiteName?.length > 0 ? selectedSiteName : undefined;
    // build account expression
    const accountExp = getAccountExpression(
      selectedAccount,
      false,
      selectedView
    );

    // build product expression
    const productExp = isArray(products)
      ? 'Product={"' + products.map((a) => a.label).join('","') + '"}'
      : products;

    const suqadExp = isArray(squad)
      ? 'Squad= {"' + squad.map((a) => a.label).join('","') + '"}'
      : squad;
    const channelExp = createExpression(
      selectedKPI,
      selectedChannel,
      'channelName',
      'Channel'
    );
    const discussionTopicExp = createExpression(
      selectedKPI,
      selectedDiscussionTopic,
      'discussionTopic',
      'Discussion Topic'
    );
    const stakeholderTypeExp = createExpression(
      selectedKPI,
      selectedStakeholderType,
      'stakeholderType',
      'Stakeholder Type'
    );
    const templateNameExp = createExpression(
      selectedKPI,
      selectedTemplateName,
      'templateName',
      'Template Name'
    );
    const surveyTypeExp = createExpression(
      selectedKPI,
      selectedSurveyType,
      'surveyType',
      'Survey Type'
    );
    const presentationNameExp = createExpression(
      selectedKPI,
      selectedPresentationName,
      'presentationName',
      'Presentation Name'
    );
    const keyMessageExp = createExpression(
      selectedKPI,
      selectedKeyMessage,
      'keyMessage',
      'Key Message'
    );

    const finalExp = [
      channelExp,
      discussionTopicExp,
      stakeholderTypeExp,
      templateNameExp,
      surveyTypeExp,
      presentationNameExp,
      keyMessageExp,
    ]
      .filter(Boolean)
      .join(',');

    const ecosystemExp = isArray(ecosystem)
      ? 'Ecosystem= {"' + ecosystem.map((a) => a.label).join('","') + '"}'
      : ecosystem;
    const siteExp = isArray(site)
      ? 'Site_ID={"' + site.map((a) => a.value).join('","') + '"}'
      : site;

    //accountable products expression
    const isAccountableProduct =
      accountableProductsChecked && `isAccountableProduct={"Y"}`;

    // combine all expression
    let filterExp = accountableProductsChecked
      ? `${accountExp}, ${productExp}, ${suqadExp},${ecosystemExp}, ${isAccountableProduct},${
          finalExp !== '' ? `${finalExp}` : ''
        }`
      : `${accountExp},${productExp}, ${suqadExp},${ecosystemExp},${
          finalExp !== '' ? `${finalExp}` : ''
        }`;

    filterExp =
      filterExp.charAt(filterExp?.length - 1) === ','
        ? filterExp?.slice(0, -1)
        : filterExp;
    if (siteExp !== undefined) filterExp = `${filterExp},${siteExp}`;

    //expression for advance
    filterExpression.current = filterExp;
    // put it together and store it
    const filters = [{ filterExp, time: timePeriodName }];
    setFilters([...filters]);

    // we want the expression. It is not stored in timePeriods
    // so filter to get the expression for the time period
    // that is selected

    let isDimensionLevelExpression =
      selectedKPI?.filterEnable?.EnableDimensionLevelExpression === 'Y';

    let getDimensionId = getSelectedDimensionId(selectedKPI, selectedDimension);

    const timePeriod = timePeriods?.filter((t) =>
      isDimensionLevelExpression
        ? t.dimensionId === getDimensionId &&
          t.timePeriodName === filters[0].time
        : t.timePeriodName === filters[0].time
    );

    // replace the placeholder string with our filters
    const expression = timePeriod?.[0]?.expression.replaceAll(
      EXPRESSION_PLACEHOLDER_TEXT,
      filters?.[0]?.filterExp ? filters?.[0]?.filterExp : 'test'
    );
    dispatch(setExpression(expression));
    generateMultiDimensionObject(
      savedAdvanceComparePropsRef,
      expression,
      undefined,
      selectedAppId,
      selectedTimePeriod,
      selectedDimension
    );
    setDisableKPI(true);
    dispatch(getTimespan(selectedTimespanExpression, selectedAppId));
    timespanExpression.current = selectedTimespanExpression;
    setEnableApplyDisableAddtoButton(true);
  };
  const getKpiInfoToSave = (selectedKPI) => {
    let finalSelectedKpi = selectedKPI;
    const dimension = selectedKPI?.dimensions.filter(
      (oneDimension) => oneDimension?.dimensionName === dimensionName
    );
    return { ...finalSelectedKpi, dimensions: dimension };
  };

  const addToHome = async () => {
    const filteredCards = cards.filter((item) => item?.isDeleted !== true);

    if (filteredCards?.length >= 12) {
      toast.error(INSIGHTS_CHART_LIMIT_ERROR);
      return;
    }
    const positionIndex = filteredCards?.length + 1;
    const product = isAllselected(localSelectedProduct.current, productOptions);
    const cardData = {
      cardId: null,
      title: updatedKpiName,
      custInsightsBrdId: null,
      positionIndex: positionIndex,
      cardView: selectedCardView,
      kpiId,
      filter: {
        selectedAccount,
        selectedEcosystem,
        selectedSiteName: selectedSite?.selectedValue,
        selectedProduct: product,
        selectedTimePeriod,
        kpiName: selectedKPI?.KpiName,
        selectedKPI: getKpiInfoToSave(selectedKPI),
        dimensionName,
        noDimensionValue,
        chartType,
        accountableProduct: accountableProductsChecked,
        selectedSquad: localSelectedSquad?.current,
        timespanExpression: timespanExpression.current,
        viewType: selectedView,
        selectedChannel: localSelectedChannel?.current?.selectedValue,
        selectedDiscussionTopic:
          localSelectedDiscussionTopic?.current?.selectedValue,
        selectedStakeholderType:
          localSelectedStakeholderType?.current?.selectedValue,
        selectedTemplateName: localSelectedTemplateName?.current?.selectedValue,
        selectedSurveyType: localSelectedSurveyType?.current?.selectedValue,
        selectedPresentationName:
          localSelectedPresentationName?.current?.selectedValue,
        selectedKeyMessage: localSelectedKeyMessage?.current?.selectedValue,
      },
      expression,
      appId: selectedAppId,
      chartData: chartData,
      timespan,
      selectedAppLink: appLink,

      advanceId: advanceComparePropsRef.current?.selectDropdownOption?.advanceId
        ? advanceComparePropsRef.current?.selectDropdownOption?.advanceId
        : null,
      advanceEnabled:
        advanceComparePropsRef.current?.selectDropdownOption &&
        advanceComparePropsRef.current?.selectDropdownOption?.length !== 0
          ? 'Y'
          : 'N',
      advanceType:
        advanceComparePropsRef.current?.compareBy &&
        advanceComparePropsRef.current?.selectDropdownOption !== '' &&
        advanceComparePropsRef.current?.selectDropdownOption?.length !== 0
          ? advanceComparePropsRef.current?.compareBy
          : null,
      advanceExpression: advanceExpressionToSave(
        advanceComparePropsRef,
        vReplaceString
      ),
      adavanceTimeSpanExpression: advanceComparePropsRef.current
        ?.selectDropdownOption?.adavanceTimeSpanExpression
        ? advanceComparePropsRef.current?.selectDropdownOption
            ?.adavanceTimeSpanExpression
        : null,
      advanceFilterValue: getAdvanceFilterValue(advanceComparePropsRef),
    };
    dispatch(setCards([...cards, cardData]));
    dispatch(setCustomerViewButtonDisabled(false));

    toast.success(CHART_ADD_MESSAGE);
  };

  const updateView = async () => {
    const product = isAllselected(localSelectedProduct.current, productOptions);
    const payload = {
      cardId: data.cardId,
      title: updatedKpiName,
      custInsightsBrdId: data.custInsightsBrdId,
      cardView: selectedCardView,
      kpiId,
      filters: {
        selectedAccount,
        selectedEcosystem,
        selectedProduct: product,
        selectedTimePeriod,
        kpiName: selectedKPI?.KpiName,
        selectedSiteName: selectedSite?.selectedValue,
        selectedKPI: getKpiInfoToSave(selectedKPI),
        dimensionName,
        noDimensionValue,
        chartType,
        accountableProduct: accountableProductsChecked,
        selectedSquad: localSelectedSquad.current,
        timespanExpression: timespanExpression.current,
        viewType: selectedView,
        selectedChannel: localSelectedChannel?.current?.selectedValue,
        selectedDiscussionTopic:
          localSelectedDiscussionTopic?.current?.selectedValue,
        selectedStakeholderType:
          localSelectedStakeholderType?.current?.selectedValue,
        selectedTemplateName: localSelectedTemplateName?.current?.selectedValue,
        selectedSurveyType: localSelectedSurveyType?.current?.selectedValue,
        selectedPresentationName:
          localSelectedPresentationName?.current?.selectedValue,
        selectedKeyMessage: localSelectedKeyMessage?.current?.selectedValue,
      },
      expression,
      appId: selectedAppId,
      positionIndex: data.positionIndex,
      timespan,

      advanceId: advanceComparePropsRef.current?.selectDropdownOption?.advanceId
        ? advanceComparePropsRef.current?.selectDropdownOption?.advanceId
        : null,
      advanceEnabled:
        advanceComparePropsRef.current?.selectDropdownOption &&
        advanceComparePropsRef.current?.selectDropdownOption?.length !== 0
          ? 'Y'
          : 'N',
      advanceType:
        advanceComparePropsRef.current?.compareBy &&
        advanceComparePropsRef.current?.selectDropdownOption !== '' &&
        advanceComparePropsRef.current?.selectDropdownOption?.length !== 0
          ? advanceComparePropsRef.current?.compareBy
          : null,
      advanceExpression: advanceExpressionToSave(
        advanceComparePropsRef,
        vReplaceString
      ),
      adavanceTimeSpanExpression: advanceComparePropsRef.current
        ?.selectDropdownOption?.adavanceTimeSpanExpression
        ? advanceComparePropsRef.current?.selectDropdownOption
            ?.adavanceTimeSpanExpression
        : null,
      advanceFilterValue: getAdvanceFilterValue(advanceComparePropsRef),
    };
    const cardData = {
      ...payload,
      title: updatedKpiName,
      filter: payload.filters,
      chartData: chartData,
      selectedAppLink: appLink,
    };

    dispatch(
      setCards(
        cards.map((item) => {
          return item.positionIndex === payload.positionIndex &&
            item.isDeleted !== true
            ? cardData
            : item;
        })
      )
    );
    toast.success(CHART_UPDATE_MESSAGE);

    dispatch(setCustomerViewButtonDisabled(false));
  };
  const [editKpiTitle, seteditKpiTitle] = useState('');

  useEffect(() => {
    if (data !== undefined) {
      seteditKpiTitle(data.title);
      setUpdatedKpiName(data.title);
    }
    if (view === 'edit' && updatedKpiName !== editKpiTitle) {
      setUpdatedKpiName(data.title);
      setInsightKpiValue(data.title);
    } else if (view === 'edit') {
      seteditKpiTitle(kpiNameValue);
      setUpdatedKpiName(kpiNameValue);
      setInsightKpiValue(kpiNameValue);
    } else {
      setUpdatedKpiName(kpiNameValue);
      setInsightKpiValue(kpiNameValue);
    }
    if (selectedKPI?.filterEnable?.productEnable === 'N') {
      setAccountableProductsChecked(false);
      dispatch(setSelectedProduct([]));
      localSelectedProduct.current = [];
      dispatch(setSelectedSquad([]));
      localSelectedSquad.current = [];
      dispatch(setSelectedEcosystem([]));
      localSelectedEcosystem.current = [];
    }
  }, [kpiNameValue]);
  const replaceFilterInExpression = (advanceCompareProps, filterExpression) => {
    let multiExpression = advanceCompareProps?.expression
      ? advanceCompareProps?.expression
      : advanceCompareProps?.advTimeperiodExp;
    let finalExpression = advanceCompareProps?.selectDropdownOption
      ? advanceCompareProps?.selectDropdownOption?.advTimeperiodExp?.replaceAll(
          FILTER_PLACE_HOLDER,
          filterExpression.current ? filterExpression.current : 'test'
        )
      : multiExpression?.replaceAll(
          FILTER_PLACE_HOLDER,
          filterExpression.current ? filterExpression.current : 'test'
        );
    finalExpression = finalExpression?.replaceAll(
      EXPRESSION_PLACEHOLDER_TEXT,
      filterExpression.current ? filterExpression?.current : 'test'
    );
    return finalExpression;
  };

  const tooltip = () => {
    return (
      <Tooltip
        placement='right'
        bsclass='gne-tooltip tooltip--left'>
        <p className='text text__x-small mb-1'>
          <strong>{selectedKPI?.Description + '.'}</strong>
        </p>
      </Tooltip>
    );
  };

  const checkSingleSelectEligibility = () => {
    let callApi = true;
    setFilterValidation(callApi);

    if (
      dimensionDetails(selectedKPI, selectedDimension)?.filterSingleSelect
        ?.squadSingleSelect === 'Y' &&
      localSelectedSquad.current.length !== 1
    ) {
      callApi = false;
      dispatch(setSelectedSquad([]));
      localSelectedSquad.current = [];
    }

    if (
      dimensionDetails(selectedKPI, selectedDimension)?.filterSingleSelect
        ?.productSingleSelect === 'Y' &&
      localSelectedProduct.current.length !== 1
    ) {
      callApi = false;
      dispatch(setSelectedProduct([]));
      localSelectedProduct.current = [];
    }
    setFilterValidation(callApi);
    return callApi;
  };

  return (
    <Container className='p-0 gne-container customer-container'>
      <Row>
        {/* Gross sale left panel */}
        <Col md={3}>
          <KPILibrary page={CONFIGURE_VIEW.insights} />
        </Col>

        {/* Col for base filter and chart */}
        <Col md={9}>
          <div className={classes['filter-container']}>
            <Row>
              <Col
                md={9}
                className='d-flex align-items-center'>
                {showKpiName ? (
                  <>
                    <label className='text__small d-flex justify-content-center w-100'>
                      <Form.Control
                        type='text'
                        value={insightKpiValue}
                        placeholder='KPI Card Title'
                        className={`mt-1 text__small ${classes['form-control']}`}
                        onChange={(e) => handleKPINameChange(e.target.value)}
                      />
                    </label>
                    <LinkButton
                      onClick={updateKpiName}
                      className='d-inline-block p-0'>
                      <i className='icon icon__configure-checkbackground'></i>
                    </LinkButton>
                  </>
                ) : (
                  <span className='title__bold d-flex justify-content-end'>
                    {updatedKpiName ? (
                      updatedKpiName?.length > 50 ? (
                        <OverlayTrigger
                          overlay={
                            <Popover className='p-2'>{updatedKpiName}</Popover>
                          }
                          placement='right'>
                          <span>{`${updatedKpiName?.substring(
                            0,
                            50
                          )}...`}</span>
                        </OverlayTrigger>
                      ) : (
                        updatedKpiName
                      )
                    ) : (
                      <>
                        {selectedKPI?.KpiName}
                        {showDimensionName && ` by ${selectedDimension}`}
                      </>
                    )}
                    <Col className='d-flex justify-content-end p-0 ms-1'>
                      <LinkButton
                        // className='LinkButton_link-button'
                        onClick={handleEdit}>
                        <i className='icon icon__configureEdit '></i>
                      </LinkButton>
                      {selectedKPI?.Description && (
                        <OverlayTrigger
                          overlay={tooltip()}
                          placement='right'>
                          <span
                            className={`d-inline-block justify-content-center  ${classes['info-icon']}`}>
                            <i className='icon icon__info__gray'></i>
                          </span>
                        </OverlayTrigger>
                      )}
                    </Col>
                  </span>
                )}
              </Col>
            </Row>
            {/* filters */}
            <Row>
              <Col md={6}>
                <label className='title__x-small'>Site</label>
                <SelectWithPagination
                  menuIsOpen={menuIsOpen}
                  onMenuOpen={onMenuOpen}
                  filter={configureInsightsFilters}
                  filterName={'configureInsights'}
                  fieldName='SiteName_Address'
                  isMulti={true}
                  appid={APP_ID}
                  isDefault={false}
                  expression={fieldExpression}
                  disableStatus={selectedView === 'site' ? true : false}
                />
              </Col>
              <Col md={6}>
                <label className='title__x-small'>Ecosystem</label>
                <Col>
                  <MultiSelectDropdown
                    className=''
                    options={ecosystemOptions}
                    selected={
                      selectedEcosystem?.[0] === undefined
                        ? []
                        : selectedEcosystem
                    }
                    setSelected={onEcosystemChange}
                    hasSelectAll={false}
                  />
                </Col>
              </Col>
            </Row>
            <Row className='mt-3 align-items-center'>
              <Col md={isExtra ? 3 : 2}>
                <label className='title__x-small'>Time period</label>
                <GNESingleSelect
                  value={selectedTimePeriod}
                  onChange={setTimePeriod}
                  options={timePeriodOptions}
                  className='single-select'
                  isDisabled={
                    selectedKPI?.filterEnable?.timePeriodEnable === 'N'
                  }
                />
              </Col>
              <Col md={3}>
                <label className='title__x-small'>Squad</label>
                <GNEAsyncPagination
                  value={selectedSquad || ''}
                  loadOptions={loadSquadOptions}
                  options={squadOptions}
                  onChange={onSquadChange}
                  isMulti={
                    dimensionDetails(selectedKPI, selectedDimension)
                      ?.filterSingleSelect?.squadSingleSelect === 'Y'
                      ? false
                      : true
                  }
                  components={{
                    ValueContainer: ValueContainer,
                    Option: wrapMenuList(dropdownOptions),
                  }}
                  placeholder={ALL}
                  closeMenuOnSelect={false}
                  isClearable
                  cacheUniqs={[
                    accountableProductsChecked,
                    selectedProduct,
                    firstLoad,
                  ]}
                  isDisabled={selectedKPI?.filterEnable?.squadEnable === 'N'}
                />
              </Col>
              <Col md={isExtra ? 6 : 3}>
                <Row className='mb-0'>
                  <Col
                    md={isExtra ? 8 : 5}
                    className='mb-0 p-0'>
                    <label className='title__x-small'>Products</label>
                  </Col>
                  <Col
                    md={isExtra ? 4 : 7}
                    className='mb-0 me-0 pt-2'>
                    <span className='title__x-small mt-2 me-0'>
                      <Form.Check
                        type='checkbox'
                        label='Accountable'
                        checked={accountableProductsChecked}
                        onChange={handleProductType}
                        className={accountableProductsChecked && CHECKBOXCLASS}
                        disabled={
                          selectedKPI?.filterEnable?.productEnable === 'N'
                        }
                      />
                    </span>
                  </Col>
                </Row>
                <Row className='m-0 p-0'>
                  <Col className='m-0 p-0'>
                    <GNEAsyncPagination
                      value={selectedProduct || ''}
                      loadOptions={loadProductOptions}
                      options={productOpts}
                      onChange={onProductChange}
                      isMulti={
                        dimensionDetails(selectedKPI, selectedDimension)
                          ?.filterSingleSelect?.productSingleSelect === 'Y'
                          ? false
                          : true
                      }
                      components={{
                        ValueContainer: ValueContainer,
                        Option: wrapMenuList(dropdownOptions),
                      }}
                      placeholder={ALL}
                      closeMenuOnSelect={false}
                      isClearable
                      cacheUniqs={[
                        accountableProductsChecked,
                        firstLoad,
                        selectedSquad,
                      ]}
                      isDisabled={
                        selectedKPI?.filterEnable?.productEnable === 'N'
                      }
                    />
                  </Col>
                </Row>
              </Col>
              {!isExtra && (
                <Col
                  md={4}
                  className='d-flex mt-4 mb-2 pt-2'>
                  <Button
                    variant={`btn btn-gne btn-primary me-2 ${
                      view === 'add' ? 'btn-gne__outline--add' : ''
                    }`}
                    onClick={buildExpression}
                    disabled={
                      promiseInProgress || enableApplyDisableAddtoButton
                    }>
                    Apply
                  </Button>
                  <Button
                    variant={`outline-primary btn-gne btn-gne__outline ${
                      view === 'add' ? 'btn-gne__outline--add' : ''
                    } add-button`}
                    onClick={view === 'add' ? addToHome : updateView}
                    disabled={
                      promiseInProgress || !enableApplyDisableAddtoButton
                    }>
                    {buttonText}
                  </Button>
                </Col>
              )}
            </Row>

            {isExtra && (
              <MarketingFilter
                page={CONFIGURE_VIEW.insights}
                enableApplyDisableAddtoButton={enableApplyDisableAddtoButton}
                buildExpression={buildExpression}
                editBuildExpression={editBuildExpression}
                addToHome={addToHome}
                updateView={updateView}
                buttonText={buttonText}
                view={view}
              />
            )}

            <Row className='mt-4 mb-0'>
              {/* Charts Or table */}
              {filterValidation && disableKPI ? (
                <ChartAndTableView
                  page='Insights'
                  timespan={timespan}
                  onResetClicked={() => {
                    generateMultiDimensionObject(
                      undefined,
                      undefined,
                      oldChartType
                    );
                  }}
                />
              ) : (
                !filterValidation && <ValidationMessage />
              )}
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
