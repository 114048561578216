import { ACCOUNT_TYPE } from 'constants/constants';
import GNERadio from '../radio/GNERadio';
import Account from 'components/customer/view/Account';
import Site from 'components/customer/view/Site';

const AccountTypeToggle = ({
  accountChecked,
  siteChecked,
  handleOptionChange,
  onChangeHandler,
  showPriorityAccount,
  showPrioritySite,
  isMulti,
  isInline,
  css,
  isMyPriority,
  priorityTabView,
}) => {
  return (
    <>
      {/* account type */}
      <div className={`${isInline ? 'd-flex align-items-center' : ''}`}>
        <div className='d-flex align-items-center'>
          <GNERadio
            checked={accountChecked}
            name='accountType'
            label='Account'
            value={ACCOUNT_TYPE.account}
            onChange={handleOptionChange}
          />
          <GNERadio
            checked={siteChecked}
            name='accountType'
            label='Site'
            value={ACCOUNT_TYPE.site}
            onChange={handleOptionChange}
            cssClass='last'
          />
        </div>
        {/* account dropdown */}
        <div>
          {accountChecked ? (
            <Account
              onAccountSelected={onChangeHandler}
              showPriorityAccount={showPriorityAccount}
              isMulti={isMulti}
              isMyPriority={isMyPriority}
              priorityTabView={priorityTabView}
              cssClass={`me-0 ${css ? css : ''}`}
            />
          ) : (
            <Site
              onSiteSelected={onChangeHandler}
              showPrioritySite={showPrioritySite}
              isMyPriority={isMyPriority}
              priorityTabView={priorityTabView}
              isMulti={isMulti}
              cssClass={`me-0 ${css ? css : ''}`}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AccountTypeToggle;
