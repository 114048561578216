import axiosDBClient from 'axiosDBClient';

export const addFeedback = async (payload) => {
  try {
    const response = await axiosDBClient.post('feedback', { payload });
    if (response.error) {
      throw new Error('Could save comments!');
    }

    return response.data.message;
  } catch (error) {
    throw new Error('Could save comments!');
    console.log('error', error);
  }
};
