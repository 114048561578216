import React from 'react';

import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { usePromiseTracker } from 'react-promise-tracker';
import { useSelector } from 'react-redux';

import classes from '../../home/configure-my-view/ConfigureMyViewLayout.module.scss';
import { useCallback } from 'react';
import SelectWithPagination from '../dropdown-with-pagination/SelectWithPagination';
import {
  CONFIGURE_VIEW,
  HOME_MARKETING_FILTERS,
  INSIGHT_MARKETING_FILTERS,
} from 'constants/constants';

const MarketingFilter = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  const dynamicStore = props.page === 'my view' ? 'HomeMyView' : 'CustomerView';
  const [menuIsOpen, setMenuIsOpen] = useState({
    Channel: false,
    DiscussionTopic: false,
    PayerStakeholderType: false,
    TemplateName: false,
    SurveyType: false,
    PresentationName: false,
    KeyMessage: false,
  });

  const marketingFilters = useSelector(
    (state) => state?.[dynamicStore]?.marketingFilters
  );
  const selectedView = useSelector(
    (state) => state?.CustomerView?.selectedView
  );
  const selectedAccount = useSelector(
    (state) => state?.[dynamicStore]?.selectedAccount
  );
  const selectedChannel = useSelector(
    (state) => state?.[dynamicStore]?.marketingFilters.Channel
  );
  const selectedDiscussionTopic = useSelector(
    (state) => state?.[dynamicStore]?.marketingFilters.DiscussionTopic
  );
  const selectedStakeholderType = useSelector(
    (state) => state?.[dynamicStore]?.marketingFilters.PayerStakeholderType
  );
  const selectedTemplateName = useSelector(
    (state) => state?.[dynamicStore]?.marketingFilters.TemplateName
  );
  const selectedSurveyType = useSelector(
    (state) => state?.[dynamicStore]?.marketingFilters.SurveyType
  );
  const selectedPresentationName = useSelector(
    (state) => state?.[dynamicStore]?.marketingFilters.PresentationName
  );
  const selectedKeyMessage = useSelector(
    (state) => state?.[dynamicStore]?.marketingFilters.KeyMessage
  );
  const selectedKPI = useSelector(
    (state) => state?.[dynamicStore]?.selectedKPI
  );

  const onMenuOpen = useCallback((filterName, value) => {
    let menuIsOpenLocal = menuIsOpen;
    if (filterName !== '' && value) {
      menuIsOpenLocal = Object.keys(menuIsOpen).reduce((acc, key) => {
        return { ...acc, [key]: filterName === key };
      }, {});
    } else if ((filterName !== '' && !value) || filterName === '') {
      menuIsOpenLocal = Object.keys(menuIsOpen).reduce((acc, key) => {
        return { ...acc, [key]: false };
      }, {});
    }
    setMenuIsOpen({ ...menuIsOpenLocal });
  }, []);

  return (
    <div>
      <Row className='mt-2'>
        {(selectedKPI?.filterEnable?.channelNameEnable === 'Y' ||
          selectedKPI?.filterEnable?.stakeholderTypeEnable === 'Y') && (
          <Col md={8}>
            <Row>
              {selectedKPI?.filterEnable?.channelNameEnable === 'Y' && (
                <Col
                  md={4}
                  className={classes['padding-0']}>
                  <label className='title__x-small'>Channel</label>
                  <SelectWithPagination
                    menuIsOpen={menuIsOpen}
                    onMenuOpen={onMenuOpen}
                    filter={marketingFilters}
                    filterName={
                      props.page === CONFIGURE_VIEW.myView
                        ? HOME_MARKETING_FILTERS
                        : INSIGHT_MARKETING_FILTERS
                    }
                    fieldName='Channel'
                    isMulti={true}
                    isTitleCase={false}
                    cacheUniqs={[
                      selectedAccount,
                      selectedDiscussionTopic,
                      selectedStakeholderType,
                    ]}
                    page={props.page}
                  />
                </Col>
              )}
              {selectedKPI?.filterEnable?.discussionTopicEnable === 'Y' && (
                <Col md={4}>
                  <label className='title__x-small'>Discussion Topic</label>
                  <SelectWithPagination
                    menuIsOpen={menuIsOpen}
                    onMenuOpen={onMenuOpen}
                    filter={marketingFilters}
                    filterName={
                      props.page === 'my view'
                        ? HOME_MARKETING_FILTERS
                        : INSIGHT_MARKETING_FILTERS
                    }
                    fieldName='DiscussionTopic'
                    isMulti={true}
                    isTitleCase={false}
                    cacheUniqs={[
                      selectedAccount,
                      selectedChannel,
                      selectedStakeholderType,
                    ]}
                    page={props.page}
                  />
                </Col>
              )}
              {selectedKPI?.filterEnable?.stakeholderTypeEnable === 'Y' && (
                <Col md={4}>
                  <Row className='mb-0'>
                    <Col className='mb-0 p-0'>
                      <label className='title__x-small'>Stakeholder Type</label>
                    </Col>
                  </Row>
                  <Row className='m-0 p-0'>
                    <Col className='m-0 p-0'>
                      <SelectWithPagination
                        menuIsOpen={menuIsOpen}
                        onMenuOpen={onMenuOpen}
                        filter={marketingFilters}
                        filterName={
                          props.page === 'my view'
                            ? HOME_MARKETING_FILTERS
                            : INSIGHT_MARKETING_FILTERS
                        }
                        fieldName='PayerStakeholderType'
                        isMulti={true}
                        isTitleCase={false}
                        cacheUniqs={[
                          selectedAccount,
                          selectedChannel,
                          selectedDiscussionTopic,
                        ]}
                        page={props.page}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
        )}
        {selectedKPI?.filterEnable?.templateNameEnable === 'Y' && (
          <>
            <Col md={3}>
              <label className='title__x-small'>Template Name</label>

              <SelectWithPagination
                menuIsOpen={menuIsOpen}
                onMenuOpen={onMenuOpen}
                filter={marketingFilters}
                filterName={
                  props.page === 'my view'
                    ? HOME_MARKETING_FILTERS
                    : INSIGHT_MARKETING_FILTERS
                }
                fieldName='TemplateName'
                isMulti={true}
                isTitleCase={false}
                cacheUniqs={[selectedAccount]}
                page={props.page}
              />
            </Col>

            <Col md={3}></Col>

            <Col md={2}></Col>
          </>
        )}
        {selectedKPI?.filterEnable?.surveyTypeEnable === 'Y' && (
          <>
            {' '}
            {selectedKPI?.filterEnable?.discussionTopicEnable === 'Y' && (
              <Col md={3}>
                <label className='title__x-small'>Discussion Topic</label>

                <SelectWithPagination
                  menuIsOpen={menuIsOpen}
                  onMenuOpen={onMenuOpen}
                  filter={marketingFilters}
                  filterName={
                    props.page === 'my view'
                      ? HOME_MARKETING_FILTERS
                      : INSIGHT_MARKETING_FILTERS
                  }
                  fieldName='DiscussionTopic'
                  isMulti={true}
                  isTitleCase={false}
                  cacheUniqs={[selectedAccount, selectedSurveyType]}
                  page={props.page}
                />
              </Col>
            )}
            {selectedKPI?.filterEnable?.surveyTypeEnable === 'Y' && (
              <Col md={3}>
                <Row className='mb-0'>
                  <Col className='mb-0 p-0'>
                    <label className='title__x-small'>Survey Type</label>
                  </Col>
                </Row>
                <Row className='m-0 p-0'>
                  <Col className='m-0 p-0'>
                    <SelectWithPagination
                      menuIsOpen={menuIsOpen}
                      onMenuOpen={onMenuOpen}
                      filter={marketingFilters}
                      filterName={
                        props.page === 'my view'
                          ? HOME_MARKETING_FILTERS
                          : INSIGHT_MARKETING_FILTERS
                      }
                      fieldName='SurveyType'
                      isMulti={true}
                      isTitleCase={false}
                      cacheUniqs={[selectedAccount, selectedDiscussionTopic]}
                      page={props.page}
                    />
                  </Col>
                </Row>
              </Col>
            )}
            {selectedKPI?.filterEnable?.surveyTypeEnable === 'Y' && (
              <Col md={2}></Col>
            )}
          </>
        )}
        {(selectedKPI?.filterEnable?.presentationNameEnable === 'Y' ||
          selectedKPI?.filterEnable?.keyMessageEnable === 'Y') && (
          <>
            {' '}
            {selectedKPI?.filterEnable?.presentationNameEnable === 'Y' && (
              <Col md={3}>
                <label className='title__x-small'>Presentation Name</label>

                <SelectWithPagination
                  menuIsOpen={menuIsOpen}
                  onMenuOpen={onMenuOpen}
                  filter={marketingFilters}
                  filterName={
                    props.page === 'my view'
                      ? HOME_MARKETING_FILTERS
                      : INSIGHT_MARKETING_FILTERS
                  }
                  fieldName='PresentationName'
                  isMulti={true}
                  isTitleCase={false}
                  cacheUniqs={[selectedAccount, selectedKeyMessage]}
                  page={props.page}
                />
              </Col>
            )}
            {selectedKPI?.filterEnable?.keyMessageEnable === 'Y' && (
              <Col md={3}>
                <Row className='mb-0'>
                  <Col className='mb-0 p-0'>
                    <label className='title__x-small'>Key Message</label>
                  </Col>
                </Row>
                <Row className='m-0 p-0'>
                  <Col className='m-0 p-0'>
                    <SelectWithPagination
                      menuIsOpen={menuIsOpen}
                      onMenuOpen={onMenuOpen}
                      filter={marketingFilters}
                      filterName={
                        props.page === 'my view'
                          ? HOME_MARKETING_FILTERS
                          : INSIGHT_MARKETING_FILTERS
                      }
                      fieldName='KeyMessage'
                      isMulti={true}
                      isTitleCase={false}
                      cacheUniqs={[selectedAccount, selectedPresentationName]}
                      page={props.page}
                    />
                  </Col>
                </Row>
              </Col>
            )}
            {selectedKPI?.filterEnable?.keyMessageEnable === 'Y' && (
              <Col md={2}></Col>
            )}
          </>
        )}
        <Col
          md={4}
          className={classes['configure-apply']}>
          <Button
            variant={`btn btn-gne btn-primary me-2  ${
              props.view === 'add' ? 'btn-gne__outline--add' : ''
            }`}
            onClick={props.buildExpression}
            className={classes['configure-apply']}
            disabled={promiseInProgress || props.enableApplyDisableAddtoButton}>
            Apply
          </Button>

          <Button
            variant={`outline-primary btn-gne btn-gne__outline ${
              props.view === 'add' ? 'btn-gne__outline--add' : ''
            } add-button`}
            onClick={props.view === 'edit' ? props.updateView : props.addToHome}
            className={classes['configure-apply']}
            disabled={
              promiseInProgress || !props.enableApplyDisableAddtoButton
            }>
            {props.buttonText}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default MarketingFilter;
