import { formatLabel } from 'utils/utils';
import classes from './NoDimension.module.scss';
import {
  BENCHMARK_LEGEND_FIRST,
  BENCHMARK_LEGEND_SECOND,
} from './../../../constants/constants';

const NoDimension = (props) => {
  const {
    isColor,
    noDimensionValue,
    benchmarkNoDimensionValue,
    kpiName,
    isValue,
  } = props;
  return (
    <>
      {benchmarkNoDimensionValue === '' ? (
        <p
          className={`money ${
            !isColor ? '' : isValue ? 'money__profit' : 'money__loss'
          } `}>
          <i
            className={` icon icon__${
              !isColor ? '' : isValue > 0 ? 'positive' : 'negative'
            } m-2 mb-1`}></i>
          {formatLabel(noDimensionValue, kpiName)}
        </p>
      ) : (
        <>
          <div className={`${classes['nodimension_container']} `}>
            <div className={`${classes['seperator']}`}>
              <p
                className={`money ${
                  !isColor ? '' : isValue ? 'money__profit' : 'money__loss'
                } `}>
                <i
                  className={` icon icon__${
                    !isColor ? '' : isValue > 0 ? 'positive' : 'negative'
                  } m-2 mb-1`}></i>
                {formatLabel(noDimensionValue, kpiName)}
              </p>
              <p className='d-flex text text__x-small justify-content-center'>
                {BENCHMARK_LEGEND_FIRST}
              </p>
            </div>

            <div className='mt-2'>
              <p
                className={`money ${
                  !isColor ? '' : isValue ? 'money__profit' : 'money__loss'
                } ms-3 text text__m-large`}>
                <i
                  className={` icon icon__${
                    !isColor ? '' : isValue > 0 ? 'positive' : 'negative'
                  } m-2 mb-1`}></i>
                {formatLabel(benchmarkNoDimensionValue, kpiName)}
              </p>
              <p className=' d-flex text text__x-small justify-content-center'>
                {BENCHMARK_LEGEND_SECOND}
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default NoDimension;
