import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import ChartAndTableView from 'components/charts/ChartAndTableView';
import MultiSelectDropdown from 'components/home/configure-my-view/MultiSelectDropdown';
import KPILibrary from 'components/home/configure-my-view/kpi-library/KPILibrary';
import LinkButton from 'components/shared/button/LinkButton';
import {
  Form,
  Button,
  Row,
  Col,
  Container,
  OverlayTrigger,
  Tooltip,
  Popover,
} from 'react-bootstrap';
import { Fade } from 'react-awesome-reveal';
import { toast } from 'react-toastify';
import {
  updatedKpiNameLength,
  kpiTitleLimitExceedMessage,
  COMPARE_BENCHMARK_NO_DIMENSION_CHARTTYPE,
  COMPARE_BENCHMARK,
  BENCHMARK_LEGEND_FIRST,
  COMPARE_KPI,
} from 'constants/constants';

import {
  setSelectedAccount,
  setSelectedEcosystem,
  setSelectedSquad,
  setChannelFilter,
  setDiscussionTopicFilter,
  setStakeholderTypeFilter,
  setTemplateNameFilter,
  setSurveyTypeFilter,
  setPresentationNameFilter,
  setKeyMessageFilter,
  setSelectedProduct,
  setSelectedTimePeriod,
  setExpression,
  setAllAccountOptions,
  setUserFocusAccounts,
  setCards,
  setFocusAccountChecked,
  setEcosystemOptions,
  setSelectedAppId,
  setNoFocusedAccount,
  setTimePeriods,
  setKpiandDimensions,
  setSelectedKPI,
  setSelectedKpiName,
  setDimensionName,
  setSelectedAppLink,
  setSelectedKpiId,
  setTimePeriodOptions,
  setSquadOptions,
  setDimensionId,
  setChartData,
  setNoDimensionValue,
  setChartType,
  setOldChartType,
  setBenchmarkNoDimensionValue,
  setSelectedCardView,
  setKPIclickedStatus,
  setMarketingFilters,
  setAdvanceOptions,
  setAdvanceDropdownOptions,
} from 'store/home/homeMyViewSlice';

import {
  getProductData,
  addToMyView,
  getEcosystemData,
  updateMyView,
  getTimespan,
  getSquadData,
} from 'store/home/home-my-view-actions';

import classes from '../home/configure-my-view/ConfigureMyViewLayout.module.scss';

import {
  ValueContainer,
  advanceExpressionToSave,
  buildAdvancePropsObject,
  createExpression,
  dropdownOptions,
  getAdvanceFilterValue,
  getChartTypeByDimension,
  replaceUndefinedWithEmptyArray,
} from 'utils/utils';
import {
  ACCOUNT_TYPE,
  ALL,
  CHART_LIMIT_ERROR,
  CONFIGURE_VIEW,
  EXPRESSION_PLACEHOLDER_TEXT,
  VIEW,
  CHECKBOXCLASS,
  FILTER_PLACE_HOLDER,
} from 'constants/constants';
import {
  CustomDropdownIndicator,
  getAccountExpression,
  isAllselected,
  isArray,
} from 'utils/utils';
import { usePromiseTracker } from 'react-promise-tracker';
import Account from './../customer/view/Account';
import Site from './../customer/view/Site';
import { wrapMenuList } from 'react-select-async-paginate';
import { getArrayLabelValuesWithoutSpace } from './../../utils/utils';
import {
  getAccountsOrSites,
  getAllKpiAndDimension,
  getDimensionsTimePeriodOptions,
  getEcosystems,
  getMultiDimensionChartData,
  getTimePeriodOptions,
  getTimePeriods,
} from 'services/trends.service';
import axiosDBClient from 'axiosDBClient';
import ValidationMessage from 'components/shared/tiles/ValidationMessage';
import {
  dimensionDetails,
  getSelectedDimensionId,
} from 'components/customer/view/utils';
import MarketingFilter from 'components/shared/custom-filter/MarketingFilter';
import { HomeMyViewInitialState } from 'store/home/homeMyViewInitialState';
import GNESingleSelect from 'components/shared/dropdown/GNESingleSelect';
import GNEAsyncPagination from 'components/shared/dropdown-with-pagination/GNEAsyncPagination';

export default function TrendsContainer({
  buttonText,
  data,
  view,
  setKpiTitleUpdateMessage,
}) {
  const dispatch = useDispatch();
  const [disableKPI, setDisableKPI] = useState(false);
  const selectedCardView = useSelector(
    (state) => state?.HomeMyView?.selectedCardView
  );

  const { promiseInProgress } = usePromiseTracker();
  const { unixId, roleId } = useSelector((state) => state.Auth.userClaimObject);

  const kpiId = useSelector((state) => state?.HomeMyView?.selectedKpiId);
  const expression = useSelector((state) => state?.HomeMyView?.expression);
  const focusCheck = useSelector(
    (state) => state?.HomeMyView?.focusAccountChecked
  );
  const [accountState, setAccountState] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [expressionToSave, setExpressionToSave] = useState('');

  //const [filterExpression, setfilterExpression] = useState('');
  const filterExpression = useRef('');

  const userFocusAccounts = useSelector(
    (state) => state?.HomeMyView?.userFocusAccounts
  );

  const noFocusedAccount = useSelector(
    (state) => state?.HomeMyView?.noFocusedAccount
  );

  const timespan = useSelector((state) => state?.HomeMyView?.timespan);

  const dimensionName = useSelector(
    (state) => state?.HomeMyView?.dimensionName
  );

  const allAccountOptions = useSelector(
    (state) => state?.HomeMyView?.allAccountOptions
  );

  const localAccount = useRef(allAccountOptions);

  const selectedAccount = useSelector(
    (state) => state?.HomeMyView?.selectedAccount
  );

  const selectedSquad = useSelector(
    (state) => state?.HomeMyView?.selectedSquad
  );
  const selectedChannel = useSelector(
    (state) => state?.HomeMyView?.marketingFilters?.Channel
  );
  const selectedDiscussionTopic = useSelector(
    (state) => state?.HomeMyView?.marketingFilters?.DiscussionTopic
  );
  const selectedStakeholderType = useSelector(
    (state) => state?.HomeMyView?.marketingFilters?.PayerStakeholderType
  );
  const selectedTemplateName = useSelector(
    (state) => state?.HomeMyView?.marketingFilters?.TemplateName
  );
  const selectedSurveyType = useSelector(
    (state) => state?.HomeMyView?.marketingFilters?.SurveyType
  );
  const selectedPresentationName = useSelector(
    (state) => state?.HomeMyView?.marketingFilters?.PresentationName
  );
  const selectedKeyMessage = useSelector(
    (state) => state?.HomeMyView?.marketingFilters?.KeyMessage
  );

  const squadOptions = useSelector((state) => state?.HomeMyView?.squadOptions);
  const ecosystemOptions = useSelector(
    (state) => state?.HomeMyView?.ecosystemOptions
  );

  const selectedEcosystem = useSelector(
    (state) => state?.HomeMyView?.selectedEcosystem
  );

  const localEcosystem = useRef(selectedEcosystem);

  const allProductOptions = useSelector(
    (state) => state?.HomeMyView?.productOptions
  );

  const accountableProductOptions = allProductOptions?.filter(
    (product) => product?.priorityFlag === 'Y'
  );

  const selectedProduct = useSelector(
    (state) => state?.HomeMyView?.selectedProduct
  );

  const timePeriodOptions = useSelector(
    (state) => state?.HomeMyView?.timePeriodOptions
  );

  const timePeriods = useSelector((state) => state?.HomeMyView?.timePeriod);

  const selectedTimePeriod = useSelector(
    (state) => state?.HomeMyView?.selectedTimePeriod
  );

  const cards = useSelector((state) => state?.HomeMyView?.cards);
  const chartData = useSelector((state) => state?.HomeMyView?.chartData);

  const selectedKPI = useSelector((state) => state?.HomeMyView?.selectedKPI);

  const selectedDimension = useSelector(
    (state) => state?.HomeMyView?.dimensionName
  );
  const selectedDimensionId = useSelector(
    (state) => state?.HomeMyView?.dimesnionId
  );
  const noDimensionValue = useSelector(
    (state) => state?.HomeMyView?.noDimensionValue
  );

  const showDimensionName = selectedDimension === 'No Dimension' ? false : true;
  const selectedAppId = useSelector(
    (state) => state?.HomeMyView?.selectedAppId
  );
  const selectedAdvanceOptions = useSelector(
    (state) => state?.HomeMyView?.advanceOptions
  );

  const chartType = useSelector((state) => state?.HomeMyView?.chartType);
  const appLink = useSelector((state) => state?.HomeMyView?.selectedAppLink);
  const oldChartType = useSelector((state) => state?.HomeMyView?.oldChartType);
  const [prevFocusedAccountState, setPrevFocusedAccountState] = useState(true);
  const [productOpts, setProductOpts] = useState(allProductOptions);
  const [selectOptions, setSelectedOptions] = useState('account');
  const [editFirstLoad, setEditFirstLoad] = useState(false);
  // const [advanceCompareProps, setAdvanceCompareProps] = useState('');
  const advanceComparePropsRef = useRef('');
  const priorityRef = useRef(false);
  const kpiTriggered = useRef(false);
  const isInitializing = useRef(false);
  const timespanExpression = useRef('');
  const firstEditRenderCustomerView = useRef(false);
  const cardViewTypeEditView = useRef('');
  const localSelectedSquad = useRef([]);
  const localSelectedChannel = useRef([]);
  const localSelectedDiscussionTopic = useRef([]);
  const localSelectedStakeholderType = useRef([]);
  const localSelectedTemplateName = useRef([]);
  const localSelectedSurveyType = useRef([]);
  const localSelectedPresentationName = useRef([]);
  const localSelectedKeyMessage = useRef([]);
  const localSelectedProduct = useRef([]);
  const [filterValidation, setFilterValidation] = useState(true);
  const vReplaceStringForDynamicFilter = useRef('');
  let kpiNameValue = '';

  kpiNameValue = showDimensionName
    ? selectedKPI?.KpiName + ` by ${selectedDimension}`
    : selectedKPI['KpiName'];

  const [showKPIName, setshowKPIName] = useState(false);
  const [newKpiTitle, setnewKpiTitle] = useState(kpiNameValue);
  const [updatedKpiName, setupdatedKpiName] = useState(newKpiTitle);
  const userRestrictedRole = useSelector(
    (state) => state?.Auth?.userRestriction
  );
  const userRestrictedEcosystemt = useSelector(
    (state) => state?.Auth?.userRestrictedEcosystemt
  );
  const restrictedProductSquad = useSelector(
    (state) => state?.Auth?.restrictedProductSquad
  );
  const [accountableProductsChecked, setAccountableProductsChecked] = useState(
    userRestrictedRole?.productRestrictions === 'Y' ? false : true
  );
  const handleEdit = () => {
    setshowKPIName(true);
  };

  const handleKPINameChange = (val) => {
    setnewKpiTitle(val);
  };

  const [editKpiTitle, seteditKpiTitle] = useState('');

  useEffect(() => {
    if (data !== undefined) {
      seteditKpiTitle(data.title);
      setupdatedKpiName(data.title);
    }
    if (view === VIEW.edit && updatedKpiName !== editKpiTitle) {
      setupdatedKpiName(data.title);
      setnewKpiTitle(data.title);
    } else if (view === VIEW.edit) {
      seteditKpiTitle(kpiNameValue);
      setupdatedKpiName(kpiNameValue);
      setnewKpiTitle(kpiNameValue);
    } else {
      setupdatedKpiName(kpiNameValue);
      setnewKpiTitle(kpiNameValue);
    }
    if (selectedKPI?.filterEnable?.productEnable === 'N') {
      setAccountableProductsChecked(false);
      dispatch(setSelectedProduct([]));
      localSelectedProduct.current = [];
      dispatch(setSelectedSquad([]));
      localSelectedSquad.current = [];
    }
    // else {
    //   setAccountableProductsChecked(
    //     userRestrictedRole?.productRestrictions === 'Y' ? false : true
    //   );
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kpiNameValue]);

  const updateKPIName = () => {
    if (newKpiTitle.length > updatedKpiNameLength) {
      toast.warning(kpiTitleLimitExceedMessage);
      setnewKpiTitle(updatedKpiName);
    } else if (newKpiTitle !== updatedKpiName && newKpiTitle.length > 0) {
      setupdatedKpiName(newKpiTitle);
    } else {
      setnewKpiTitle(updatedKpiName);
    }
    setshowKPIName(false);
  };

  const [enableApplyDisableAddtoButton, setEnableApplyDisableAddtoButton] =
    useState(true);

  const loadTimePeriods = async (kpiId, dimensionName) => {
    const timePeriods = await getTimePeriods(kpiId);
    dispatch(setTimePeriods(timePeriods));

    let dimensionId = getSelectedDimensionId(selectedKPI, dimensionName);

    let isDimensionLevelExpression =
      selectedKPI?.filterEnable?.EnableDimensionLevelExpression === 'Y';

    const timePeriodOptions = isDimensionLevelExpression
      ? await getDimensionsTimePeriodOptions(timePeriods, dimensionId)
      : await getTimePeriodOptions(timePeriods);

    dispatch(setTimePeriodOptions(timePeriodOptions));
    return timePeriods;
  };

  useEffect(() => {
    setEnableApplyDisableAddtoButton(false);
  }, [
    selectedAccount,
    selectedEcosystem,
    selectedProduct,
    selectedSquad,
    selectedChannel,
    selectedDiscussionTopic,
    selectedStakeholderType,
    selectedTemplateName,
    selectedSurveyType,
    selectedPresentationName,
    selectedKeyMessage,
    selectedTimePeriod,
    accountableProductsChecked,
    focusCheck,
    selectedKPI,
  ]);
  useEffect(() => {
    isInitializing.current = true;
    //editflow
    if (view === 'edit') {
      setDisableKPI(false);
      setKpiTitleUpdateMessage(false);
      const {
        filter,
        expression,
        advanceEnabled,
        advanceFilterValue,
        advanceType,
        advanceExpression,
      } = data;
      const {
        selectedAccount,
        selectedProduct,
        selectedTimePeriod,
        focusAccountChecked,
        selectedEcosystem,
        accountableProduct,
        selectedSquad,
        viewType,
        selectedKPI,
        dimensionName,
      } = filter;

      let {
        selectedChannel,
        selectedDiscussionTopic,
        selectedStakeholderType,
        selectedTemplateName,
        selectedSurveyType,
        selectedPresentationName,
        selectedKeyMessage,
        chartType,
      } = filter;
      selectedChannel = replaceUndefinedWithEmptyArray(selectedChannel);
      selectedDiscussionTopic = replaceUndefinedWithEmptyArray(
        selectedDiscussionTopic
      );
      selectedStakeholderType = replaceUndefinedWithEmptyArray(
        selectedStakeholderType
      );
      selectedTemplateName =
        replaceUndefinedWithEmptyArray(selectedTemplateName);
      selectedSurveyType = replaceUndefinedWithEmptyArray(selectedSurveyType);
      selectedPresentationName = replaceUndefinedWithEmptyArray(
        selectedPresentationName
      );
      selectedKeyMessage = replaceUndefinedWithEmptyArray(selectedKeyMessage);
      //setting old chartType
      dispatch(
        setOldChartType(
          getChartTypeByDimension(selectedKPI?.dimensions, dimensionName)
        )
      );

      if (advanceEnabled === 'Y') {
        advanceComparePropsRef.current = buildAdvancePropsObject(
          advanceFilterValue,
          advanceType,
          advanceExpression,
          selectedDimension,
          chartType
        );
      } else {
        advanceComparePropsRef.current = '';
      }

      dispatch(setAdvanceOptions(advanceComparePropsRef.current));

      cardViewTypeEditView.current = viewType;
      if (userRestrictedRole?.isNational === 'N') {
        dispatch(setEcosystemOptions(userRestrictedEcosystemt));
      } else if (ecosystemOptions && ecosystemOptions?.length === 0) {
        dispatch(getEcosystemData(unixId, roleId));
      }
      localEcosystem.current = [...selectedEcosystem];
      localSelectedProduct.current = selectedProduct;
      dispatch(setFocusAccountChecked(focusAccountChecked));

      const getData = async () => {
        const kpiDimensions = await getAllKpiAndDimension(
          roleId,
          viewType === ACCOUNT_TYPE.site ? 'Account' : null
        );
        dispatch(setKpiandDimensions(kpiDimensions?.result));

        const timePeriods = await loadTimePeriods(
          selectedKPI?.KpiId,
          dimensionName
        );
        const accounts = await getAccountsOrSites(unixId, viewType, roleId);

        localAccount.current = [...accounts];

        dispatch(
          setSelectedAccount(isArray(selectedAccount) ? selectedAccount : [])
        );

        dispatch(setSelectedEcosystem(selectedEcosystem));

        isInitializing.current = false;

        await processAccountData(accounts, true, focusAccountChecked);

        dispatch(setExpression(expression));
        // generateMultiDimensionObject('');
        dispatch(setSelectedTimePeriod(selectedTimePeriod));

        setAccountableProductsChecked(accountableProduct);
        setSelectedOptions(viewType);

        dispatch(setSelectedAccount(selectedAccount));

        let checkedProducts =
          selectedProduct === 'All'
            ? accountableProduct
              ? accountableProductOptions
              : allProductOptions
            : selectedProduct;

        await loadSquadOptions();
        await loadProductOptions();
        dispatch(setSelectedProduct(checkedProducts));
        let checkedSquads =
          selectedSquad === 'All' ? squadOptions : selectedSquad;
        dispatch(setSelectedSquad(checkedSquads));

        dispatch(
          setChannelFilter({ options: [], selectedValue: selectedChannel })
        );
        dispatch(
          setDiscussionTopicFilter({
            options: [],
            selectedValue: selectedDiscussionTopic,
          })
        );
        dispatch(
          setStakeholderTypeFilter({
            options: [],
            selectedValue: selectedStakeholderType,
          })
        );
        dispatch(
          setTemplateNameFilter({
            options: [],
            selectedValue: selectedTemplateName,
          })
        );
        dispatch(
          setSurveyTypeFilter({
            options: [],
            selectedValue: selectedSurveyType,
          })
        );
        dispatch(
          setPresentationNameFilter({
            options: [],
            selectedValue: selectedPresentationName,
          })
        );
        dispatch(
          setKeyMessageFilter({
            options: [],
            selectedValue: selectedKeyMessage,
          })
        );

        // pass all variable that build need
        // because state isn't updated in time for use to use the data
        editBuildExpression(
          focusAccountChecked,
          selectedAccount,
          selectedProduct,
          selectedTimePeriod,
          selectedEcosystem,
          accountableProduct,
          selectedSquad,
          selectedKPI,
          accounts,
          dimensionName,
          timePeriods,
          selectedKPI.AppId,
          viewType,
          advanceComparePropsRef.current,
          selectedChannel,
          selectedDiscussionTopic,
          selectedStakeholderType,
          selectedTemplateName,
          selectedSurveyType,
          selectedPresentationName,
          selectedKeyMessage
        );
      };

      getData();
      setDisableKPI(true);
    } else {
      // add flow
      // setAdvanceCompareProps('');

      dispatch(setSelectedCardView('chart'));
      advanceComparePropsRef.current = '';
      dispatch(setAdvanceOptions(advanceComparePropsRef.current));
      if (userRestrictedRole?.isNational === 'N') {
        dispatch(setEcosystemOptions(userRestrictedEcosystemt));
      } else if (ecosystemOptions && ecosystemOptions?.length === 0) {
        dispatch(getEcosystemData(unixId, roleId));
      }

      const getData = async () => {
        const accounts = await getAccountsOrSites(
          unixId,
          ACCOUNT_TYPE.account,
          roleId
        );
        localAccount.current = [...accounts];
        isInitializing.current = false;
        await loadProductOptions();
        await processAccountData(accounts, false);
      };

      getData();
      dispatch(setSelectedProduct([]));
      dispatch(setSelectedSquad([]));
      dispatch(setMarketingFilters(HomeMyViewInitialState.marketingFilters));
      localSelectedChannel.current = [];
      localSelectedDiscussionTopic.current = [];
      localSelectedStakeholderType.current = [];
      localSelectedTemplateName.current = [];
      localSelectedSurveyType.current = [];
      localSelectedPresentationName.current = [];
      localSelectedKeyMessage.current = [];

      if (productOpts && productOpts?.length === 0) {
        dispatch(getProductData());
      } else {
        if (accountableProductsChecked) {
          setProductOpts(productOpts);
        }
      }

      if (squadOptions && squadOptions?.length === 0) {
        dispatch(getSquadData([]));
      }

      if (focusCheck) {
        dispatch(setSelectedEcosystem([]));
        localEcosystem.current = [];

        dispatch(setUserFocusAccounts(userFocusAccounts));
        dispatch(setSelectedAccount(userFocusAccounts));
      }
      setEnableApplyDisableAddtoButton(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (view === VIEW.edit) {
      if (firstEditRenderCustomerView.current) {
        advanceComparePropsRef.current = '';
        dispatch(setAdvanceOptions(advanceComparePropsRef.current));
      }
    } else {
      advanceComparePropsRef.current = '';
      dispatch(setAdvanceOptions(advanceComparePropsRef.current));
    }
    if (selectedKPI?.filterEnable?.accountEnable === 'Y') {
      setAccountState(false);
    } else {
      setAccountState(true);
      dispatch(setSelectedAccount([]));

      if (priorityRef?.current?.checked) {
        dispatch(setFocusAccountChecked(false));
        priorityRef.current.checked = false;
      }

      if (
        localEcosystem?.current?.toString() !==
        ecosystemOptions?.[0]?.toString()
      ) {
        dispatch(setSelectedEcosystem([]));
      }
    }
    let callApi = checkSingleSelectEligibility();
    // only execute if there isn't an api call in progress
    if (!promiseInProgress && !isInitializing.current && callApi) {
      kpiTriggered.current = true;
      buildExpression();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDimensionId]);

  useEffect(() => {
    if (selectedEcosystem?.[0] !== undefined) {
      localEcosystem.current = [...selectedEcosystem];
    }
    // if (kpiTriggered.current) {
    //   setTimeout(() => {
    //     buildExpression();
    //     kpiTriggered.current = false;
    //   }, 500);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEcosystem]);

  //'Cleanup is in progress'
  useEffect(() => {
    return () => {
      dispatch(setSelectedAppId(null));
      // dispatch(setKpiandDimensions(null));
      dispatch(setSelectedKPI(null));
      dispatch(setSelectedKpiName(null));
      dispatch(setDimensionName(null));
      dispatch(setSelectedAppLink(null));
      dispatch(setSelectedKpiId(null));
      dispatch(setDimensionId(null));
      dispatch(setOldChartType('KPI'));
      dispatch(setChartType('KPI'));
      dispatch(setAdvanceOptions(''));
      dispatch(setAdvanceDropdownOptions([]));
      dispatch(setSelectedTimePeriod([]));
      dispatch(setSelectedEcosystem([]));
    };
  }, []);

  useEffect(() => {
    const loadViewRelatedDataAsync = async () => {
      await loadViewRelatedData();
    };
    if (
      view !== VIEW.edit ||
      (firstEditRenderCustomerView.current && view === VIEW.edit)
    ) {
      loadViewRelatedDataAsync();
    }
    if (view === VIEW.edit && cardViewTypeEditView.current === selectOptions)
      firstEditRenderCustomerView.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptions]);

  useEffect(() => {
    localSelectedSquad.current = selectedSquad;
    localSelectedChannel.current = selectedChannel?.selectedValue;
    localSelectedDiscussionTopic.current =
      selectedDiscussionTopic?.selectedValue;
    localSelectedStakeholderType.current =
      selectedStakeholderType?.selectedValue;
    localSelectedTemplateName.current = selectedTemplateName?.selectedValue;
    localSelectedSurveyType.current = selectedSurveyType?.selectedValue;
    localSelectedPresentationName.current =
      selectedPresentationName?.selectedValue;
    localSelectedKeyMessage.current = selectedKeyMessage?.selectedValue;
  }, [
    selectedSquad,
    selectedChannel,
    selectedDiscussionTopic,
    selectedStakeholderType,
    selectedTemplateName,
    selectedSurveyType,
    selectedPresentationName,
    selectedKeyMessage,
  ]);

  useEffect(() => {
    localSelectedProduct.current = selectedProduct;
  }, [selectedProduct]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadViewRelatedData = async () => {
    setDisableKPI(false);
    let res;

    if (selectOptions === ACCOUNT_TYPE.site) {
      res = await getAllKpiAndDimension(roleId, 'Account');
    } else {
      res = await getAllKpiAndDimension(roleId);
    }

    let getDimensionId = getSelectedDimensionId(selectedKPI, selectedDimension);

    let isDimensionLevelExpression =
      selectedKPI?.filterEnable?.EnableDimensionLevelExpression === 'Y';

    const timePeriods = await getTimePeriods(res.result[0]?.KpiId);

    dispatch(setTimePeriods(timePeriods));

    const timePeriodOptions = isDimensionLevelExpression
      ? await getDimensionsTimePeriodOptions(timePeriods, getDimensionId)
      : await getTimePeriodOptions(timePeriods);

    dispatch(setTimePeriodOptions(timePeriodOptions));

    let exp = timePeriods.filter((tp) => tp.uXDefaultValue === 'Y')[0];

    dispatch(
      setSelectedTimePeriod({
        label: exp.timePeriodName,
        value: exp.timePeriodId,
      })
    );
    dispatch(setSelectedAppId(res.result[0]?.AppId));
    dispatch(setKpiandDimensions(res.result));
    dispatch(setSelectedKPI(res.result[0]));
    dispatch(setSelectedKpiName(res.result[0]?.KpiName));
    dispatch(setDimensionName(res.result[0]?.dimensions[0]?.dimensionName));
    dispatch(setSelectedAppLink(res.result[0]?.appLink));
    dispatch(setSelectedKpiId(res.result[0]?.KpiId));
    dispatch(setDimensionId(res.result[0]?.dimensions[0]?.id));
    // if (res.result[0]?.AppId === selectedAppId) buildExpression();
    if (res.result[0]?.dimensions[0]?.id === selectedDimensionId)
      buildExpression();
    setDisableKPI(true);
  };

  const processAccountData = async (
    accounts,
    editFlow,
    editfocusAccountChecked
  ) => {
    let accountOptions = [];
    // if priority accounts exist
    if (accounts?.length > 0) {
      dispatch(setAllAccountOptions(accounts));
      dispatch(setUserFocusAccounts(accounts));
      dispatch(setNoFocusedAccount(false));

      if (
        (!focusCheck && !editFlow) ||
        (!editfocusAccountChecked && editFlow)
      ) {
        processEcosystemSelection(editFlow);
      } else {
        // set ecosystem to All
        // we check if the user has some selected ecosystem
        // and use that otherwise select the 1st one
        dispatch(
          setSelectedEcosystem(
            selectedEcosystem?.length > 0
              ? selectedEcosystem
              : await getEcosystemOptions()
          )
        );
      }
    } else {
      // Code is added to handle the scenario when
      // there is no priority account of user
      dispatch(setFocusAccountChecked(false));
      dispatch(setNoFocusedAccount(true));
      if (!editfocusAccountChecked && editFlow) {
        processEcosystemSelection(editFlow);
      } else {
        let allEcosystemOptions = await getEcosystemOptions();

        dispatch(setEcosystemOptions(allEcosystemOptions));
        localEcosystem.current = [allEcosystemOptions?.[0]];
        dispatch(setSelectedEcosystem([allEcosystemOptions?.[0]]));

        localAccount.current = { ...allEcosystemOptions?.[0]?.accountOptions };
        dispatch(
          setAllAccountOptions(allEcosystemOptions?.[0]?.accountOptions)
        );
      }
    }

    if (editFlow) {
      if (selectedAccount === 'All') {
        dispatch(setSelectedAccount(accountOptions));
      } else {
        dispatch(setSelectedAccount(selectedAccount));
      }
    } else {
      dispatch(setSelectedAccount([]));
    }
  };

  const getEcosystemOptions = async () => {
    return userRestrictedRole?.isNational === 'N'
      ? userRestrictedEcosystemt
      : ecosystemOptions?.length === 0
      ? await getEcosystems(unixId, roleId)
      : ecosystemOptions;
  };

  const processEcosystemSelection = async (editFlow) => {
    dispatch(setFocusAccountChecked(false));

    let allEcosystemOptions = await getEcosystemOptions();

    if (allEcosystemOptions?.length > 0) {
      dispatch(setEcosystemOptions(allEcosystemOptions));

      // we check if the user has some selected ecosystem
      // and use that otherwise select the 1st one

      if (!editFlow) {
        dispatch(
          setSelectedEcosystem(
            selectedEcosystem?.length > 0 ? selectedEcosystem : []
          )
        );
      }
    }
  };

  /**
   * Get priority accounts based on the selected type
   * @param {string} accountType This is either site or account
   */
  const getPriorityAccountsData = async (accountType) => {
    const accounts = await getAccountsOrSites(unixId, accountType, roleId);
    localAccount.current = [...accounts];
    await processAccountData(accounts, view === 'edit', accounts.length > 0);

    dispatch(setFocusAccountChecked(accounts.length > 0));
    dispatch(setNoFocusedAccount(accounts.length === 0));
  };

  const setEcosystem = (value) => {
    if (value?.length > 0) {
      dispatch(setSelectedEcosystem(value));

      localEcosystem.current = [...value];
      localAccount.current = [];
      dispatch(setAllAccountOptions([]));
      dispatch(setSelectedAccount([]));
    } else {
      localEcosystem.current = [];
      dispatch(setSelectedEcosystem([]));
    }
  };

  const setTimePeriod = (value) => {
    dispatch(setSelectedTimePeriod(value));
  };

  const loadProductOptions = async (searchQuery) => {
    setFirstLoad(true);
    const response = await axiosDBClient.get('filter/products', {
      params: {
        squads:
          selectedSquad?.length > 0
            ? JSON.stringify(
                getArrayLabelValuesWithoutSpace(selectedSquad).split(',')
              )
            : [],
        productRestriction: userRestrictedRole?.productRestrictions,
        unixId,
        roleId: roleId,
        searchToken: searchQuery,
      },
    });
    if (selectedProduct.length === 0 && view !== 'edit') {
      dispatch(setSelectedProduct([]));
    }

    const accountableProductOptions = response?.data?.result?.filter(
      (product) => product?.priorityFlag === 'Y'
    );

    const allProductOptions = response?.data?.result;

    if (
      accountableProductsChecked &&
      localSelectedProduct?.current?.length > 0
    ) {
      const selectedAccountableProducts = localSelectedProduct.current.filter(
        (selection) =>
          accountableProductOptions.find(
            (item) => item.label === selection.label
          )
      );

      dispatch(setSelectedProduct(selectedAccountableProducts));
    }

    const allProducts = accountableProductsChecked
      ? accountableProductOptions
      : allProductOptions;
    setProductOpts(allProducts);

    return {
      options: allProducts,
    };
  };

  const loadSquadOptions = async (searchQuery) => {
    setFirstLoad(true);
    const response = await axiosDBClient.get('filter/squads', {
      params: {
        products:
          selectedProduct?.length > 0
            ? JSON.stringify(
                getArrayLabelValuesWithoutSpace(selectedProduct).split(',')
              )
            : [],
        squadRestriction: userRestrictedRole?.squadRestriction,
        unixId,
        roleId,
        searchToken: searchQuery,
      },
    });
    dispatch(setSquadOptions(response.data.result));

    if (selectedSquad?.length === 0 && view !== VIEW.edit) {
      dispatch(setSelectedSquad([]));
    } else if (selectedProduct?.length > 0 && selectedSquad?.length !== 0) {
      dispatch(setSelectedSquad(response.data.result));
    }

    return {
      options: response.data.result,
    };
  };

  const onProductChange = (value) => {
    value = ConvertSingleMultiSelectValue(value);
    dispatch(setSelectedProduct(value));
    localSelectedProduct.current = value;
  };

  const onSquadChange = (value) => {
    value = ConvertSingleMultiSelectValue(value);
    dispatch(setSelectedSquad(value));
    localSelectedSquad.current = [];
  };

  const handleProductType = (value) => {
    let updateValue = value?.target?.checked;
    setAccountableProductsChecked(updateValue);
    dispatch(setSelectedProduct([]));
  };
  const ConvertSingleMultiSelectValue = (value) => {
    if (!Array.isArray(value)) {
      value = value === undefined || value === null ? [] : [value];
    }
    return value;
  };
  const accountFocusAction = (value) => {
    const updateValue = value?.target?.checked;

    dispatch(setSelectedAccount([]));
    dispatch(setFocusAccountChecked(updateValue));

    setPrevFocusedAccountState(updateValue);

    if (isArray(ecosystemOptions)) {
      if (!updateValue) {
        setDefaultOptions();
      } else {
        setFocusedAccount();
      }
    }
  };

  const setDefaultOptions = () => {
    if (ecosystemOptions?.[0] !== undefined) {
      localEcosystem.current = [ecosystemOptions?.[0]];

      dispatch(setSelectedEcosystem([ecosystemOptions?.[0]]));
      dispatch(setSelectedAccount([]));
    }
  };

  const setFocusedAccount = async () => {
    if (selectedEcosystem?.length !== 0) {
      dispatch(setSelectedEcosystem([]));
    }
    localAccount.current = [...userFocusAccounts];
    dispatch(setAllAccountOptions(userFocusAccounts));
  };

  const handleOptionChange = async (event) => {
    await getPriorityAccountsData(event.target.value);
    dispatch(setSelectedAccount([]));
    setSelectedOptions(event.target.value);
  };

  const buildExpression = async () => {
    let isAllEco = focusCheck;
    let isPriority = focusCheck;

    if (
      !noFocusedAccount &&
      selectedKPI?.filterEnable?.accountEnable === 'Y' &&
      !isAllEco &&
      prevFocusedAccountState &&
      view !== VIEW.edit
    ) {
      dispatch(setFocusAccountChecked(true));
      isPriority = true;
      isAllEco = true;
      await setFocusedAccount();
    }
    const allPriorityAccounts =
      isArray(localAccount.current) &&
      isPriority &&
      (selectedAccount?.length === 0 ||
        selectedAccount?.length === localAccount?.current?.length);

    const accountExpression =
      selectedKPI?.filterEnable?.accountEnable === 'Y'
        ? allPriorityAccounts
          ? getAccountExpression(localAccount.current, true, selectOptions)
          : selectedAccount?.length > 0
          ? getAccountExpression(selectedAccount, true, selectOptions)
          : undefined
        : undefined;

    const productExpression =
      userRestrictedRole?.productRestrictions === 'Y' &&
      selectedProduct?.length === 0
        ? 'Product={"' +
          restrictedProductSquad?.map((a) => a?.product).join('","') +
          '"}'
        : selectedProduct?.length > 0
        ? 'Product={"' +
          selectedProduct?.map((a) => a?.label).join('","') +
          '"}'
        : undefined;
    const squadExpression =
      userRestrictedRole?.squadRestriction === 'Y' &&
      selectedSquad?.length === 0
        ? 'Squad={"' +
          restrictedProductSquad?.map((a) => a?.squad).join('","') +
          '"}'
        : selectedSquad?.length > 0
        ? 'Squad={"' + selectedSquad?.map((a) => a?.label).join('","') + '"}'
        : undefined;

    const channelExp = createExpression(
      selectedKPI,
      selectedChannel?.selectedValue,
      'channelName',
      'Channel'
    );
    const discussionTopicExp = createExpression(
      selectedKPI,
      selectedDiscussionTopic?.selectedValue,
      'discussionTopic',
      'Discussion Topic'
    );
    const stakeholderTypeExp = createExpression(
      selectedKPI,
      selectedStakeholderType?.selectedValue,
      'stakeholderType',
      'Stakeholder Type'
    );
    const templateNameExp = createExpression(
      selectedKPI,
      selectedTemplateName?.selectedValue,
      'templateName',
      'Template Name'
    );
    const surveyTypeExp = createExpression(
      selectedKPI,
      selectedSurveyType?.selectedValue,
      'surveyType',
      'Survey Type'
    );
    const presentationNameExp = createExpression(
      selectedKPI,
      selectedPresentationName?.selectedValue,
      'presentationName',
      'Presentation Name'
    );
    const keyMessageExp = createExpression(
      selectedKPI,
      selectedKeyMessage?.selectedValue,
      'keyMessage',
      'Key Message'
    );

    const finalExp = [
      channelExp,
      discussionTopicExp,
      stakeholderTypeExp,
      templateNameExp,
      surveyTypeExp,
      presentationNameExp,
      keyMessageExp,
    ]
      .filter(Boolean)
      .join(',');
    const ecosystemExpression =
      !priorityRef?.current?.checked &&
      userRestrictedRole?.isNational === 'N' &&
      localEcosystem?.current?.length === 0
        ? 'Ecosystem={"' +
          userRestrictedEcosystemt?.map((a) => a?.label).join('","') +
          '"}'
        : !priorityRef?.current?.checked && localEcosystem?.current?.length > 0
        ? 'Ecosystem={"' +
          localEcosystem?.current?.map((a) => a?.label).join('","') +
          '"}'
        : undefined;

    const isAccountableProductExpression =
      accountableProductsChecked && `isAccountableProduct={"Y"}`;

    let finalExpression = '';

    if (accountExpression !== undefined)
      finalExpression = finalExpression.concat(`${accountExpression},`);
    if (squadExpression !== undefined)
      finalExpression = finalExpression.concat(`${squadExpression},`);
    if (productExpression !== undefined)
      finalExpression = finalExpression.concat(`${productExpression},`);
    if (ecosystemExpression !== undefined)
      finalExpression = finalExpression.concat(`${ecosystemExpression},`);
    if (isAccountableProductExpression)
      finalExpression = finalExpression.concat(
        `${isAccountableProductExpression},`
      );
    if (finalExp !== '')
      finalExpression = finalExpression.concat(`${finalExp} `);

    finalExpression =
      finalExpression.charAt(finalExpression?.length - 1) === ','
        ? finalExpression?.slice(0, -1)
        : finalExpression;

    //Save filter to use for advance expression
    //setfilterExpression(finalExpression);
    filterExpression.current = finalExpression;
    //Building Expression to Save and will dynamicall populate at Home Page.
    const finalExpressionWithVReplaceToSave = allPriorityAccounts
      ? selectOptions === 'account'
        ? `vAllPriorityAccounts, vAllFilters`
        : `vAllPrioritySites, vAllFilters`
      : `vAllFilters`;

    // put it together and store it
    const filters = [
      { finalExpression, time: selectedTimePeriod?.label },
      { finalExpressionWithVReplaceToSave, time: selectedTimePeriod?.label },
    ];
    vReplaceStringForDynamicFilter.current = finalExpressionWithVReplaceToSave;
    // we want the expression. It is not stored in timePeriods
    // so filter to get the expression for the time period
    // that is selected
    let isDimensionLevelExpression =
      selectedKPI?.filterEnable?.EnableDimensionLevelExpression === 'Y';

    let getDimensionId = getSelectedDimensionId(selectedKPI, selectedDimension);

    const timePeriod = timePeriods?.filter((t) =>
      isDimensionLevelExpression
        ? t.dimensionId === getDimensionId &&
          t.timePeriodName === filters[0].time
        : t.timePeriodName === filters[0].time
    );

    const expression = timePeriod?.[0]?.expression.replaceAll(
      EXPRESSION_PLACEHOLDER_TEXT,
      filters[0].finalExpression ? filters[0].finalExpression : 'test'
    );

    dispatch(setExpression(expression));

    // replace the placeholder string with our filters
    if (!editFirstLoad) {
      dispatch(setSelectedAppId(selectedKPI.AppId));
      setEditFirstLoad(true);
    }

    const expressionToSave = timePeriod?.[0]?.expression.replaceAll(
      EXPRESSION_PLACEHOLDER_TEXT,
      filters[1].finalExpressionWithVReplaceToSave
    );

    setExpressionToSave(expressionToSave);
    //call api on apply
    if (checkSingleSelectEligibility()) {
      generateMultiDimensionObject(
        advanceComparePropsRef.current,
        expression,
        oldChartType
      );

      dispatch(getTimespan(timePeriod?.[0]?.timespanExpression, selectedAppId));
    }

    timespanExpression.current = timePeriod?.[0]?.timespanExpression;
    setEnableApplyDisableAddtoButton(true);

    if (view === VIEW.edit) setKpiTitleUpdateMessage(true);
  };

  const editBuildExpression = async (
    focusAccountChecked,
    selectedAccount,
    selectedProduct,
    selectedTimePeriod,
    selectedEcosystem,
    accountableProduct,
    selectedSquad,
    selectedKPI,
    accountOpts,
    selectedDimension,
    timePeriods,
    appId,
    viewType,
    savedAdvanceComparePropsRef,
    selectedChannel,
    selectedDiscussionTopic,
    selectedStakeholderType,
    selectedTemplateName,
    selectedSurveyType,
    selectedPresentationName,
    selectedKeyMessage
  ) => {
    let isAllEco = focusAccountChecked;
    let isPriority = focusAccountChecked;

    //let allEcosystemOptions = await getEcosystemOptions();

    if (
      !noFocusedAccount &&
      selectedKPI?.filterEnable?.accountEnable === 'Y' &&
      !isAllEco &&
      prevFocusedAccountState &&
      view !== 'edit'
    ) {
      dispatch(setFocusAccountChecked(true));
      isPriority = true;
      isAllEco = true;
      await setFocusedAccount();
    }
    const allPriorityAccounts =
      isArray(localAccount.current) &&
      isPriority &&
      selectedAccount?.length === 0;
    const accountExpression =
      selectedKPI?.filterEnable?.accountEnable === 'Y'
        ? allPriorityAccounts
          ? getAccountExpression(localAccount.current, true, viewType)
          : selectedAccount?.length > 0
          ? getAccountExpression(selectedAccount, true, viewType)
          : undefined
        : undefined;

    const productExpression =
      userRestrictedRole?.productRestrictions === 'Y' &&
      selectedProduct?.length === 0
        ? 'Product={"' +
          restrictedProductSquad?.map((a) => a?.product).join('","') +
          '"}'
        : selectedProduct?.length > 0
        ? 'Product={"' +
          selectedProduct?.map((a) => a?.label).join('","') +
          '"}'
        : undefined;

    const squadExpression =
      userRestrictedRole?.squadRestriction === 'Y' &&
      selectedSquad?.length === 0
        ? 'Squad={"' +
          restrictedProductSquad?.map((a) => a?.squad).join('","') +
          '"}'
        : selectedSquad?.length > 0
        ? 'Squad={"' + selectedSquad?.map((a) => a?.label).join('","') + '"}'
        : undefined;
    const channelExp = createExpression(
      selectedKPI,
      selectedChannel,
      'channelName',
      'Channel'
    );
    const discussionTopicExp = createExpression(
      selectedKPI,
      selectedDiscussionTopic,
      'discussionTopic',
      'Discussion Topic'
    );
    const stakeholderTypeExp = createExpression(
      selectedKPI,
      selectedStakeholderType,
      'stakeholderType',
      'Stakeholder Type'
    );
    const templateNameExp = createExpression(
      selectedKPI,
      selectedTemplateName,
      'templateName',
      'Template Name'
    );
    const surveyTypeExp = createExpression(
      selectedKPI,
      selectedSurveyType,
      'surveyType',
      'Survey Type'
    );
    const presentationNameExp = createExpression(
      selectedKPI,
      selectedPresentationName,
      'presentationName',
      'Presentation Name'
    );
    const keyMessageExp = createExpression(
      selectedKPI,
      selectedKeyMessage,
      'keyMessage',
      'Key Message'
    );

    const finalExp = [
      channelExp,
      discussionTopicExp,
      stakeholderTypeExp,
      templateNameExp,
      surveyTypeExp,
      presentationNameExp,
      keyMessageExp,
    ]
      .filter(Boolean)
      .join(',');

    const ecosystemExpression =
      !focusAccountChecked &&
      userRestrictedRole?.isNational === 'N' &&
      selectedEcosystem?.length === 0
        ? 'Ecosystem={"' +
          userRestrictedEcosystemt?.map((a) => a?.label).join('","') +
          '"}'
        : !focusAccountChecked && selectedEcosystem?.length > 0
        ? 'Ecosystem={"' +
          selectedEcosystem?.map((a) => a?.label).join('","') +
          '"}'
        : focusAccountChecked && accountExpression === undefined
        ? 'Ecosystem={"' + userRestrictedEcosystemt[0]?.label + '"}'
        : undefined;

    const isAccountableProductExpression =
      accountableProduct && `isAccountableProduct={"Y"}`;

    let finalExpression = '';

    if (accountExpression !== undefined)
      finalExpression = finalExpression.concat(`${accountExpression},`);
    if (squadExpression !== undefined)
      finalExpression = finalExpression.concat(`${squadExpression},`);
    if (productExpression !== undefined)
      finalExpression = finalExpression.concat(`${productExpression},`);
    if (ecosystemExpression !== undefined)
      finalExpression = finalExpression.concat(`${ecosystemExpression},`);
    if (isAccountableProductExpression)
      finalExpression = finalExpression.concat(
        `${isAccountableProductExpression} `
      );
    if (finalExp !== '')
      finalExpression = finalExpression.concat(`,${finalExp}`);

    finalExpression =
      finalExpression.charAt(finalExpression?.length - 1) === ','
        ? finalExpression?.slice(0, -1)
        : finalExpression;

    //setfilterExpression(finalExpression);
    filterExpression.current = finalExpression;

    //Building Expression to Save and will dynamicall populate at Home Page.
    const finalExpressionWithVReplaceToSave = allPriorityAccounts
      ? selectOptions === 'account'
        ? `vAllPriorityAccounts, vAllFilters`
        : `vAllPrioritySites, vAllFilters`
      : `vAllFilters`;

    vReplaceStringForDynamicFilter.current = finalExpressionWithVReplaceToSave;

    // put it together and store it
    const filters = [
      { finalExpression, time: selectedTimePeriod?.label },
      { finalExpressionWithVReplaceToSave, time: selectedTimePeriod?.label },
    ];

    // we want the expression. It is not stored in timePeriods
    // so filter to get the expression for the time period
    // that is selected

    let isDimensionLevelExpression =
      selectedKPI?.filterEnable?.EnableDimensionLevelExpression === 'Y';

    let getDimensionId = getSelectedDimensionId(selectedKPI, selectedDimension);

    const timePeriod = timePeriods?.filter((t) =>
      isDimensionLevelExpression
        ? t.dimensionId === getDimensionId &&
          t.timePeriodName === filters[0].time
        : t.timePeriodName === filters[0].time
    );

    const expression = timePeriod?.[0]?.expression.replaceAll(
      EXPRESSION_PLACEHOLDER_TEXT,
      filters[0].finalExpression ? filters[0].finalExpression : 'test'
    );

    dispatch(setExpression(expression));

    // replace the placeholder string with our filters
    if (!editFirstLoad) {
      dispatch(setSelectedAppId(selectedKPI.AppId));
      setEditFirstLoad(true);
    }

    const expressionToSave = timePeriod?.[0]?.expression.replaceAll(
      EXPRESSION_PLACEHOLDER_TEXT,
      filters[1].finalExpressionWithVReplaceToSave
    );

    setExpressionToSave(expressionToSave);
    //api call for edit flow
    generateMultiDimensionObject(
      savedAdvanceComparePropsRef,
      expression,
      undefined,
      appId,
      selectedTimePeriod
    );
    dispatch(getTimespan(timePeriod?.[0]?.timespanExpression, appId));

    timespanExpression.current = timePeriod?.[0]?.timespanExpression;
    setEnableApplyDisableAddtoButton(true);
  };

  const onChangeHandler = (selected) => {
    selected = ConvertSingleMultiSelectValue(selected);
    dispatch(setSelectedAccount(selected));
  };

  const updateView = async () => {
    const account = isAllselected(selectedAccount, allAccountOptions);
    const payload = {
      isAlignmentChange: 'N',
      kpiId,
      roleId,
      cardId: data.cardId,
      title: updatedKpiName,
      cardView: selectedCardView,
      filters: {
        selectedAccount: account,
        selectedEcosystem: focusCheck
          ? []
          : selectedEcosystem?.length === ecosystemOptions.length
          ? []
          : selectedEcosystem,
        selectedProduct: localSelectedProduct.current,
        selectedTimePeriod,
        focusAccountChecked: focusCheck,
        kpiName: selectedKPI?.KpiName,
        selectedKPI: getKpiInfoToSave(selectedKPI),
        dimensionName,
        noDimensionValue,
        chartType,
        accountableProduct: accountableProductsChecked,
        viewType: selectOptions,
        selectedSquad: localSelectedSquad.current,
        timespanExpression: timespanExpression.current,
        selectedChannel: localSelectedChannel.current,
        selectedDiscussionTopic: localSelectedDiscussionTopic.current,
        selectedStakeholderType: localSelectedStakeholderType.current,
        selectedTemplateName: localSelectedTemplateName.current,
        selectedSurveyType: localSelectedSurveyType.current,
        selectedPresentationName: localSelectedPresentationName.current,
        selectedKeyMessage: localSelectedKeyMessage.current,
      },
      expression: expressionToSave,
      appId: selectedAppId,
      timespan: timespan,
      advanceId: advanceComparePropsRef.current?.selectDropdownOption?.advanceId
        ? advanceComparePropsRef.current?.selectDropdownOption?.advanceId
        : null,
      advanceEnabled:
        advanceComparePropsRef.current?.selectDropdownOption &&
        advanceComparePropsRef.current?.selectDropdownOption?.length !== 0
          ? 'Y'
          : 'N',
      advanceType:
        advanceComparePropsRef.current?.compareBy &&
        advanceComparePropsRef.current?.selectDropdownOption !== '' &&
        advanceComparePropsRef.current?.selectDropdownOption?.length !== 0
          ? advanceComparePropsRef.current?.compareBy
          : null,
      advanceExpression: advanceExpressionToSave(
        advanceComparePropsRef,
        vReplaceStringForDynamicFilter
      ),
      adavanceTimeSpanExpression: advanceComparePropsRef.current
        ?.adavanceTimeSpanExpression
        ? advanceComparePropsRef.current?.selectDropdownOption
            ?.adavanceTimeSpanExpression
        : null,
      advanceFilterValue: getAdvanceFilterValue(advanceComparePropsRef),
    };
    const { message } = await updateMyView(payload);
    const cardData = {
      ...payload,
      filter: payload.filters,
      selectedAppLink: appLink,
      chartData: chartData,
      positionIndex: data.positionIndex,
    };

    dispatch(
      setCards(
        cards.map((item) => {
          return item.cardId === payload.cardId ? cardData : item;
        })
      )
    );
    toast.success(message);
  };

  const getKpiInfoToSave = (selectedKPI) => {
    let finalSelectedKpi = selectedKPI;
    const dimension = selectedKPI?.dimensions.filter(
      (oneDimension) => oneDimension?.dimensionName === dimensionName
    );
    return { ...finalSelectedKpi, dimensions: dimension };
  };

  const addToHome = async () => {
    if (cards?.length >= 20) {
      toast.error(CHART_LIMIT_ERROR);
      return;
    }
    const positionIndex = cards?.length + 1;
    const account = isAllselected(selectedAccount, allAccountOptions);
    const payload = {
      operation: 'POST',
      unixId,
      roleId,
      positionIndex: positionIndex,
      kpiId,
      title: updatedKpiName,
      cardView: selectedCardView,
      cardId: '',
      filters: {
        selectedAccount: account,
        selectedEcosystem: focusCheck
          ? []
          : selectedEcosystem?.length === ecosystemOptions.length
          ? []
          : selectedEcosystem,
        selectedProduct: localSelectedProduct.current,
        selectedTimePeriod,
        focusAccountChecked: focusCheck,
        kpiName: selectedKPI?.KpiName,
        selectedKPI: getKpiInfoToSave(selectedKPI),
        dimensionName,
        noDimensionValue,
        chartType,
        accountableProduct: accountableProductsChecked,
        viewType: selectOptions,
        selectedSquad: localSelectedSquad.current,
        timespanExpression: timespanExpression.current,
        selectedChannel: localSelectedChannel.current,
        selectedDiscussionTopic: localSelectedDiscussionTopic.current,
        selectedStakeholderType: localSelectedStakeholderType.current,
        selectedTemplateName: localSelectedTemplateName.current,
        selectedSurveyType: localSelectedSurveyType.current,
        selectedPresentationName: localSelectedPresentationName.current,
        selectedKeyMessage: localSelectedKeyMessage.current,
      },
      expression: expressionToSave,
      appId: selectedAppId,
      timespan: timespan,
      advanceId: advanceComparePropsRef.current?.selectDropdownOption?.advanceId
        ? advanceComparePropsRef.current?.selectDropdownOption?.advanceId
        : null,
      advanceEnabled:
        advanceComparePropsRef.current?.selectDropdownOption &&
        advanceComparePropsRef.current?.selectDropdownOption?.length !== 0
          ? 'Y'
          : 'N',

      advanceType:
        advanceComparePropsRef.current?.compareBy &&
        advanceComparePropsRef.current?.selectDropdownOption !== '' &&
        advanceComparePropsRef.current?.selectDropdownOption?.length !== 0
          ? advanceComparePropsRef.current?.compareBy
          : null,
      advanceExpression: advanceExpressionToSave(
        advanceComparePropsRef,
        vReplaceStringForDynamicFilter
      ),
      adavanceTimeSpanExpression: advanceComparePropsRef.current
        ?.selectDropdownOption?.adavanceTimeSpanExpression
        ? advanceComparePropsRef.current?.selectDropdownOption
            ?.adavanceTimeSpanExpression
        : null,
      advanceFilterValue: getAdvanceFilterValue(advanceComparePropsRef),
    };
    const { message = '', cardId } = await addToMyView(payload);
    const cardData = {
      ...payload,
      filter: payload.filters,
      cardId: cardId,
      title: updatedKpiName,
      chartData: chartData,
      selectedAppLink: appLink,
      expression: expression,
    };

    dispatch(setCards([...cards, cardData]));

    toast.success(message);
  };

  const checkSingleSelectEligibility = () => {
    let callApi = true;
    setFilterValidation(callApi);
    if (
      dimensionDetails(selectedKPI, selectedDimension)?.filterSingleSelect
        ?.accountSingleSelect === 'Y' &&
      localAccount.current.length !== 1
    ) {
      callApi = false;
      dispatch(setSelectedAccount([]));
      localAccount.current = [];
    }
    if (
      dimensionDetails(selectedKPI, selectedDimension)?.filterSingleSelect
        ?.squadSingleSelect === 'Y' &&
      localSelectedSquad.current.length !== 1
    ) {
      callApi = false;
      dispatch(setSelectedSquad([]));
      localSelectedSquad.current = [];
    }
    if (
      dimensionDetails(selectedKPI, selectedDimension)?.filterSingleSelect
        ?.ecosystemSingleSelect === 'Y' &&
      selectedKPI?.filterEnable?.ecosystemEnable === 'Y' &&
      !priorityRef?.current &&
      localEcosystem.current.length !== 1
    ) {
      callApi = false;
      dispatch(setSelectedEcosystem([]));
      localEcosystem.current = [];
    }
    if (
      dimensionDetails(selectedKPI, selectedDimension)?.filterSingleSelect
        ?.productSingleSelect === 'Y' &&
      localSelectedProduct.current.length !== 1
    ) {
      callApi = false;
      dispatch(setSelectedProduct([]));
      localSelectedProduct.current = [];
    }
    setFilterValidation(callApi);
    return callApi;
  };

  const tooltip = () => {
    return (
      <Tooltip
        placement='right'
        bsclass='gne-tooltip tooltip--left'>
        <p className='text text__x-small mb-1'>
          <strong>{selectedKPI?.Description + '.'}</strong>
        </p>
      </Tooltip>
    );
  };
  //onselection of advance dropdown value
  useEffect(() => {
    if (editFirstLoad && view === 'edit') {
      advanceComparePropsRef.current = selectedAdvanceOptions;
      if (
        selectedAdvanceOptions &&
        selectedAdvanceOptions?.selectDropdownOption !== ''
      ) {
        buildExpression();
      }
    } else if (view !== 'edit') {
      advanceComparePropsRef.current = selectedAdvanceOptions;
      if (
        selectedAdvanceOptions &&
        selectedAdvanceOptions?.selectDropdownOption !== ''
      ) {
        buildExpression();
      }
    }
  }, [selectedAdvanceOptions?.selectDropdownOption]);

  //onchange of advance compareType load default view
  useEffect(() => {
    if (selectedAdvanceOptions?.selectDropdownOption === '') {
      buildExpression();
    }
  }, [selectedAdvanceOptions?.compareBy]);

  const generateMultiDimensionObject = async (
    advanceCompareProps,
    firstExp,
    oldChartType,
    editAppId,
    editFlowSelectedTimePeriod
  ) => {
    dispatch(setKPIclickedStatus(true));
    //advanceCompareProps will be undefined only for reset
    if (advanceCompareProps === undefined) {
      advanceComparePropsRef.current = '';
      dispatch(setAdvanceOptions(advanceCompareProps));
    }

    let dimensionObjOne = {
      dimesnionType: 'Cateogry',
      dimesnionName: selectedDimension,
    };
    let dimensionObjTwo;
    if (
      advanceCompareProps &&
      advanceCompareProps?.compareBy === 'dimension' &&
      advanceCompareProps?.selectDropdownOption?.PossibleDimensionName &&
      advanceCompareProps?.selectDropdownOption?.PossibleDimensionName !==
        'undefined'
    ) {
      dimensionObjTwo = {
        dimesnionType: 'Advance',
        dimesnionName:
          advanceCompareProps?.selectDropdownOption?.PossibleDimensionName,
      };
    }
    dispatch(setChartData([]));
    dispatch(
      setChartType(
        advanceCompareProps?.selectDropdownOption?.chartType
          ? advanceCompareProps?.selectDropdownOption?.chartType
          : oldChartType
          ? oldChartType
          : chartType
      )
    );
    let dimension = [];

    dimension.push(JSON.stringify(dimensionObjOne));
    if (dimensionObjTwo) {
      dimension.push(JSON.stringify(dimensionObjTwo));
    }
    let measure = [];
    let measureObjOne = {
      timesPeriod:
        advanceCompareProps?.compareBy === COMPARE_BENCHMARK &&
        advanceCompareProps?.selectDropdownOption !== ''
          ? BENCHMARK_LEGEND_FIRST
          : advanceCompareProps?.compareBy === COMPARE_KPI &&
            advanceCompareProps?.selectDropdownOption !== ''
          ? selectedKPI?.KpiName
          : editFlowSelectedTimePeriod?.label
          ? editFlowSelectedTimePeriod?.label
          : selectedTimePeriod?.label,
      index: 1,
      expression: firstExp || expression,
    };
    measure.push(JSON.stringify(measureObjOne));
    let measureObjTwo;
    if (
      advanceCompareProps?.compareBy === 'time-period' &&
      advanceCompareProps?.selectDropdownOption?.possibleTimePeriod
    ) {
      measureObjTwo = {
        timesPeriod:
          advanceCompareProps?.selectDropdownOption?.possibleTimePeriod,
        index: 2,
        expression:
          view === 'add'
            ? advanceCompareProps?.selectDropdownOption?.advTimeperiodExp?.replaceAll(
                EXPRESSION_PLACEHOLDER_TEXT,
                filterExpression.current ? filterExpression?.current : 'test'
              )
            : replaceFilterInExpression(advanceCompareProps, filterExpression),
      };

      measure.push(JSON.stringify(measureObjTwo));

      //SETTING UP THE DIMESNION NAME TO MONTH_N IF PRIMARY DIMESNION IS MONTH AND COMPARE BY IS TIME PERIOD.

      if (selectedDimension === 'Month') {
        let dimensionObjOne = {
          dimesnionType: 'Cateogry',
          dimesnionName: 'Month_N',
        };

        dimension = [JSON.stringify(dimensionObjOne)];
      }
    }
    if (
      advanceCompareProps?.compareBy === COMPARE_BENCHMARK &&
      advanceCompareProps?.selectDropdownOption !== ''
    ) {
      measureObjTwo = {
        timesPeriod: advanceCompareProps?.selectDropdownOption?.label,
        index: 2,
        expression:
          view === 'add'
            ? advanceCompareProps?.selectDropdownOption?.advTimeperiodExp?.replaceAll(
                EXPRESSION_PLACEHOLDER_TEXT,
                filterExpression.current ? filterExpression?.current : 'test'
              )
            : replaceFilterInExpression(advanceCompareProps, filterExpression),
      };
      measure.push(JSON.stringify(measureObjTwo));
      //SETTING UP THE DIMESNION NAME TO MONTH_N IF PRIMARY DIMESNION IS MONTH AND COMPARE BY IS TIME PERIOD.
      if (selectedDimension === 'Month') {
        let dimensionObjOne = {
          dimesnionType: 'Cateogry',
          dimesnionName: 'Month_N',
        };

        dimension = [JSON.stringify(dimensionObjOne)];
      }
    }
    if (
      advanceCompareProps?.compareBy === COMPARE_KPI &&
      advanceCompareProps?.selectDropdownOption !== ''
    ) {
      let exp = advanceCompareProps?.selectDropdownOption?.advTimeperiodExp
        ? advanceCompareProps?.selectDropdownOption?.advTimeperiodExp
        : advanceCompareProps?.selectDropdownOption;
      exp?.map((oneKpi) => {
        measureObjTwo = {
          timesPeriod: oneKpi?.label || oneKpi?.timesPeriod,
          index: measure.length + 1,
          expression:
            view === 'add'
              ? oneKpi?.advTimeperiodExp?.replaceAll(
                  EXPRESSION_PLACEHOLDER_TEXT,
                  filterExpression.current ? filterExpression?.current : 'test'
                )
              : replaceFilterInExpression(oneKpi, filterExpression),
        };
        measure.push(JSON.stringify(measureObjTwo));
      });
      //SETTING UP THE DIMESNION NAME TO MONTH_N IF PRIMARY DIMESNION IS MONTH AND COMPARE BY IS TIME PERIOD.
      if (selectedDimension === 'Month') {
        let dimensionObjOne = {
          dimesnionType: 'Cateogry',
          dimesnionName: 'Month_N',
        };

        dimension = [JSON.stringify(dimensionObjOne)];
      }
    }
    // trackPromise(
    let limit =
      advanceCompareProps?.selectDropdownOption?.rowsLimit?.toString();

    await getMultiDimensionChartData(
      measure,
      dimension,
      editAppId !== undefined ? editAppId : selectedAppId,
      advanceCompareProps?.selectDropdownOption?.chartType
        ? advanceCompareProps?.selectDropdownOption?.chartType
        : oldChartType
        ? oldChartType
        : chartType,
      limit
    )
      .then((res) => {
        if (!res.data?.values[0]) {
          if (
            advanceCompareProps?.selectDropdownOption?.chartType ===
              COMPARE_BENCHMARK_NO_DIMENSION_CHARTTYPE ||
            res?.data[0]?.values[0]?.name === BENCHMARK_LEGEND_FIRST
          ) {
            dispatch(setNoDimensionValue(res?.data[0]?.values[0]?.data[0]));
            dispatch(
              setBenchmarkNoDimensionValue(res?.data[0]?.values[1]?.data[0])
            );
          } else {
            dispatch(setNoDimensionValue(res?.data[0]?.category[0]));
            dispatch(setBenchmarkNoDimensionValue(''));
          }
        }
        dispatch(setChartData(res.data[0] ? res.data[0] : []));
        dispatch(setKPIclickedStatus(false));
      })
      .catch((err) => {
        dispatch(setKPIclickedStatus(false));
      });
  };
  const replaceFilterInExpression = (advanceCompareProps, filterExpression) => {
    let multiExpression = advanceCompareProps?.expression
      ? advanceCompareProps?.expression
      : advanceCompareProps?.advTimeperiodExp;
    let finalExpression = advanceCompareProps?.selectDropdownOption
      ? advanceCompareProps?.selectDropdownOption?.advTimeperiodExp?.replaceAll(
          FILTER_PLACE_HOLDER,
          filterExpression.current ? filterExpression.current : 'test'
        )
      : multiExpression.replaceAll(
          FILTER_PLACE_HOLDER,
          filterExpression.current ? filterExpression.current : 'test'
        );

    finalExpression = finalExpression?.replaceAll(
      EXPRESSION_PLACEHOLDER_TEXT,
      filterExpression.current ? filterExpression?.current : 'test'
    );
    return finalExpression;
  };
  const isExtra =
    selectedKPI?.filterEnable?.channelNameEnable === 'Y' ||
    selectedKPI?.filterEnable?.templateNameEnable === 'Y' ||
    selectedKPI?.filterEnable?.surveyTypeEnable === 'Y' ||
    selectedKPI?.filterEnable?.keyMessageEnable === 'Y';

  return (
    <Container className='p-0 gne-container customer-container'>
      <Row>
        {/* Gross sale left panel */}
        <Col
          md={4}
          xs={5}
          lg={3}
          className={classes['library-container']}>
          {disableKPI && (
            <Fade>
              <KPILibrary page={CONFIGURE_VIEW.myView} />
            </Fade>
          )}
        </Col>

        {/* Col for base filter and chart */}
        <Col
          md={8}
          xs={7}
          lg={9}>
          <div className={classes['filter-container']}>
            {disableKPI && (
              <Fade>
                <Row>
                  <Col
                    md={10}
                    className=' d-flex align-items-center text-left'>
                    {showKPIName ? (
                      <>
                        <label className='text__small d-flex justify-content-center w-100'>
                          <Form.Control
                            type='text'
                            value={newKpiTitle}
                            placeholder='KPI Card Title'
                            className={`mt-1 text__small ${classes['form-control']}`}
                            onChange={(e) =>
                              handleKPINameChange(e.target.value)
                            }
                          />
                        </label>
                        <LinkButton
                          onClick={updateKPIName}
                          className='d-inline-block p-0'>
                          <i className='icon icon__configure-checkbackground'></i>
                        </LinkButton>
                      </>
                    ) : (
                      <div className='title__bold d-flex justify-content-end'>
                        {updatedKpiName ? (
                          updatedKpiName?.length > 65 ? (
                            <OverlayTrigger
                              overlay={
                                <Popover className='p-2'>
                                  {updatedKpiName}
                                </Popover>
                              }
                              placement='right'>
                              <span>{`${updatedKpiName?.substring(
                                0,
                                65
                              )}...`}</span>
                            </OverlayTrigger>
                          ) : (
                            updatedKpiName
                          )
                        ) : (
                          <>
                            {selectedKPI?.KpiName}
                            {showDimensionName && ` by ${selectedDimension}`}
                          </>
                        )}
                        <LinkButton
                          className='ms-1'
                          onClick={handleEdit}>
                          <i className='icon icon__configureEdit d-flex p-0'></i>
                        </LinkButton>
                        {selectedKPI?.Description && (
                          <OverlayTrigger
                            overlay={tooltip()}
                            placement='right'>
                            <span
                              className={`d-inline-block justify-content-center ${classes['info-icon']}`}>
                              <i className='icon icon__info__gray'></i>
                            </span>
                          </OverlayTrigger>
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
              </Fade>
            )}

            {/* filters */}
            <Row>
              <Col md={6}>
                <Row>
                  <div
                    className={`title__x-small ${classes['radio-btn-wrapper']} ${classes['radio_selection']}`}>
                    <Col md={3}>
                      <label
                        className={`d-flex align-items-center ${classes['radio_button']}`}>
                        <input
                          type='radio'
                          name='option'
                          value={ACCOUNT_TYPE.account}
                          className={classes['radio_trend']}
                          checked={selectOptions === ACCOUNT_TYPE.account}
                          onChange={handleOptionChange}
                          disabled={
                            accountState ||
                            selectedKPI?.filterEnable?.accountEnable === 'N'
                          }
                        />{' '}
                        <span className='ms-1 me-2'>Account</span>
                      </label>
                    </Col>
                    <Col md={4}>
                      <label
                        className={`d-flex align-items-center ${classes['radio_button']}`}>
                        <input
                          type='radio'
                          name='option'
                          className={classes['radio_trend']}
                          value={ACCOUNT_TYPE.site}
                          checked={selectOptions === ACCOUNT_TYPE.site}
                          onChange={handleOptionChange}
                          disabled={
                            accountState ||
                            selectedKPI?.filterEnable?.accountEnable === 'N'
                          }
                        />{' '}
                        <span className='ms-1'>Site</span>
                      </label>
                    </Col>
                    <Col>
                      <div
                        className={`d-flex justify-content-end ms-1  ${classes['radio_button']}`}>
                        <span className='title__x-small float-end pl-4 '>
                          <Form.Check
                            inline
                            type='checkbox'
                            label={
                              selectOptions === ACCOUNT_TYPE.account
                                ? 'Priority Accounts'
                                : 'Priority Sites'
                            }
                            checked={focusCheck}
                            onChange={accountFocusAction}
                            disabled={accountState || noFocusedAccount}
                            ref={priorityRef}
                            className={focusCheck && CHECKBOXCLASS}
                          />
                        </span>
                      </div>
                    </Col>
                  </div>
                </Row>
                {/* <Row> */}
                {selectOptions === ACCOUNT_TYPE.account ? (
                  <Account
                    onAccountSelected={onChangeHandler}
                    viewName={VIEW.trends}
                    isMulti={
                      dimensionDetails(selectedKPI, selectedDimension)
                        ?.filterSingleSelect?.accountSingleSelect === 'Y'
                        ? false
                        : true
                    }
                    isFocusedChecked={focusCheck}
                    cssClass={`d-inline-block w-100 m-0 ${classes.trends} ${classes.trendsAccount}`}
                    cacheUniqs={[focusCheck, selectedEcosystem]}
                    viewMode={view}
                    isDisabled={
                      accountState ||
                      selectedKPI?.filterEnable?.accountEnable === 'N'
                    }
                    clearDependencies={selectedEcosystem?.length}
                  />
                ) : (
                  <Site
                    onSiteSelected={onChangeHandler}
                    cssClass={`d-inline-block w-100 m-0 ${classes.trends} ${classes.trendsAccount}`}
                    viewName={VIEW.trends}
                    isMulti={
                      dimensionDetails(selectedKPI, selectedDimension)
                        ?.filterSingleSelect?.accountSingleSelect === 'Y'
                        ? false
                        : true
                    }
                    isFocusedChecked={focusCheck}
                    cacheUniqs={[focusCheck, selectedEcosystem]}
                    viewMode={view}
                    isDisabled={
                      accountState ||
                      selectedKPI?.filterEnable?.accountEnable === 'N'
                    }
                  />
                )}
                {/* </Row> */}
              </Col>
              <Col md={6}>
                <label className='title__x-small'>Ecosystem</label>
                <Col className='mt-1'>
                  {dimensionDetails(selectedKPI, selectedDimension)
                    ?.filterSingleSelect?.ecosystemSingleSelect === 'N' ||
                  dimensionDetails(selectedKPI, selectedDimension)
                    ?.filterSingleSelect?.ecosystemSingleSelect ===
                    undefined ? (
                    <MultiSelectDropdown
                      className='mt-1'
                      options={ecosystemOptions}
                      selected={
                        selectedEcosystem?.[0] === undefined
                          ? []
                          : selectedEcosystem
                      }
                      setSelected={setEcosystem}
                      hasSelectAll={false}
                      disabled={
                        focusCheck ||
                        selectedKPI?.filterEnable?.ecosystemEnable === 'N'
                      }
                    />
                  ) : (
                    <GNESingleSelect
                      onChange={setEcosystem}
                      options={ecosystemOptions}
                      className='single-select'
                      isDisabled={
                        focusCheck ||
                        selectedKPI?.filterEnable?.ecosystemEnable === 'N'
                      }
                      placeholder='Select Ecosystem'
                    />
                  )}
                </Col>
              </Col>
            </Row>
            {
              <Row className='mt-2 align-items-center'>
                <Col md={isExtra ? 3 : 2}>
                  <label className='title__x-small'>Time period</label>
                  <div>
                    <GNESingleSelect
                      value={selectedTimePeriod}
                      onChange={setTimePeriod}
                      options={timePeriodOptions}
                      className='single-select'
                      isDisabled={
                        selectedKPI?.filterEnable?.timePeriodEnable === 'N'
                      }
                    />
                  </div>
                </Col>
                <Col md={3}>
                  <label className='title__x-small'>Squad</label>
                  <GNEAsyncPagination
                    value={selectedSquad || ''}
                    loadOptions={loadSquadOptions}
                    options={squadOptions}
                    onChange={onSquadChange}
                    isMulti={
                      dimensionDetails(selectedKPI, selectedDimension)
                        ?.filterSingleSelect?.squadSingleSelect === 'Y'
                        ? false
                        : true
                    }
                    components={{
                      ValueContainer: ValueContainer,
                      Option: wrapMenuList(dropdownOptions),
                    }}
                    placeholder={
                      dimensionDetails(selectedKPI, selectedDimension)
                        ?.filterSingleSelect?.squadSingleSelect === 'Y'
                        ? 'Select Squad'
                        : ALL
                    }
                    closeMenuOnSelect={false}
                    isClearable
                    cacheUniqs={[
                      accountableProductsChecked,
                      selectedProduct,
                      firstLoad,
                    ]}
                    isDisabled={selectedKPI?.filterEnable?.squadEnable === 'N'}
                  />
                </Col>
                <Col md={isExtra ? 6 : 3}>
                  <Row className='mb-0'>
                    <Col
                      md={isExtra ? 9 : 6}
                      className='mb-0 p-0'>
                      <label className='title__x-small'>Products</label>
                    </Col>
                    <Col
                      md={isExtra ? 3 : 6}
                      className='mb-0 me-0 p-0'>
                      <span className='title__x-small mb-0 me-0'>
                        <Form.Check
                          inline
                          type='checkbox'
                          label='Accountable'
                          checked={accountableProductsChecked}
                          onChange={handleProductType}
                          className={
                            accountableProductsChecked && CHECKBOXCLASS
                          }
                          disabled={
                            selectedKPI?.filterEnable?.productEnable === 'N'
                          }
                        />
                      </span>
                    </Col>
                  </Row>
                  <Row className='m-0 p-0'>
                    <Col
                      md={12}
                      className='m-0 p-0'>
                      <GNEAsyncPagination
                        value={selectedProduct || ''}
                        loadOptions={loadProductOptions}
                        options={productOpts}
                        onChange={onProductChange}
                        isMulti={
                          dimensionDetails(selectedKPI, selectedDimension)
                            ?.filterSingleSelect?.productSingleSelect === 'Y'
                            ? false
                            : true
                        }
                        components={{
                          ValueContainer: ValueContainer,
                          Option: wrapMenuList(dropdownOptions),
                        }}
                        placeholder={
                          dimensionDetails(selectedKPI, selectedDimension)
                            ?.filterSingleSelect?.productSingleSelect === 'Y'
                            ? 'Select'
                            : ALL
                        }
                        closeMenuOnSelect={!true}
                        isClearable
                        cacheUniqs={[
                          accountableProductsChecked,
                          selectedSquad,
                          firstLoad,
                        ]}
                        isDisabled={
                          selectedKPI?.filterEnable?.productEnable === 'N'
                        }
                      />
                    </Col>
                  </Row>
                </Col>
                {!isExtra && (
                  <Col
                    md={4}
                    className={`${classes['configure-apply']} d-flex justify-content-end`}>
                    <Button
                      variant={`btn btn-gne btn-primary me-2  ${
                        view === 'add' ? 'btn-gne__outline--add' : ''
                      }`}
                      onClick={buildExpression}
                      className={classes['configure-apply']}
                      disabled={
                        promiseInProgress || enableApplyDisableAddtoButton
                      }>
                      Apply
                    </Button>

                    <Button
                      variant={`outline-primary btn-gne btn-gne__outline ${
                        view === 'add' ? 'btn-gne__outline--add' : ''
                      } add-button`}
                      onClick={view === 'edit' ? updateView : addToHome}
                      className={classes['configure-apply']}
                      disabled={
                        promiseInProgress || !enableApplyDisableAddtoButton
                      }>
                      {buttonText}
                    </Button>
                  </Col>
                )}
              </Row>
            }
            {isExtra && (
              <MarketingFilter
                page={CONFIGURE_VIEW.myView}
                enableApplyDisableAddtoButton={enableApplyDisableAddtoButton}
                buildExpression={buildExpression}
                editBuildExpression={editBuildExpression}
                addToHome={addToHome}
                updateView={updateView}
                buttonText={buttonText}
                view={view}
              />
            )}

            {disableKPI && (
              <Fade>
                <Row className='mt-4 mb-2'>
                  {/* Charts Or table */}
                  {filterValidation ? (
                    <ChartAndTableView
                      page='My View'
                      // selectedAdvanceProps={advanceComparePropsRef}
                      timespan={timespan}
                      onResetClicked={() => {
                        generateMultiDimensionObject(
                          undefined,
                          undefined,
                          oldChartType
                        );
                      }}
                    />
                  ) : (
                    <ValidationMessage />
                  )}
                </Row>
              </Fade>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
