import React, { useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import ConfigureMyViewLayout from 'components/home/configure-my-view/ConfigureMyViewLayout';
import ExpandMyView from './ExpandMyView';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import classes from './Tiles.module.scss';
import {
  setCards,
  setChartType,
  setDimensionName,
  setExpression,
  setSelectedKPI,
  setSelectedKpiId,
  setSelectedCardView,
  setAdvanceButtonStatus,
} from 'store/home/homeMyViewSlice';

import {
  setCards as customerCards,
  setChartType as customerChartType,
  setDimensionName as customerSetDimensionName,
  setExpression as customerSetExpression,
  setSelectedKPI as customerSetSelectedKPI,
  setSelectedKpiId as customerSetSelectedKpiId,
  setSelectedCardView as customerSetSelectedCardView,
  setCustomerViewButtonDisabled,
} from 'store/customer/customerViewSlice';

import {
  deleteMyView,
  downloadTileData,
} from 'store/home/home-my-view-actions';
import LinkButton from 'components/shared/button/LinkButton';
import {
  CONFIGURE_VIEW,
  COMPARE_BENCHMARK,
  BENCHMARK_LEGEND_FIRST,
  VIEW,
  EXPRESSION_PLACEHOLDER_TEXT,
  FILTER_PLACE_HOLDER,
  COMPARE_KPI,
  DUPLICATE_INSIGHT_VIEW_MESSAGE,
  DUPLICATE_TO_MY_VIEW_MESSAGE,
  ADD_TO_MY_VIEW_MESSAGE,
  ACTION_COPY,
  ACTION_ADD_TO_MY_VIEW,
} from 'constants/constants';
import {
  buildAdvancePropsObject,
  buildExpressionCustomerViewDownload,
  buildExpressionOutOfFilter,
  createCardPayload,
} from 'utils/utils';
import { addToMyView } from 'store/home/home-my-view-actions';
import { CHART_LIMIT_ERROR } from 'constants/constants';
import { getAccountsOrSites } from 'services/trends.service';
import { CHART_ADD_MESSAGE } from 'constants/constants';
import { INSIGHTS_CHART_LIMIT_ERROR } from 'constants/constants';
import { filter } from 'd3';

function TileActions({ card, page }) {
  const dispatch = useDispatch();
  const insightType = useSelector((state) => state?.CustomerView?.insightType);
  const isSampleInsight =
    insightType === 'sample' && page === CONFIGURE_VIEW.insights ? true : false;

  const [modalShow, setModalShow] = useState(false);
  const [expandViewModalShow, setExpandViewModalShow] = useState(false);

  const { unixId, roleId } = useSelector((state) => state.Auth.userClaimObject);
  const cards = useSelector((state) => state?.HomeMyView?.cards);
  const insightCards = useSelector((state) => state?.CustomerView?.cards);
  const getPositionIndex =
    cards?.length === 0 ? 1 : cards[cards?.length - 1]?.positionIndex + 1;
  const customerViewcards = useSelector((state) => state?.CustomerView?.cards);
  const [showMenu, setShowMenu] = useState(false);
  const userRestrictedRole = useSelector(
    (state) => state?.Auth?.userRestriction
  );
  const userRestrictedEcosystemt = useSelector(
    (state) => state?.Auth?.userRestrictedEcosystemt
  );
  const restrictedProductSquad = useSelector(
    (state) => state?.Auth?.restrictedProductSquad
  );
  const selectedCardView = useSelector(
    (state) => state?.HomeMyView?.selectedCardView
  );
  const kpiId = useSelector((state) => state?.HomeMyView?.selectedKpiId);
  const appLink = useSelector((state) => state?.HomeMyView?.selectedAppLink);

  const showEditModal = () => {
    const { filter, expression, kpiId, cardView } = card;
    const { dimensionName, selectedKPI, chartType } = filter;
    if (page === CONFIGURE_VIEW.myView) {
      dispatch(setExpression(expression));
      dispatch(setSelectedKpiId(kpiId));
      dispatch(setDimensionName(dimensionName));
      dispatch(setSelectedKPI(selectedKPI));
      dispatch(setChartType(chartType));
      dispatch(setSelectedCardView(cardView));
      setModalShow(true);
      dispatch(setAdvanceButtonStatus(false));
    } else {
      dispatch(customerSetExpression(expression));
      dispatch(customerSetSelectedKpiId(kpiId));
      dispatch(customerSetDimensionName(dimensionName));
      dispatch(customerSetSelectedKPI(selectedKPI));
      dispatch(customerChartType(chartType));
      dispatch(customerSetSelectedCardView(cardView));
      setModalShow(true);
    }
  };

  const deleteCard = async () => {
    const { cardId, positionIndex } = card;
    if (page === CONFIGURE_VIEW.myView) {
      const response = await deleteMyView(cardId, positionIndex, unixId);
      if (response) {
        const filteredCards = getfilteredCard(card);
        const updatedCards = filteredCards.map((item) => {
          const updatedPosition =
            item.positionIndex > positionIndex
              ? item.positionIndex - 1
              : item.positionIndex;
          return { ...item, positionIndex: updatedPosition };
        });

        dispatch(setCards(updatedCards));
      }
    } else {
      const filteredCards =
        cardId === null
          ? customerViewcards.filter((c) => c !== card)
          : customerViewcards;
      const updatedCards = filteredCards.map((item) => {
        const updatedPosition =
          item.positionIndex > positionIndex
            ? item.positionIndex - 1
            : item.positionIndex;
        if (cardId !== null && item.cardId === cardId) {
          return { ...item, positionIndex: updatedPosition, isDeleted: true };
        }
        return { ...item, positionIndex: updatedPosition };
      });
      dispatch(customerCards(updatedCards));
      dispatch(setCustomerViewButtonDisabled(false));
    }
  };

  const getfilteredCard = (card) => {
    if (page !== CONFIGURE_VIEW.insights) {
      return cards.filter((c) => c.cardId !== card.cardId);
    } else {
      return customerViewcards.filter((c) => c.cardId !== card.cardId);
    }
  };

  const handleOnMouseEnter = () => {
    setShowMenu(true);
  };
  const handleOnMouseLeave = () => {
    setShowMenu(false);
  };

  const downloadData = async () => {
    try {
      setShowMenu(false);
      toast.info(
        'The download has been initiated, and we will notify you once it is completed.'
      );
      const { appId, expression, title, advanceEnabled } = card;
      const {
        selectedTimePeriod,
        dimensionName,
        focusAccountChecked,
        viewType,
        selectedAccount,
      } = card?.filter;

      let measure = [];
      let dimension = [];
      let newFilter = { ...card?.filter };

      const accounts = await getAccountsOrSites(unixId, viewType, roleId);
      const allPriorityAccounts =
        focusAccountChecked &&
        (selectedAccount?.length === 0 ||
          accounts?.length === newFilter.selectedAccount);

      newFilter.selectedAccount =
        page === CONFIGURE_VIEW.insights
          ? [card?.filter?.selectedAccount]
          : allPriorityAccounts
          ? accounts
          : newFilter.selectedAccount;

      const filterExpression =
        page === CONFIGURE_VIEW.insights
          ? buildExpressionCustomerViewDownload(newFilter)
          : buildExpressionOutOfFilter(
              newFilter,
              userRestrictedRole,
              userRestrictedEcosystemt,
              restrictedProductSquad
            );

      if (advanceEnabled === 'Y') {
        const advanceComparePropsRef = buildAdvancePropsObject(
          card?.advanceFilterValue,
          card?.advanceType,
          card?.advanceExpression,
          card?.selectedDimension
        );

        dimension.push({
          dimesnionType: 'Cateogry',
          dimesnionName: dimensionName,
          sortByExpression: expression,
        });

        if (
          card?.advanceEnabled === 'Y' &&
          advanceComparePropsRef?.compareBy === 'dimension'
        ) {
          dimension.push({
            dimesnionType: 'Advance',
            dimesnionName: card?.advanceFilterValue,
            sortByExpression: expression,
          });
        }
        //Settingup first measure object
        measure.push({
          name:
            advanceComparePropsRef?.compareBy === COMPARE_BENCHMARK
              ? BENCHMARK_LEGEND_FIRST
              : advanceComparePropsRef?.compareBy === COMPARE_KPI
              ? card?.filter?.selectedKPI?.KpiName
              : selectedTimePeriod?.label,
          index: 1,
          //Replace the expression with dynamic filters
          expression: card.expression.replaceAll(
            page === CONFIGURE_VIEW.insights
              ? EXPRESSION_PLACEHOLDER_TEXT
              : FILTER_PLACE_HOLDER,
            filterExpression ? filterExpression : 'test'
          ),
        });
        if (card?.advanceType === 'time-period') {
          //Settingup second measure object

          measure.push({
            name: advanceComparePropsRef?.selectDropdownOption
              ?.possibleTimePeriod,
            index: 2,
            expression:
              advanceComparePropsRef?.selectDropdownOption?.advTimeperiodExp
                ?.replaceAll(
                  page === CONFIGURE_VIEW.insights
                    ? EXPRESSION_PLACEHOLDER_TEXT
                    : FILTER_PLACE_HOLDER,
                  filterExpression ? filterExpression : 'test'
                )
                ?.replace(/(^"|"$)/g, ''),
          });

          //SETTING-UP THE DIMESNION NAME TO MONTH_N IF PRIMARY DIMESNION IS MONTH AND COMPARE BY IS TIME PERIOD.
          if (card.filter.dimensionName === 'Month') {
            let dimensionObjOne = {
              dimesnionType: 'Cateogry',
              dimesnionName: 'Month_N',
            };

            dimension = [dimensionObjOne];
          }
        } else if (card?.advanceType === COMPARE_BENCHMARK) {
          //Setting-up second measure object
          measure.push({
            name: advanceComparePropsRef?.selectDropdownOption
              ?.possibleTimePeriod,
            index: 2,
            expression:
              advanceComparePropsRef?.selectDropdownOption?.advTimeperiodExp
                ?.replaceAll(
                  page === CONFIGURE_VIEW.insights
                    ? EXPRESSION_PLACEHOLDER_TEXT
                    : 'vAllFilters',
                  filterExpression ? filterExpression : 'test'
                )
                ?.replace(/(^"|"$)/g, ''),
          });
        } else if (card?.advanceType === COMPARE_KPI) {
          advanceComparePropsRef?.selectDropdownOption?.advTimeperiodExp?.map(
            (oneKpi) => {
              measure.push({
                name: oneKpi?.label || oneKpi?.timesPeriod,
                index: measure.length + 1,
                expression: oneKpi?.expression
                  ?.replaceAll(
                    page === CONFIGURE_VIEW.insights
                      ? EXPRESSION_PLACEHOLDER_TEXT
                      : 'vAllFilters',
                    filterExpression ? filterExpression : 'test'
                  )
                  ?.replace(/(^"|"$)/g, ''),
              });
            }
          );
          //SETTING UP THE DIMESNION NAME TO MONTH_N IF PRIMARY DIMESNION IS MONTH AND COMPARE BY IS TIME PERIOD.
          if (card.filter.dimensionName === 'Month') {
            let dimensionObjOne = {
              dimesnionType: 'Cateogry',
              dimesnionName: 'Month_N',
            };

            dimension = [JSON.stringify(dimensionObjOne)];
          }
        }
      } else {
        //In case of No Dimesnion and one measure
        dimension.push({
          dimesnionType: 'Cateogry',
          dimesnionName: dimensionName,
          sortByExpression: expression,
        });

        measure.push({
          name: selectedTimePeriod?.label,
          index: 1,
          expression: card.expression.replaceAll(
            FILTER_PLACE_HOLDER,
            filterExpression ? filterExpression : 'test'
          ),
        });
      }

      const buildExpression = {
        dimensions: dimension,
        measure: measure,
        appId: appId,
      };

      await downloadTileData(title, buildExpression);
      toast.success('Download completed. Please check your download folder.');
    } catch (error) {
      console.error(error);
      toast.error(
        'An error occurred. Please raise a request under the Help menu.'
      );
    }
  };

  const addToMyViews = async () => {
    if (cards?.length >= 20) {
      toast.error(CHART_LIMIT_ERROR);
      return;
    }
    const payload = createCardPayload(
      card,
      unixId,
      getPositionIndex,
      ACTION_ADD_TO_MY_VIEW
    );

    const { cardId } = await addToMyView(payload);
    const cardData = {
      ...payload,
      filter: payload.filter,
      cardId: cardId,
      chartData: card.chartData,
      cardView: card.cardView,
    };
    dispatch(setCards([...cards, cardData]));
    toast.success(ADD_TO_MY_VIEW_MESSAGE);
  };

  const copyCard = async (card) => {
    if (page === CONFIGURE_VIEW.insights) {
      const addToHome = async () => {
        const filteredCards = insightCards.filter(
          (item) => item?.isDeleted !== true
        );

        if (filteredCards?.length >= 12) {
          toast.error(INSIGHTS_CHART_LIMIT_ERROR);
          return;
        }
        const cardData = createCardPayload(
          card,
          unixId,
          card?.positionIndex + 1,
          ACTION_COPY
        );

        const insertIndex = card.positionIndex;
        const updatedCards = [...insightCards];
        updatedCards.splice(insertIndex, 0, cardData);
        let flag = true;
        const newCards = updatedCards.map((item) => {
          if (item.positionIndex > insertIndex) {
            if (item.positionIndex === insertIndex + 1 && flag) {
              flag = false;
              return item;
            }
            return {
              ...item,
              positionIndex: item.positionIndex + 1,
            };
          } else return item;
        });
        dispatch(customerCards(newCards));
        dispatch(setCustomerViewButtonDisabled(false));
        toast.success(DUPLICATE_INSIGHT_VIEW_MESSAGE);
      };
      addToHome();
    } else {
      if (cards?.length >= 20) {
        toast.error(CHART_LIMIT_ERROR);
        return;
      }
      const payload = createCardPayload(
        card,
        unixId,
        card?.positionIndex + 1,
        ACTION_COPY
      );
      const { cardId } = await addToMyView(payload);

      const cardData = {
        ...payload,
        cardId: cardId,
        chartData: card.chartData,
      };
      const insertIndex = card.positionIndex;
      const updatedCards = [...cards];
      updatedCards.splice(insertIndex, 0, cardData);
      const newCards = updatedCards.map((item) =>
        item.positionIndex > insertIndex && item.cardId !== cardId
          ? { ...item, positionIndex: item.positionIndex + 1 }
          : item
      );
      dispatch(setCards(newCards));
      toast.success(DUPLICATE_TO_MY_VIEW_MESSAGE);
    }
  };

  return (
    <>
      <div className='d-flex justify-content-end'>
        {/* expand view */}
        <LinkButton
          onClick={() => setExpandViewModalShow(true)}
          className='d-inline-block'>
          <i className='icon icon__expand_view'></i>
        </LinkButton>

        {/* edit */}
        {!isSampleInsight && (
          <>
            {!isSampleInsight && page !== CONFIGURE_VIEW.myDefault && (
              <LinkButton
                onClick={() => showEditModal()}
                className='d-inline-block'>
                <i className='icon icon__edit-metric'></i>
              </LinkButton>
            )}
            <div
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
              role='button'>
              <OverlayTrigger
                show={showMenu}
                key='avatar'
                placement='bottom'
                overlay={
                  <Popover id='popover-contained'>
                    <div>
                      {!isSampleInsight &&
                        page !== CONFIGURE_VIEW.myDefault && (
                          <>
                            <div
                              className={`${classes['list-item']} mt-2`}
                              role='button'
                              onClick={() => copyCard(card)}>
                              <LinkButton className='d-inline-block w-25'>
                                <i
                                  className={`icon icon__duplicate me-2 p-0`}
                                />
                              </LinkButton>
                              <span className='d-inline-block w-75 title title__small'>
                                Duplicate
                              </span>
                            </div>
                            <div
                              className={classes['list-item']}
                              role='button'
                              onClick={() => downloadData()}>
                              <LinkButton className='d-inline-block w-25'>
                                <i
                                  className={`icon icon__affiliations_export_excel1`}
                                />
                              </LinkButton>
                              <span className='d-inline-block w-75 title title__small'>
                                Download Data
                              </span>
                            </div>
                            <div
                              className={`${classes['list-item']} mb-2`}
                              role='button'
                              onClick={() => deleteCard()}>
                              <LinkButton className='d-inline-block w-25'>
                                <i className='icon icon__delete-tile'></i>
                              </LinkButton>
                              <span className='d-inline-block w-75 title title__small'>
                                Remove
                              </span>
                            </div>
                          </>
                        )}
                    </div>
                    <div>
                      {!isSampleInsight &&
                        page === CONFIGURE_VIEW.myDefault && (
                          <div
                            className={`${classes['list-item']} `}
                            role='button'
                            onClick={() => addToMyViews(card)}>
                            <LinkButton className='d-inline-block w-25 mt-1'>
                              <i className={`icon icon__add_default `} />
                            </LinkButton>
                            <span className='d-inline-block w-75 title title__small'>
                              Add to My View
                            </span>
                          </div>
                        )}
                    </div>
                  </Popover>
                }
                popperConfig={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: ({ popper, reference }) => {
                          const offsetX = -65;
                          const offsetY = 5;
                          return [offsetX, offsetY];
                        },
                      },
                    },
                    {
                      name: 'flip',
                      options: {
                        fallbackPlacements: ['top', 'right', 'left'],
                      },
                    },
                  ],
                }}>
                <i className='icon icon__card-toggle text-end'></i>
              </OverlayTrigger>
            </div>
          </>
        )}
      </div>

      {/* modals */}
      <ConfigureMyViewLayout
        show={modalShow}
        onHide={() => setModalShow(false)}
        page={page}
        view={VIEW.edit}
        data={card}
        css={`animate__animated animate__${modalShow ? 'zoomIn' : 'zoomOut'}`}
      />

      <ExpandMyView
        show={expandViewModalShow}
        onHide={() => setExpandViewModalShow(false)}
        card={card}
        page={page}
        css={`animate__animated animate__${
          expandViewModalShow ? 'zoomIn' : 'zoomOut'
        }`}
      />
    </>
  );
}

export default TileActions;
