/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react';
import SelectWithPagination from 'components/shared/dropdown-with-pagination/SelectWithPagination';
import { Spin } from 'antd';

import ConfigurableTable from 'components/shared/table/ConfigurableTable';
import classes from './ProductMix.module.scss';
import {
  setIsAccountableProduct,
  setIsAccountableProductMix,
  setPrductMixExpression,
  setProductMixProduct,
  setProductMixSquad,
  setSiteLoader,
} from 'store/customer/customerViewSlice';
import {
  PRODUCT_MIX_COLUMNS,
  PRODUCT_MIX_FILTER_NAME,
  SITE_PERFORMANCE_EXPRESSION,
  TIME_RANGE_EXP,
  SITE_PERF_APP_ID,
  CHECKBOXCLASS,
  TABLE_NAME_PRODUCT_MIX,
} from 'constants/constants';
import {
  exportData,
  getProductMixData,
} from 'store/customer/customer-view-actions';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getRestrictedColumn, isArray } from 'utils/utils';

import { getQlikSenseData } from 'services/qlik.service';
import GNESingleSelect from 'components/shared/dropdown/GNESingleSelect';

const ProductMix = () => {
  const [menuIsOpen, setMenuIsOpen] = useState({
    SiteSquad: false,
    Product: false,
  });
  const isAccountableProduct = useSelector(
    (state) => state?.CustomerView?.productMixFilters?.IsAccountableProduct
  );
  const selectedSquad = useSelector(
    (state) => state?.CustomerView?.productMixFilters?.SiteSquad?.selectedValue
  );
  const selectedProducts = useSelector(
    (state) => state?.CustomerView?.productMixFilters?.Product?.selectedValue
  );

  const productMixData = useSelector(
    (state) => state?.CustomerView?.productMixData
  );

  const isLoading = useSelector((state) => state?.CustomerView?.siteLoader);
  const isExporting = useSelector((state) => state?.CustomerView?.exportSite);
  useEffect(() => {
    setSearchedData(productMixData?.values);
  }, [productMixData]);

  const [searchedData, setSearchedData] = useState(productMixData?.values);

  const dispatch = useDispatch();

  const qlikToken = localStorage.getItem('qlikToken');
  const qlikCookie = localStorage.getItem('qlikCookie');

  const selectedAccount = useSelector(
    (state) => state?.CustomerView?.selectedAccount
  );

  const filteredColumns = PRODUCT_MIX_COLUMNS;

  const productMixFilters = useSelector(
    (state) => state?.CustomerView?.productMixFilters
  );
  const productOptions = useSelector(
    (state) => state?.CustomerView?.productMixFilters?.Product
  );

  const squadOptions = useSelector(
    (state) => state?.CustomerView?.productMixFilters?.Squad
  );
  let productMixExpression = useSelector(
    (state) => state?.CustomerView?.productMixExpression
  );

  const columnRestriction = useSelector(
    (state) => state?.CustomerView?.columnsRestiction
  );

  const [timeRange, setTimeRange] = useState('');
  const defaultTimePeriod = {
    value: 'R12',
    label: 'R12M',
  };
  const [selectedTimePeriod, setselectedTimePeriod] =
    useState(defaultTimePeriod);

  useEffect(() => {
    getProductMixTimeRange();
  }, []);
  useEffect(() => {
    dispatch(setIsAccountableProduct(true));
    setselectedTimePeriod(defaultTimePeriod);
  }, [selectedAccount]);

  useEffect(() => {
    if (selectedSquad?.length > 1)
      dispatch(setProductMixSquad({ options: [], selectedValue: [] }));
  }, [selectedProducts]);
  useEffect(() => {
    getProductMixTimeRange();
  }, [selectedTimePeriod]);

  useEffect(() => {
    if (!Array.isArray(selectedAccount)) buildExpression();
  }, [
    selectedAccount,
    selectedProducts,
    selectedTimePeriod,
    isAccountableProduct,
    selectedSquad,
  ]);

  const getProductMixTimeRange = async () => {
    const response = await getQlikSenseData(
      TIME_RANGE_EXP.get(selectedTimePeriod?.value),
      null,
      SITE_PERFORMANCE_EXPRESSION.appId
    );
    setTimeRange(response.data.data.category[0].replaceAll("'", ' '));
  };

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const totalRecords =
    searchedData?.length >= 0 && searchedData?.length < 100
      ? searchedData?.length
      : 100;

  const [total, setTotal] = useState(totalRecords);
  useEffect(() => {
    setTotal(totalRecords);
  }, [totalRecords]);

  const pagination = {
    total: total,
    onChange: (page, size) => {
      setPageIndex(page);
      setPageSize(size);
    },
    pageSizeOptions: [10, 20, 30],
    showSizeChanger: true,
  };

  useEffect(() => {
    setPageIndex(pageIndex);
    const isLastPage = pageIndex === Math.ceil(total / pageSize);
    buildExpression();
    const selectedPageIndex =
      pageIndex > 0 && Math.ceil((pageIndex * pageSize) / 100);
    if (isLastPage && total < productMixData.totalSize) {
      dispatch(
        getProductMixData(
          productMixExpression,
          selectedPageIndex,
          qlikCookie,
          qlikToken,
          selectedTimePeriod
        )
      );
      setTotal(total + 100);
    }
  }, [pageIndex]);
  const timePeriodOptions = [
    { value: 'R3', label: 'R3M' },
    { value: 'R6', label: 'R6M' },
    { value: 'R12', label: 'R12M' },
    { value: 'YTD', label: 'YTD' },
  ];

  const buildExpression = () => {
    const isAllProd =
      isArray(productOptions) &&
      productOptions?.length === selectedProducts?.length;

    const isAllSquad =
      isArray(squadOptions) && squadOptions?.length === selectedSquad?.length;

    const squad = isAllSquad ? 'Squad=' : selectedSquad;

    const products = isAllProd ? 'Product=' : selectedProducts;

    const accountExp = `Site_ID={"${selectedAccount?.value}"}`;

    // build product expression
    const productExp = isArray(products)
      ? 'Product={"' + products.map((a) => a.label).join('","') + '"}'
      : '';

    // build business site expression

    const squadExp = isArray(squad)
      ? 'Squad={"' + squad.map((a) => a.label).join('","') + '"}'
      : '';
    productMixExpression = `${accountExp}`;
    if (productExp !== '')
      productMixExpression = productMixExpression.concat(`, ${productExp}`);
    if (squadExp !== '')
      productMixExpression = productMixExpression.concat(`, ${squadExp}`);
    if (isAccountableProduct !== undefined && isAccountableProduct === true)
      productMixExpression = productMixExpression.concat(
        ', "isAccountableProduct"={"Y"}'
      );
    dispatch(setPrductMixExpression(productMixExpression));
  };

  useEffect(() => {
    if (!selectedAccount?.label) {
      dispatch(setSiteLoader(false));
      setSearchedData([]);
      return;
    }
    dispatch(
      getProductMixData(
        productMixExpression,
        0,
        qlikCookie,
        qlikToken,
        selectedTimePeriod,
        'grossSales',
        'descend'
      )
    );
  }, [
    selectedAccount,
    selectedProducts,
    selectedTimePeriod,
    selectedSquad,
    isAccountableProduct,
  ]);

  const handleSorting = (column, order) => {
    buildExpression();

    dispatch(
      getProductMixData(
        productMixExpression,
        0,
        qlikCookie,
        qlikToken,
        selectedTimePeriod,
        column,
        order
      )
    );
  };
  const handleAccountProdCheckbox = (e) => {
    dispatch(setIsAccountableProductMix(e?.target?.checked));
    dispatch(setProductMixProduct({ options: [], selectedValue: [] }));
    dispatch(setProductMixSquad({ options: [], selectedValue: [] }));
  };

  const onMenuOpen = useCallback((filterName, value) => {
    let menuIsOpenLocal = menuIsOpen;
    if (filterName !== '' && value) {
      menuIsOpenLocal = Object.keys(menuIsOpen).reduce((acc, key) => {
        return { ...acc, [key]: filterName === key };
      }, {});
    } else if ((filterName !== '' && !value) || filterName === '') {
      menuIsOpenLocal = Object.keys(menuIsOpen).reduce((acc, key) => {
        return { ...acc, [key]: false };
      }, {});
    }
    setMenuIsOpen({ ...menuIsOpenLocal });
  }, []);
  const downloadData = () => {
    dispatch(
      exportData(
        'ProductMix',
        selectedAccount?.value,
        qlikCookie,
        qlikToken,
        productMixExpression,
        selectedTimePeriod
      )
    );
  };
  const exportTooltip = (
    <Tooltip bsclass='gne-tooltip tooltip--left'>Send to Excel</Tooltip>
  );
  const size = { x: 800, y: 400 };

  return (
    <div className='full-width-in-container light-grey'>
      <div
        id='ProductMix'
        className='pt-3'>
        <Row>
          <Col
            className='pt-3'
            md={6}>
            <p className='title title__bold title__large mb-0'>Product Mix</p>
            <p className='title__small pt-0 text text__grey'>{timeRange}</p>
          </Col>
          <Col
            className='d-flex justify-content-end'
            md={6}>
            <OverlayTrigger
              placement='top'
              overlay={exportTooltip}>
              <i
                onClick={downloadData}
                className={`icon icon__affiliations_export_excel1 mt-3`}
              />
            </OverlayTrigger>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <label className='title__x-small'>Time Period</label>
            <GNESingleSelect
              value={selectedTimePeriod}
              onChange={(e) => setselectedTimePeriod(e)}
              options={timePeriodOptions}
              className='single-select'
            />
          </Col>
          <Col md={2}>
            <label className='title__x-small'>Squad</label>
            <SelectWithPagination
              menuIsOpen={menuIsOpen}
              onMenuOpen={onMenuOpen}
              appid={SITE_PERF_APP_ID}
              filter={productMixFilters}
              fieldName='SiteSquad'
              isMulti={true}
              filterName={PRODUCT_MIX_FILTER_NAME}
              cacheUniqs={[
                selectedAccount,
                isAccountableProduct,
                selectedProducts,
                selectedTimePeriod,
              ]}
            />
          </Col>

          <Col md={2}>
            <label className='title__x-small'>Products</label>
            <SelectWithPagination
              menuIsOpen={menuIsOpen}
              onMenuOpen={onMenuOpen}
              appid={SITE_PERF_APP_ID}
              filter={productMixFilters}
              fieldName='Product'
              isMulti={true}
              filterName={PRODUCT_MIX_FILTER_NAME}
              cacheUniqs={[
                selectedAccount,
                isAccountableProduct,
                selectedSquad,
                selectedTimePeriod,
              ]}
            />
          </Col>
          <Col md={4}>
            <label className='title__x-small w-100'></label>
            <span className='title__small'>
              <Form.Check
                inline
                type='checkbox'
                label='Accountable Products'
                checked={isAccountableProduct}
                onChange={handleAccountProdCheckbox}
                className={isAccountableProduct && CHECKBOXCLASS}
              />
            </span>
          </Col>
        </Row>
        &nbsp;
      </div>

      <Row
        id='ProductMix'
        className={classes.sitePerformanceContainer}>
        <Col>
          <Spin spinning={isLoading || isExporting}>
            <ConfigurableTable
              rows={searchedData}
              columns={getRestrictedColumn(
                TABLE_NAME_PRODUCT_MIX,
                filteredColumns,
                columnRestriction
              )}
              expandRowByClick={false}
              enableColumnSelection={false}
              enableExport={false}
              exportFileName='productMixDetails'
              tableName={TABLE_NAME_PRODUCT_MIX}
              expression={productMixExpression}
              isGlobalExport={true}
              pagination={pagination}
              isGlobalSort={true}
              onSort={handleSorting}
              timePeriod={selectedTimePeriod}
              dimentions={size}
            />
          </Spin>
        </Col>
      </Row>
    </div>
  );
};

export default ProductMix;
