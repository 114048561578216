/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { wrapMenuList } from 'react-select-async-paginate';
import { useSelector } from 'react-redux';

import classes from './Filter.module.scss';

import LinkButton from 'components/shared/button/LinkButton';
import { filterSelectionRefactoring } from 'services/org/org.service';
import GNERadio from 'components/shared/radio/GNERadio';
import {
  ValueContainer,
  dropdownOptions,
  formatOptionLabel,
  menuIsOpenFilterState,
} from 'utils/utils';
import {
  ACCOUNT_TYPE,
  ACTION_TYPE,
  FILTERSCREEN,
  optionsInitialState,
  optionsInitialStateDefault,
  optionsInitialStateOffset,
} from 'constants/constants';
import { useRef } from 'react';

import { loadOptionss } from './Utils';
import GNEAsyncPagination from 'components/shared/dropdown-with-pagination/GNEAsyncPagination';
const Filters = ({
  accountId,
  onFilterSelected,
  onFilterApply,
  page,
  pageType,
  onhandleAddTo,
  selectedType,
  onhandleAddType,
  labelsData,
  onCallReset,
  filterSection,
  labelAccess,
  initialFilterValue,
  action,
  onSaveFilter,
  savedFilterView,
  showSavedFilterValue,
  showInfo,
  classType,
}) => {
  const [selectedFilters, setSelectedFilters] = useState(
    initialFilterValue || optionsInitialState
  );
  const [showHoverSiteName, setShowHoverSiteName] = useState();
  const [showHoveEcoSystem, setShowHoverEcoSystem] = useState();
  const [showHoverLabel, setShowHoverLabel] = useState();
  const [addTo, setAddTo] = useState(ACCOUNT_TYPE.site);
  const [disabled, setDisabled] = useState(true);
  const [addType, setAddType] = useState(selectedType);
  const firstLoading = useRef(true);
  const shouldLoadMore = useRef(false);
  const [offset, setOffset] = useState(optionsInitialStateOffset);
  const [options, setOptions] = useState(optionsInitialState);
  const [optionsDefault, setOptionsDefault] = useState(
    optionsInitialStateDefault
  );
  const [menuIsOpen, setMenuIsOpen] = useState(menuIsOpenFilterState());
  const [filterClicked, setFilterClicked] = useState(menuIsOpenFilterState());
  const [currentFilterName, setCurrentFilterName] = useState('');
  const { unixId } = useSelector((state) => state.Auth.userClaimObject);
  const [showSaveFilter, setShowSaveFilter] = useState(false);

  const shouldLoadMoreOptions = (scrollHeight, clientHeight, scrollTop) => {
    if (firstLoading.current) {
      firstLoading.current = false;
      return true;
    }
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    const loadMore = bottomBorder < scrollTop;
    shouldLoadMore.current = loadMore;

    return loadMore;
  };
  useEffect(() => {
    const updatedFilterCount = Object.entries(selectedFilters)?.filter(
      (item) => item[1]?.length > 0
    );
    setShowSaveFilter(updatedFilterCount?.length > 0);
  }, [selectedFilters]);

  const loadOptions = async (filterName, searchQuery, firstLoad) => {
    try {
      const dataOffset =
        firstLoad === true
          ? 0
          : firstLoading.current
          ? 0
          : !shouldLoadMore.current
          ? 0
          : offset[filterName];

      const filters = filterSelectionRefactoring(selectedFilters);
      const { data, hasMore, flag, selectedOpt, currentListSize } =
        await loadOptionss(
          filterName,
          accountId,
          selectedFilters[filterName],
          filters,
          dataOffset,
          options,
          searchQuery,
          action,
          unixId,
          selectedFilters,
          filterClicked,
          currentFilterName,
          setCurrentFilterName,
          shouldLoadMore.current,
          classType
        );

      if (flag) {
        if (selectedOpt && selectedOpt.length) {
          setSelectedFilters((prevSelectedFilters) => ({
            ...prevSelectedFilters,
            [filterName]: selectedOpt,
          }));
        }
        setOptions((prevoptions) => ({
          ...prevoptions,
          [filterName]: [...data],
        }));

        if (hasMore) {
          setOffset((prevoptions) => ({
            ...prevoptions,
            [filterName]: offset[filterName] + currentListSize,
          }));
        }
        return {
          options: data,
          hasMore: hasMore,
        };
      }

      if (hasMore) {
        setOffset((prevoptions) => ({
          ...prevoptions,
          [filterName]: offset[filterName] + currentListSize,
        }));
      }

      return {
        options: options[filterName],
        hasMore: hasMore,
      };
    } catch (error) {
      console.log(error);
      return {
        options: options[filterName],
        hasMore: false,
      };
    }
  };

  const handleFilterChange = async (filterName, selectedOption, action) => {
    try {
      if (filterName === 'siteName') {
        let label = [];
        await selectedOption.forEach((i) => label.push(i['label']));
        setShowHoverSiteName({ label });
      }
      if (filterName === 'ecosystem') {
        let label = [];
        await selectedOption.forEach((i) => label.push(i['label']));
        setShowHoverEcoSystem({
          label,
        });
      }
      if (filterName === 'label') {
        let label = [];
        await selectedOption.forEach((i) => label.push(i['label']));
        setShowHoverLabel({
          label,
        });
      }
      const _action = selectedOption.length === 0 ? 'clear' : action;
      setSelectedFilters((prevSelectedFilters) => ({
        ...prevSelectedFilters,
        [filterName]: selectedOption,
      }));

      onFilterSelected(filterName, _action);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilterInput = (event, filterName) => {
    const value = event?.target?.value;
    let action = '';

    if (value < 0) return;

    if (!value) {
      action = 'clear';
    }

    try {
      setSelectedFilters((prevSelectedFilters) => ({
        ...prevSelectedFilters,
        [filterName]: event.target.value,
      }));
      onFilterSelected(filterName, action);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (page === ACTION_TYPE.filter) {
      isApplyDisabled();
    }
  }, [selectedFilters]);

  useEffect(() => {
    setSelectedFilters(initialFilterValue);
  }, [initialFilterValue]);

  const handleAddTO = (e) => {
    setAddTo(e.target.value);
    onhandleAddTo(e.target.value);
  };

  const handleAddType = (e) => {
    setAddType(e.target.value);
    setSelectedFilters(optionsInitialState);
    onhandleAddType(e.target.value);
  };

  const resetOptions = () => {
    setShowSaveFilter(false);
    setSelectedFilters(optionsInitialState);
    setShowHoverSiteName([]);
    setShowHoverEcoSystem([]);
    setShowHoverLabel([]);
    if (page !== ACTION_TYPE.filter) {
      onCallReset();
    }
  };

  const saveFilter = () => {
    onSaveFilter(selectedFilters);
  };

  const handleApply = () => {
    setShowSaveFilter(true);
    const filters = filterSelectionRefactoring(selectedFilters);
    if (page === ACTION_TYPE.filter) {
      onFilterApply(filters, addTo, addType, selectedFilters);
    } else {
      onFilterApply(filters, selectedFilters);
      showInfo(filters, showHoverSiteName, showHoveEcoSystem, showHoverLabel);
    }
  };

  const isApplyDisabled = () => {
    let hasNoData = true;
    for (const key in selectedFilters) {
      if (selectedFilters[key]?.length > 0) {
        hasNoData = false;
        break;
      }
    }
    setDisabled(hasNoData);
  };

  const onMenuOpen = useCallback((filterName) => {
    // setOptionsDefault((prevOptions) => ({
    //   ...prevOptions,
    //   [filterName]: true,
    // }));
    setMenuIsOpen({ ...menuIsOpenFilterState(filterName) });
    if (filterName) {
      setFilterClicked({ ...menuIsOpenFilterState(filterName) });
      setCurrentFilterName(filterName);
    }
  }, []);

  return (
    <>
      <div
        className={`animate__animated animate__fadeIn ${
          page === ACTION_TYPE.filter
            ? classes['filter-container-actions']
            : classes['filter-container']
        }`}>
        {page === ACTION_TYPE.filter ? (
          <div>
            <div
              className={`d-flex align-items-center ${classes['filter-wrap']}`}>
              <span className={classes['filters-title']}>Filters</span>
              <div
                className={`${
                  pageType === ACTION_TYPE.addTo ? classes['isDisabled'] : ''
                }`}>
                {pageType === 'add' || pageType === ACTION_TYPE.addTo ? (
                  <div className='d-flex align-items-center'>
                    <GNERadio
                      checked={addType === 'add'}
                      name='accountType'
                      label='Add'
                      value='add'
                      onChange={handleAddType}
                    />
                    <GNERadio
                      checked={addType === 'shared'}
                      name='accountType'
                      label='Shared'
                      value='shared'
                      onChange={handleAddType}
                    />
                  </div>
                ) : (
                  <div className={classes['radioSelectionClass']}></div>
                )}
              </div>
              <LinkButton
                className={
                  disabled
                    ? `text text__small float-right ${classes['reset-btn']}`
                    : 'text text__small float-right'
                }
                onClick={() => resetOptions()}>
                Reset
              </LinkButton>
            </div>

            <div>
              {pageType === ACTION_TYPE.addTo && (
                <div className='d-flex align-items-center'>
                  <GNERadio
                    checked={addTo === ACCOUNT_TYPE.account}
                    name='addToTypes'
                    label='Account'
                    value={ACCOUNT_TYPE.account}
                    onChange={handleAddTO}
                  />
                  <GNERadio
                    checked={addTo === ACCOUNT_TYPE.site}
                    name='addToTypes'
                    label='Site'
                    value='site'
                    onChange={handleAddTO}
                  />
                </div>
              )}
            </div>
          </div>
        ) : (
          <Row>
            {/* reset */}
            <Col
              xs={1}
              sm={1}
              md={1}
              lg={1}>
              <LinkButton
                className={`text text__small float-left ${
                  !showSaveFilter ? 'disabled' : ''
                }`}
                onClick={saveFilter}
                disabled={!showSaveFilter}>
                <i
                  className={
                    !showSaveFilter
                      ? 'icon icon__org-save-disabled'
                      : 'icon icon__org-save'
                  }></i>
                Save
              </LinkButton>
            </Col>
            <Col>
              <LinkButton
                className='text text__small float-right'
                onClick={() => resetOptions()}>
                <i className='icon icon__reset'></i>
                Reset
              </LinkButton>
            </Col>
          </Row>
        )}

        <div
          className={`${
            addTo === ACCOUNT_TYPE.account && pageType === ACTION_TYPE.addTo
              ? classes['isDisabled']
              : ''
          }`}>
          <Row>
            <Col
              md={page === ACTION_TYPE.filter ? 12 : 4}
              className='mb-3'>
              <label className='title__x-small mb-1'>Site Name </label>
              <GNEAsyncPagination
                menuIsOpen={menuIsOpen['siteName']}
                onMenuOpen={() => onMenuOpen('siteName')}
                onMenuClose={() => onMenuOpen('')}
                placeholder={
                  filterSection === FILTERSCREEN.main ? 'All' : 'Select'
                }
                closeMenuOnSelect={false}
                value={selectedFilters?.siteName}
                loadOptions={(searchQuery, firstLoad) =>
                  loadOptions('siteName', searchQuery, firstLoad)
                }
                getOptionValue={(option) => option?.value}
                getOptionLabel={(option) => option?.label}
                formatOptionLabel={(data, { context }) =>
                  formatOptionLabel(data, { context })
                }
                onChange={(selectedOption) =>
                  handleFilterChange('siteName', selectedOption)
                }
                isMulti={true}
                components={{
                  ValueContainer: ValueContainer,
                  Option: wrapMenuList(dropdownOptions),
                }}
                isClearable
                shouldLoadMore={shouldLoadMoreOptions}
                cacheUniqs={[
                  selectedFilters?.ecosystem,
                  selectedFilters?.city,
                  selectedFilters?.state,
                  selectedFilters?.zip,
                  selectedFilters?.class,
                  selectedFilters?.contract_segment,
                  selectedFilters?.hin,
                  selectedFilters?.dea,
                  selectedFilters?.program_type,
                  selectedFilters?.label,
                  accountId,
                  addType,
                  // filterClicked?.ecosystem,
                  // filterClicked?.city,
                  // filterClicked?.state,
                  // filterClicked?.zip,
                  // filterClicked?.class,
                  // filterClicked?.contract_segment,
                  // filterClicked?.hin,
                  // filterClicked?.dea,
                  // filterClicked?.program_type,
                  // filterClicked?.label,
                ]}
              />
            </Col>
            <Col
              md={page === ACTION_TYPE.filter ? 6 : 2}
              className='mb-3'>
              <label className='title__x-small mb-1'>Ecosystem</label>
              <GNEAsyncPagination
                menuIsOpen={menuIsOpen['ecosystem']}
                onMenuOpen={() => onMenuOpen('ecosystem')}
                onMenuClose={() => onMenuOpen('')}
                placeholder={
                  filterSection === FILTERSCREEN.main ? 'All' : 'Select'
                }
                closeMenuOnSelect={false}
                value={selectedFilters?.ecosystem}
                loadOptions={(searchQuery, firstLoad) =>
                  loadOptions('ecosystem', searchQuery, firstLoad)
                }
                onChange={(selectedOption) =>
                  handleFilterChange('ecosystem', selectedOption)
                }
                isMulti={true}
                components={{
                  ValueContainer: ValueContainer,
                  Option: wrapMenuList(dropdownOptions),
                }}
                isClearable
                shouldLoadMore={shouldLoadMoreOptions}
                cacheUniqs={[
                  selectedFilters?.siteName,
                  selectedFilters?.city,
                  selectedFilters?.state,
                  selectedFilters?.zip,
                  selectedFilters?.class,
                  selectedFilters?.contract_segment,
                  selectedFilters?.hin,
                  selectedFilters?.dea,
                  selectedFilters?.program_type,
                  selectedFilters?.label,
                  accountId,
                  addType,
                  // filterClicked?.siteName,
                  // filterClicked?.city,
                  // filterClicked?.state,
                  // filterClicked?.zip,
                  // filterClicked?.class,
                  // filterClicked?.contract_segment,
                  // filterClicked?.hin,
                  // filterClicked?.dea,
                  // filterClicked?.program_type,
                  // filterClicked?.label,
                ]}
              />
            </Col>
            <Col
              md={page === ACTION_TYPE.filter ? 6 : 2}
              className='mb-3'>
              <label className='title__x-small mb-1'>State</label>
              <GNEAsyncPagination
                menuIsOpen={menuIsOpen['state']}
                onMenuOpen={() => onMenuOpen('state')}
                onMenuClose={() => onMenuOpen('')}
                placeholder={
                  filterSection === FILTERSCREEN.main ? 'All' : 'Select'
                }
                closeMenuOnSelect={false}
                value={selectedFilters?.state}
                loadOptions={(searchQuery, firstLoad) =>
                  loadOptions('state', searchQuery, firstLoad)
                }
                onChange={(selectedOption) =>
                  handleFilterChange('state', selectedOption)
                }
                isMulti={true}
                components={{
                  ValueContainer: ValueContainer,
                  Option: wrapMenuList(dropdownOptions),
                }}
                isClearable
                shouldLoadMore={shouldLoadMoreOptions}
                cacheUniqs={[
                  selectedFilters?.siteName,
                  selectedFilters?.ecosystem,
                  selectedFilters?.city,
                  selectedFilters?.zip,
                  selectedFilters?.class,
                  selectedFilters?.contract_segment,
                  selectedFilters?.hin,
                  selectedFilters?.dea,
                  selectedFilters?.program_type,
                  selectedFilters?.label,
                  accountId,
                  addType,
                  // filterClicked?.siteName,
                  // filterClicked?.ecosystem,
                  // filterClicked?.city,
                  // filterClicked?.zip,
                  // filterClicked?.class,
                  // filterClicked?.contract_segment,
                  // filterClicked?.hin,
                  // filterClicked?.dea,
                  // filterClicked?.program_type,
                  // filterClicked?.label,
                ]}
              />
            </Col>
            <Col
              md={page === ACTION_TYPE.filter ? 6 : 2}
              className='mb-3'>
              <label className='title__x-small mb-1'>City</label>
              <GNEAsyncPagination
                menuIsOpen={menuIsOpen['city']}
                onMenuOpen={() => onMenuOpen('city')}
                onMenuClose={() => onMenuOpen('')}
                placeholder={
                  filterSection === FILTERSCREEN.main ? 'All' : 'Select'
                }
                closeMenuOnSelect={false}
                value={selectedFilters?.city}
                loadOptions={(searchQuery, firstLoad) =>
                  loadOptions('city', searchQuery, firstLoad)
                }
                onChange={(selectedOption) =>
                  handleFilterChange('city', selectedOption)
                }
                isMulti={true}
                components={{
                  ValueContainer: ValueContainer,
                  Option: wrapMenuList(dropdownOptions),
                }}
                isClearable
                shouldLoadMore={shouldLoadMoreOptions}
                cacheUniqs={[
                  selectedFilters?.siteName,
                  selectedFilters?.ecosystem,
                  selectedFilters?.state,
                  selectedFilters?.zip,
                  selectedFilters?.class,
                  selectedFilters?.contract_segment,
                  selectedFilters?.hin,
                  selectedFilters?.dea,
                  selectedFilters?.program_type,
                  selectedFilters?.label,
                  accountId,
                  addType,
                  // filterClicked?.siteName,
                  // filterClicked?.ecosystem,
                  // filterClicked?.state,
                  // filterClicked?.zip,
                  // filterClicked?.class,
                  // filterClicked?.contract_segment,
                  // filterClicked?.hin,
                  // filterClicked?.dea,
                  // filterClicked?.program_type,
                  // filterClicked?.label,
                ]}
              />
            </Col>
            <Col
              md={page === ACTION_TYPE.filter ? 6 : 2}
              className='mb-3'>
              <label className='title__x-small mb-1'>ZIP</label>
              <GNEAsyncPagination
                menuIsOpen={menuIsOpen['zip']}
                onMenuOpen={() => onMenuOpen('zip')}
                onMenuClose={() => onMenuOpen('')}
                placeholder={
                  filterSection === FILTERSCREEN.main ? 'All' : 'Select'
                }
                closeMenuOnSelect={false}
                value={selectedFilters?.zip}
                loadOptions={(searchQuery, firstLoad) =>
                  loadOptions('zip', searchQuery, firstLoad)
                }
                onChange={(selectedOption) =>
                  handleFilterChange('zip', selectedOption)
                }
                isMulti={true}
                components={{
                  ValueContainer: ValueContainer,
                  Option: wrapMenuList(dropdownOptions),
                }}
                isClearable
                shouldLoadMore={shouldLoadMoreOptions}
                cacheUniqs={[
                  selectedFilters?.siteName,
                  selectedFilters?.ecosystem,
                  selectedFilters?.city,
                  selectedFilters?.state,
                  selectedFilters?.class,
                  selectedFilters?.contract_segment,
                  selectedFilters?.hin,
                  selectedFilters?.dea,
                  selectedFilters?.program_type,
                  selectedFilters?.label,
                  accountId,
                  addType,
                  // filterClicked?.siteName,
                  // filterClicked?.ecosystem,
                  // filterClicked?.state,
                  // filterClicked?.city,
                  // filterClicked?.class,
                  // filterClicked?.contract_segment,
                  // filterClicked?.hin,
                  // filterClicked?.dea,
                  // filterClicked?.program_type,
                  // filterClicked?.label,
                ]}
              />
            </Col>

            <Col
              md={page === ACTION_TYPE.filter ? 6 : 2}
              className='mb-3'
              xs={12}>
              <label className='title__x-small mb-1'>Site Class</label>
              <GNEAsyncPagination
                menuIsOpen={menuIsOpen['class']}
                onMenuOpen={() => onMenuOpen('class')}
                onMenuClose={() => onMenuOpen('')}
                placeholder={
                  filterSection === FILTERSCREEN.main ? 'All' : 'Select'
                }
                closeMenuOnSelect={false}
                value={selectedFilters?.class}
                loadOptions={(searchQuery, firstLoad) =>
                  loadOptions('class', searchQuery, firstLoad)
                }
                onChange={(selectedOption) =>
                  handleFilterChange('class', selectedOption)
                }
                isMulti={true}
                components={{
                  ValueContainer: ValueContainer,
                  Option: wrapMenuList(dropdownOptions),
                }}
                isClearable
                shouldLoadMore={shouldLoadMoreOptions}
                cacheUniqs={[
                  selectedFilters?.ecosystem,
                  selectedFilters?.siteName,
                  selectedFilters?.city,
                  selectedFilters?.state,
                  selectedFilters?.zip,
                  selectedFilters?.contract_segment,
                  selectedFilters?.hin,
                  selectedFilters?.dea,
                  selectedFilters?.program_type,
                  selectedFilters?.label,
                  accountId,
                  addType,
                  // filterClicked?.siteName,
                  // filterClicked?.ecosystem,
                  // filterClicked?.state,
                  // filterClicked?.city,
                  // filterClicked?.zip,
                  // filterClicked?.contract_segment,
                  // filterClicked?.hin,
                  // filterClicked?.dea,
                  // filterClicked?.program_type,
                  // filterClicked?.label,
                ]}
              />
            </Col>
            <Col
              md={page === ACTION_TYPE.filter ? 6 : 2}
              className='mb-3'>
              <label className='title__x-small mb-1'>DEA</label>
              <GNEAsyncPagination
                menuIsOpen={menuIsOpen['dea']}
                onMenuOpen={() => onMenuOpen('dea')}
                onMenuClose={() => onMenuOpen('')}
                placeholder={
                  filterSection === FILTERSCREEN.main ? 'All' : 'Select'
                }
                closeMenuOnSelect={false}
                value={selectedFilters?.dea}
                loadOptions={(searchQuery, firstLoad) =>
                  loadOptions('dea', searchQuery, firstLoad)
                }
                onChange={(selectedOption) =>
                  handleFilterChange('dea', selectedOption)
                }
                isMulti={true}
                components={{
                  ValueContainer: ValueContainer,
                  Option: wrapMenuList(dropdownOptions),
                }}
                isClearable
                shouldLoadMore={shouldLoadMoreOptions}
                cacheUniqs={[
                  selectedFilters?.ecosystem,
                  selectedFilters?.siteName,
                  selectedFilters?.city,
                  selectedFilters?.state,
                  selectedFilters?.zip,
                  selectedFilters?.class,
                  selectedFilters?.contract_segment,
                  selectedFilters?.hin,
                  selectedFilters?.program_type,
                  selectedFilters?.label,
                  accountId,
                  addType,
                  // filterClicked?.siteName,
                  // filterClicked?.ecosystem,
                  // filterClicked?.state,
                  // filterClicked?.city,
                  // filterClicked?.zip,
                  // filterClicked?.class,
                  // filterClicked?.contract_segment,
                  // filterClicked?.hin,
                  // filterClicked?.program_type,
                  // filterClicked?.label,
                ]}
              />
            </Col>
            <Col
              md={page === ACTION_TYPE.filter ? 6 : 2}
              className='mb-3'>
              <label className='title__x-small mb-1'>HIN</label>
              <GNEAsyncPagination
                menuIsOpen={menuIsOpen['hin']}
                onMenuOpen={() => onMenuOpen('hin')}
                onMenuClose={() => onMenuOpen('')}
                placeholder={
                  filterSection === FILTERSCREEN.main ? 'All' : 'Select'
                }
                closeMenuOnSelect={false}
                value={selectedFilters?.hin}
                loadOptions={(searchQuery, firstLoad) =>
                  loadOptions('hin', searchQuery, firstLoad)
                }
                onChange={(selectedOption) =>
                  handleFilterChange('hin', selectedOption)
                }
                isMulti={true}
                components={{
                  ValueContainer: ValueContainer,
                  Option: wrapMenuList(dropdownOptions),
                }}
                isClearable
                shouldLoadMore={shouldLoadMoreOptions}
                cacheUniqs={[
                  selectedFilters?.ecosystem,
                  selectedFilters?.siteName,
                  selectedFilters?.city,
                  selectedFilters?.state,
                  selectedFilters?.zip,
                  selectedFilters?.class,
                  selectedFilters?.contract_segment,
                  selectedFilters?.dea,
                  selectedFilters?.program_type,
                  selectedFilters?.label,
                  accountId,
                  addType,
                  // filterClicked?.siteName,
                  // filterClicked?.ecosystem,
                  // filterClicked?.state,
                  // filterClicked?.city,
                  // filterClicked?.zip,
                  // filterClicked?.class,
                  // filterClicked?.contract_segment,
                  // filterClicked?.dea,
                  // filterClicked?.program_type,
                  // filterClicked?.label,
                ]}
              />
            </Col>
            <Col
              md={page === 'actions' ? 6 : 2}
              className='mb-3'>
              <label className='title__x-small mb-1'>Contract Segment</label>
              <GNEAsyncPagination
                menuIsOpen={menuIsOpen['contract_segment']}
                onMenuOpen={() => onMenuOpen('contract_segment')}
                onMenuClose={() => onMenuOpen('')}
                placeholder={
                  filterSection === FILTERSCREEN.main ? 'All' : 'Select'
                }
                closeMenuOnSelect={false}
                value={selectedFilters?.contractSegment}
                loadOptions={(searchQuery, firstLoad) =>
                  loadOptions('contract_segment', searchQuery, firstLoad)
                }
                onChange={(selectedOption) =>
                  handleFilterChange('contract_segment', selectedOption)
                }
                isMulti={true}
                components={{
                  ValueContainer: ValueContainer,
                  Option: wrapMenuList(dropdownOptions),
                }}
                isClearable
                shouldLoadMore={shouldLoadMoreOptions}
                cacheUniqs={[
                  selectedFilters?.ecosystem,
                  selectedFilters?.siteName,
                  selectedFilters?.city,
                  selectedFilters?.state,
                  selectedFilters?.zip,
                  selectedFilters?.class,
                  selectedFilters?.hin,
                  selectedFilters?.dea,
                  selectedFilters?.program_type,
                  selectedFilters?.label,
                  accountId,
                  addType,
                  // filterClicked?.siteName,
                  // filterClicked?.ecosystem,
                  // filterClicked?.state,
                  // filterClicked?.city,
                  // filterClicked?.zip,
                  // filterClicked?.class,
                  // filterClicked?.contract_segment,
                  // filterClicked?.dea,
                  // filterClicked?.hin,
                  // filterClicked?.program_type,
                ]}
              />
            </Col>
            <Col
              md={page === 'actions' ? 6 : 2}
              className='mb-3'>
              <label className='title__x-small mb-1'>340B Program Type</label>
              <GNEAsyncPagination
                menuIsOpen={menuIsOpen['program_type']}
                onMenuOpen={() => onMenuOpen('program_type')}
                onMenuClose={() => onMenuOpen('')}
                placeholder={
                  filterSection === FILTERSCREEN.main ? 'All' : 'Select'
                }
                closeMenuOnSelect={false}
                value={selectedFilters?.program_type}
                loadOptions={(searchQuery, firstLoad) =>
                  loadOptions('program_type', searchQuery, firstLoad)
                }
                onChange={(selectedOption) =>
                  handleFilterChange('program_type', selectedOption)
                }
                isMulti={true}
                components={{
                  ValueContainer: ValueContainer,
                  Option: wrapMenuList(dropdownOptions),
                }}
                isClearable
                shouldLoadMore={shouldLoadMoreOptions}
                cacheUniqs={[
                  selectedFilters?.ecosystem,
                  selectedFilters?.siteName,
                  selectedFilters?.city,
                  selectedFilters?.state,
                  selectedFilters?.zip,
                  selectedFilters?.class,
                  selectedFilters?.contract_segment,
                  selectedFilters?.hin,
                  selectedFilters?.dea,
                  selectedFilters?.label,
                  accountId,
                  addType,
                  // filterClicked?.siteName,
                  // filterClicked?.ecosystem,
                  // filterClicked?.state,
                  // filterClicked?.city,
                  // filterClicked?.zip,
                  // filterClicked?.class,
                  // filterClicked?.contract_segment,
                  // filterClicked?.dea,
                  // filterClicked?.hin,
                  // filterClicked?.program_type,
                ]}
              />
            </Col>
            {labelAccess && (
              <Col
                md={page === ACTION_TYPE.filter ? 6 : 2}
                className='mb-3'>
                <label className='title__x-small mb-1'>Label</label>
                <GNEAsyncPagination
                  menuIsOpen={menuIsOpen['label']}
                  onMenuOpen={() => onMenuOpen('label')}
                  onMenuClose={() => onMenuOpen('')}
                  placeholder={
                    filterSection === FILTERSCREEN.main ? 'All' : 'Select'
                  }
                  isDisabled={labelsData?.length === 0}
                  closeMenuOnSelect={false}
                  value={selectedFilters?.label}
                  loadOptions={(searchQuery, firstLoad) =>
                    loadOptions('label', searchQuery, firstLoad)
                  }
                  onChange={(selectedOption) =>
                    handleFilterChange('label', selectedOption)
                  }
                  isMulti={true}
                  components={{
                    ValueContainer: ValueContainer,
                    Option: wrapMenuList(dropdownOptions),
                  }}
                  isClearable
                  shouldLoadMore={shouldLoadMoreOptions}
                  cacheUniqs={[
                    selectedFilters?.ecosystem,
                    selectedFilters?.siteName,
                    selectedFilters?.city,
                    selectedFilters?.state,
                    selectedFilters?.zip,
                    selectedFilters?.class,
                    selectedFilters?.contract_segment,
                    selectedFilters?.hin,
                    selectedFilters?.dea,
                    selectedFilters?.program_type,
                    accountId,
                    addType,
                    // filterClicked?.siteName,
                    // filterClicked?.ecosystem,
                    // filterClicked?.state,
                    // filterClicked?.city,
                    // filterClicked?.zip,
                    // filterClicked?.class,
                    // filterClicked?.contract_segment,
                    // filterClicked?.dea,
                    // filterClicked?.hin,
                    // filterClicked?.program_type,
                  ]}
                />
              </Col>
            )}
            {page === ACTION_TYPE.orgFilter && (
              <>
                <Col
                  md={page === 'actions' ? 6 : 2}
                  className='mb-3'>
                  <label className='title__x-small mb-1'>Squad</label>
                  <GNEAsyncPagination
                    menuIsOpen={menuIsOpen['squad']}
                    onMenuOpen={() => onMenuOpen('squad')}
                    onMenuClose={() => onMenuOpen('')}
                    placeholder={
                      filterSection === FILTERSCREEN.main ? 'All' : 'Select'
                    }
                    closeMenuOnSelect={false}
                    value={selectedFilters?.squad}
                    loadOptions={(searchQuery, firstLoad) =>
                      loadOptions('squad', searchQuery, firstLoad)
                    }
                    onChange={(selectedOption) =>
                      handleFilterChange('squad', selectedOption)
                    }
                    isMulti={true}
                    components={{
                      ValueContainer: ValueContainer,
                      Option: wrapMenuList(dropdownOptions),
                    }}
                    isClearable
                    shouldLoadMore={shouldLoadMoreOptions}
                    cacheUniqs={[
                      selectedFilters?.siteName,
                      accountId,
                      // filterClicked?.siteName,
                      // filterClicked?.product,
                      selectedFilters?.product,
                    ]}
                  />
                </Col>

                <Col
                  md={page === 'actions' ? 6 : 2}
                  className='mb-3'>
                  <label className='title__x-small mb-1'>Product</label>
                  <GNEAsyncPagination
                    menuIsOpen={menuIsOpen['product']}
                    onMenuOpen={() => onMenuOpen('product')}
                    onMenuClose={() => onMenuOpen('')}
                    placeholder={
                      filterSection === FILTERSCREEN.main ? 'All' : 'Select'
                    }
                    closeMenuOnSelect={false}
                    value={selectedFilters?.product}
                    loadOptions={(searchQuery, firstLoad) =>
                      loadOptions('product', searchQuery, firstLoad)
                    }
                    onChange={(selectedOption) =>
                      handleFilterChange('product', selectedOption)
                    }
                    isMulti={true}
                    components={{
                      ValueContainer: ValueContainer,
                      Option: wrapMenuList(dropdownOptions),
                    }}
                    isClearable
                    shouldLoadMore={shouldLoadMoreOptions}
                    cacheUniqs={[
                      selectedFilters?.siteName,
                      accountId,
                      // filterClicked?.siteName,
                      // filterClicked?.squad,
                      selectedFilters?.squad,
                    ]}
                  />
                </Col>
                <Col
                  md={2}
                  className='mb-3'>
                  <label className='title__x-small mb-1'># Stakeholder</label>
                  <InputGroup
                    size='sm'
                    className='mb-3'>
                    <InputGroup.Text id='basic-addon1'>&ge;</InputGroup.Text>
                    <Form.Control
                      placeholder='# Stakeholder'
                      aria-label='Stakeholder'
                      type='number'
                      aria-describedby='basic-addon1'
                      value={selectedFilters.stakeHolderCounts}
                      onChange={(e) =>
                        handleFilterInput(e, 'stakeHolderCounts')
                      }
                      onKeyDown={(event) => {
                        if (event.key === '.' || event.key === '-') {
                          event.preventDefault();
                        }
                      }}
                    />
                  </InputGroup>
                </Col>
              </>
            )}

            {page === ACTION_TYPE.orgFilter && (
              <Col
                md={2}
                className='mb-3'>
                <label className='title__x-small mb-1'>Gross Sales($M) </label>
                <InputGroup
                  size='sm'
                  className='mb-3'>
                  <InputGroup.Text id='basic-addon1'>&ge;</InputGroup.Text>
                  <Form.Control
                    placeholder='Gross Sales($M)'
                    type='number'
                    aria-label='Gross Sales($M)'
                    aria-describedby='basic-addon1'
                    value={selectedFilters.grossSales}
                    onChange={(e) => handleFilterInput(e, 'grossSales')}
                    onKeyDown={(event) => {
                      if (event.key === '-') {
                        event.preventDefault();
                      }
                    }}
                  />
                </InputGroup>
              </Col>
            )}

            {/* {page === ACTION_TYPE.orgFilter && (
              <Col
                md={2}
                className='mb-3'>
                <label className='title__x-small mb-1'># Engagement</label>
                <InputGroup
                  size='sm'
                  className='mb-3'>
                  <InputGroup.Text id='basic-addon1'>&ge;</InputGroup.Text>
                  <Form.Control
                    placeholder='# Engagement'
                    aria-label='Engagement'
                    type='number'
                    aria-describedby='basic-addon1'
                    value={selectedFilters.engagementCount}
                    onChange={(e) => handleFilterInput(e, 'engagementCount')}
                  />
                </InputGroup>
              </Col>
            )} */}
          </Row>

          <div className='d-flex justify-content-center'>
            <Button
              variant={`btn btn-gne btn-primary mt-2 ${classes.apply}`}
              onClick={handleApply}
              disabled={page === ACTION_TYPE.filter && disabled}>
              Apply
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Filters;
