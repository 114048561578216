import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedKPI,
  setDimensionName,
  setSelectedKpiId,
  setSelectedAppId,
  setChartType,
  setSelectedAppLink,
  setTimePeriods,
  setTimePeriodOptions,
  setSelectedTimePeriod,
  setOldChartType,
  setDimensionId,
} from 'store/customer/customerViewSlice';

import KPISideBar from 'components/shared/KPISidebar/KPISideBar';
import {
  getDimensionsTimePeriodOptions,
  getTimePeriodOptions,
  getTimePeriods,
} from 'services/trends.service';

const KPILibrary = ({ page }) => {
  const dispatch = useDispatch();

  const kpiLibData = useSelector(
    (state) => state?.CustomerView?.kpiAndDimensions
  );

  const [filteredData, setFilteredData] = useState(kpiLibData);
  const selectedKpi = useSelector((state) => state?.CustomerView?.selectedKPI);
  const dimensionName = useSelector(
    (state) => state?.CustomerView?.dimensionName
  );

  const timePeriods = useSelector((state) => state?.CustomerView?.timePeriod);

  useEffect(() => {
    setFilteredData(kpiLibData);
  }, [kpiLibData]);

  const handleFilter = (filteredData) => {
    setFilteredData(filteredData);
  };

  const handleClick = async (item) => {
    await handleKPIChanged(item);

    dispatch(setDimensionName(item.dimensions[0].dimensionName));
    dispatch(
      setDimensionId(
        item?.dimensions?.filter((onedim) => {
          if (onedim.dimensionName === dimensionName) return onedim.id;
        })
      )
    );
  };
  const loadTimePeriodFilter = async (item, dimensionName) => {
    let dimensionId = item?.dimensions.filter(
      (item) => item.dimensionName === dimensionName
    )?.[0]?.id;

    const timePeriodOptions = await getDimensionsTimePeriodOptions(
      timePeriods,
      dimensionId
    );

    dispatch(setTimePeriodOptions(timePeriodOptions));

    let exp = timePeriods.filter(
      (tp) => tp.uXDefaultValue === 'Y' && tp.dimensionId === dimensionId
    )[0];

    dispatch(
      setSelectedTimePeriod({
        label: exp.timePeriodName,
        value: exp.timePeriodId,
      })
    );
  };

  const handleDimensionClick = async (item, dimensionName) => {
    await handleKPIChanged(item);

    if (item?.filterEnable?.EnableDimensionLevelExpression === 'Y') {
      await loadTimePeriodFilter(item, dimensionName);
    }

    dispatch(setDimensionName(dimensionName));
    dispatch(
      setChartType(getChartTypeByDimension(item?.dimensions, dimensionName))
    );
    dispatch(
      setOldChartType(getChartTypeByDimension(item?.dimensions, dimensionName))
    );
    dispatch(
      setDimensionId(
        item?.dimensions?.filter((onedim) => {
          if (onedim.dimensionName === dimensionName) return onedim.id;
        })
      )
    );
  };

  const handleKPIChanged = async (item) => {
    if (item.KpiId !== selectedKpi.KpiId) {
      const timePeriods = await getTimePeriods(item.KpiId);
      dispatch(setTimePeriods(timePeriods));

      let dimensionId = item?.dimensions?.[0]?.id;
      let isDimensionLevelExpression =
        item?.filterEnable?.EnableDimensionLevelExpression === 'Y';

      const timePeriodOptions = await getTimePeriodOptions(timePeriods);
      dispatch(setTimePeriodOptions(timePeriodOptions));

      let exp = isDimensionLevelExpression
        ? timePeriods.filter(
            (tp) => tp.uXDefaultValue === 'Y' && tp.dimensionId === dimensionId
          )[0]
        : timePeriods.filter((tp) => tp.uXDefaultValue === 'Y')[0];

      dispatch(
        setSelectedTimePeriod({
          label: exp.timePeriodName,
          value: exp.timePeriodId,
        })
      );
    }

    dispatch(setSelectedKPI(item));
    dispatch(setSelectedAppId(item.AppId));
    dispatch(setSelectedKpiId(item.KpiId));
    dispatch(setSelectedAppLink(item.appLink));
  };

  const getChartTypeByDimension = (dimensions, dimensionName) => {
    const matchedDimension = dimensions.find(
      (dimension) => dimension.dimensionName === dimensionName
    );
    const barChart =
      matchedDimension.chartType === 'Bar Chart'
        ? 'bar'
        : matchedDimension.chartType === 'Line'
        ? 'line'
        : null;
    return barChart;
  };

  return (
    <>
      <KPISideBar
        kpiLibData={kpiLibData}
        handleFilter={handleFilter}
        filteredData={filteredData}
        selectedKpi={selectedKpi}
        handleClick={handleClick}
        handleDimensionClick={handleDimensionClick}
        dimensionName={dimensionName}
        page={page}
      />
    </>
  );
};

export default KPILibrary;
