import { createSlice } from '@reduxjs/toolkit';
import { customerViewInitialState } from './customerViewInitialState';

const customerViewSlice = createSlice({
  name: 'customerView',
  initialState: customerViewInitialState,
  reducers: {
    //used set  all focus accounts when user uncheck the focus_account checkbox
    setSeletedView: (state, action) => {
      state.selectedView = action.payload;
    },
    setAllAccountOptions: (state, action) => {
      state.allAccountOptions = action.payload;
    },
    setAllSiteOptions: (state, action) => {
      state.allSiteOptions = action.payload;
    },
    setEcosystemOptions: (state, action) => {
      state.ecosystemOptions = action.payload;
    },
    setProductOptions: (state, action) => {
      state.productOptions = action.payload;
    },
    setAllProductOptions: (state, action) => {
      state.allProductOptions = action.payload;
    },
    setSquadOptions: (state, action) => {
      state.squadOptions = action.payload;
    },
    setAllSquadOptions: (state, action) => {
      state.allSquadOptions = action.payload;
    },
    setTimePeriodOptions: (state, action) => {
      state.timePeriodOptions = action.payload;
    },
    setTimePeriods: (state, action) => {
      state.timePeriod = action.payload;
    },
    setSelectedAccount: (state, action) => {
      state.selectedAccount = action.payload;
    },
    setSelectedEcosystem: (state, action) => {
      state.selectedEcosystem = action.payload;
    },
    setSelectedProduct: (state, action) => {
      state.selectedProduct = action.payload;
    },
    setSelectedSquad: (state, action) => {
      state.selectedSquad = action.payload;
    },

    setSelectedTimePeriod: (state, action) => {
      state.selectedTimePeriod = action.payload;
    },
    setSelectedSiteView: (state, action) => {
      state.selectedSiteView = action.payload;
    },
    setSelectedSite: (state, action) => {
      state.selectedSite = action.payload;
    },
    setFocusAccountChecked: (state, action) => {
      state.focusAccountChecked = action.payload;
    },
    setFocusSiteChecked: (state, action) => {
      state.focusSiteChecked = action.payload;
    },
    setIsAccountAvailable: (state, action) => {
      state.isAccountAvailable = action.payload;
    },
    setViewName: (state, action) => {
      state.viewName = action.payload;
    },
    
    setKpiId: (state, action) => {
      state.kpiId = action.payload;
    },
    setKpiName: (state, action) => {
      state.kpiName = action.payload;
    },
    setDimensionId: (state, action) => {
      state.dimesnionId = action.payload;
    },
    setDimensionName: (state, action) => {
      state.dimensionName = action.payload;
    },
    setChartData: (state, action) => {
      state.chartData = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
    setCards: (state, action) => {
      state.cards = action.payload;
    },
    setTimespan: (state, action) => {
      state.timespan = action.payload;
    },
    setPayerTimeSpan: (state, action) => {
      state.payertimeSpan = action.payload;
    },
    setSiteExpression: (state, action) => {
      state.siteExpression = action.payload;
    },
    setPayerExpression: (state, action) => {
      state.payerExpression = action.payload;
    },
    setAffiliationsExpression: (state, action) => {
      state.affiliationsExpression = action.payload;
    },
    setSiteUtilizationExpression: (state, action) => {
      state.siteUtilizationExpression = action.payload;
    },
    setPrductMixExpression: (state, action) => {
      state.productMixExpression = action.payload;
    },
    setKpiandDimensions: (state, action) => {
      state.kpiAndDimensions = action.payload;
    },
    setSelectedKPI: (state, action) => {
      state.selectedKPI = action.payload;
    },
    setExpression: (state, action) => {
      state.expression = action.payload;
    },
    setNoDimensionValue: (state, action) => {
      state.noDimensionValue = action.payload;
    },
    setBenchmarkNoDimensionValue: (state, action) => {
      state.benchmarkNoDimensionValue = action.payload;
    },
    setSelectedKpiId: (state, action) => {
      state.selectedKpiId = action.payload;
    },
    setSelectedKpiName: (state, action) => {
      state.selectedKpiName = action.payload;
    },
    setShowSuccess: (state, action) => {
      state.showSuccess = action.payload;
    },
    setSelectedAppId: (state, action) => {
      state.selectedAppId = action.payload;
    },
    setSelectedAppLink: (state, action) => {
      state.selectedAppLink = action.payload;
    },
    setCustomerViewButtonText: (state, action) => {
      state.customerViewButtonText = action.payload;
    },
    setAccountDetails: (state, action) => {
      state.accountDetails = action.payload;
    },
    setBeamChart: (state, action) => {
      state.beamChart = action.payload;
    },
    setBeamChartSite: (state, action) => {
      state.beamChartSite = action.payload;
    },
    setPayerChart: (state, action) => {
      state.payerChart = action.payload;
    },
    setCRMScores: (state, action) => {
      state.crmScores = action.payload;
    },

    setCrmPercent: (state, action) => {
      state.crmPercent = action.payload;
    },
    setUses: (state, action) => {
      state.uses = action.payload;
    },
    setCrmQuarter: (state, action) => {
      state.crmQuarter = action.payload;
    },
    setSurveyTotal: (state, action) => {
      state.surveyTotal = action.payload;
    },
    setAffiliated: (state, action) => {
      state.affiliated = action.payload;
    },
    setInsightBoards: (state, action) => {
      state.insightBoards = action.payload;
    },
    setContacts: (state, action) => {
      state.contacts = action.payload;
    },
    setSelectedAccountId: (state, action) => {
      state.selectedAccountId = action.payload;
    },
    setChartType: (state, action) => {
      state.chartType = action.payload;
    },
    setOldChartType: (state, action) => {
      state.oldChartType = action.payload;
    },
    setEngagementFilters: (state, action) => {
      state.engagementFilters = action.payload;
    },
    setStakeholderName: (state, action) => {
      state.engagementFilters.StakeholderName = action.payload;
    },
    setStakeholderType: (state, action) => {
      state.engagementFilters.StakeholderType = action.payload;
    },
    setEngagement: (state, action) => {
      state.engagementFilters.Engagement = action.payload;
    },
    setSelectedEngagement: (state, action) => {
      state.engagementFilters.Engagement.selectedValue = action.payload;
    },
    setBrand: (state, action) => {
      state.engagementFilters.Brand = action.payload;
    },
    setSquad: (state, action) => {
      state.engagementFilters.Squad = action.payload;
    },
    setIndication: (state, action) => {
      state.engagementFilters.Indication = action.payload;
    },
    setEngagmentEcosystem: (state, action) => {
      state.engagementFilters.EngagementEcosystem = action.payload;
    },
    setUserName: (state, action) => {
      state.engagementFilters.GNE_User_Name = action.payload;
    },
    setUserRole: (state, action) => {
      state.engagementFilters.GENE_User_Role = action.payload;
    },
    setMarketingFilters: (state, action) => {
      state.marketingFilters = action.payload;
    },
    setChannelFilters: (state, action) => {
      state.marketingFilters.Channel = action.payload;
    },
    setDiscussionTopicFilters: (state, action) => {
      state.marketingFilters.DiscussionTopic = action.payload;
    },
    setStakeholderTypeFilters: (state, action) => {
      state.marketingFilters.PayerStakeholderType = action.payload;
    },
    setTemplateNameFilters: (state, action) => {
      state.marketingFilters.TemplateName = action.payload;
    },
    setSurveyTypeFilters: (state, action) => {
      state.marketingFilters.SurveyType = action.payload;
    },
    setPresentationNameFilters: (state, action) => {
      state.marketingFilters.PresentationName = action.payload;
    },
    setKeyMessageFilters: (state, action) => {
      state.marketingFilters.KeyMessage = action.payload;
    },
    setAffiliationsData: (state, action) => {
      if (
        Object.keys(state.affiliationsData)?.length &&
        action?.payload?.append === true
      ) {
        state.affiliationsData = {
          ...state.affiliationsData,
          values: [
            ...state.affiliationsData.values,
            ...(action?.payload?.values || []),
          ],
        };
      } else if (action?.payload?.append === false) {
        state.affiliationsData = action.payload;
      }
    },
    setAffiliationsSiteData: (state, action) => {
      if (
        Object.keys(state.affiliationsSiteData)?.length &&
        action?.payload?.append === true
      ) {
        state.affiliationsSiteData = {
          ...state.affiliationsSiteData,
          values: [
            ...state.affiliationsSiteData.values,
            ...(action?.payload?.values || []),
          ],
        };
      } else if (action?.payload?.append === false) {
        state.affiliationsSiteData = action.payload;
      }
    },
    setSitePerformanceData: (state, action) => {
      if (
        Object.keys(state.sitePerformanceData)?.length &&
        action?.payload?.append === true
      ) {
        state.sitePerformanceData = {
          ...state.sitePerformanceData,
          values: [
            ...state?.sitePerformanceData?.values,
            ...(action?.payload?.values || []),
          ],
        };
      } else if (action?.payload?.append === false) {
        state.sitePerformanceData = action.payload;
      }
    },

    setPayerMixData: (state, action) => {
      if (
        Object.keys(state.payerMiXData)?.length &&
        action?.payload?.append === true
      ) {
        state.payerMiXData = {
          ...state.payerMiXData,
          values: [
            ...state?.payerMiXData?.values,
            ...(action?.payload?.values || []),
          ],
        };
      } else if (action?.payload?.append === false) {
        state.payerMiXData = action.payload;
      }
    },

    setAccountMixData: (state, action) => {
      if (
        Object.keys(state.accountMiXData)?.length &&
        action?.payload?.append === true
      ) {
        state.accountMiXData = {
          ...state.accountMiXData,
          values: [
            ...state?.accountMiXData?.values,
            ...(action?.payload?.values || []),
          ],
        };
      } else if (action?.payload?.append === false) {
        state.accountMiXData = action.payload;
      }
    },

    setProductMixData: (state, action) => {
      if (
        Object.keys(state.productMixData)?.length &&
        action?.payload?.append === true
      ) {
        state.productMixData = {
          ...state.productMixData,
          values: [
            ...state?.productMixData?.values,
            ...(action?.payload?.values || []),
          ],
        };
      } else if (action?.payload?.append === false) {
        state.productMixData = action.payload;
      }
    },
    setAffiliationFilters: (state, action) => {
      state.affiliationsFilters = action.payload;
    },
    setSiteFilters: (state, action) => {
      state.siteFilter = action.payload;
    },
    setSiteName: (state, action) => {
      state.affiliationsFilters.SiteName_Address = action.payload;
    },
    setSiteHCPAddress: (state, action) => {
      state.affiliationsFilters.HCPAddress = action.payload;
    },
    setSiteState: (state, action) => {
      state.affiliationsFilters.SiteState = action.payload;
    },
    setSiteHIN: (state, action) => {
      state.affiliationsFilters.SiteHIN = action.payload;
    },
    setSiteAddress: (state, action) => {
      state.affiliationsFilters.SiteAddress = action.payload;
    },
    setSiteHCPStakeholderType: (state, action) => {
      state.affiliationsFilters.HCPStakeholderTypes = action.payload;
    },
    setSiteHCPName: (state, action) => {
      state.affiliationsFilters.HCPNames = action.payload;
    },
    setSiteSpecility: (state, action) => {
      state.affiliationsFilters.SiteSpeciality = action.payload;
    },
    setInitialSiteUtilization: (state, action) => {
      state.siteUtilizationFilters = action.payload;
    },
    setSiteUtilizationEcosystem: (state, action) => {
      state.siteUtilizationFilters.SiteUtilization_Ecosystem = action.payload;
    },
    setSiteUtilizationSite: (state, action) => {
      state.siteUtilizationFilters.SiteUtilization_SiteName_Address = action.payload;
    },
    setSiteUtilizationAddress: (state, action) => {
      state.siteUtilizationFilters.SiteUtilization_Site_Address =
        action.payload;
    },
    setSiteUtilizationSiteCity: (state, action) => {
      state.siteUtilizationFilters.SiteUtilization_SiteCity = action.payload;
    },
    setSiteUtilizationSiteState: (state, action) => {
      state.siteUtilizationFilters.SiteUtilization_SiteState = action.payload;
    },
    setSites: (state, action) => {
      state.sitePerformanceFilters.Site_Performance_SiteName_Address =
        action.payload;
    },
    setProducts: (state, action) => {
      state.sitePerformanceFilters.Product = action.payload;
    },
    setEcosystem: (state, action) => {
      state.sitePerformanceFilters.Ecosystem = action.payload;
    },
    setSitePerformanceSquad: (state, action) => {
      state.sitePerformanceFilters.SiteSquad = action.payload;
    },
    setIsAccountableProduct: (state, action) => {
      state.sitePerformanceFilters.IsAccountableProduct = action.payload;
    },
    setContractSegmentFilter: (state, action) => {
      state.sitePerformanceFilters.contract_segment_Filter = action.payload;
    },
    setIsAccountableProductMix: (state, action) => {
      state.productMixFilters.IsAccountableProduct = action.payload;
    },
    setCustomerInsightBoardId: (state, action) => {
      state.custInsightsBrdId = action.payload;
    },
    setInsightType: (state, action) => {
      state.insightType = action.payload;
    },
    setHcpFlag: (state, action) => {
      state.hcpFlag = action.payload;
    },
    setDarwinReportsData: (state, action) => {
      state.darwinReportsData = action.payload;
    },
    setSiteLoader: (state, action) => {
      state.siteLoader = action.payload;
    },
    setAccountPayerLoader: (state, action) => {
      state.accountPayerLoader = action.payload;
    },
    setSitePayerLoader: (state, action) => {
      state.sitePayerLoader = action.payload;
    },
    setCopayLoader: (state, action) => {
      state.copayLoader = action.payload;
    },
    setCopayData: (state, action) => {
      if (
        Object.keys(state.copayData)?.length &&
        action?.payload?.append === true
      ) {
        state.copayData = {
          ...state.copayData,
          values: [
            ...state.copayData.values,
            ...(action?.payload?.values || []),
          ],
        };
      } else if (action?.payload?.append === false) {
        state.copayData = action.payload;
      }
    },
    setAffLoader: (state, action) => {
      state.affLoader = action.payload;
    },
    setPayerMixCustomColumns: (state, action) => {
      state.payerCustomColumns = action.payload;
    },
    setAccountPayerCustomColumn: (state, action) => {
      state.accountPayerCustomColumn = action.payload;
    },
    setExportLoader: (state, action) => {
      action.payload.data === 'affiliation'
        ? (state.exportAffiliation = action.payload.export)
        : action.payload.data === 'siteutilization'
        ? (state.exportSiteUtilizaztion = action.payload.export)
        : action.payload.data === 'accountpayermix'
        ? (state.exportAccountpayermix = action.payload.export)
        : action.payload.data === 'copayanalytics'
        ? (state.exportCopayAnalytics = action.payload.export)
        : (state.exportSite = action.payload.export);
    },
    setCustomerViewButtonDisabled: (state, action) => {
      state.customerViewButtonDisabled = action.payload;
    },
    setSitePerformanceFilters: (state, action) => {
      state.sitePerformanceFilters = action.payload;
    },
    setSiteUtilizationFilters: (state, action) => {
      state.siteUtilizationFilters = action.payload;
    },
    setpayerMixFilters: (state, action) => {
      state.payerMixFilters = action.payload;
    },

    setParentPayer: (state, action) => {
      state.payerMixFilters.ParentPayer = action.payload;
    },
    setPayer: (state, action) => {
      state.payerMixFilters.Payer = action.payload;
    },
    setBookOfBusiness: (state, action) => {
      state.payerMixFilters.BookOfBusiness = action.payload;
    },
    setAccountpayerMixFilters: (state, action) => {
      state.AccountPayerMixFilters = action.payload;
    },
    setAccountParentPayer: (state, action) => {
      state.AccountPayerMixFilters.AccountPayerParentPayer = action.payload;
    },
    setAccountPayer: (state, action) => {
      state.AccountPayerMixFilters.AccountPayerName = action.payload;
    },
    setAccountBookOfBusiness: (state, action) => {
      state.AccountPayerMixFilters.AccountPayerBookOfBusiness = action.payload;
    },
    setAccountEcosystem: (state, action) => {
      state.AccountPayerMixFilters.AccountPayerEcosystem = action.payload;
    },
    setAccountProduct: (state, action) => {
      state.AccountPayerMixFilters.AccountPayerProduct = action.payload;
    },

    setEstimatedPayerSale: (state, action) => {
      state.AccountPayerMixFilters.EstimatedPayerSale = action.payload;
    },
    setEstimatedContributionToAccount: (state, action) => {
      state.AccountPayerMixFilters.EstimatedContributionToAccount =
        action.payload;
    },
    setBenifiteType: (state, action) => {
      state.AccountPayerMixFilters.BenefitType = action.payload;
    },

    //copayAnalytics filter //
    setCopayCustomColumn: (state, action) => {
      state.copayCustomColumn = action.payload;
    },
    setCopayExpression: (state, action) => {
      state.copayExpression = action.payload;
    },

    setCopayAnalyticsFilters: (state, action) => {
      state.copayAnalyticsFilters = action.payload;
    },
    setCopayAnalyticsEcosystem: (state, action) => {
      state.copayAnalyticsFilters.copayEcosystem = action.payload;
    },
    setcopayEcosystemvalue: (state, action) => {
      state.copayAnalyticsFilters.Ecosystem = action.payload;
    },
    setCopayAnalyticsProduct: (state, action) => {
      state.copayAnalyticsFilters.copayProduct = action.payload;
    },
    setCopayAnalyticsSquad: (state, action) => {
      state.copayAnalyticsFilters.copaySquad = action.payload;
    },
    setCopayAnalyticsSiteName: (state, action) => {
      state.copayAnalyticsFilters.copaySiteAddressName = action.payload;
    },
    setCopayAnalyticsTimePeriod: (state, action) => {
      state.copayAnalyticsFilters.copayQuarter = action.payload;
    },

    setHCPName: (state, action) => {
      state.siteInfoFilters.HCPName = action.payload;
    },
    setHCPStakeholderType: (state, action) => {
      state.siteInfoFilters.HCPStakeholderType = action.payload;
    },
    setHCPSpeciality: (state, action) => {
      state.siteInfoFilters.Speciality = action.payload;
    },
    setHCPState: (state, action) => {
      state.siteInfoFilters.State = action.payload;
    },
    setHCPCity: (state, action) => {
      state.siteInfoFilters.City = action.payload;
    },
    setHCPZip: (state, action) => {
      state.siteInfoFilters.Zip = action.payload;
    },

    setProductMixFilters: (state, action) => {
      state.productMixFilters = action.payload;
    },

    setProductMixProduct: (state, action) => {
      state.productMixFilters.Product = action.payload;
    },

    setProductMixSquad: (state, action) => {
      state.productMixFilters.SiteSquad = action.payload;
    },

    setConfigureInsightFilters: (state, action) => {
      state.configureInsightsFilters = action.payload;
    },
    setConfigureInsightProducts: (state, action) => {
      state.configureInsightsFilters.Product = action.payload;
    },
    setConfigureInsightSquad: (state, action) => {
      state.configureInsightsFilters.SiteSquad = action.payload;
    },
    setConfigureInsightEcosystem: (state, action) => {
      state.configureInsightsFilters.Ecosystem = action.payload;
    },
    setConfigInsightSiteFilter: (state, action) => {
      state.configureInsightsFilters.SiteName_Address = action.payload;
    },
    setIsAccountableProductConfigure: (state, action) => {
      state.configureInsightsFilters.IsAccountableProduct = action.payload;
    },
    setAppUsers: (state, action) => {
      state.appUsers = action.payload;
    },
    setColumnRestricted: (state, action) => {
      state.columnsRestiction = action.payload;
    },
    setExcelColumnRestiction: (state, action) => {
      state.excelColumnsRestiction = action.payload;
    },
    setNotificationEmailStatus: (state, action) => {
      state.notificationEmailStatus = action.payload;
    },
    setNotifyuserList: (state, action) => {
      state.notifyUsersByEmailList = action.payload;
    },
    setSelectedCardView: (state, action) => {
      state.selectedCardView = action.payload;
    },
    setAdvanceOptions: (state, action) => {
      state.advanceOptions = action.payload;
    },
    setAdvanceDropdownOptions: (state, action) => {
      state.advanceDropdownOptions = action.payload;
    },
    setFilterInit: (state, action) => {
      state.engagementFilters.StakeholderName =
        customerViewInitialState.engagementFilters.StakeholderName;
      state.engagementFilters.StakeholderType =
        customerViewInitialState.engagementFilters.StakeholderType;
      state.engagementFilters.Brand =
        customerViewInitialState.engagementFilters.Brand;
      state.engagementFilters.Squad =
        customerViewInitialState.engagementFilters.Squad;
      state.engagementFilters.Indication =
        customerViewInitialState.engagementFilters.Indication;
      state.cards = customerViewInitialState.cards;
      state.sitePerformanceFilters =
        customerViewInitialState.sitePerformanceFilters;
      state.siteUtilizationFilters =
        customerViewInitialState.siteUtilizationFilters;
      state.configureInsightsFilters =
        customerViewInitialState.configureInsightsFilters;
      state.productMixFilters = customerViewInitialState.productMixFilters;
      state.payerMixFilters = customerViewInitialState.payerMixFilters;
      state.customerViewButtonText =
        customerViewInitialState.customerViewButtonText;
      state.custInsightsBrdId = null;
      state.copayAnalyticsFilters =
        customerViewInitialState.copayAnalyticsFilters;
      state.payerCustomColumns = customerViewInitialState.payerCustomColumns;
      state.accountPayerCustomColumn =
        customerViewInitialState.accountPayerCustomColumn;
      state.hcpFlag = customerViewInitialState.hcpFlag;
      state.engagementFilters = customerViewInitialState.engagementFilters;
      state.customerViewButtonDisabled =
        customerViewInitialState.customerViewButtonDisabled;
    },
  },
});

export const {
  setCopayAnalyticsFilters,
  setCopayAnalyticsEcosystem,
  setCopayAnalyticsProduct,
  setcopayEcosystemvalue,
  setCopayAnalyticsTimePeriod,
  setCopayAnalyticsSquad,
  setCopayAnalyticsSiteName,
  setCopayCustomColumn,
  setSeletedView,
  setUserFocusAccounts,
  setAllAccountOptions,
  setEcosystemOptions,
  setTimePeriodOption,
  setSelectedAccount,
  setSelectedEcosystem,
  setSelectedProduct,
  setSelectedSquad,
  setSelectedDiscussionTopic,
  setSelectedStakeholderType,
  setSelectedTemplateName,
  setSelectedSurveyType,
  setSelectedPresentationName,
  setSelectedKeyMessage,
  setSelectedTimePeriod,
  setFocusAccountChecked,
  setSelectedSite,
  setIsAccountAvailable,
  setViewName,
  setKpiId,
  setKpiName,
  setDimensionId,
  setDimensionName,
  setChartData,
  setTableData,
  setCards,
  setProductOptions,
  setAllProductOptions,
  setSquadOptions,
  setAllSquadOptions,
  setTimePeriods,
  setTimePeriodOptions,
  setKpiandDimensions,
  setSelectedKpiId,
  setSelectedKpiName,
  setSelectedKPI,
  setExpression,
  setNoDimensionValue,
  setBenchmarkNoDimensionValue,
  setShowSuccess,
  setSelectedAppId,
  setCustomerViewButtonText,
  setAccountDetails,
  setInsightBoards,
  setContacts,
  setSelectedAccountId,
  setBeamChart,
  setBeamChartSite,
  setPayerChart,
  setCRMScores,
  setCrmPercent,
  setChartType,
  setOldChartType,
  setUses,
  setCrmQuarter,
  setSurveyTotal,
  setAffiliated,
  setEngagementFilters,
  setKeyMessageFilters,
  setPresentationNameFilters,
  setSurveyTypeFilters,
  setTemplateNameFilters,
  setStakeholderTypeFilters,
  setDiscussionTopicFilters,
  setChannelFilters,
  setMarketingFilters,
  setStakeholderName,
  setStakeholderType,
  setEngagement,
  setBrand,
  setIndication,
  setUserName,
  setUserRole,
  setSquad,
  setAffiliationsData,
  setAffiliationsSiteData,
  setSitePerformanceData,
  setSites,
  setProducts,
  setCustomerInsightBoardId,
  setInsightType,
  setTimespan,
  setSiteExpression,
  setPayerExpression,
  setAffiliationsExpression,
  setPrductMixExpression,
  setHcpFlag,
  setDarwinReportsData,
  setSiteLoader,
  setAffLoader,
  setExportLoader,
  setSelectedAppLink,
  setCustomerViewButtonDisabled,
  setSelectedEngagement,
  setSitePerformanceSquad,
  setEcosystem,
  setIsAccountableProduct,
  setContractSegmentFilter,
  setSitePerformanceFilters,
  setSiteUtilizationFilters,
  setSiteUtilizationEcosystem,
  setSiteUtilizationSite,
  setSiteUtilizationAddress,
  setSiteUtilizationSiteCity,
  setSiteUtilizationSiteState,
  setSiteUtilizationExpression,
  setSelectedSiteView,
  setFocusSiteChecked,
  setAllSiteOptions,
  setParentPayer,
  setPayer,
  setPayerTimeSpan,
  setpayerMixFilters,
  setAffiliationFilters,
  setSiteFilters,
  setConfigInsightSiteFilter,
  setBookOfBusiness,
  setAccountpayerMix,
  setAccountParentPayer,
  setAccountPayer,
  setAccountBookOfBusiness,
  setAccountEcosystem,
  setAccountProduct,
  setBenifiteType,
  setEstimatedPayerSale,
  setEstimatedContributionToAccount,
  setPayerMixData,
  setAccountMixData,
  setAccountpayerMixFilters,
  setProductMixData,
  setProductMixFilters,
  setProductMixProduct,
  setProductMixSquad,
  setIsAccountableProductMix,
  setPayerMixCustomColumns,
  setAccountPayerCustomColumn,
  setConfigureInsightFilters,
  setConfigureInsightSquad,
  setConfigureInsightEcosystem,
  setConfigureInsightProducts,
  setIsAccountableProductConfigure,
  setHCPCity,
  setHCPName,
  setHCPSpeciality,
  setHCPStakeholderType,
  setHCPState,
  setHCPZip,
  setSiteName,
  setSiteState,
  setSiteHIN,
  setSiteAddress,
  setAccountPayerLoader,
  setSitePayerLoader,
  setCopayLoader,
  setEngagmentEcosystem,
  setCopayData,
  setSiteHCPStakeholderType,
  setSiteHCPName,
  setSiteHCPAddress,
  setSiteSpecility,
  setAppUsers,
  setInitialSiteUtilization,
  setColumnRestricted,
  setExcelColumnRestiction,
  setNotificationEmailStatus,
  setNotifyuserList,
  setSelectedCardView,
  setAdvanceOptions,
  setAdvanceDropdownOptions,
  setFilterInit,
} = customerViewSlice.actions;

export const customerViewSliceActions = customerViewSlice.actions;

export default customerViewSlice.reducer;
