import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import classes from './ConfigureMyViewLayout.module.scss';
import ConfigureMyViewLayout from './ConfigureMyViewLayout';
import LinkButton from 'components/shared/button/LinkButton';
import { useDispatch, useSelector } from 'react-redux';
import { setAdvanceOptions, setShowSuccess } from 'store/home/homeMyViewSlice';
import {
  setConfigureInsightProducts,
  setConfigureInsightSquad,
  setIsAccountableProductConfigure,
} from 'store/customer/customerViewSlice';
import { CONFIGURE_VIEW, VIEW } from 'constants/constants';

function ConfigureMyView({ page }) {
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();

  const hideModal = () => {
    setModalShow(false);
    dispatch(setShowSuccess(false));
    dispatch(setIsAccountableProductConfigure(true));
    dispatch(setConfigureInsightProducts({ options: [], selectedValue: [] }));
    dispatch(setConfigureInsightSquad({ options: [], selectedValue: [] }));
    dispatch(setAdvanceOptions(''));
  };

  const isInsights = page?.toLowerCase().indexOf('insights') > -1;
  const insightType = useSelector((state) => state?.CustomerView?.insightType);
  const isSampleInsight =
    insightType === 'sample' && page === CONFIGURE_VIEW.insights ? true : false;
  return (
    <Row>
      <Col
        md={12}
        className={isInsights ? classes['insights-container'] : ''}>
        {isInsights ? (
          <p className='title title__bold title__large d-inline-block'>
            Insights
          </p>
        ) : (
          ''
        )}

        {!isSampleInsight && (
          <LinkButton
            onClick={() => setModalShow(true)}
            className={classes['configure-cta']}>
            <i className='icon icon__configure'></i>Configure {page}
          </LinkButton>
        )}

        <ConfigureMyViewLayout
          show={modalShow}
          page={page}
          view={VIEW.add}
          onHide={() => hideModal()}
          css={`animate__animated animate__${modalShow ? 'zoomIn' : 'zoomOut'}`}
        />
      </Col>
    </Row>
  );
}

export default ConfigureMyView;
