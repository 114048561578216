/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import React from 'react';
import { Fade } from 'react-awesome-reveal';

import { setFavReportObj } from 'store/auth/Authslice';

import FavoriteDashboards from '../../home/favorites/FavoriteDashboards';

import classes from './RightSideBar.module.scss';

import LinkButton from 'components/shared/button/LinkButton';
import LinkList from 'components/shared/List/LinkList';
import axiosDBClient from 'axiosDBClient';

import {
  INSIGHTS_DEFAULT_TEXT,
  FAVORITE_DASHBOARD_DEFAULT_TEXT,
  CUSTOMER_VIEW_TEXT,
  CUSTOMER_VIEW_UPDATE_TO_INSIGHTS,
} from 'constants/constants';
import { getAllInsightBoards } from 'store/customer/customer-view-actions';
import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  setCards,
  setCustomerInsightBoardId,
  setCustomerViewButtonText,
  setSelectedAccount,
  setSelectedAccountId,
  setCustomerViewButtonDisabled,
  setFocusAccountChecked,
  setInsightType,
  setSeletedView,
} from 'store/customer/customerViewSlice';

const RightSideBar = () => {
  const dispatch = useDispatch();
  const favorites = useSelector((state) => state.Auth.favReportObj);
  const { unixId, roleId } = useSelector((state) => state.Auth.userClaimObject);
  const [modalShow, setModalShow] = useState(false);
  const favoritesData = JSON.parse(JSON.stringify(favorites));

  const [reportsData, setReportData] = useState(favoritesData);

  const [updatedReports, setUpdatedReports] = useState([]);
  const showFavorites =
    favorites && favorites?.some((item) => item?.isFavorite === 'Y');

  const insightsBoards = useSelector(
    (state) => state?.CustomerView?.insightBoards
  );
  const [maxLength] = useState(5);

  useEffect(() => {
    dispatch(getAllInsightBoards(unixId, roleId));

    const tempReport = [...reportsData];

    tempReport?.sort((a, b) => a?.reportName?.localeCompare(b.reportName));
    setReportData(tempReport);
  }, [dispatch]);

  const favoriteClickHandler = () => {
    setModalShow(true);
  };
  const itemClickHandler = (insight) => {
    dispatch(setSeletedView(insight?.label?.toLowerCase()));
    dispatch(setInsightType('general'));
    dispatch(setCards([]));
    dispatch(setCustomerViewButtonText(CUSTOMER_VIEW_UPDATE_TO_INSIGHTS));
    dispatch(
      setSelectedAccount({
        value: insight.accountId,
        label: insight.accountName,
      })
    );

    let isPriorityAccount = insight?.isPriorityAccount === 'Y' ? true : false;
    dispatch(setSelectedAccountId(insight.accountId));
    dispatch(setCustomerInsightBoardId(insight.id));
    dispatch(setFocusAccountChecked(isPriorityAccount));
    dispatch(setCustomerViewButtonDisabled(true));
  };

  const onSave = () => {
    const filteredData = reportsData.filter(
      (report) => updatedReports.indexOf(report.reportID) !== -1
    );

    if (filteredData?.length === 0) {
      return;
    }

    axiosDBClient
      .post(
        'report/favorites',
        {
          userId: unixId,
          reports: filteredData,
          roleId: roleId,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(() => {
        dispatch(setFavReportObj(reportsData));
      })
      .catch((err) => {
        console.log(err);
      });

    setUpdatedReports([]);
    setModalShow(false);
  };

  const onHide = () => {
    setUpdatedReports([]);
    setReportData(favoritesData);
    setModalShow(false);
  };

  return (
    <aside className={`${classes['right-side-bar']}`}>
      {/*insights section*/}
      <section className='divider'>
        <Fade>
          <Row>
            <p className='title__normal title__bold d-flex align-items-center'>
              <i className='icon icon__insights'></i>Insight Boards
            </p>
          </Row>
        </Fade>

        <Fade cascade>
          {insightsBoards?.slice(0, maxLength).map((item, index) => {
            return (
              item.insightType !== 'sample' && (
                <Row key={index}>
                  <p className='title__small'>
                    <Link
                      to={
                        '/customerView/list/' +
                        item.name.replace(CUSTOMER_VIEW_TEXT, '')
                      }
                      onClick={() => itemClickHandler(item)}>
                      {item.name.replace(CUSTOMER_VIEW_TEXT, '')}
                    </Link>
                  </p>
                </Row>
              )
            );
          })}
        </Fade>
        {insightsBoards?.length > maxLength - 1 ? (
          <Fade>
            <Row>
              <Link to='/customerView/list'>
                <div
                  className={`${classes['view-all-class-btn']} title__small title__blue title__bold`}>
                  View All
                </div>
              </Link>
            </Row>
          </Fade>
        ) : (
          ''
        )}

        {!insightsBoards ? (
          <Fade>
            <Row>
              <p>{INSIGHTS_DEFAULT_TEXT}</p>
            </Row>
          </Fade>
        ) : (
          ''
        )}
      </section>

      {/*favorites section*/}
      <section>
        <Fade cascade>
          <p className='title__normal title__bold d-flex align-items-center'>
            <i className='icon icon__favorite m-1'></i>
            <span className={`${classes['left-align']}`}>
              {' '}
              Favorite Dashboards
            </span>

            <LinkButton
              className='ms-2 me-0'
              onClick={favoriteClickHandler}>
              <i className='icon icon__edit m-0'></i>
            </LinkButton>
          </p>

          <FavoriteDashboards
            show={modalShow}
            onSave={onSave}
            onHide={onHide}
            reportsData={reportsData}
            setReportData={setReportData}
            updatedReports={updatedReports}
            setUpdatedReports={setUpdatedReports}
          />

          {showFavorites && <LinkList items={reportsData} />}
          {!showFavorites && <p>{FAVORITE_DASHBOARD_DEFAULT_TEXT}</p>}
        </Fade>
      </section>
    </aside>
  );
};

export default RightSideBar;
