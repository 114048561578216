import classes from './SystemAlerts.module.scss';
import { Button } from 'react-bootstrap';
import { useState } from 'react';
import SystemAlertsList from './SystemAlertsList';

const SystemAlerts = () => {
  const navigateToHome = () => {
    window.location.href = '/home';
  };

  const [expandedDescriptions, setExpandedDescriptions] = useState([]);

  const toggleDescription = (index) => {
    setExpandedDescriptions((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      newExpanded[index] = !newExpanded[index];
      return newExpanded;
    });
  };
  return (
    <div className={`d-flex flex-column align-items-center bg-white`}>
      <div className={`border-lightgrey border-2`}>
        <i
          className={`icon icon__logo animate__animated animate__rubberBand ${classes.img_width} `}></i>
      </div>

      <div className='col text-center'>
        <div className={`${classes.messageContainer} mt-4`}>
          <p className={`${classes.sessionMessage} mt-4 mb-4`}>
            We Are Experiencing Data Discrepancies
          </p>
        </div>
      </div>
      <SystemAlertsList viewName='login' />
      <div className='col text-center p-4'>
        <Button
          variant={`btn btn-gne btn-primary`}
          onClick={navigateToHome}>
          <span>Proceed To C360</span>
        </Button>
      </div>
    </div>
  );
};

export default SystemAlerts;
