export const HomeMyViewInitialState = {
  userFocusAccounts: [],
  allAccountOptions: [],
  ecosystemOptions: [],
  productOptions: [],
  squadOptions: [],
  allProductOptions: [],
  timePeriodOptions: [],
  timePeriods: [],

  selectedAccount: [],
  selectedEcosystem: [],
  selectedProduct: [],
  selectedSquad: [],
  selectedTimePeriod: [],

  marketingFilters: {
    Channel: {
      options: [],
      selectedValue: [],
    },
    DiscussionTopic: {
      options: [],
      selectedValue: [],
    },
    PayerStakeholderType: {
      options: [],
      selectedValue: [],
    },

    TemplateName: {
      options: [],
      selectedValue: [],
    },
    SurveyType: {
      options: [],
      selectedValue: [],
    },
    PresentationName: {
      options: [],
      selectedValue: [],
    },
    KeyMessage: {
      options: [],
      selectedValue: [],
    },
  },

  timePeriodName: 'Oct 21-Sep 22',
  focusAccountChecked: true,
  noFocusedAccount: false,
  isAccountAvailable: true,
  viewName: 'Gross Sales by Account',
  kpiId: 1,
  kpiName: 'Gross Sales',
  dimesnionId: 2,
  dimensionName: '',
  noDimensionValue: '',
  benchmarkNoDimensionValue: '',
  chartType: 'KPI',
  oldChartType: '',
  chartData: [],
  tableData: [],
  cards: [],
  defaultCards: [],
  kpiAndDimensions: [],
  selectedKpiId: 1,
  selectedKpiName: '',
  selectedKpi: {},
  expression: '',
  showSuccess: false,
  selectedAppId: 1,
  timespan: '',
  selectedAppLink: '',
  advanceButtonValue: false,
  KPIselectedStatus: false,
  advanceOptions: '',
  advanceDropdownOptions: [],
  systemAlertsData: [],
  notificationsData: [],
  whatsNewData: {},
};
