/* eslint-disable react-hooks/exhaustive-deps */
import { ProSidebarProvider } from 'react-pro-sidebar';
import { Outlet } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import Layout from 'components/layout/Layout';

const CustomerView = () => {
  return (
    <ProSidebarProvider>
      <Layout showRighSideBar={false}>
        <Container className='gne-container customer-container'>
          {/* we load the child routes here (customer view list and insights) */}
          <Outlet />
        </Container>
      </Layout>
    </ProSidebarProvider>
  );
};

export default CustomerView;
