/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PuffLoader from 'react-spinners/PuffLoader';

import api from 'api';

import {
  setToken,
  setUserClaimObject,
  setWrapperObj,
  setFavReportObj,
  setComponentAccessList,
  setRestrictedEcosystem,
  setUserRestriction,
  setRestrictedProductSquad,
  setRoles,
} from 'store/auth/Authslice';
import { setSystemAlertsData } from 'store/home/homeMyViewSlice';
import { fetchQlikToken } from 'store/auth/auth-actions';
import { isTokenExpired } from '../../utils/token-expired';
import { getTableRestrictions } from 'store/customer/customer-view-actions';
import {
  getSystemAlertsData,
  getFavoriteData,
} from 'store/home/home-my-view-actions';

const AuthenticateUser = () => {
  const [searchParams] = useSearchParams();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const loading = useRef(true);

  const getSystemAlerts = async (role) => {
    let data = await getSystemAlertsData(role);
    dispatch(setSystemAlertsData(data));
    data?.length ? navigation('/system-alerts') : navigation('/home');
  };

  const favoritesData = async (unixId, roleId) => {
    let favData = await getFavoriteData(unixId, roleId);
    dispatch(setFavReportObj(favData));
  };

  useEffect(() => {
    if (isTokenExpired(localStorage.getItem('token'))) {
      dispatch(setToken({ accessToken: null, isLoading: true }));
    }

    if (searchParams.get('code') !== '') {
      api
        .post(
          'authenticate',
          {
            access_code: searchParams.get('code'),
            redirect_uri: `${process.env.REACT_APP_WEB_HOST}/authenticate`,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then((res, err) => {
          localStorage.setItem('token', res.data.token.access_token);

          dispatch(
            setToken({
              accessToken: res.data.token.access_token,
              isLoading: false,
            })
          );
          dispatch(setRoles(res.data.roles));
          dispatch(fetchQlikToken(res.data?.userClaimObject?.unixId));

          if (res.data.roles.length === 1) {
            let roleData = res.data.roles[0];
            dispatch(setRestrictedEcosystem(roleData.restrictedEcosystem));
            dispatch(setUserRestriction(roleData.roleRestriction));

            dispatch(setRestrictedProductSquad(roleData.restrictionsDetails));
            dispatch(setUserClaimObject(res.data.userClaimObject));
            dispatch(setComponentAccessList(roleData.componentAccessList));

            dispatch(setWrapperObj(roleData.wrapperObj));
            dispatch(getTableRestrictions(res.data.userClaimObject?.roleId));
            favoritesData(
              res?.data?.userClaimObject?.unixId,
              res?.data?.userClaimObject?.roleId
            );

            if (process.env.REACT_APP_MAINTENANCE_IN_PROGRESS === 'true') {
              localStorage.clear();
              navigation('/maintenance');
            } else {
              // giving the dispatch time to get qlik token and store it
              // before we navigate to home
              setTimeout(() => {
                if (process.env.REACT_APP_SHOW_NOTIFICATION === 'true') {
                  navigation('/notification');
                } else {
                  getSystemAlerts(res?.data?.userClaimObject?.roleId);
                }

                loading.current = false;
              }, 4000);
            }
          } else {
            let userClaimObject = res.data.userClaimObject;
            userClaimObject = { ...userClaimObject, roleId: '' };
            dispatch(setUserClaimObject(userClaimObject));
            navigation('/roleSelection');
          }
        })
        .catch((err) => {
          localStorage.clear();
          console.log(err);
          if (err?.response?.data?.Message === 'Invalid access') {
            navigation('/unauthorized');
          } else if (process.env.REACT_APP_MAINTENANCE_IN_PROGRESS === 'true') {
            navigation('/maintenance');
          }
        });
    }
  }, []);

  return (
    <div className='spinner center-page animate__animated animate__fadeIn'>
      <PuffLoader
        color='#004677'
        size={60}
        loading={loading}
      />
      <i className='icon icon__logo animate__animated animate__rubberBand'></i>
    </div>
  );
};
export default AuthenticateUser;
