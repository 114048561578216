/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import SelectWithPagination from 'components/shared/dropdown-with-pagination/SelectWithPagination';
import { Spin, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';

import ConfigurableTable from 'components/shared/table/ConfigurableTable';
import classes from './SiteUtilization.module.scss';
import {
  setFilterInit,
  setInsightType,
  setSelectedAccount,
  setSeletedView,
  setSiteLoader,
  setSiteUtilizationExpression,
} from 'store/customer/customerViewSlice';
import {
  SITE_UTILIZATION_UTILIZATION,
  SITE_UTILIZATION_INCIDENCE,
  SITE_UTILIZATION_DEMOGRAPHICS,
  SITE_UTILIZATION_COLUMNS,
  SITE_UTILIZATION_TIME_RANGE_EXP,
  SITE_UTILIZATION_FILTER_NAME,
  TABLE_NAME_SITE_UTILIZATION,
  SITE_UTILIZATION_HIGH_MEDIUM_LOW_COLUMN_NAME,
} from 'constants/constants';
import { getSiteUtilizationData } from 'store/customer/customer-view-actions';

import {
  getAccountExpression,
  getRestrictedColumn,
  isArray,
} from 'utils/utils';
import { SITE_UTILIZATION_APP_ID } from 'constants/constants';

import { getQlikSenseData } from 'services/qlik.service';

import { useCallback } from 'react';
import ExportTables from './ExportTables';
import uuid from 'react-native-uuid';
import { useNavigate } from 'react-router-dom';
import { setEngagementDateInit } from 'store/customer/filter/filter-slice';

const SiteUtilization = () => {
  const selectedEcosystem = useSelector(
    (state) =>
      state?.CustomerView?.siteUtilizationFilters?.SiteUtilization_Ecosystem
        ?.selectedValue
  );
  const selectedSite = useSelector(
    (state) =>
      state?.CustomerView?.siteUtilizationFilters
        ?.SiteUtilization_SiteName_Address?.selectedValue
  );
  const selectedCity = useSelector(
    (state) =>
      state?.CustomerView?.siteUtilizationFilters?.SiteUtilization_SiteCity
        ?.selectedValue
  );
  const selectedState = useSelector(
    (state) =>
      state?.CustomerView?.siteUtilizationFilters?.SiteUtilization_SiteState
        ?.selectedValue
  );
  const selectedSiteAddress = useSelector(
    (state) =>
      state?.CustomerView?.siteUtilizationFilters?.SiteUtilization_Site_Address
        ?.selectedValue
  );
  const selectedView = useSelector(
    (state) => state?.CustomerView?.selectedView
  );
  const navigation = useNavigate();
  // eslint-disable-next-line no-unused-vars

  const [isLoading, setIsLoading] = useState(false);
  const isExporting = useSelector(
    (state) => state?.CustomerView?.exportSiteUtilizaztion
  );
  const [fieldExpression, setFieldExpression] = useState();
  const [filteredColumns, setFilteredColumns] = useState(
    SITE_UTILIZATION_UTILIZATION
  );
  const [modalShow, setModalShow] = useState(false);

  const [allColumnData, setAllColumnData] = useState(SITE_UTILIZATION_COLUMNS);
  const [updatedColumnData, setupdatedColumnData] = useState(
    SITE_UTILIZATION_UTILIZATION
  );

  const dispatch = useDispatch();

  const qlikToken = localStorage.getItem('qlikToken');
  const qlikCookie = localStorage.getItem('qlikCookie');

  const selectedAccount = useSelector(
    (state) => state?.CustomerView?.selectedAccount
  );

  const siteUtilizationFilters = useSelector(
    (state) => state?.CustomerView?.siteUtilizationFilters
  );
  const [siteType, setSiteType] = useState('SiteUtilization');
  const [utilizationData, setUtilizationData] = useState([]);

  const ecosystemOptions = useSelector(
    (state) =>
      state?.CustomerView?.siteUtilizationFilters?.SiteUtilization_Ecosystem
  );

  const [size, setSize] = useState({ x: 3900, y: 400 });

  const columnRestriction = useSelector(
    (state) => state?.CustomerView?.columnsRestiction
  );
  const [timeRange, setTimeRange] = useState('');

  const [selectRadioBtn, setSelectRadioBtn] = useState('SiteUtilization');
  useEffect(() => {
    if (!Array.isArray(selectedAccount)) {
      getSiteUtilizationTimeRange();
    }
  }, []);
  useEffect(() => {
    setSelectRadioBtn('SiteUtilization');
    setFilteredColumns(SITE_UTILIZATION_UTILIZATION);
    setSiteType('SiteUtilization');
    setSize({ x: 3900, y: 400 });
    const expression = getAccountExpression(
      selectedAccount,
      false,
      selectedView
    );
    setFieldExpression(expression);
    getUtilizationData(expression);
  }, [selectedAccount]);

  let siteFilterExpression;

  const getSiteUtilizationTimeRange = async () => {
    const response = await getQlikSenseData(
      SITE_UTILIZATION_TIME_RANGE_EXP,
      null,
      SITE_UTILIZATION_APP_ID
    );
    setTimeRange(response.data.data.category[0].replaceAll("'", ' '));
  };

  const [firstLoad, setFirstLoad] = useState(true);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [menuIsOpen, setMenuIsOpen] = useState({
    SiteUtilization_Ecosystem: false,
    SiteUtilization_SiteName_Address: false,
    SiteUtilization_SiteCity: false,
    SiteUtilization_SiteState: false,
    SiteUtilization_Site_Address: false,
  });
  const totalRecords =
    utilizationData?.length >= 0 && utilizationData?.length < 100
      ? utilizationData?.length
      : 100;

  const [total, setTotal] = useState(totalRecords);
  useEffect(() => {
    setTotal(totalRecords);
  }, [totalRecords]);

  const pagination = {
    total: total,
    onChange: (page, size) => {
      setPageIndex(page);
      setPageSize(size);
    },
    pageSizeOptions: [10, 20, 30],
    showSizeChanger: true,
  };
  useEffect(() => {
    if (!firstLoad) {
      let expression = buildExpression();
      getUtilizationData(expression, 0);
    }
  }, [siteType]);

  useEffect(() => {
    setPageIndex(pageIndex);
    const isLastPage = pageIndex === Math.ceil(total / pageSize);
    let expression = buildExpression();
    const selectedPageIndex =
      pageIndex > 0 && Math.ceil((pageIndex * pageSize) / 100);
    if (isLastPage && total < utilizationData.totalSize) {
      getUtilizationData(expression, pageIndex);

      setTotal(total + 100);
    }
  }, [pageIndex]);

  const buildExpression = () => {
    const isAllEcosystem =
      isArray(ecosystemOptions) &&
      ecosystemOptions?.length === selectedEcosystem?.length;

    const ecosystem = isAllEcosystem ? 'Ecosystem=' : selectedEcosystem;

    const accountExp = getAccountExpression(
      selectedAccount,
      false,
      selectedView
    );

    //build Ecosystem  expression
    const ecosystemExp = isArray(ecosystem)
      ? 'Ecosystem={"' + ecosystem.map((a) => a.label).join('","') + '"}'
      : '';

    const siteNameExp = isArray(selectedSite)
      ? 'Site_ID ={"' + selectedSite.map((a) => a.value).join('","') + '"}'
      : '';
    const siteCityExp = isArray(selectedCity)
      ? '[Site City] ={"' + selectedCity.map((a) => a.label).join('","') + '"}'
      : '';
    const siteStateExp = isArray(selectedState)
      ? '[Site State] ={"' +
        selectedState.map((a) => a.label).join('","') +
        '"}'
      : '';
    const siteAddressExp = isArray(selectedSiteAddress)
      ? '[Site Address] ={"' +
        selectedSiteAddress.map((a) => a.label).join('","') +
        '"}'
      : '';

    siteFilterExpression = `${accountExp}`;

    if (siteNameExp !== '')
      siteFilterExpression = siteFilterExpression.concat(`, ${siteNameExp}`);
    if (ecosystemExp !== '')
      siteFilterExpression = siteFilterExpression.concat(`, ${ecosystemExp}`);
    if (siteCityExp !== '')
      siteFilterExpression = siteFilterExpression.concat(`, ${siteCityExp}`);
    if (siteStateExp !== '')
      siteFilterExpression = siteFilterExpression.concat(`, ${siteStateExp}`);
    if (siteAddressExp !== '')
      siteFilterExpression = siteFilterExpression.concat(`, ${siteAddressExp}`);

    setFieldExpression(siteFilterExpression);
    dispatch(setSiteUtilizationExpression(siteFilterExpression));
    return siteFilterExpression;
  };

  const getUtilizationData = async (
    expression,
    pageIndex = 0,
    column = 'summary_score',
    order = 'desc'
  ) => {
    setIsLoading(true);
    let data = await getSiteUtilizationData(
      expression,
      siteType,
      pageIndex,
      qlikCookie,
      qlikToken,
      column,
      order
    );
    setUtilizationData(data?.values);
    setIsLoading(false);
    setFirstLoad(false);
  };
  useEffect(() => {
    if (!selectedAccount?.label) {
      dispatch(setSiteLoader(false));
      return;
    }
    if (!firstLoad) {
      let expression = buildExpression();
      getUtilizationData(expression);
    }
  }, [
    selectedCity,
    selectedSite,
    selectedState,
    selectedSiteAddress,
    selectedEcosystem,
  ]);

  useEffect(() => {
    setAllColumnData(
      allColumnData?.map((item) => ({
        ...item,
        isSelected: filteredColumns?.some(
          (filterColumn) => filterColumn.dataIndex === item.dataIndex
        )
          ? 'Y'
          : 'N',
      }))
    );

    setupdatedColumnData(filteredColumns);
  }, [filteredColumns, modalShow]);

  const handleClick = (rowData, index) => {
    const payload = {
      value: rowData?.mdmId,
      label: rowData?.site_name,
      zip: rowData?.site_zip,
    };
    dispatch(setFilterInit());
    dispatch(setEngagementDateInit());
    dispatch(setSeletedView('site'));
    dispatch(setSelectedAccount(payload));
    dispatch(setInsightType('general'));
    navigation(`/customerView/list/${rowData.site_name}`);
  };
  const handleSorting = (column, order) => {
    let expression = buildExpression();
    order = order === 'ascend' ? 'ASC' : order;
    getUtilizationData(expression, 0, column, order);
  };

  const onMenuOpen = useCallback((filterName, value) => {
    let menuIsOpenLocal = menuIsOpen;
    if (filterName !== '' && value) {
      menuIsOpenLocal = Object.keys(menuIsOpen).reduce((acc, key) => {
        return { ...acc, [key]: filterName === key };
      }, {});
    } else if ((filterName !== '' && !value) || filterName === '') {
      menuIsOpenLocal = Object.keys(menuIsOpen).reduce((acc, key) => {
        return { ...acc, [key]: false };
      }, {});
    }
    setMenuIsOpen({ ...menuIsOpenLocal });
  }, []);

  const handleoptionchange = (e) => {
    setSelectRadioBtn(e.target.value);
    if (e.target.value === 'SiteUtilization') {
      setFilteredColumns(SITE_UTILIZATION_UTILIZATION);
      setSize({ x: 3900, y: 400 });
      setSiteType('SiteUtilization');
    } else if (e.target.value === 'SiteIncidence') {
      setFilteredColumns(SITE_UTILIZATION_INCIDENCE);
      setSize({ x: 1900, y: 400 });
      setSiteType('SiteIncidence');
    } else if (e.target.value === 'SiteDemographic') {
      setFilteredColumns(SITE_UTILIZATION_DEMOGRAPHICS);
      setSize({ x: 1900, y: 400 });
      setSiteType('SiteDemographic');
    }
  };
  const ExportClickHandler = () => {
    setupdatedColumnData(filteredColumns);
    setModalShow(true);
  };
  const exportTooltip = (
    <Tooltip bsclass='gne-tooltip tooltip--left'>Send to Excel</Tooltip>
  );
  const onHide = () => {
    setModalShow(false);
    handleClearAll();
  };
  const handleClearAll = () => {
    setAllColumnData(
      allColumnData?.map((report) => {
        if (true) {
          const updatedData = {
            ...report,
            isSelected: 'N',
          };
          return updatedData;
        }
      })
    );
    setupdatedColumnData([]);
  };

  const handleSelectAll = () => {
    setAllColumnData(
      allColumnData?.map((report) => {
        if (true) {
          const updatedData = {
            ...report,
            isSelected: 'Y',
          };
          return updatedData;
        }
      })
    );
    setupdatedColumnData(allColumnData);
  };

  return (
    <div className='full-width-in-container light-grey'>
      <div
        id='SiteUtilization'
        className='pt-3'>
        <Row>
          <Col
            className='pt-3'
            md={4}>
            <p
              className='title title__bold title__large mb-0'
              style={{ paddingRight: 0, marginRight: 0 }}>
              Site Utilization & Demographics
            </p>
            {<p className='title__small pt-0 text text__grey'>{timeRange}</p>}
          </Col>
          <Col
            className='pt-3'
            md={6}
            style={{ paddingLeft: 0, marginLeft: 0 }}>
            <div
              className={`title__small ${classes['radio_selection']}`}
              style={{ paddingLeft: 0, marginLeft: 0 }}>
              <label className={classes['radio_button']}>
                <input
                  type='radio'
                  name='siteUtilizationRadioBtn'
                  value='SiteUtilization'
                  className={classes['radio_label']}
                  checked={selectRadioBtn === 'SiteUtilization'}
                  onChange={handleoptionchange}
                />
                Utilization
              </label>
              <label className={classes['radio_button']}>
                <input
                  type='radio'
                  name='siteUtilizationRadioBtn'
                  className={classes['radio_label']}
                  value='SiteIncidence'
                  checked={selectRadioBtn === 'SiteIncidence'}
                  onChange={handleoptionchange}
                />
                Incidence (Per 100K)
              </label>
              <label className={classes['radio_button']}>
                <input
                  type='radio'
                  name='siteUtilizationRadioBtn'
                  className={classes['radio_label']}
                  value='SiteDemographic'
                  checked={selectRadioBtn === 'SiteDemographic'}
                  onChange={handleoptionchange}
                />
                Demographics
              </label>
            </div>
          </Col>
          <Col
            className='pt-3'
            md={2}
            style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <OverlayTrigger
              placement='top'
              overlay={exportTooltip}>
              <i
                onClick={ExportClickHandler}
                className={`icon icon__affiliations_export_excel1 mt-3`}
              />
            </OverlayTrigger>
            {modalShow && (
              <ExportTables
                show={modalShow}
                modalshow={modalShow}
                onHide={onHide}
                allColumnData={allColumnData}
                setAllColumnData={setAllColumnData}
                updatedColumnData={updatedColumnData}
                setupdatedColumnData={setupdatedColumnData}
                handleClearAll={handleClearAll}
                handleSelectAll={handleSelectAll}
                expression={fieldExpression}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col
            md={12}
            className={'p-0 m-0'}>
            <Row>
              <Col md={3}>
                <label className='title__x-small'>Ecosystem</label>
                <SelectWithPagination
                  menuIsOpen={menuIsOpen}
                  onMenuOpen={onMenuOpen}
                  filter={siteUtilizationFilters}
                  appid={SITE_UTILIZATION_APP_ID}
                  fieldName='SiteUtilization_Ecosystem'
                  isMulti={true}
                  filterName={SITE_UTILIZATION_FILTER_NAME}
                  cacheUniqs={[
                    selectedCity,
                    selectedSite,
                    selectedState,
                    selectedAccount,
                    selectedSiteAddress,
                  ]}
                  expression={fieldExpression}
                  isDefault={false}
                />
              </Col>
              <Col md={2}>
                <label className='title__x-small'>Site Name</label>
                <SelectWithPagination
                  menuIsOpen={menuIsOpen}
                  onMenuOpen={onMenuOpen}
                  filter={siteUtilizationFilters}
                  fieldName='SiteUtilization_SiteName_Address'
                  appid={SITE_UTILIZATION_APP_ID}
                  isMulti={true}
                  filterName={SITE_UTILIZATION_FILTER_NAME}
                  expression={fieldExpression}
                  cacheUniqs={[
                    selectedCity,
                    selectedEcosystem,
                    selectedState,
                    selectedAccount,
                    selectedSiteAddress,
                  ]}
                />
              </Col>
              <Col md={3}>
                <label className='title__x-small'>Site Address</label>
                <SelectWithPagination
                  filter={siteUtilizationFilters}
                  fieldName='SiteUtilization_Site_Address'
                  isMulti={true}
                  appid={SITE_UTILIZATION_APP_ID}
                  isDefault={false}
                  menuIsOpen={menuIsOpen}
                  onMenuOpen={onMenuOpen}
                  expression={fieldExpression}
                  filterName={SITE_UTILIZATION_FILTER_NAME}
                  cacheUniqs={[
                    selectedCity,
                    selectedEcosystem,
                    selectedSite,
                    selectedAccount,
                    selectedState,
                  ]}
                />
              </Col>
              <Col md={2}>
                <label className='title__x-small'>Site City</label>
                <SelectWithPagination
                  menuIsOpen={menuIsOpen}
                  onMenuOpen={onMenuOpen}
                  filter={siteUtilizationFilters}
                  fieldName='SiteUtilization_SiteCity'
                  appid={SITE_UTILIZATION_APP_ID}
                  isMulti={true}
                  filterName={SITE_UTILIZATION_FILTER_NAME}
                  cacheUniqs={[
                    selectedSite,
                    selectedEcosystem,
                    selectedState,
                    selectedAccount,
                    selectedSiteAddress,
                  ]}
                  expression={fieldExpression}
                />
              </Col>
              <Col md={2}>
                <label className='title__x-small'>Site State</label>
                <SelectWithPagination
                  menuIsOpen={menuIsOpen}
                  onMenuOpen={onMenuOpen}
                  filter={siteUtilizationFilters}
                  fieldName='SiteUtilization_SiteState'
                  isMulti={true}
                  appid={SITE_UTILIZATION_APP_ID}
                  filterName={SITE_UTILIZATION_FILTER_NAME}
                  cacheUniqs={[
                    selectedCity,
                    selectedEcosystem,
                    selectedSite,
                    selectedAccount,
                    selectedSiteAddress,
                  ]}
                  expression={fieldExpression}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        &nbsp;
      </div>

      <Row
        id='SiteUtilization'
        className={classes.sitePerformanceContainer}>
        <Col xs={12}>
          <Spin spinning={isLoading || isExporting}>
            <ConfigurableTable
              rows={utilizationData}
              columns={getRestrictedColumn(
                TABLE_NAME_SITE_UTILIZATION,
                filteredColumns,
                columnRestriction
              ).map((col) => {
                if (col.dataIndex === 'site_name') {
                  return {
                    ...col,
                    render: (text, record) => (
                      <span
                        className='span-link'
                        href={`/customerView/list/${record.site_name}`}
                        onClick={() => handleClick(record)}>
                        {text}
                      </span>
                    ),
                  };
                } else if (col.dataIndex === 'summary_score') {
                  return {
                    ...col,
                    render: (text, record) => (
                      <Tag
                        color={
                          text >= 51 && text <= 80
                            ? '#56a944'
                            : text >= 36 && text <= 50
                            ? '#fbbc04'
                            : text >= 1 && text <= 35
                            ? '#c23934'
                            : ''
                        }
                        key={uuid.v4()}>
                        {text}
                      </Tag>
                    ),
                  };
                } else if (
                  SITE_UTILIZATION_HIGH_MEDIUM_LOW_COLUMN_NAME.includes(
                    col?.dataIndex
                  )
                ) {
                  return {
                    ...col,
                    render: (text, record) => (
                      <Tag
                        color={
                          text === 'High'
                            ? '#56a944'
                            : text === 'Medium'
                            ? '#fbbc04'
                            : text === 'Low'
                            ? '#c23934'
                            : ''
                        }
                        key={uuid.v4()}>
                        {text}
                      </Tag>
                    ),
                  };
                }
                return col;
              })}
              expandRowByClick={false}
              enableColumnSelection={false}
              enableExport={false}
              exportFileName='siteUtilizationDetails'
              tableName={TABLE_NAME_SITE_UTILIZATION}
              expression={siteFilterExpression}
              isGlobalExport={true}
              pagination={pagination}
              isGlobalSort={true}
              onSort={handleSorting}
              tableType={selectRadioBtn}
              dimentions={size}
            />
          </Spin>
        </Col>
      </Row>
    </div>
  );
};

export default SiteUtilization;
