/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState, useRef } from 'react';

import { toast } from 'react-toastify';
import classes from './View.module.scss';

import Insights from '../insights/Insights';
import Banner from '../banner/Banner';
import Account from './Account';
import Affiliations from '../affiliations/Affiliations';

import { Fade } from 'react-awesome-reveal';
import SectionMenu from '../section-menu/SectionMenu';
import {
  setCards,
  setCustomerInsightBoardId,
  setCustomerViewButtonDisabled,
  setCustomerViewButtonText,
  setSelectedAccount,
  setSeletedView,
  setInsightType,
  setFilterInit,
} from 'store/customer/customerViewSlice';
import Engagements from '../engagements/Engagements';
import {
  addToInsightsBoard,
  getFocusAccountData,
} from 'store/customer/customer-view-actions';
import SitePerformance from '../site-performance/SitePerformance';

import {
  CUSTOMER_VIEW_DETAIL_PAGE,
  CUSTOMER_VIEW_UPDATE_TO_INSIGHTS,
} from 'constants/constants';
import Site from './Site';
import ProductMix from '../product-mix/ProductMix';
import PayerMix from '../payer-mix/PayerMix';
import SiteAffiliations from '../affiliations/SiteAffiliation';
import SiteUtilization from '../site-utilization/SiteUtilization';
import AccountPayerMix from '../payer-mix/AccountPayerMix';
import CopayFRMView from '../copay-Analystic/CopayFRMView';
import CopayHDView from '../copay-Analystic/CopayHDView';
import './warningMsg.css';
import { setEngagementDateInit } from 'store/customer/filter/filter-slice';

const ViewInsights = () => {
  const dispatch = useDispatch();
  const buttonText = useSelector(
    (state) => state?.CustomerView?.customerViewButtonText
  );
  const componentAccessList = useSelector(
    (state) => state?.Auth?.componentAccessList
  );

  const { unixId, roleId } = useSelector(
    (state) => state?.Auth?.userClaimObject
  );
  const selectedAccount = useSelector(
    (state) => state?.CustomerView?.selectedAccount
  );
  const selectedView = useSelector(
    (state) => state?.CustomerView?.selectedView
  );

  const customerViewButtonDisabled = useSelector(
    (state) => state.CustomerView.customerViewButtonDisabled
  );

  const insightsBrdId = JSON.parse(
    JSON.stringify(
      useSelector((state) => state.CustomerView?.custInsightsBrdId)
    )
  );

  const focusAccountChecked = useSelector(
    (state) => state.CustomerView?.focusAccountChecked
  );

  const cards = useSelector((state) => state?.CustomerView?.cards);
  const notificationId = useRef(null);

  const isUpdate = buttonText.toLowerCase().indexOf('update') > -1;
  const [loadComponents, setLoadComponents] = useState(
    selectedAccount !== undefined || selectedAccount?.length > 0
  );
  const insightType = useSelector((state) => state?.CustomerView?.insightType);
  const isSampleInsight = insightType === 'sample' ? true : false;

  const onChangeHandler = (selected) => {
    toast.dismiss(notificationId.current);
    dispatch(setCustomerViewButtonDisabled(true));
    if (selected?.label?.length > 1) {
      setLoadComponents(true);
      dispatch(setFilterInit());
      dispatch(setEngagementDateInit());
      dispatch(setSelectedAccount(selected));
      dispatch(setSeletedView(selectedView));
      dispatch(setInsightType('general'));
    }
  };

  const [tableTimeSlot, setTableTimeSlot] = useState();
  const [selectOptions, setSelectedOptions] = useState(selectedView);
  const handleOptionChange = (event) => {
    dispatch(setSeletedView(event.target.value));
    toast.dismiss(notificationId.current);
    dispatch(setCustomerViewButtonDisabled(true));
    dispatch(setSelectedAccount([]));
    setLoadComponents(false);
    dispatch(setInsightType('general'));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedView, selectedAccount]);

  useEffect(() => {
    dispatch(
      getFocusAccountData(
        unixId,
        roleId,
        selectedView,
        CUSTOMER_VIEW_DETAIL_PAGE
      )
    );
  }, [selectedView]);

  useEffect(() => {
    if (!customerViewButtonDisabled && selectedAccount) {
      toast.dismiss(notificationId.current);
      notificationId.current = toast.warning(
        `Your recent edits to the insights section in this customer view haven't been saved yet. To keep your changes,
      click the '${buttonText}’ button. If you don't save, your changes will be lost.`,
        {
          position: 'bottom-left',
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: false,
          className: 'warningMsgAlignment',
        }
      );
    } else {
      toast.dismiss(notificationId.current);
    }
    return () => {
      toast.dismiss(notificationId.current);
    };
  }, [cards]);

  const addUpdateInsightsBoard = async (isUpdate) => {
    const cardsData = cards.map((item) => {
      return {
        title: item.title,
        cardId: item.cardId,
        custInsightsBrdId: item.custInsightsBrdId,
        kpiId: item.kpiId,
        expression: item.expression,
        appId: item.appId,
        cardView: item.cardView,
        filters: item.filter,
        positionIndex: item.positionIndex,
        unixId,
        isDeleted: item?.isDeleted === true ? true : false,
        timespan: item.timespan,
        advanceEnabled: item.advanceEnabled,
        advanceType: item.advanceType,
        advanceId: item.advanceId,
        advanceExpression: item.advanceExpression,
        adavanceTimeSpanExpression: item.adavanceTimeSpanExpression,
        advanceFilterValue: item.advanceFilterValue,
        roleId: roleId,
      };
    });

    const payload = {
      name: `${selectedAccount?.label}`,
      unixId,
      accountId: selectedAccount?.value,
      custInsightsBrdId: isUpdate
        ? insightsBrdId
        : cards?.[0]?.custInsightsBrdId,
      isPriorityAccount: focusAccountChecked === true ? 'Y' : 'N',
      cardsData,
      boardType: selectedView,
      roleId,
    };

    const data = await addToInsightsBoard(payload, isUpdate);
    const { cardId: cardIdsArray, custInsightsBrdId, message } = data;

    const updatedCards = cards
      ?.filter((crd) => crd.isDeleted !== true)
      ?.map((item, index) => {
        return {
          ...item,
          cardId: cardIdsArray?.[index],
          custInsightsBrdId,
        };
      });
    dispatch(setCards(updatedCards));

    dispatch(setCustomerInsightBoardId(custInsightsBrdId));
    dispatch(setCustomerViewButtonText(CUSTOMER_VIEW_UPDATE_TO_INSIGHTS));
    dispatch(setCustomerViewButtonDisabled(true));
    toast.success(message);
  };

  return (
    <>
      {/* customer view filter */}
      <Row className={classes['title-container']}>
        <Col
          md={10}
          xs={12}
          className='d-flex align-items-center'>
          <Fade>
            <p
              className={`title__bold title__medium  m-0 d-inline-block  ${classes['customer-insight-label']}`}>
              <i className='icon icon__customer-view--title'></i>
              Customer View
            </p>
          </Fade>

          {/* account dropdown here */}
          <div className={`title__small ${classes['radio_selection']}`}>
            <label className={classes['radio_button']}>
              <input
                type='radio'
                name='option'
                value='account'
                className={classes['radio_label']}
                checked={selectedView === 'account'}
                onChange={handleOptionChange}
              />
              Account
            </label>
            <label className={classes['radio_button']}>
              <input
                type='radio'
                name='option'
                className={classes['radio_label']}
                value='site'
                checked={selectedView === 'site'}
                onChange={handleOptionChange}
              />
              Site
            </label>
          </div>
          <div>
            {selectedView === 'account' ? (
              <Account
                cssClass={`d-inline-block ${classes.wide}`}
                onAccountSelected={onChangeHandler}
                showPriorityAccount={true}
                customerView={true}
                isClearable={false}
              />
            ) : (
              <Site
                cssClass={`d-inline-block ${classes.wide}`}
                onSiteSelected={onChangeHandler}
                showPrioritySite={true}
                customerView={true}
                isFocusedChecked={focusAccountChecked}
                isClearable={false}
              />
            )}
          </div>
        </Col>
      </Row>
      {/* sections */}
      {loadComponents && (
        <>
          <Fade>
            <Banner data={selectedView} />
          </Fade>
          <SectionMenu
            data={selectedView}
            role={roleId}
          />
          <Col
            md={12}
            xs={12}
            className='d-flex justify-content-end align-items-right mt-3'>
            {!isSampleInsight && (
              <Button
                variant={` btn-gne ${
                  isUpdate
                    ? 'btn-primary'
                    : 'btn-outline-primary btn-gne__outline'
                }`}
                onClick={() => addUpdateInsightsBoard(isUpdate)}
                disabled={cards?.length === 0 || customerViewButtonDisabled}>
                <div className={classes['update-insight-btn-label']}>
                  {!isUpdate && (
                    <i
                      className='icon icon__add'
                      style={{ marginTop: 2 }}></i>
                  )}
                  {buttonText}
                </div>
              </Button>
            )}
          </Col>
          <Fade>
            <Insights />
          </Fade>
          <Fade>
            <Engagements />
          </Fade>
        </>
      )}
      {loadComponents &&
        selectedView === 'account' &&
        componentAccessList?.includes('Site-Performance') && (
          <Fade>
            <SitePerformance />
          </Fade>
        )}
      {loadComponents &&
        selectedView === 'account' &&
        componentAccessList?.includes('Payer-Mix-Account') && (
          <Fade>
            <AccountPayerMix />
          </Fade>
        )}
      {loadComponents && componentAccessList?.includes('Site-Utilization') && (
        <Fade>
          <SiteUtilization />
        </Fade>
      )}
      {loadComponents &&
        selectedView === 'site' &&
        componentAccessList?.includes('Product-Mix') && (
          <Fade>
            <ProductMix />
          </Fade>
        )}
      {loadComponents &&
        selectedView === 'site' &&
        componentAccessList?.includes('Payer-Mix') && (
          <Fade>
            <PayerMix />
          </Fade>
        )}
      {loadComponents && selectedView === 'account' && (
        <Fade>
          <Affiliations
            data={selectedView}
            role={roleId}
          />
        </Fade>
      )}
      {loadComponents && selectedView === 'site' && (
        <Fade>
          <SiteAffiliations
            data={selectedView}
            role={roleId}
            filterExp=''
            enableTitle={true}
            tableType={'siteView'}
          />
        </Fade>
      )}
      {loadComponents && componentAccessList?.includes('Copay-FRM') && (
        <Fade>
          <div
            className='full-width-in-container light-grey'
            id='copayFRMView'>
            <CopayFRMView />
          </div>
        </Fade>
      )}{' '}
      {loadComponents && componentAccessList?.includes('Copay-HD') && (
        <Fade>
          <div
            className='full-width-in-container light-grey'
            id='copayHDView'>
            <CopayHDView />
          </div>
        </Fade>
      )}
    </>
  );
};

export default ViewInsights;
