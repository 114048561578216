import axiosQlikClient from 'api';
import axiosDBClient from 'axiosDBClient';
import { trackPromise } from 'react-promise-tracker';
import {
  setUserFocusAccounts,
  setAllAccountOptions,
  setEcosystemOptions,
  setProductOptions,
  setTimePeriodOptions,
  setTimePeriods,
  setChartData,
  setNoDimensionValue,
  setSelectedAccount,
  setKpiandDimensions,
  setSelectedKPI,
  setSelectedKpiId,
  setSelectedKpiName,
  setDimensionName,
  setSelectedProduct,
  setCards,
  setSelectedTimePeriod,
  setSelectedAppId,
  setTimespan,
  setFocusAccountChecked,
  setSelectedEcosystem,
  setNoFocusedAccount,
  setSelectedAppLink,
  setSquadOptions,
  setDimensionId,
  setDefaultCard,
  setSelectedSquad,
} from './homeMyViewSlice';
import { setQlikToken } from 'store/auth/Authslice';
import isArray from 'lodash/isArray';
import { getQlikSenseData, setQlikAuth } from 'services/qlik.service';
import {
  COMPARE_BENCHMARK,
  BENCHMARK_LEGEND_FIRST,
  areas,
  COMPARE_KPI,
} from 'constants/constants';
import {
  buildAdvancePropsObject,
  buildExpressionOutOfFilter,
  getAccountExpression,
} from 'utils/utils';
import { getMultiDimensionChartData } from 'services/trends.service';

const getEcosystems = async (unixId, roleId) => {
  const response = await axiosDBClient.get('filter/ecosystem', {
    params: { unixId, roleId },
  });

  const ecosystems = response.data.result;
  let ecosystemOptions = [];

  if (ecosystems?.length > 0) {
    ecosystemOptions = ecosystems.map((ecosystem) => {
      return {
        label: ecosystem.ecosystemName,
        value: ecosystem.ecosystemId,
      };
    });
  }
  return ecosystemOptions;
};
/**
 * Get product data from the database
 * that will be displayed in the product dropdown
 * store the product in the store
 * @returns
 */
export const getEcosystemData = (unixId, roleId) => {
  return async (dispatch) => {
    try {
      const ecoSystem = await getEcosystems(unixId, roleId);
      if (ecoSystem?.length > 0) {
        dispatch(setEcosystemOptions(ecoSystem));
      }
    } catch (error) {}
  };
};

/**
 * Get product data from the database
 * that will be displayed in the product dropdown
 * store the product in the store
 * @returns
 */
export const getProductData = (selectedProduct) => {
  return async (dispatch) => {
    const getProducts = async () => {
      const response = await axiosDBClient.get('filter/products');

      if (response.data.error) {
        throw new Error('Could not fetch products!');
      }

      return response.data.result;
    };

    try {
      const products = await getProducts();

      dispatch(setProductOptions(products));
      if (isArray(selectedProduct)) {
        dispatch(setSelectedProduct(selectedProduct));
      }
    } catch (error) {
      console.log(error);
    }
  };
};
/**
 * Get squad data from the database
 * that will be displayed in the squad dropdown
 * store the squad in the store
 * @returns
 */
export const getSquadData = (selectedSquad) => {
  return async (dispatch) => {
    const getSquads = async () => {
      const url = `filter/squads`;
      const response = await axiosDBClient.get(url);
      if (response.data.error) {
        throw new Error('Could not fetch squads!');
      }

      return response.data.result;
    };

    try {
      const squads = await getSquads();
      dispatch(setSquadOptions(squads));
      if (isArray(selectedSquad)) {
        dispatch(setSelectedSquad(selectedSquad));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

/**
 * Gets time period data and formats it so
 * the Time Period dropdown can use it
 * also stores time period
 * store the kpi time period in the store
 * @param {*} kpiId the kpi id
 * @returns
 */
export const getTimePeriodData = (kpiId) => {
  return async (dispatch) => {
    const getTimePeriods = async () => {
      const response = await axiosDBClient.get('filter/time-period', {
        params: { kpiId },
      });

      if (response.data.error) {
        throw new Error('Could not fetch products!');
      }
      return response.data.result;
    };

    try {
      const timePeriods = await getTimePeriods();
      dispatch(setTimePeriods(timePeriods));
      const timePeriodOptions = timePeriods.map((timePeriod) => {
        return {
          label: timePeriod.timePeriodName,
          value: timePeriod.timePeriodId,
        };
      });

      dispatch(setTimePeriodOptions(timePeriodOptions));
      let exp = timePeriods.filter((tp) => tp.uXDefaultValue === 'Y')[0];
      dispatch(
        setSelectedTimePeriod({
          label: exp.timePeriodName,
          value: exp.timePeriodId,
        })
      );
    } catch (error) {
      console.log(error.message);
    }
  };
};

/**
 * Make an API call to get qlik data
 * @param {*} token
 * @param {*} expression the expression used qMeasure
 * @param {*} dimension the dimension used for qDimension
 * @returns
 */
export const getQlikFilteredData = (
  token,
  cookie,
  expression,
  dimension,
  appId
) => {
  return async (dispatch) => {
    const getQlikData = async () => {
      const limit = 30;
      const response = await axiosQlikClient.get('qlik', {
        params: { measure: expression, dimension, appId, limit },
        headers: { qlikCookie: cookie, Authorization: `Bearer ${token}` },
      });

      if (response.error) {
        throw new Error('Could not fetch qlik data!');
      }

      return response;
    };

    try {
      trackPromise(
        getQlikData().then((response) => {
          setQlikAuth(
            response?.headers['authorization']?.split(' ')[1],
            response?.headers['qlikcookie']
          );

          dispatch(
            setQlikToken({
              qlikToken: response?.headers['authorization']?.split(' ')[1],
              qlikCookie: response?.headers['qlikcookie'],
            })
          );

          if (!response.data?.data?.values[0]) {
            dispatch(setNoDimensionValue(response.data.data.category[0]));
          }
          dispatch(setChartData(response.data.data));
        })
      );
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const getAllKpiAndDimension = (roleId, view) => {
  return async (dispatch) => {
    const getKPIAndDimensions = async () => {
      const response = await axiosDBClient.get(
        `kpi/kpi-dimension?roleId=${roleId}&field=${view}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.error) {
        throw new Error('Could not kpi and dimensions data!');
      }

      return response.data;
    };

    try {
      const res = await getKPIAndDimensions();
      dispatch(getTimePeriodData(res.result[0]?.KpiId));
      dispatch(setKpiandDimensions(res.result));
      dispatch(setSelectedKPI(res.result[0]));
      dispatch(setSelectedAppId(res.result[0]?.AppId));
      dispatch(setSelectedKpiName(res.result[0]?.KpiName));
      dispatch(setDimensionName(res.result[0]?.dimensions[0]?.dimensionName));
      dispatch(setDimensionId(res.result[0]?.dimensions[0]?.id));
      dispatch(setSelectedAppLink(res.result[0]?.appLink));
      dispatch(setSelectedKpiId(res.result[0]?.KpiId));
    } catch (error) {
      console.log(error);
    }
  };
};

export const addToMyView = async (payload) => {
  const setAddToMyView = async () => {
    const response = await axiosDBClient.post('views/configure', {
      ...payload,
    });

    if (response.data.error) {
      throw new Error('Could not add data!');
    }

    return response.data;
  };

  try {
    const data = await setAddToMyView();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const updateMyView = async (payload) => {
  const setUpdateMyView = async () => {
    const response = await axiosDBClient.put(
      `views/configure/${payload.cardId}`,
      {
        ...payload,
      }
    );

    if (response.data.error) {
      throw new Error('Could not update data!');
    }

    return response.data;
  };

  try {
    const data = await setUpdateMyView();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const deleteMyView = async (cardId, positionIndex, unixId) => {
  const setDeleteMyView = async () => {
    const response = await axiosDBClient.delete(`views/configure/${cardId}`, {
      data: {
        positionIndex: positionIndex,
        unixId: unixId,
      },
    });

    if (response.data.error) {
      throw new Error('Could not delete data!');
    }

    return response.data;
  };

  try {
    const data = await setDeleteMyView();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};
export const updateCardTitle = async (cardId, title, unixId) => {
  const setUpdateMyView = async () => {
    const response = await axiosDBClient.put(
      `views/configure/title/${cardId}`,
      {
        title: title,
        unixId: unixId,
      }
    );

    if (response.data.error) {
      throw new Error('Could not delete data!');
    }

    return response.data;
  };

  try {
    const data = await setUpdateMyView();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getQlikDataForCard = async (
  expression,
  dimension,
  appId,
  limit
) => {
  try {
    return await getQlikSenseData(expression, dimension, appId, limit);
  } catch (error) {
    console.log(error.message);
  }
};

export const getDefultViewCard = (
  unixId,
  userRestrictedRole,
  userRestrictedEcosystemt,
  restrictedProductSquad,
  roleId
) => {
  return async (dispatch) => {
    const getDefulatViewCards = async () => {
      const response = await axiosDBClient.get(
        `myview/configuredefault?roleId=${roleId}&unixId=${unixId}`
      );

      if (response.data.error) {
        throw new Error('Could not fetch my view data!');
      }

      return response.data.result;
    };

    try {
      const defaultCards = await getDefulatViewCards();
      let advanceComparePropsRef = {};
      for (const card of defaultCards) {
        if (card.advanceEnabled === 'Y') {
          advanceComparePropsRef = buildAdvancePropsObject(
            card?.advanceType === COMPARE_BENCHMARK ||
              card?.advanceType === COMPARE_KPI
              ? JSON.parse(card?.advanceFilterValue)
              : card?.advanceFilterValue,
            card?.advanceType,
            card?.advanceType === COMPARE_BENCHMARK
              ? JSON.parse(card?.advanceExpression)
              : card?.advanceExpression,
            card?.selectedDimension
          );
        }
        const { filter } = card;
        const filterData = filter ? JSON.parse(filter) : {};
        card.filter = filterData;
        card.chartData = [];
        card.expression = card.expression?.replace(/(^"|"$)/g, '');
        card.advanceExpression =
          card?.advanceType === COMPARE_BENCHMARK
            ? JSON.parse(card?.advanceExpression)
            : card?.advanceExpression;
        card.advanceFilterValue =
          card?.advanceType === COMPARE_BENCHMARK ||
          card?.advanceType === COMPARE_KPI
            ? JSON.parse(card?.advanceFilterValue)
            : card?.advanceFilterValue;
        const filterExpression = buildExpressionOutOfFilter(
          filterData,
          userRestrictedRole,
          userRestrictedEcosystemt,
          restrictedProductSquad
        );

        let advance = {};
        let measure = [];
        let dimension = [];

        let dimensionObjOne = {
          dimesnionType: 'Cateogry',
          dimesnionName: filterData?.dimensionName,
        };
        dimension.push(JSON.stringify(dimensionObjOne));
        let dimensionObjTwo;

        if (
          card?.advanceEnabled === 'Y' &&
          advanceComparePropsRef?.compareBy === 'dimension'
        ) {
          dimensionObjTwo = {
            dimesnionType: 'Advance',
            dimesnionName: card?.advanceFilterValue,
          };
          dimension.push(JSON.stringify(dimensionObjTwo));
        }

        let measureObjOne = {
          timesPeriod:
            advanceComparePropsRef?.compareBy === COMPARE_BENCHMARK
              ? BENCHMARK_LEGEND_FIRST
              : advanceComparePropsRef?.compareBy === COMPARE_KPI
              ? filterData?.kpiName
              : filterData?.selectedTimePeriod?.label,
          index: 1,
          expression: card.expression
            .replaceAll(
              'vAllFilters',
              filterExpression ? filterExpression : 'test'
            )
            ?.replace(/(^"|"$)/g, ''),
        };
        measure.push(JSON.stringify(measureObjOne));

        let measureObjTwo;
        if (card?.advanceType === 'time-period') {
          measureObjTwo = {
            timesPeriod:
              advanceComparePropsRef?.selectDropdownOption?.possibleTimePeriod,
            index: 2,
            expression:
              advanceComparePropsRef?.selectDropdownOption?.advTimeperiodExp
                ?.replaceAll(
                  'vAllFilters',
                  filterExpression ? filterExpression : 'test'
                )
                ?.replace(/(^"|"$)/g, ''),
          };
          measure.push(JSON.stringify(measureObjTwo));
          //SETTING UP THE DIMESNION NAME TO MONTH_N IF PRIMARY DIMESNION IS MONTH AND COMPARE BY IS TIME PERIOD.
          if (card.filter.dimensionName === 'Month') {
            let dimensionObjOne = {
              dimesnionType: 'Cateogry',
              dimesnionName: 'Month_N',
            };

            dimension = [JSON.stringify(dimensionObjOne)];
          }
        }
        if (card?.advanceType === COMPARE_BENCHMARK) {
          measureObjTwo = {
            timesPeriod:
              advanceComparePropsRef?.selectDropdownOption?.possibleTimePeriod,
            index: 2,
            expression:
              advanceComparePropsRef?.selectDropdownOption?.advTimeperiodExp
                ?.replaceAll(
                  'vAllFilters',
                  filterExpression ? filterExpression : 'test'
                )
                ?.replace(/(^"|"$)/g, ''),
          };
          measure.push(JSON.stringify(measureObjTwo));

          //SETTING UP THE DIMESNION NAME TO MONTH_N IF PRIMARY DIMESNION IS MONTH AND COMPARE BY IS TIME PERIOD.
          if (card.filter.dimensionName === 'Month') {
            let dimensionObjOne = {
              dimesnionType: 'Cateogry',
              dimesnionName: 'Month_N',
            };

            dimension = [JSON.stringify(dimensionObjOne)];
          }
        }
        if (advanceComparePropsRef?.compareBy === COMPARE_KPI) {
          JSON.parse(
            advanceComparePropsRef?.selectDropdownOption?.advTimeperiodExp
          )?.map((oneKpi) => {
            measureObjTwo = {
              timesPeriod: oneKpi?.timesPeriod,
              index: measure.length + 1,
              expression: oneKpi.expression
                ?.replaceAll(
                  'vAllFilters',
                  filterExpression ? filterExpression : 'test'
                )
                ?.replace(/(^"|"$)/g, ''),
            };
            measure.push(JSON.stringify(measureObjTwo));
          });
        }
        advance.measure = measure;
        advance.dimension = dimension;
        advance.appId = card?.appId;

        advance.chartType = filterData?.chartType;
        card.advance = advance;

        card.filterData = {};
        card.filterData.chartType = filterData?.chartType;
      }
      if (defaultCards?.length === 0) {
        dispatch(setDefaultCard([]));
        return;
      } else {
        dispatch(setDefaultCard(defaultCards));
      }
      let cardData = [...defaultCards];

      defaultCards.map(async (val, i) => {
        await getMultiDimensionChartData(
          val?.advance?.measure,
          val?.advance?.dimension,
          val?.appId,
          val?.advance?.chartType
        ).then((res) => {
          let savedTimespan;
          trackPromise(
            getQlikSenseData(
              val.filter.timespanExpression,
              null,
              val.appId,
              null
            ).then((response) => {
              savedTimespan = response.data.data.category[0]?.replace(
                new RegExp("'", 'g'),
                ' '
              );

              cardData[defaultCards.indexOf(val)] = {
                ...val,
                chartData: res?.data[0],
                timespan: savedTimespan,
                chartType: val?.advance?.chartType,
              };
              dispatch(setDefaultCard([...cardData]));
            }),
            areas.defaultView
          );
        });
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const getCardsData = (
  unixId,
  roleId,
  userRestrictedRole,
  userRestrictedEcosystemt,
  restrictedProductSquad
) => {
  return async (dispatch) => {
    const getCards = async () => {
      const response = await axiosDBClient.get(`views/configure`, {
        params: { unixId, roleId },
      });

      if (response.data.error) {
        throw new Error('Could not fetch my view data!');
      }

      return response.data.result;
    };

    try {
      const cards = await getCards();
      let advanceComparePropsRef = {};
      for (const card of cards) {
        try {
          if (card.advanceEnabled === 'Y') {
            advanceComparePropsRef = buildAdvancePropsObject(
              card?.advanceType === COMPARE_BENCHMARK ||
                card?.advanceType === COMPARE_KPI
                ? JSON.parse(card?.advanceFilterValue)
                : card?.advanceFilterValue,
              card?.advanceType,
              card?.advanceType === COMPARE_BENCHMARK
                ? JSON.parse(card?.advanceExpression)
                : card?.advanceExpression,
              card?.selectedDimension
            );
          } else {
            advanceComparePropsRef = null;
          }

          const { filter } = card;
          const filterData = filter ? JSON.parse(filter) : {};
          card.expression = card.expression?.replace(/(^"|"$)/g, '');
          card.filter = filterData;
          card.chartData = [];
          card.advanceExpression =
            card?.advanceType === COMPARE_BENCHMARK
              ? JSON.parse(card?.advanceExpression)
              : card?.advanceExpression;
          card.advanceFilterValue =
            card?.advanceType === COMPARE_BENCHMARK ||
            card?.advanceType === COMPARE_KPI
              ? JSON.parse(card?.advanceFilterValue)
              : card?.advanceFilterValue;
          const filterExpression = buildExpressionOutOfFilter(
            filterData,
            userRestrictedRole,
            userRestrictedEcosystemt,
            restrictedProductSquad
          );
          let advance = {};
          let measure = [];
          let dimension = [];

          let dimensionObjOne = {
            dimesnionType: 'Cateogry',
            dimesnionName: filterData?.dimensionName,
          };
          dimension.push(JSON.stringify(dimensionObjOne));
          let dimensionObjTwo;

          if (
            card?.advanceEnabled === 'Y' &&
            advanceComparePropsRef?.compareBy === 'dimension'
          ) {
            dimensionObjTwo = {
              dimesnionType: 'Advance',
              dimesnionName: card?.advanceFilterValue,
            };
            dimension.push(JSON.stringify(dimensionObjTwo));
          }
          let measureObjOne = {
            timesPeriod:
              advanceComparePropsRef?.compareBy === COMPARE_BENCHMARK
                ? BENCHMARK_LEGEND_FIRST
                : advanceComparePropsRef?.compareBy === COMPARE_KPI
                ? filterData?.kpiName
                : filterData?.selectedTimePeriod?.label,
            index: 1,
            expression: card.expression
              .replaceAll(
                'vAllFilters',
                filterExpression ? filterExpression : 'test'
              )
              ?.replace(/(^"|"$)/g, ''),
          };
          measure.push(JSON.stringify(measureObjOne));

          let measureObjTwo;
          if (card?.advanceType === 'time-period') {
            measureObjTwo = {
              timesPeriod:
                advanceComparePropsRef?.selectDropdownOption
                  ?.possibleTimePeriod,
              index: 2,
              expression:
                advanceComparePropsRef?.selectDropdownOption?.advTimeperiodExp
                  ?.replaceAll(
                    'vAllFilters',
                    filterExpression ? filterExpression : 'test'
                  )
                  ?.replace(/(^"|"$)/g, ''),
            };
            measure.push(JSON.stringify(measureObjTwo));

            //SETTING UP THE DIMESNION NAME TO MONTH_N IF PRIMARY DIMESNION IS MONTH AND COMPARE BY IS TIME PERIOD.
            if (card.filter.dimensionName === 'Month') {
              let dimensionObjOne = {
                dimesnionType: 'Cateogry',
                dimesnionName: 'Month_N',
              };

              dimension = [JSON.stringify(dimensionObjOne)];
            }
          }
          if (card?.advanceType === COMPARE_BENCHMARK) {
            measureObjTwo = {
              timesPeriod:
                advanceComparePropsRef?.selectDropdownOption
                  ?.possibleTimePeriod,
              index: 2,
              expression:
                advanceComparePropsRef?.selectDropdownOption?.advTimeperiodExp
                  ?.replaceAll(
                    'vAllFilters',
                    filterExpression ? filterExpression : 'test'
                  )
                  ?.replace(/(^"|"$)/g, ''),
            };
            measure.push(JSON.stringify(measureObjTwo));

            //SETTING UP THE DIMESNION NAME TO MONTH_N IF PRIMARY DIMESNION IS MONTH AND COMPARE BY IS TIME PERIOD.
            if (card.filter.dimensionName === 'Month') {
              let dimensionObjOne = {
                dimesnionType: 'Cateogry',
                dimesnionName: 'Month_N',
              };

              dimension = [JSON.stringify(dimensionObjOne)];
            }
          }
          if (advanceComparePropsRef?.compareBy === COMPARE_KPI) {
            advanceComparePropsRef?.selectDropdownOption?.advTimeperiodExp?.map(
              (oneKpi) => {
                measureObjTwo = {
                  timesPeriod: oneKpi?.timesPeriod,
                  index: measure.length + 1,
                  expression: oneKpi.expression
                    ?.replaceAll(
                      'vAllFilters',
                      filterExpression ? filterExpression : 'test'
                    )
                    ?.replace(/(^"|"$)/g, ''),
                };
                measure.push(JSON.stringify(measureObjTwo));
              }
            );
            //SETTING UP THE DIMESNION NAME TO MONTH_N IF PRIMARY DIMESNION IS MONTH AND COMPARE BY IS TIME PERIOD.
            if (card.filter.dimensionName === 'Month') {
              let dimensionObjOne = {
                dimesnionType: 'Cateogry',
                dimesnionName: 'Month_N',
              };

              dimension = [JSON.stringify(dimensionObjOne)];
            }
          }
          advance.measure = measure;
          advance.dimension = dimension;
          advance.appId = card?.appId;

          advance.chartType = filterData?.chartType;
          card.advance = advance;

          card.filterData = {};
          card.filterData.chartType = filterData?.chartType;
        } catch (error) {
          console.log(error);
        }
      }
      if (cards?.length === 0) {
        dispatch(setCards([]));
        return;
      }

      let cardData = [...cards];
      cards.map(async (val, i) => {
        trackPromise(
          getMultiDimensionChartData(
            val?.advance?.measure,
            val?.advance?.dimension,
            val?.appId,
            val?.advance?.chartType
          ).then((res) => {
            let savedTimespan;

            getQlikSenseData(
              val.filter.timespanExpression,
              null,
              val.appId,
              null
            ).then((response) => {
              savedTimespan = response.data.data.category[0]?.replace(
                new RegExp("'", 'g'),
                ' '
              );

              cardData[cards.indexOf(val)] = {
                ...val,
                chartData: res?.data[0],
                timespan: savedTimespan,
                chartType: val?.advance?.chartType,
              };
              dispatch(setCards([...cardData]));
            });
          }),
          areas.home
        );
      });
    } catch (error) {
      console.log('ERROR', error);
    }
  };
};

export const setQlikCardData = (cards) => {
  return async (dispatch) => {
    let chartDataArr = [...cards];

    for (const card of cards) {
      const { filter, expression, appId } = card;
      const { dimensionName = '', timespanExpression } = filter;
      const expressionWithoutQuotes = expression?.replace(/(^"|"$)/g, '');
      const limit = 30;

      trackPromise(
        getQlikDataForCard(
          expressionWithoutQuotes,
          dimensionName,
          appId,
          limit
          // eslint-disable-next-line no-loop-func
        ).then((data) => {
          if (data) {
            try {
              // get the timespan based on the timespan expression
              getQlikSenseData(timespanExpression, null, appId, null).then(
                (response) => {
                  const savedTimespan = response.data.data.category[0].replace(
                    new RegExp("'", 'g'),
                    ' '
                  );
                  chartDataArr[cards.indexOf(card)] = {
                    ...card,
                    chartData: data?.data?.data,
                    timespan: savedTimespan,
                  };

                  dispatch(setCards([...chartDataArr]));
                }
              );
            } catch (error) {
              console.log('ERROR', error);
            }

            setQlikAuth(
              data?.headers['authorization']?.split(' ')[1],
              data?.headers['qlikcookie']
            );
            dispatch(
              setQlikToken({
                qlikToken: data?.headers['authorization']?.split(' ')[1],
                qlikCookie: data?.headers['qlikcookie'],
              })
            );
          }
        }),
        areas.home
      );
    }
  };
};
export const updateCardPositions = async (payload) => {
  const updatePositions = async () => {
    const response = await axiosDBClient.put(`views/configure/`, {
      ...payload,
    });

    if (response.data.error) {
      throw new Error('Could not update positions!');
    }

    return response.data;
  };

  try {
    const data = await updatePositions();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getTimespan = (expression, appId) => {
  return async (dispatch) => {
    try {
      trackPromise(
        getQlikSenseData(expression, null, appId, null).then((data) => {
          dispatch(
            setTimespan(
              data.data.data.category[0].replace(new RegExp("'", 'g'), ' ')
            )
          );

          setQlikAuth(
            data?.headers['authorization']?.split(' ')[1],
            data?.headers['qlikcookie']
          );

          dispatch(
            setQlikToken({
              qlikToken: data?.headers['authorization']?.split(' ')[1],
              qlikCookie: data?.headers['qlikcookie'],
            })
          );
        })
      );
    } catch (error) {
      console.log(error.message);
    }
  };
};
export const getReleaseData = async (roleId, unixId) => {
  try {
    const response = await axiosDBClient.get(`release-notes`, {
      params: { roleId, unixId },
    });
    if (response.error) {
      throw new Error('NO release Data');
    }
    return response?.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const logoutSSO = async (id) => {
  if (id !== undefined && id !== '') {
    try {
      const payload = { id };
      return await axiosDBClient.post('logout', {
        ...payload,
      });
    } catch (error) {
      console.log(error.message);
      return error.message;
    }
  }
};

export const updateReleaseData = async (roleId, unixId) => {
  try {
    const body = { roleId: roleId, unixId: unixId };
    const response = await axiosDBClient.put(`release-notes`, {
      ...body,
    });
    if (response.error) {
      throw new Error('NO release Data');
    }
    return response.data.result;
  } catch (error) {
    console.log(error.message);
  }
};
export const getSystemAlertsData = async (roleId) => {
  try {
    const response = await axiosDBClient.get(
      `/notifications/system-alert?roleId=${roleId}`
    );
    return response?.data?.result;
  } catch (error) {
    console.log(error);
  }
};

export const getNotificationsData = async (unixId, roleId) => {
  try {
    const response = await axiosDBClient.get(
      `/notifications/user?unixId=${unixId}&roleId=${roleId}`
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const downloadTileData = async (kpiTtitle, payload) => {
  try {
    const res = await axiosQlikClient.post(`/export`, payload, {
      headers: {
        qlikCookie: localStorage.getItem('qlikCookie'),
        Authorization: `Bearer ${localStorage.getItem('qlikToken')}`,
      },
      responseType: 'blob',
    });
    const FileDownload = require('js-file-download');
    const response = FileDownload(res?.data, `${kpiTtitle}-Data.xlsx`);
    return response;
  } catch (error) {
    throw new Error('Could not download data!');
  }
};

export const getFavoriteData = async (unixId, roleId) => {
  try {
    const response = await axiosDBClient.get(
      `/report/getReports?unixId=${unixId}&roleId=${roleId}`
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
