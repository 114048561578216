/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import classes from './TeamPOC.module.scss';

import AccountTypeToggle from 'components/shared/account-type-toggle/AccountTypeToggle';
import { ACCOUNT_TYPE, VIEW } from 'constants/constants';
import ConfigurableTable from 'components/shared/table/ConfigurableTable';
import {
  getPriorityTeam,
  getTeamMembers,
  addMemberToTeam,
  updateMemberOfTeam,
  deleteAccount,
} from 'services/configure-priority.service';
import GNERadio from 'components/shared/radio/GNERadio';
import LinkButton from 'components/shared/button/LinkButton';
import GNEModal from 'components/shared/modal/Modal';
import { formatNewMemberOptionLabel } from 'utils/utils';
import GNECheckbox from 'components/shared/checkbox/Checkbox';
import { setSelectedAccount } from 'store/customer/customerViewSlice';
import Confirmation from 'components/shared/confirmation/Confirmation';
import { getFocusAccountData } from 'store/customer/customer-view-actions';
import { toast } from 'react-toastify';
import GNESingleSelect from 'components/shared/dropdown/GNESingleSelect';

const TeamPOC = (props) => {
  const dispatch = useDispatch();
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [userRecordToDelete, setUserRecordToDelete] = useState('');
  //table Data
  const [teamRows, setTeamRows] = useState([]);
  const [selectedTeamMember, setSelectedTteamMember] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedToggleOption, setSelectedToggleOption] = useState(
    ACCOUNT_TYPE.account
  );
  const [selectedOption, setSelectedOption] = useState('');
  const { unixId, roleId } = useSelector((state) => state.Auth.userClaimObject);
  const selectedAccount = useSelector(
    (state) => state?.CustomerView?.selectedAccount
  );
  const [isAddMemberToTeam, setIsAddMemberToTeam] = useState(false);
  const PRIORITY_TEAM_POC_TABLE_COLUMNS = [
    {
      id: 1,
      dataIndex: 'userName',
      key: 'name',
      title: 'Team Member',
      isMandatory: 'Y',
      width: 250,
      sorter: (a, b) => a.userName.localeCompare(b.userName),
    },
    {
      id: 2,
      dataIndex: 'roleName',
      key: 'role',
      title: 'Role',
      isMandatory: 'Y',
      width: 250,
    },
    {
      id: 3,
      dataIndex: 'squad',
      key: 'squad',
      title: 'Squad',
      isMandatory: 'Y',
      width: 250,
    },

    {
      id: 4,
      dataIndex: 'primaryContact',
      key: 'primaryContact',
      title: 'Primary Contact',
      isMandatory: 'Y',
      width: 300,
      render: (_, record) => (
        <GNERadio
          name='primaryContact'
          value={record.unixId}
          checked={record.primaryContact === 'Y'}
          onChange={() => handleChange(record)}
          wrapperCss='ms-4'
        />
      ),
    },
  ];

  useEffect(() => {
    if (props.tabView === 'myPriority') {
      setSelectedToggleOption(ACCOUNT_TYPE.account);
    }
  }, [props]);

  const updateTeamData = (value) => {
    const updatedData = teamRows.filter((item) => item.unixId !== value);
    if (updatedData?.length === 0 || value === unixId) {
      dispatch(setSelectedAccount([]));
      dispatch(getFocusAccountData(unixId, roleId, selectedToggleOption));
    }
    setTeamRows(updatedData);
  };

  const onChangeHandler = async (customer) => {
    dispatch(setSelectedAccount(customer));
    const result = await getPriorityTeam(customer.value);
    setTeamRows(result);
    setSelectedOption(customer);
  };

  //switch to account or site
  const handleOptionChange = (event) => {
    let field = event.target.value;
    dispatch(setSelectedAccount([]));
    setSelectedToggleOption(field);
    dispatch(getFocusAccountData(unixId, roleId, field));
    setTeamRows([]);
  };
  const handleNewMemberClick = async () => {
    setSelectedTteamMember('');
    setShowModal(true);
    const result = await getTeamMembers(selectedOption?.value);
    setTeamMembers(result);
  };

  const onChange = (event) => {
    setIsAddMemberToTeam(event.target.checked);
  };

  const handleCancel = () => {
    onChangeHandler(selectedOption);
    setShowModal(false);
  };

  const onDelete = async () => {
    try {
      const response = await deleteAccount(
        selectedOption?.value,
        userRecordToDelete?.unixId,
        userRecordToDelete?.roleName
      );
      if (response) {
        updateTeamData(userRecordToDelete.unixId);
      }
    } catch (err) {
      console.log(err.message);
    }
    setConfirmModalShow(false);
  };

  const handleDelete = (record) => {
    setUserRecordToDelete(record);
    setConfirmModalShow(true);
  };

  const notifyNewMember = (isAddMemberToTeam, selectedTeamMember) => {
    let message = isAddMemberToTeam
      ? `${selectedTeamMember.label} added to Team as primary`
      : `${selectedTeamMember.label} added to Team`;
    toast.success(message);
  };
  const handleSaveNew = async () => {
    let payload = {
      unixId: selectedTeamMember?.value,
      customerId: selectedOption?.value,
      primaryFlag: isAddMemberToTeam === true ? 'Y' : 'N',
      squad: selectedTeamMember?.squad,
      roleId: selectedTeamMember?.roleId,
    };
    await addMemberToTeam(payload);
    notifyNewMember(isAddMemberToTeam, selectedTeamMember);
    setIsAddMemberToTeam(false);
    setSelectedTteamMember('');
    const memberListOptions = teamMembers.filter(
      (item) => item.value !== selectedTeamMember.value
    );
    setTeamMembers(memberListOptions);
    const result = await getPriorityTeam(selectedOption?.value);
    setTeamRows(result);
  };
  const handleSaveClose = async () => {
    let payload = {
      unixId: selectedTeamMember?.value,
      customerId: selectedOption?.value,
      primaryFlag: isAddMemberToTeam === true ? 'Y' : 'N',
      squad: selectedTeamMember?.squad,
      roleId: selectedTeamMember?.roleId,
    };

    if (payload.unixId !== undefined && payload.customerId !== undefined) {
      notifyNewMember(isAddMemberToTeam, selectedTeamMember);
      await addMemberToTeam(payload);
      setIsAddMemberToTeam(false);
      onChangeHandler(selectedOption);
    }
    setShowModal(false);
  };

  const handleMemberSelection = (selected) => {
    setSelectedTteamMember(selected);
  };

  const handleChange = async (record) => {
    await updateMemberOfTeam(selectedOption?.value, record);
    onChangeHandler(selectedOption);
  };
  const onHide = () => {
    setSelectedTteamMember('');
    setConfirmModalShow(false);
  };
  const size = { x: 1100, y: 400 };

  // html modal title
  const title = (
    <>
      <p
        className='title__large title__bold d-flex align-items-center'
        title='Add New Customer title'>
        <i className='icon icon__add-big'></i>Add New Team Member
      </p>
      <p
        className='text text__small'
        title='Sub title'>
        Search for team member below.
      </p>
    </>
  );

  // html modal content
  const content = (
    <div className='mb-4 d-flex align-items-center'>
      <GNESingleSelect
        value={selectedTeamMember}
        onChange={handleMemberSelection}
        options={teamMembers}
        placeholder='Please Select Team Member to Add'
        className={`single-select ${classes['team-member-wrapper']}`}
        formatOptionLabel={(data, { context }) =>
          formatNewMemberOptionLabel(data, { context })
        }
      />
      <GNECheckbox
        checked={isAddMemberToTeam}
        label='Primary Contact'
        wrapperCss={classes['checkbox-space']}
        onChange={onChange}
      />
    </div>
  );

  // html modal footer
  const footer = (
    <div className='d-flex justify-content-end w-100'>
      <LinkButton
        className='text text__small'
        onClick={handleCancel}>
        Cancel
      </LinkButton>
      <Button
        variant='btn btn-gne btn-outline-primary btn-gne__outline'
        disabled={selectedTeamMember?.value === undefined}
        onClick={handleSaveNew}
        className='text__small ms-3'>
        Save and New
      </Button>
      <Button
        variant='btn btn-gne btn-primary'
        disabled={selectedTeamMember?.value === undefined}
        onClick={handleSaveClose}
        className='text__small ms-3'>
        Save and Close
      </Button>
    </div>
  );

  return (
    <>
      {/* account toggle and new member button */}
      <Row className='mb-5'>
        <Col>
          <AccountTypeToggle
            accountChecked={selectedToggleOption === ACCOUNT_TYPE.account}
            siteChecked={selectedToggleOption === ACCOUNT_TYPE.site}
            handleOptionChange={handleOptionChange}
            onChangeHandler={onChangeHandler}
            isInline={true}
            priorityTabView={VIEW.teamPOC}
            isMulti={false}
          />
        </Col>
        <Col>
          <div className='d-flex justify-content-end'>
            <Button
              disabled={selectedAccount?.value === undefined}
              variant='btn btn-gne btn-outline-primary btn-gne__outline btn-gne__outline--add'
              onClick={handleNewMemberClick}>
              Add New Team Member
            </Button>
          </div>
        </Col>
      </Row>
      {/* table */}
      <ConfigurableTable
        rows={selectedAccount?.value !== undefined ? teamRows : []}
        columns={PRIORITY_TEAM_POC_TABLE_COLUMNS}
        expandRowByClick={false}
        enableColumnSelection={false}
        enableExport={false}
        showActionIcon={true}
        iconProps={{ iconCss: 'delete' }}
        onActionClickHandler={handleDelete}
        tableName='myPriorityPOC'
        isGlobalSort={false}
        pagination={false}
        basicSort={true}
        dimentions={size}
      />
      {/* add modal */}
      <GNEModal
        titlecontent={title}
        show={showModal}
        onHide={handleCancel}
        content={content}
        css='size-medium'
        footer={footer}
        backdrop='static'
      />
      {/* confirmation popup modal */}
      <Confirmation
        show={confirmModalShow}
        onOK={() => onDelete()}
        onHide={onHide}
      />
    </>
  );
};

export default TeamPOC;
