import { useState } from 'react';
import { useClickAway } from 'utils/hooks/useClickAway';

import {
  CustomDropdownIndicator,
  CustomMenuList,
  CustomValueContainer,
  handleInputChange,
} from 'utils/utils';

const ReactSelectSearch = (WrappedComponent) => {
  const WithSearch = (props) => {
    const [isFocused, setIsFocused] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const containerRef = useClickAway(() => {
      setInputValue('');
      setIsFocused(false);
    });

    return (
      <div
        ref={containerRef}
        className={props.containerCss}>
        <WrappedComponent
          {...props}
          onMenuOpen={() => {
            setInputValue('');
            props?.onMenuOpen && props?.onMenuOpen();
          }}
          onMenuClose={() => {
            setIsFocused(false);
            props?.onMenuClose && props?.onMenuClose();
          }}
          components={{
            DropdownIndicator: CustomDropdownIndicator,
            MenuList: CustomMenuList,
            ValueContainer: CustomValueContainer,
            ...props?.components,
          }}
          inputValue={inputValue}
          isSearchable={false}
          onMenuInputFocus={() => setIsFocused(true)}
          onChange={(value) => {
            props.onChange(value);
            setIsFocused(props.isMulti ?? false);
          }}
          onInputChange={(value, action) => {
            props?.onDropdownAction && props?.onDropdownAction(action?.action);
            setInputValue(handleInputChange(value, action));
          }}
          onFocus={() => {
            if (!isFocused) {
              setTimeout(() => {
                document.querySelector('.search-box')?.focus();
              });
            }
          }}
          {...{
            menuIsOpen: isFocused || undefined,
            isFocused: isFocused || undefined,
          }}
        />
      </div>
    );
  };

  return WithSearch;
};
export default ReactSelectSearch;
