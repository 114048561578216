import React from 'react';
import { Card } from 'react-bootstrap';

const ValidationMessage = () => {
  return (
    <Card className='gne-tile mt-5'>
      <Card.Body className='d-flex flex-column align-items-center justify-content-center'>
        {/* <p>
          <i className={`icon icon__oops`}></i>
        </p> */}
        <p className='fs-6 text-warning'>
          Select one product to populate Market Share metrics.
        </p>
      </Card.Body>
    </Card>
  );
};

export default ValidationMessage;
