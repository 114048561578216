import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import api from 'api';

const SESSION_IDEL_MINUTES = process.env.IDLE_SESSION_TIME_OUT || 30;

const AutoTimeOut = (props) => {
  const { id } = useSelector((state) => state.Auth.userClaimObject);

  const { ComposedClass } = props;

  const navigate = useNavigate();

  const handleOnIdle = async () => {
    if (id !== undefined && id !== '') {
      try {
        const payload = { id };
        await api.post('logout', {
          ...payload,
        });
        localStorage.clear();
        navigate('/logout');
      } catch (error) {
        localStorage.clear();
        navigate('/logout');
      }
    }
  };

  useIdleTimer({
    timeout: 1000 * 60 * SESSION_IDEL_MINUTES,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return <ComposedClass />;
};

export default AutoTimeOut;
