import React, { useRef } from 'react';
import { Card } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import Chart from './Echart';
import { formatLabel } from 'utils/utils';
import TableView from './TableView';
import Loader from 'components/shared/loader/Loader';
import { usePromiseTracker } from 'react-promise-tracker';

import classes from './Chart.module.scss';
import LinkButton from 'components/shared/button/LinkButton';
import AdvanceFilter from './AdvanceFilter';
import { useState } from 'react';
import { loadDropdownOptions } from './chart.service';
import { useEffect } from 'react';
import NoDimension from 'components/shared/no-dimension/NoDimensions';
import {
  COMPARE_BENCHMARK,
  COMPARE_TIME_PERIOD,
  DIMENSION,
  COMPARE_KPI,
} from 'constants/constants';
import {
  setAdvanceOptions,
  setSelectedCardView,
  setAdvanceButtonStatus,
} from 'store/home/homeMyViewSlice';
import {
  setSelectedCardView as customerSetSelectedCardView,
  setAdvanceOptions as setInsightAdvanceOptions,
} from 'store/customer/customerViewSlice';

const ChartAndTableView = ({ page, timespan, onResetClicked }) => {
  const dispatch = useDispatch();

  const reducerName = page === 'My View' ? 'HomeMyView' : 'CustomerView';
  const isMyView = page === 'My View' ? true : false;

  const view = useSelector((state) => {
    const selectedCardView = state?.[reducerName]?.selectedCardView;
    return selectedCardView || 'chart';
  });

  const noDimensionValue = useSelector(
    (state) => state?.[reducerName]?.noDimensionValue
  );
  const benchmarkNoDimensionValue = useSelector(
    (state) => state?.[reducerName]?.benchmarkNoDimensionValue
  );
  const kpiName = useSelector(
    (state) => state?.[reducerName]?.selectedKPI?.KpiName
  );
  const chartData = useSelector((state) => state?.[reducerName]?.chartData);
  const dimensionName = useSelector(
    (state) => state?.[reducerName]?.dimensionName
  );
  const selectedDimensionId = useSelector(
    (state) => state?.[reducerName]?.dimesnionId
  );
  const advanceButtonValue = useSelector(
    (state) => state?.HomeMyView?.advanceButtonValue
  );

  const showChart =
    chartData?.values[0] !== null &&
    chartData?.values[0] !== undefined &&
    dimensionName !== 'No Dimension';

  const isColor =
    kpiName?.slice(-3) === '(%)' || kpiName?.toLowerCase().includes('change');

  const isValue = noDimensionValue > 0;
  const noData = chartData.values?.length === 0 && !noDimensionValue;

  const chartType = useSelector((state) => state?.[reducerName]?.chartType);

  const { promiseInProgress } = usePromiseTracker();

  const selectedTimePeriod = useSelector(
    (state) => state?.[reducerName]?.selectedTimePeriod
  );

  const [showAdvanceOption, setShowAdvanceOption] = useState(false);

  const selectedKPI = useSelector((state) => state?.[reducerName]?.selectedKPI);
  const selectedAdvanceOptions = useSelector(
    (state) => state?.[reducerName]?.advanceOptions
  );
  const compareByType = useSelector(
    (state) => state?.[reducerName]?.advanceOptions?.compareBy
  );

  const firstRenderTimePeriod = useRef(false);

  const handleAdvanceClick = (e) => {
    setShowAdvanceOption(!showAdvanceOption);
    dispatch(
      setAdvanceButtonStatus(
        advanceButtonValue === false && e?.type === 'click' ? true : false
      )
    );
  };
  const handleReset = () => {
    onResetClicked();
    loadDropdownOptions(
      DIMENSION,
      dispatch,
      selectedKPI,
      selectedTimePeriod,
      dimensionName,
      reducerName
    );

    dispatch(
      isMyView
        ? setAdvanceOptions({ compareBy: DIMENSION, selectDropdownOption: '' })
        : setInsightAdvanceOptions({
            compareBy: DIMENSION,
            selectDropdownOption: '',
          })
    );
  };

  const handleChartViewClicked = (cardView) => {
    if (reducerName === 'HomeMyView') {
      dispatch(setSelectedCardView(cardView));
    } else {
      dispatch(customerSetSelectedCardView(cardView));
    }
  };

  //on opening of advance button
  useEffect(() => {
    dispatch(setAdvanceButtonStatus(false));
  }, []);

  //on change of primary dimension
  useEffect(() => {
    if (showAdvanceOption && selectedTimePeriod.length != 0) {
      dispatch(
        isMyView
          ? setAdvanceOptions({ compareBy: DIMENSION })
          : setInsightAdvanceOptions({ compareBy: DIMENSION })
      );

      loadDropdownOptions(
        DIMENSION,
        dispatch,
        selectedKPI,
        selectedTimePeriod,
        dimensionName,
        reducerName
      );

      dispatch(setAdvanceButtonStatus(false));
      handleAdvanceClick();
    }
  }, [selectedDimensionId]);

  //on change of primary timee-period
  useEffect(() => {
    if (selectedTimePeriod.length !== 0) {
      if (firstRenderTimePeriod.current) {
        if (
          compareByType === COMPARE_TIME_PERIOD ||
          compareByType === COMPARE_BENCHMARK ||
          compareByType === COMPARE_KPI
        ) {
          let options = {
            ...selectedAdvanceOptions,
            selectDropdownOption: '',
          };

          dispatch(
            isMyView
              ? setAdvanceOptions(options)
              : setInsightAdvanceOptions(options)
          );

          loadDropdownOptions(
            compareByType,
            dispatch,
            selectedKPI,
            selectedTimePeriod,
            dimensionName,
            reducerName
          );
        }
      }
      firstRenderTimePeriod.current = true;
    }
  }, [selectedTimePeriod?.label]);

  return (
    <Card className={classes['chart-container']}>
      <Card.Body>
        <Card.Subtitle>
          <div className={classes['chart-title']}>
            <label className='sub-title sub-title__x-small me-2 text text__grey'>
              {timespan}
            </label>
            <>
              {showChart && !noData && (
                <LinkButton
                  onClick={() => handleChartViewClicked('chart')}
                  className='d-inline-block p-0 mt-1'
                  disabled={compareByType === COMPARE_KPI}>
                  <i
                    className={`icon icon__chartView${
                      view === 'chart' ? '--active' : ''
                    }  ms-2 border-0`}></i>
                </LinkButton>
              )}
              {showChart && (
                <LinkButton
                  onClick={() => handleChartViewClicked('table')}
                  className='d-inline-block p-0 mt-1'>
                  <i
                    className={`icon icon__tableView${
                      view === 'table' ? '--active' : ''
                    } border-0`}></i>
                </LinkButton>
              )}
              {showChart && (
                <LinkButton
                  onClick={() => handleChartViewClicked('hash')}
                  className='d-inline-block p-0 mt-1'
                  disabled={
                    selectedAdvanceOptions?.selectDropdownOption?.label ||
                    compareByType === COMPARE_KPI
                      ? true
                      : false
                  }>
                  <i
                    className={`icon icon__hashtag${
                      view === 'hash' ? '--active' : ''
                    }  border-0`}></i>
                </LinkButton>
              )}
              <LinkButton
                className={`text text__x-small float-right mt-1 ms-1 
                  ${
                    (selectedAdvanceOptions?.selectDropdownOption?.label &&
                      selectedAdvanceOptions?.selectDropdownOption?.label
                        ?.length !== 0) ||
                    selectedAdvanceOptions?.selectDropdownOption?.length > 0
                      ? ''
                      : 'disabled'
                  }`}
                onClick={handleReset}
                disabled={
                  (selectedAdvanceOptions?.selectDropdownOption?.label &&
                    selectedAdvanceOptions?.selectDropdownOption?.label
                      ?.length !== 0) ||
                  selectedAdvanceOptions?.selectDropdownOption?.length > 0
                    ? false
                    : true
                }>
                {
                  //Reset Icon
                  (selectedAdvanceOptions?.selectDropdownOption?.label &&
                    selectedAdvanceOptions?.selectDropdownOption?.label
                      ?.length !== 0) ||
                  selectedAdvanceOptions?.selectDropdownOption?.length > 0 ? (
                    <i className='icon icon__reset mb-1 '></i>
                  ) : (
                    <i className='icon icon__reset-disabled mb-1'></i>
                  )
                }
                Reset
              </LinkButton>
              <Button
                variant={`btn btn-gne btn-secondary text text__x-small float-right pb-1 pt-1 me-2 w-15 ${
                  showAdvanceOption ? 'active' : ''
                } ${classes.configure} `}
                onClick={(e) => {
                  handleAdvanceClick(e);
                }}>
                <i
                  className={`pt-1 icon ${
                    showAdvanceOption
                      ? 'icon__configure-advance-active'
                      : 'icon__configure-advance'
                  }`}></i>
                Advanced
              </Button>
              {showAdvanceOption && <AdvanceFilter reducerName={reducerName} />}
            </>
          </div>
        </Card.Subtitle>

        <div className={`mt-2 ${classes['table-chart-container']}`}>
          {view !== 'table' && showChart && (
            <Chart
              chartData={chartData}
              kpiName={kpiName}
              chartType={chartType}
              hashView={view === 'hash'}
            />
          )}

          {!showChart && (
            <>
              {promiseInProgress ? (
                <Loader component='chart' />
              ) : (
                !isNaN(noDimensionValue) &&
                noDimensionValue !== '' &&
                dimensionName === 'No Dimension' && (
                  <NoDimension
                    isColor={isColor}
                    noDimensionValue={noDimensionValue}
                    benchmarkNoDimensionValue={benchmarkNoDimensionValue}
                    kpiName={kpiName}
                    isValue={isValue}
                  />
                )
              )}
            </>
          )}

          {view === 'table' && showChart && (
            <div className={`mt-4 ${classes['table-container']}`}>
              <TableView
                data={chartData}
                kpiName={kpiName}
                dimensionName={dimensionName}
                chartType={chartType}
                page={'chart'}
              />
            </div>
          )}

          {noData && !promiseInProgress && (
            <i className='icon icon__no-data'></i>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default ChartAndTableView;
