import React, { useState } from 'react';
import { Table, Checkbox, Popover } from 'antd';
import styles from './ConfigurableTable.module.scss';
import { exportToExcel } from 'utils/utils';
import LinkButton from 'components/shared/button/LinkButton';
import classes from 'components/customer/engagements/Timeline.module.scss';
import { Fade } from 'react-awesome-reveal';
import { useDispatch, useSelector } from 'react-redux';
import { exportData } from 'store/customer/customer-view-actions';

import {
  setHcpFlag,
  setPayerMixCustomColumns,
} from 'store/customer/customerViewSlice';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
const ConfigurableTable = ({
  rows,
  columns,
  expandRowByClick,
  enableColumnSelection,
  enableExport,
  exportFileName,
  showActionIcon,
  iconProps,
  onActionClickHandler,
  tableName,
  isGlobalExport,
  pagination,
  isGlobalSort,
  onSort,
  timePeriod,
  basicSort,
  tableType,
  filterExp,
  dimentions,
}) => {
  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((column) => {
      return column.isMandatory === 'Y' && column.key;
    })
  );
  const dimensions = useSelector(
    (state) => state?.CustomerView?.payerCustomColumns
  );
  const affiliationExpression = useSelector(
    (state) => state?.CustomerView?.affiliationsExpression
  );

  const [popoverVisible, setPopoverVisible] = useState(false);
  const [activeExpRow, setActiveExpRow] = React.useState();
  const qlikToken = localStorage.getItem('qlikToken');
  const qlikCookie = localStorage.getItem('qlikCookie');
  const selectedAccount = useSelector(
    (state) => state?.CustomerView?.selectedAccount
  );
  const siteExpression = useSelector(
    (state) => state?.CustomerView?.siteExpression
  );
  const excludeColumnFromExcel = useSelector(
    (state) => state?.CustomerView?.excelColumnsRestiction
  );

  const productMixExpression = useSelector(
    (state) => state?.CustomerView?.productMixExpression
  );

  const payerExpression = useSelector(
    (state) => state?.CustomerView?.payerExpression
  );
  
  const copayExpression = useSelector(
    (state) => state?.CustomerView?.copayCustomColumn
  );
  

  const hcpFlag = useSelector((state) => state?.CustomerView.hcpFlag);

  const dispatch = useDispatch();
  const [payerColumns, setPayerColumn] = useState([
    'ParentPayer',
    'Payer',
    'BookOfBusiness',
  ]);
  // dispatch(setPayerMixCustomColumns(selectedColumns));

  const handleColumnSelection = (checkedValues) => {
    setSelectedColumns(checkedValues);
  };

  const handlePopoverVisibleChange = (visible) => {
    setPopoverVisible(visible);
  };

  const filteredColumns = columns.filter((column) =>
    selectedColumns.includes(column.key)
  );

  const exportTableData = () => {
    if (tableName === 'Affiliation') {
      dispatch(
        exportData(
          tableName,
          selectedAccount?.value,
          qlikCookie,
          qlikToken,
          hcpFlag,
          affiliationExpression
        )
      );
    } else if (tableName === 'SitePerformance') {
      dispatch(
        exportData(
          tableName,
          selectedAccount?.value,
          qlikCookie,
          qlikToken,
          siteExpression,
          timePeriod,
          '',
          '',
          excludeColumnFromExcel
        )
      );
    } else if (tableName === 'ProductMix') {
      dispatch(
        exportData(
          tableName,
          selectedAccount?.value,
          qlikCookie,
          qlikToken,
          productMixExpression,
          timePeriod
        )
      );
    } else if (tableName === 'PayerMix') {
      dispatch(
        exportData(
          tableName,
          selectedAccount?.value,
          qlikCookie,
          qlikToken,
          payerExpression,
          undefined,
          dimensions
        )
      );
    }else if (tableName === 'CopayAnalytics') {
      dispatch(
        exportData(
          tableName,
          selectedAccount?.value,
          qlikCookie,
          qlikToken,
          copayExpression,
          undefined,
          
        )
      );
    }  else if (tableName === 'SiteAffiliation') {
      dispatch(
        exportData(
          tableName,
          selectedAccount?.value,
          qlikCookie,
          qlikToken,
          null,
          null,
          null,
          affiliationExpression
        )
      );
    }
  };

  const handleCheckboxChange = (event) => {};

  const columnSelectionPanel = (
    <Checkbox.Group
      defaultValue={selectedColumns}
      onChange={handleColumnSelection}
      className={styles.checkboxGroup}>
      {columns.map((column) => (
        <div
          className={styles.checkboxWrapper}
          key={column.id}>
          <Checkbox
            className={styles.checkbox}
            key={column.key}
            value={column.key}
            disabled={column.key === 'percentage_life_covered'}
            onChange={handleCheckboxChange}>
            {column.title}
          </Checkbox>
        </div>
      ))}
    </Checkbox.Group>
  );

  const customColumns = [
    ...filteredColumns,
    {
      title: () =>
        enableColumnSelection && (
          <Popover
            content={popoverContent}
            trigger='click'
            open={popoverVisible}
            onOpenChange={handlePopoverVisibleChange}>
            <span className='d-flex'>
              <i className={`icon icon__affiliations_standardTable_settings`} />{' '}
            </span>
          </Popover>
        ),
      dataIndex: 'columnSelection',
      fixed: 'right',
      width: 65,
      key: 'columnSelection',
      render: () => null,
    },
    {
      title: () =>
        enableExport && (
          <span
            className='d-flex'
            onClick={() =>
              isGlobalExport
                ? exportTableData()
                : exportToExcel(rows, columns, exportFileName)
            }>
            <i className={`icon icon__affiliations_standardTable_excel`} />{' '}
          </span>
        ),
      dataIndex: 'columnSelection',
      key: 'columnSelection',
      render: () => null,
    },
  ];

  const customColumnsWithoutSelection = [
    ...filteredColumns,
    {
      title: () =>
        enableExport && (
          <span
            className='d-flex'
            onClick={() =>
              isGlobalExport
                ? exportTableData()
                : exportToExcel(rows, columns, exportFileName)
            }>
            <i className={`icon icon__affiliations_standardTable_excel`} />{' '}
          </span>
        ),
      dataIndex: 'columnSelection',
      width: 50,
      fixed: iconProps?.iconCss === 'view' ? 'right' : 'none',
      key: 'columnSelection',
      render: (_, record) =>
        showActionIcon && (
          <>
            <LinkButton
              className={`ms-0 me-0 ps-0 d-inline-block ${classes['icon-wrapper']}`}
              onClick={() => onActionClickHandler(record)}>
              {iconProps?.iconCss === 'e-mail' ? (
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip bsclass='gne-tooltip tooltip--left '>
                      <span className='text text__x-small'>
                        {`Click here to email the POC`}
                      </span>
                    </Tooltip>
                  }>
                  <i className={`m-0 icon icon__${iconProps?.iconCss}`}></i>
                </OverlayTrigger>
              ) : (
                <i className={`m-0 icon icon__${iconProps?.iconCss}`}></i>
              )}
            </LinkButton>
          </>
        ),
    },
  ];

  const popoverContent = (
    <div className={styles.customPopup}>{columnSelectionPanel}</div>
  );

  const CustomExpandIcon = ({ expanded, onExpand, record }) => {
    return expandRowByClick && record?.children && record?.children?.length ? (
      <span
        className={`${styles.expandIcon} ant-table-row-expand-icon`}
        style={{ paddingRight: '10px' }}>
        {!expanded ? (
          <i className={`icon icon__affiliations_pivotTable_expand`} />
        ) : (
          <i className={`icon icon__affiliations_pivotTable_collapse`} />
        )}
      </span>
    ) : null;
  };
  const expandableConfig = {
    expandRowByClick: expandRowByClick,
    indentSize: 0,
    rowExpandable: (record) =>
      expandRowByClick === true && record.children?.length > 0,
    expandIcon: CustomExpandIcon,
    expandedRowKeys: activeExpRow,
    onExpand: (expanded, record) => {
      const keys = [];
      if (expanded) {
        keys.push(record.id);
      }
      setActiveExpRow(keys);
    },
  };

  const modifiedRows = rows?.map((item, index) => ({
    ...item,
    key: index + 1,
    id: index + 1,
  }));

  const paginationConfigs = pagination
    ? {
        ...pagination,
        itemRender: (page, type, originalElement) => {
          if (type === 'next') {
            return (
              <button
                className={styles.nextButton}
                disabled={false}>
                Next
              </button>
            );
          } else if (type === 'prev') {
            return (
              <button
                className={styles.nextButton}
                disabled={false}>
                Previous
              </button>
            );
          }
          return originalElement;
        },
      }
    : pagination;

  const handleTableChange = (pagination, filters, sorter) => {
    if (isGlobalSort && sorter && sorter.column) {
      const { dataIndex } = sorter.column;
      onSort(dataIndex, sorter.order);
    } else {
      onSort();
    }
  };

  return (
    <Fade cascade>
      <Table
        dataSource={modifiedRows}
        columns={
          enableColumnSelection ? customColumns : customColumnsWithoutSelection
        }
        className='custom-table gne-table engagement-table'
        expandable={expandableConfig}
        pagination={paginationConfigs}
        scroll={
          dimentions
            ? { x: dimentions?.x, y: dimentions?.y }
            : { x: 1000, y: 400 }
        }
        onChange={!basicSort ? handleTableChange : null}
      />
    </Fade>
  );
};
export default ConfigurableTable;
