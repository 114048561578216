import api from 'api';
import { setQlikToken } from './Authslice';
import { setQlikAuth } from 'services/qlik.service';

export const fetchQlikToken = (userId) => {
  return async (dispatch) => {
    const fetchToken = async () => {
      const response = await api.get(`qlik/token?userId=${userId}`);

      if (!response.statusText === 'OK') {
        throw new Error('Could not get token');
      }

      const data = await response.data;
      return data;
    };

    try {
      // gets the qlik jwt token and set it in the store
      const response = await fetchToken();

      setQlikAuth(response?.token, response?.cookie);

      dispatch(
        setQlikToken({ qlikToken: response.token, qlikCookie: response.cookie })
      );
    } catch (error) {
      console.log(error.message);
    }
  };
};
