import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

import classes from './SearchBar.module.scss';

const SearchBar = ({ data, filterKey, onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);

    if (e.target.value === '') {
      onSearch(data);
    } else {
      const filteredData = data.filter((item) =>
        item[filterKey].toLowerCase().includes(searchTerm.toLowerCase())
      );
      onSearch(filteredData);
    }
  };

  return (
    <div className={classes['search-container']}>
      <Form.Control
        type='text'
        placeholder='Search'
        value={searchTerm}
        onChange={handleSearch}
      />
      <i className={`icon icon__search ${classes['search-icon']}`}></i>
    </div>
  );
};

export default SearchBar;
