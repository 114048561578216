import moment from 'moment';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
// import { Popconfirm } from 'antd';
// import { handleDelete } from 'components/customer/account-plan/AccountDetails';

const documentTooltip = (description) => {
  return (
    <Tooltip className='gne-tooltip tooltip__black'>
      <span className='text text__x-small'>{description}</span>
    </Tooltip>
  );
};
export const HOME_MARKETING_FILTERS = 'HomeMarketingFilters';
export const INSIGHT_MARKETING_FILTERS = 'InsightMarketingFilters';
export const INSIGHTS_DEFAULT_TEXT =
  'Navigate to a customer view and select “Add to My Insights” to populate a list of recent insight boards';
export const FAVORITE_DASHBOARD_DEFAULT_TEXT =
  'Select a dashboard in the navigation to populate a list of recent dashboards.';
export const EXPRESSION_PLACEHOLDER_TEXT = 'vReplaceString';
export const FILTER_PLACE_HOLDER = 'vAllFilters';
export const ACCOUNT_PLACE_HOLDER = 'vAllPriorityAccounts';
export const PING_FEDERATE_URL = `https://${process.env.REACT_APP_WEB_ENV}.roche.com/as/authorization.oauth2?response_type=code&client_id=analyticsHub&scope=openid+profile&redirect_uri=${process.env.REACT_APP_WEB_HOST}/authenticate`;

export const CUSTOMER_VIEW_ADD_TO_INSIGHTS = 'Add to Insight Boards';
export const CUSTOMER_VIEW_UPDATE_TO_INSIGHTS = 'Update Insight Board';
export const CUSTOMER_VIEW_TEXT = 'Customer View - ';
export const CUSTOMER_VIEW_SHARE_IB = 'ShareInsightBoard';
export const ADVANCE_FILTER = 'AdvanceFilter';

export const REPLACE_STRING = 'vReplaceString';
export const DATE_FORMAT = 'MMM D, yyyy';
export const DATE_FORMAT_COMMA = 'll';
export const DEFAULT_DATE_FORMAT = 'dddd MMM DD YYYY hh:mm:ss';
export const DEFAULT_DATE_FORMAT_2 = 'dddd MMM D YYYY hh:mm:ss';
export const FILTER_DATE_FORMAT = 'MM/DD/YYYY';
export const FILTER_DATE_FORMAT_2 = 'M/D/YYYY';
export const NULL_STRING = '--';
export const CHART_UPDATE_MESSAGE = 'Chart updated successfully';
export const KPI_UPDATE_MESSAGE =
  'Please update the title of this KPI (key performance indicator) tile if needed';
export const CHART_ADD_MESSAGE = 'Chart added successfully';
export const CHART_LIMIT_ERROR = 'Cannot add more than 20 cards to My View';
export const ADD_TO_MY_VIEW_MESSAGE = 'The tile has been added to My View.';
export const DUPLICATE_INSIGHT_VIEW_MESSAGE =
  ' The tile has been duplicated and added to the Insight Board.';
export const DUPLICATE_TO_MY_VIEW_MESSAGE =
  'The tile has been duplicated and added to My View.';
export const ACTION_COPY = 'copy';
export const ACTION_ADD_TO_MY_VIEW = 'addToMyView';
export const COPY_TITLE_PREFIX = 'Copy of ';
export const SITE_PERFORMANCE_FILTER_NAME = 'Site';
export const SITE_UTILIZATION_FILTER_NAME = 'SiteUtilization';
export const PAYER_MIX_FILTER_NAME = 'Payer';
export const COPAY_ANALYTICS_FILTER_NAME = 'CopayAnalytics';
export const AFFILATION_FILTER_NAME = 'Affilation';
export const ACCOUNT_PAYER_MIX_FILTER_NAME = 'AccountPayer';
export const PRODUCT_MIX_FILTER_NAME = 'ProductMix';
export const CONFIGURE_INSIGHTS_FILTER_NAME = 'configureInsights';
export const DEFAULT_MESSAGE =
  'Please select an Account to view the hierarchy. An Account could be a corporate parent or a sub-account that rolls up to a corporate parent. Please select the Site radio button if you are looking for specific sites of care.';
export const DEFAULT_MESSAGE_SITE =
  'Please select Site of Care to view the hierarchy it belongs to. If you are looking for a corporate parent or a sub-account that rolls up to a corporate parent, please select the Account radio button.';

export const INSIGHTS_CHART_LIMIT_ERROR =
  'Cannot add more than 12 cards to insights';
export const INSIGHTS_BOARD_ACCOUNT_PLACEHOLDER_TEXT =
  'Please Select Account to View';
export const INSIGHTS_BOARD_SITE_PLACEHOLDER_TEXT =
  'Please Select Site to View';
export const CARD_POISTIONS_UPDATED_MESSAGE = 'Positions updated successfully.';
export const CARD_POISTIONS_ERROR_MESSAGE =
  'Positions not updated! Some error occured';
export const ADD_SITE_SUBTEXT =
  'Search for sites below to change its hierarchy.';
export const REMOVE_SITE_SUBTEXT =
  'Search for site(s) below to remove them from the hierarchy.';
export const EDIT_340B_SUBTEXT =
  'Search for site(s) below to update 340B contract information.';
export const NO_SITE_SELECTED = 'No Site Selected';
export const NO_SITE_SELECTED_SUBTEXT = 'Please use the filters to view sites';
export const ALL = 'All';
export const FILTER_MESSAGE =
  'This account has a large dataset. Consider filtering if you plan to export it to a PDF file';
export const PATHWAY_URL =
  'https://drive.google.com/drive/folders/191ZWMnQ4SUvJg7d6scNpXHxOE0th_HLq?pli=1';
export const PRP_ENOTICE = 'PRP eNotices';
export const areas = {
  bannerAccount: 'banner-account',
  bannerInteraction: 'banner-interaction',
  bannerUses: 'banner-uses',
  bannerNPS: 'banner-nps',
  bannerSat: 'banner-sat',
  bannerPartner: 'banner-partner',
  bannerSegment: 'banner-segment',
  home: 'home-area',
  customerViewList: 'view-list',
  customerView: 'customer-view',
  trends: 'trends-view',
  customerViewLoader: 'customer-loader',
  timeline: 'timeline-view',
  defaultView: 'default-view',
};
export const CONFIGURE_VIEW = {
  insights: 'Insights',
  myView: 'my view',
  myDefault: 'my default',
};

export const QLIK_DATATYPE = {
  single: 'single',
  chartBar: 'bar',
  chartPie: 'pie',
  payerPie: 'payerPie',
  contractPie: 'contractPie',
  multiObject: 'object',
};

export const ACCOUNT_TYPE = {
  account: 'account',
  shared: 'shared',
  site: 'site',
  corp: 'corporate parent',
  os: 'owner subsidiary',
};

export const VIEW = {
  trends: 'trends',
  myPriority: 'myPriority',
  teamPOC: 'teamPOC',
  edit: 'edit',
  add: 'add',
};

export const VIEW_TEXT = {
  updateInsights: 'Update Insights',
  addInsights: 'Add to Insights',
  addMyView: 'Add To My View',
  updateMyView: 'Update My View',
};

export const TILE_TEXT = {
  nineTiles: 'You can add up to 12 tiles to display',
  twentyTiles: 'You can add up to 20 tiles to display',
};

export const ORG_ACTIONS = {
  add: 'add',
  remove: 'remove',
  move: 'move',
  create: 'create',
  assign: 'assign',
  comments: 'comments',
  filter: 'filter',
  info: 'info',
  edit340: 'edit',
  contractsegment: 'contractsegment',
};

export const FLAG = {
  site: [2, 3],
  account: 1,
  filter: 'filter',
};

export const optionsInitialState = {
  ecosystem: [],
  siteName: [],
  city: [],
  state: [],
  zip: [],
  class: [],
  label: [],
  contract_segment: [],
  hin: [],
  dea: [],
  program_type: [],
  stakeHolderCounts: '',
  grossSales: '',
  squad: [],
  product: [],
};

export const optionsInitialStateOffset = {
  ecosystem: 0,
  siteName: 0,
  city: 0,
  state: 0,
  zip: 0,
  class: 0,
  label: 0,
  contract_segment: 0,
  hin: 0,
  dea: 0,
  program_type: 0,
  squad: 0,
  product: 0,
};

export const optionsInitialStateDefault = {
  ecosystem: false,
  siteName: false,
  city: false,
  state: false,
  zip: false,
  class: false,
  label: false,
  contract_segment: false,
  hin: false,
  dea: false,
  program_type: false,
  squad: false,
  product: false,
};
export const ACTION_TYPE = {
  node: 'actions-node',
  filter: 'actions',
  addTo: 'addTo',
  moveSite: 'moveSite',
  addSite: 'addSites',
  reason: 'reason',
  info: 'info',
  orgFilter: 'orgFilter',
  add: 'add',
  move: 'move',
  remove: 'remove',
};

export const SITE_PERFORMANCE_EXPRESSION = {
  expression: `Date(AddMonths(Date#(max({1<[Latest Month Flag]={1}>} Date((Date#([Month Year],'YYYYMMDD')),'MM''YYYY')),'MM''YYYY'),-(11)),'MMM''YY')&' - ' & Date(Date#(max({1<[Latest Month Flag]={1}>} Date((Date#([Month Year],'YYYYMMDD')),'MM''YYYY')),'MM''YYYY'),'MMM''YY')`,
  appId: process.env.REACT_APP_EABI_APP_ID,
};

export const CRM_EXPRESSIONS = {
  quarters: `Only({<curr_qtr={"C"}, vReplaceString>}[Quarter Year])`,
  month: `Date(min({<curr_qtr={"C"}>}Date), 'MMM''YY') & ' - ' & Date(max({<curr_qtr={"C"}>}Date), 'MMM''YY')`,
  nps: {
    score: 'Max({<curr_qtr={"C"}, vReplaceString>}relationship_nps)',
    percent: `
    if(IsNull( max({<curr_qtr={'C'}, vReplaceString>}relationship_nps)),'-',(max({<curr_qtr={'C'}, vReplaceString>}relationship_nps)-max({<curr_qtr={'P'}, vReplaceString>}relationship_nps)))
    `,
  },
  nps_site: {
    score: 'Max({<curr_qtr={"C"}, vReplaceString>}site_relationship_nps)',
    percent: `
  if(IsNull( max({<curr_qtr={'C'}, vReplaceString>}site_relationship_nps)),'-',(max({<curr_qtr={'C'}, vReplaceString>}site_relationship_nps)-max({<curr_qtr={'P'}, vReplaceString>}site_relationship_nps)))
  `,
  },
  sat: {
    score:
      'Max({<curr_qtr={"C"}, vReplaceString>}post_interaction_overall_sat)',
    percent: `
    if(IsNull( max({<curr_qtr={'C'}, vReplaceString>}post_interaction_overall_sat)),'-',(max({<curr_qtr={'C'}, vReplaceString>}post_interaction_overall_sat)-max({<curr_qtr={'P'}, vReplaceString>}post_interaction_overall_sat)))
    `,
  },
  sat_site: {
    score:
      'Max({<curr_qtr={"C"}, vReplaceString>}site_post_interaction_overall_sat)',
    percent: `
    if(IsNull( max({<curr_qtr={'C'}, vReplaceString>}site_post_interaction_overall_sat)),'-',(max({<curr_qtr={'C'}, vReplaceString>}site_post_interaction_overall_sat)-max({<curr_qtr={'P'}, vReplaceString>}site_post_interaction_overall_sat)))
    `,
  },
  partner: {
    score:
      'Max({<curr_qtr={"C"}, vReplaceString>}relationship_trusted_partner)',
    percent: `
    if(IsNull( max({<curr_qtr={'C'}, vReplaceString>}relationship_trusted_partner)),'-',(max({<curr_qtr={'C'}, vReplaceString>}relationship_trusted_partner)-max({<curr_qtr={'P'}, vReplaceString>}relationship_trusted_partner)))
    `,
  },
  partner_site: {
    score:
      'Max({<curr_qtr={"C"}, vReplaceString>}site_relationship_trusted_partner)',
    percent: `
    if(IsNull( max({<curr_qtr={'C'}, vReplaceString>}site_relationship_trusted_partner)),'-',(max({<curr_qtr={'C'}, vReplaceString>}site_relationship_trusted_partner)-max({<curr_qtr={'P'}, vReplaceString>}site_relationship_trusted_partner)))
    `,
  },
  lineChart: {
    nps: {
      expression: 'Max({< vReplaceString>}relationship_nps)',
    },
    sat: {
      expression: 'Max({<vReplaceString>}post_interaction_overall_sat)',
    },
    partner: {
      expression: 'Max({<vReplaceString>}relationship_trusted_partner)',
    },
    dimension: 'Quarter Year',
  },
  lineChart_site: {
    nps: {
      expression: 'Max({< vReplaceString>}site_relationship_nps)',
    },
    sat: {
      expression: 'Max({<vReplaceString>}site_post_interaction_overall_sat)',
    },
    partner: {
      expression: 'Max({<vReplaceString>}site_relationship_trusted_partner)',
    },
    dimension: 'Quarter Year',
  },
  surveys: {
    nps: 'Count({< curr_qtr={"C"}, Engagement={"VOC Survey Response"},tableflag={"medallia_field_relationship_daily"}, vReplaceString>} distinct ID)',
    sat: 'Count({< curr_qtr={"C"}, Engagement={"VOC Survey Response"},tableflag={"medallia_field_post_interaction_commercial_medical_daily"}, vReplaceString>} distinct ID)',
    partner:
      'Count({< curr_qtr={"C"}, Engagement={"VOC Survey Response"},tableflag={"medallia_field_relationship_daily"}, vReplaceString>} distinct ID)',
  },
  appId: process.env.REACT_APP_CRM_APP_ID,
};
export const BEAM_EXPRESSIONS = {
  contractSegment: {
    expression: `Sum({<curr_qtr={"C"}, segment_flag={1}, vReplaceString>}sales_amt)`,
    dimension: 'rpt_segment_name',
  },
  quarter: 'Only({<curr_qtr={"C"}, vReplaceString>}[Quarter Year])',
  affilliated: 'maxstring({<vReplaceString>}[GPO NAME])',
  appId: process.env.REACT_APP_BEAM_APP_ID,
};

export const BEAM_EXPRESSIONS_SITE = {
  contractSegment: {
    expression: `Sum({<curr_qtr={"C"}, segment_flag={1}, vReplaceString>}sales_amt)`,
    dimension: 'rpt_segment_name',
  },
  contractSegmentRebate: {
    expression: `Sum({<curr_qtr={"C"}, segment_flag={1}, vReplaceString>}rebate)`,
    dimension: 'rpt_segment_name',
  },
  contractSegmentDiscount: {
    expression: `Sum({<curr_qtr={"C"}, segment_flag={1}, vReplaceString>}discount)`,
    dimension: 'rpt_segment_name',
  },
  contractSegmentMembership: {
    expression: `MaxString({<vReplaceString>}Membership)`,
    dimension: 'rpt_segment_name',
  },
  quarter: 'Only({<curr_qtr={"C"}, vReplaceString>}[Quarter Year])',
  affilliated: 'maxstring({<vReplaceString>}[GPO NAME])',
  appId: process.env.REACT_APP_BEAM_APP_ID,
};

export const Affiliated_Expression = {
  affilliated: {
    expression: `Only({<vReplaceString>}corp_mdm_id), Only({<vReplaceString>}Account`,
    dimension: 'site_mdm_id',
  },
  OSAffilliated: {
    expression: `Only({<Account_ID={vReplaceString}>}OS_Parent_ID), Only({<Account_ID={vReplaceString}>}OS_Parent_Name)`,
    dimension: 'Account_ID',
  },
  appId: process.env.REACT_APP_AFFILIATION_APP_ID,
};

export const PAYER_EXPRESSIONS = {
  payerMix: {
    expression:
      '100*Sum({< vReplaceString>}drg_lives_count)/Sum(Total {< vReplaceString>}drg_lives_count)',
    dimension: 'ParentPayer',
  },
  AccountPayer: {
    expression:
      '100*(Sum({<vReplaceString>}sales_amount)/Sum({<vReplaceString>}total sales_amount))',
    dimension: 'Parent_Payer',
    timeSlotExpression: `date(AddMonths( Max(date_year_month_ims),-11),'MMM YY')&' - '&date(Max(date_year_month_ims),'MMM YY')`,
    tableTimeSoltExpression: `date(AddMonths( Max(date_year_month_ims),-11),'MMM YY')&' - '&date(Max(date_year_month_ims),'MMM YY')`,
  },
  dataType: 'pie',
  limit: 6,
  appId: process.env.REACT_APP_PAYER_ID,
  other: true,
};

export const USES = {
  expression: `Only({<vReplaceString>}[Parent Organization])`,
  dimension: 'Account',
  appId: process.env.REACT_APP_PATHWAYS_APP_ID,
};

export const PAYERMIX_EXPRESSION = {
  expression: ``,
  dimension: ``,
};

export const ENGAGEMENT_TYPE = {
  internal: 'Internal',
  external: 'External',
};
export const ENGAGEMENTS = {
  phone: 'Phone',
  email: 'Email',
  inPerson: 'In Person (F2F)',
  video: 'Video',
  houseEmail: 'House Email',
  surveySent: 'VOC Survey Sent',
  surveyResponse: 'VOC Survey Response',
  medicalInquiry: 'Medical Inquiry',
  accessSolutions: 'Access Solutions - Enrollment',
  message: 'Message',
  vae: 'VAE',
  pact: 'PACT - Enrollment',
  prpeNotices: PRP_ENOTICE,
};
export const HOUSE_EMAIL_STATUS = {
  delivered: 'DELIVERED',
  clicked: 'DELIVERED CLICKED',
  opened: 'DELIVERED OPENED',
};

export const AFFILIATION_INFO_EXPRESSIONS = {
  expression: 'Count({<vReplaceString>} distinct hcp_outlet_mdm_id)',
  AFFILIATION_QLIK_APP_ID: process.env.AFFILIATION_APP_ID,
  ENGAGEMENT_COUNT: `Count({<Date ={">=$(=Date(Today()-90, 'MM/DD/YYYY'))"},  Site_ID={"vReplaceString"}>} distinct ID)`,
  GROSS_SALES: `sum({<R12={"C"},brand_flag={1}, Site_ID={"vReplaceString"}>}[GNE 867 Dollar Sales])`,
};

export const ENGAGEMENT_EXPRESSIONS = {
  details: {
    expression: 'Count({<vReplaceString>} distinct ID)',
    dimensions: [
      'Source',
      'Engagement',
      'Engagement Type',
      'Date',
      'ID',
      'GNE User Name',
      'GENE User Role',
      'Person Stakeholder Name',
      'Person Stakeholder Type',
      'Topic',
      'Indication',
      'Squad',
      'Product_CV',
      'Child Call ID',
      'Person_Stakeholder_Link',
      'veevaUrl',
      'Engagement Note',
      'Discussion Note',
      'Engagement_CLM_Presentation',
      'BEM Email Address',
      'Account Payable Email',
      'Email Subject Line',
      'Email Link',
    ],
  },
  metrics: {
    expression: 'Count({<vReplaceString>} distinct ID)',
    dimension: ['Engagement', 'Engagement Type', 'Source'],
  },
  appId: process.env.REACT_APP_CRM_APP_ID,
};

export const FIELDNAMELIST = [
  'StakeholderName',
  'StakeholderType',
  'Engagement',
  'Squad',
  'Brand',
  'Indication',
];
export const SITE_PERF_FIELDNAME_LIST = ['Site', 'Product'];
export const SITE_PERF_APP_ID = process.env.REACT_APP_EABI_APP_ID;
export const SITE_UTILIZATION_TABLE = 'siteutilization';
export const SITE_UTILIZATION_APP_ID =
  process.env.REACT_APP_SITE_UTILIZATION_APP_ID;
export const PAYER_MIX_QLIK_ID = process.env.REACT_APP_PAYER_ID;
export const COPAY_ANALYTICS_APP_ID =
  process.env.REACT_APP_COPAY_ANALYTICS_APP_ID;
export const PRODUCT_MIX_QLIK_ID = process.env.REACT_APP_PRODUCT_MIX_ID;
export const CRM_APP_ID = process.env.REACT_APP_CRM_APP_ID;
export const ACCOUNT_PAYER_APP_ID = process.env.REACT_APP_ACCOUNT_PAYER_APP_ID;
const generalDimensions = [
  'Source',
  'Engagement',
  'Date',
  'GNE User Name',
  'GENE User Role',
  'Person Stakeholder Name',
  'Person Stakeholder Type',
  'Topic',
  'ID',
  'Status',
  'Site Name',
  'Site Address',
  'Submitted Date',
  'Product',
  'Indication',
  'Engagement Note',
  'Discussion Note',
  'Engagement Type',
  'Engagement_CLM_Presentation',
];

const surveyDimensions = [
  'Source',
  'Engagement',
  'Date',
  'GNE User Name',
  'GENE User Role',
  'Person Stakeholder Name',
  'Person Stakeholder Type',
  'Topic',
  'ID',
  'Status',
  'Site Name',
  'Site Address',
  'Submitted Date',
  'Product',
  'Indication',
  'Engagement Note',
  'Engagement Type',
  'Engagement_CLM_Presentation',
];
const productDimensions = [
  'Source',
  'Engagement',
  'Date',
  'GNE User Name',
  'GENE User Role',
  'Person Stakeholder Name',
  'Person Stakeholder Type',
  'Topic',
  'ID',
  'Status',
  'Product',
  'Engagement Type',
  'Engagement_CLM_Presentation',
];

const contractDimensions = [
  'Source',
  'Engagement',
  'Date',
  'GNE User Name',
  'GENE User Role',
  'Person Stakeholder Name',
  'Person Stakeholder Type',
  'Topic',
  'ID',
  'Status',
  'Site Name',
  'Site Address',
  'Submitted Date',
  'Product',
  'Indication',
  'Engagement Note',
  'Discussion Note',
  'BEM Email Address',
  'Account Payable Email',
  'Email Subject Line',
  'Email Link',
];
export const ENGAGEMENT_DRILL_DOWN = {
  expression: 'Count({< vReplaceString>} distinct ID)',
  mappings: {
    VAE: {
      'Veeva CRM': {
        dimensions: productDimensions,
      },
    },
    Email: {
      'Veeva CRM': {
        dimensions: generalDimensions,
      },
    },
    Phone: {
      'Veeva CRM': {
        dimensions: generalDimensions,
      },
    },
    'In Person (F2F)': {
      'Veeva CRM': {
        dimensions: generalDimensions,
      },
    },
    Message: {
      'Veeva CRM': {
        dimensions: generalDimensions,
      },
    },
    Video: {
      'Veeva CRM': {
        dimensions: generalDimensions,
      },
    },
    'VOC Survey Response': {
      'Veeva CRM': {
        dimensions: surveyDimensions,
      },
    },
    'VOC Survey Sent': {
      'Veeva CRM': {
        dimensions: surveyDimensions,
      },
    },
    'House Email': {
      Marketing: {
        dimensions: productDimensions,
      },
    },
    'Medical Inquiry': {
      'Synapse- MedComm': {
        dimensions: [
          'Source',
          'Engagement',
          'Date',
          'GNE User Name',
          'GENE User Role',
          'Person Stakeholder Name',
          'Person Stakeholder Type',
          'Topic',
          'ID',
          'Status',
          'Inquiry Type',
          'Product',
          'Indication',
          'Engagement Note',
          'Engagement_CLM_Presentation',
        ],
      },
    },
    'PACT - Enrollment': {
      'Access Solution': {
        dimensions: [
          'Source',
          'Engagement',
          'Date',
          'GNE User Name',
          'GENE User Role',
          'Person Stakeholder Name',
          'Person Stakeholder Type',
          'Topic',
          'ID',
          'Status',
          'Site Name',
          'Site Address',
          'Product',
          'Engagement Note',
          'Engagement_CLM_Presentation',
        ],
      },
    },
    'PRP eNotices': {
      PRP: {
        dimensions: contractDimensions,
      },
    },
  },
  appId: process.env.REACT_APP_CRM_APP_ID,
};

export const ENGAGEMENT_COLUMNS = [
  {
    id: 2,
    dataIndex: 'engagement',
    key: 'engagement',
    title: 'Engagement',
    isMandatory: 'Y',
    width: 145,
    sorter: (a, b) => a.engagement.localeCompare(b.engagement),
  },
  {
    id: 3,
    dataIndex: 'engagementType',
    key: 'engagementType',
    title: 'Engagement Type',
    isMandatory: 'N',
    width: 155,
    sorter: (a, b) => a?.engagementType?.localeCompare(b?.engagementType),
  },
  {
    id: 4,
    dataIndex: 'engagementDate',
    key: 'engagementDate',
    title: 'Date',
    isMandatory: 'Y',
    width: 120,
    render: (_, record) => (
      <>
        {moment(record?.engagementDate, [
          FILTER_DATE_FORMAT,
          FILTER_DATE_FORMAT_2,
        ]).format(DATE_FORMAT_COMMA)}
      </>
    ),
    sorter: (a, b) =>
      moment(a.engagementDate).unix() - moment(b.engagementDate).unix(),
    defaultSortOrder: 'ascend',
  },
  {
    id: 5,
    dataIndex: 'engagementId',
    key: 'engagementId',
    title: 'Engagement ID',
    isMandatory: 'N',
    width: 150,
    sorter: (a, b) => a?.engagementId - b?.engagementId,
  },
  {
    id: 6,
    dataIndex: 'userName',
    key: 'userName',
    title: 'User Name',
    isMandatory: 'Y',
    width: 160,
    sorter: (a, b) => a.userName.localeCompare(b.userName),
  },
  {
    id: 7,
    dataIndex: 'userRole',
    key: 'userRole',
    title: 'User Role',
    isMandatory: 'Y',
    width: 100,
    sorter: (a, b) => {
      const roleA = a?.userRole?.toLowerCase();
      const roleB = b?.userRole?.toLowerCase();

      if (roleA && roleB) {
        return roleA.localeCompare(roleB);
      } else if (roleA) {
        return -1;
      } else if (roleB) {
        return 1;
      } else {
        return 0;
      }
    },
  },
  {
    id: 8,
    dataIndex: 'stakeholderName',
    key: 'stakeholderName',
    title: 'Stakeholder Name',
    isMandatory: 'Y',
    width: 280,
    sorter: (a, b) => a.stakeholderName.localeCompare(b.stakeholderName),
  },
  {
    id: 9,
    dataIndex: 'stakeholderType',
    key: 'stakeholderType',
    title: 'Stakeholder Type',
    isMandatory: 'Y',
    width: 130,
    sorter: (a, b) => a.stakeholderType.localeCompare(b.stakeholderType),
  },
  {
    id: 10,
    dataIndex: 'topic',
    key: 'topic',
    title: 'Topic',
    isMandatory: 'Y',
    width: 130,
    sorter: (a, b) => a?.topic?.localeCompare(b?.topic),
  },
  {
    id: 11,
    dataIndex: 'indication',
    key: 'indication',
    title: 'Indication',
    isMandatory: 'N',
    width: 150,
    sorter: (a, b) => a?.indication?.localeCompare(b?.topic),
  },
  {
    id: 12,
    dataIndex: 'squad',
    key: 'squad',
    title: 'Squad',
    isMandatory: 'N',
    width: 150,
    sorter: (a, b) => a?.squad?.localeCompare(b?.squad),
  },
  {
    id: 13,
    dataIndex: 'product',
    key: 'product',
    title: 'Product',
    isMandatory: 'Y',
    width: 150,
    sorter: (a, b) => a?.product?.localeCompare(b?.product),
  },
  {
    id: 1,
    dataIndex: 'source',
    key: 'source',
    title: 'Source',
    isMandatory: 'Y',
    width: 110,
    sorter: {
      sorter: (a, b) => a.source.localeCompare(b.source),
    },
  },
];

export const ENGAGEMENT_DOWNLOAD_COLUMNS = [
  {
    id: 2,
    dataIndex: 'engagement',
    key: 'engagement',
    title: 'Engagement',
    isMandatory: 'Y',
    width: 145,
    sorter: (a, b) => a.engagement.localeCompare(b.engagement),
  },
  {
    id: 3,
    dataIndex: 'engagementType',
    key: 'engagementType',
    title: 'Engagement Type',
    isMandatory: 'N',
    width: 155,
    sorter: (a, b) => a?.engagementType?.localeCompare(b?.engagementType),
  },
  {
    id: 4,
    dataIndex: 'engagementDate',
    key: 'engagementDate',
    title: 'Date',
    isMandatory: 'Y',
    width: 120,
    render: (_, record) => (
      <>
        {moment(record?.engagementDate, [
          FILTER_DATE_FORMAT,
          FILTER_DATE_FORMAT_2,
        ]).format(DATE_FORMAT_COMMA)}
      </>
    ),
    sorter: (a, b) =>
      moment(a.engagementDate).unix() - moment(b.engagementDate).unix(),
    defaultSortOrder: 'ascend',
  },
  {
    id: 5,
    dataIndex: 'engagementId',
    key: 'engagementId',
    title: 'Engagement ID',
    isMandatory: 'N',
    width: 150,
    sorter: (a, b) => a?.engagementId - b?.engagementId,
  },
  {
    id: 6,
    dataIndex: 'userName',
    key: 'userName',
    title: 'User Name',
    isMandatory: 'Y',
    width: 160,
    sorter: (a, b) => a.userName.localeCompare(b.userName),
  },
  {
    id: 7,
    dataIndex: 'userRole',
    key: 'userRole',
    title: 'User Role',
    isMandatory: 'Y',
    width: 100,
    sorter: (a, b) => {
      const roleA = a?.userRole?.toLowerCase();
      const roleB = b?.userRole?.toLowerCase();

      if (roleA && roleB) {
        return roleA.localeCompare(roleB);
      } else if (roleA) {
        return -1;
      } else if (roleB) {
        return 1;
      } else {
        return 0;
      }
    },
  },
  {
    id: 8,
    dataIndex: 'stakeholderName',
    key: 'stakeholderName',
    title: 'Stakeholder Name',
    isMandatory: 'Y',
    width: 280,
    sorter: (a, b) => a.stakeholderName.localeCompare(b.stakeholderName),
  },
  {
    id: 9,
    dataIndex: 'stakeholderType',
    key: 'stakeholderType',
    title: 'Stakeholder Type',
    isMandatory: 'Y',
    width: 130,
    sorter: (a, b) => a.stakeholderType.localeCompare(b.stakeholderType),
  },
  {
    id: 10,
    dataIndex: 'topic',
    key: 'topic',
    title: 'Topic',
    isMandatory: 'Y',
    width: 130,
    sorter: (a, b) => a?.topic?.localeCompare(b?.topic),
  },
  {
    id: 11,
    dataIndex: 'indication',
    key: 'indication',
    title: 'Indication',
    isMandatory: 'N',
    width: 150,
    sorter: (a, b) => a?.indication?.localeCompare(b?.topic),
  },
  {
    id: 12,
    dataIndex: 'squad',
    key: 'squad',
    title: 'Squad',
    isMandatory: 'N',
    width: 150,
    sorter: (a, b) => a?.squad?.localeCompare(b?.squad),
  },
  {
    id: 13,
    dataIndex: 'product',
    key: 'product',
    title: 'Product',
    isMandatory: 'Y',
    width: 150,
    sorter: (a, b) => a?.product?.localeCompare(b?.product),
  },
  {
    id: 1,
    dataIndex: 'source',
    key: 'source',
    title: 'Source',
    isMandatory: 'Y',
    width: 110,
    sorter: {
      sorter: (a, b) => a.source.localeCompare(b.source),
    },
  },
  {
    id: 23,
    dataIndex: 'childEngagementId',
    key: 'childEngagementId',
    title: 'Child Engagement Id',
    isMandatory: 'N',
    width: 200,
    sorter: (a, b) => a?.engagementNotes?.localeCompare(b?.childEngagementId),
  },
  {
    id: 24,
    dataIndex: 'veevaUrl',
    key: 'veevaUrl',
    title: 'Veeva Url',
    isMandatory: 'N',
    width: 200,
    sorter: (a, b) => a?.veevaUrl?.localeCompare(b?.veevaUrl),
  },

  {
    id: 26,
    dataIndex: 'EngagementNote',
    key: 'EngagementNote',
    title: 'Engagement Notes',
    isMandatory: 'N',
    width: 200,
    sorter: (a, b) => a?.engagementNotes?.localeCompare(b?.engagementNotes),
  },
  {
    id: 31,
    dataIndex: 'DiscussionNote',
    key: 'DiscussionNote',
    title: 'Discussion Notes',
    isMandatory: 'N',
    width: 200,
    sorter: (a, b) => a?.discussionNotes?.localeCompare(b?.discussionNotes),
  },

  {
    id: 30,
    dataIndex: 'Engagement_CLM_Presentation',
    key: 'Engagement_CLM_Presentation',
    title: 'CLM Presentation Name',
    isMandatory: 'N',
    width: 200,
    sorter: (a, b) =>
      a?.clmPresentationName?.localeCompare(b?.clmPresentationName),
  },
  {
    id: 41,
    dataIndex: 'BEM Email Address',
    key: 'BEM Email Address',
    title: 'BEM Email Address',
    isMandatory: 'N',
    width: 200,
    sorter: (a, b) =>
      a?.clmPresentationName?.localeCompare(b?.clmPresentationName),
  },
  {
    id: 42,
    dataIndex: 'Account Payable Email',
    key: 'Account Payable Email',
    title: 'Account Payable Email',
    isMandatory: 'N',
    width: 200,
    sorter: (a, b) =>
      a?.clmPresentationName?.localeCompare(b?.clmPresentationName),
  },
  {
    id: 43,
    dataIndex: 'Email Subject Line',
    key: 'Email Subject Line',
    title: 'Email Subject Line',
    isMandatory: 'N',
    width: 200,
    sorter: (a, b) =>
      a?.clmPresentationName?.localeCompare(b?.clmPresentationName),
  },
  {
    id: 43,
    dataIndex: 'Email Link',
    key: 'Email Link',
    title: 'Link to Attached PDF',
    isMandatory: 'N',
    width: 200,
    sorter: (a, b) =>
      a?.clmPresentationName?.localeCompare(b?.clmPresentationName),
  },
];

export const AFFILIATIONS_COLUMNS = [
  {
    id: 9,
    dataIndex: 'hcpName',
    key: 'siteId',
    title: 'Stakeholder Name',
    width: 250,
    isMandatory: 'N',
    sorter: true,
  },
  {
    id: 10,
    dataIndex: 'hcpAddress',
    key: 'siteId',
    title: 'Stakeholder Address',
    isMandatory: 'N',
    width: 250,
    sorter: true,
  },
  {
    id: 11,
    dataIndex: 'hcpCity',
    key: 'siteId',
    title: 'Stakeholder City',
    isMandatory: 'N',
    width: 150,
    sorter: true,
  },
  {
    id: 12,
    dataIndex: 'hcpState',
    key: 'siteId',
    title: 'Stakeholder State',
    width: 250,
    isMandatory: 'N',
    sorter: true,
  },
  {
    id: 13,
    dataIndex: 'hcpZip',
    key: 'siteId',
    title: 'Stakeholder Zip',
    isMandatory: 'N',
    width: 150,
    sorter: true,
  },

  {
    id: 17,
    dataIndex: 'hcpSpecialty',
    key: 'siteId',
    title: 'Primary Speciality',
    width: 250,
    isMandatory: 'N',
    sorter: true,
  },
  {
    id: 18,
    dataIndex: 'secondarySpeciality',
    key: 'siteId',
    title: 'Secondary Speciality',
    isMandatory: 'N',
    width: 250,
    sorter: true,
  },
  {
    id: 19,
    title: 'Stakeholder Type',
    dataIndex: 'stakeholderType',
    key: 'siteId',
    isMandatory: 'N',
    width: 150,
    sorter: true,
    defaultSortOrder: 'ascend',
  },
  {
    id: 2,
    dataIndex: 'siteName',
    key: 'siteName',
    title: 'Site Name',
    isMandatory: 'Y',
    width: 250,
    sorter: true,
    defaultSortOrder: 'ascend',
  },
  {
    id: 3,
    dataIndex: 'siteAddress',
    key: 'siteAddress',
    title: 'Site Address',
    width: 250,
    isMandatory: 'Y',
    sorter: true,
  },
  {
    id: 4,
    dataIndex: 'siteCity',
    key: 'siteCity',
    title: 'Site City',
    isMandatory: 'Y',
    width: 150,
    sorter: true,
  },
  {
    id: 5,
    dataIndex: 'siteState',
    key: 'siteState',
    title: 'Site State',
    isMandatory: 'Y',
    width: 150,
    sorter: true,
  },
  {
    id: 6,
    dataIndex: 'zip',
    key: 'zip',
    title: 'Site Zip',
    isMandatory: 'Y',
    width: 150,
    sorter: true,
  },
  {
    id: 16,
    dataIndex: 'siteDea',
    key: 'siteDea',
    title: 'Site DEA',
    isMandatory: 'Y',
    width: 150,
    sorter: true,
  },
  {
    id: 14,
    dataIndex: 'siteHin',
    key: 'siteHin',
    title: 'Site HIN',
    isMandatory: 'Y',
    width: 150,
    sorter: true,
  },

  {
    id: 15,
    dataIndex: 'siteClassification',
    key: 'siteClassification',
    title: 'Site Classification',
    isMandatory: 'Y',
    width: 250,
    sorter: true,
  },
  {
    id: 1,
    title: 'Site ID',
    dataIndex: 'siteId',
    key: 'siteId',
    isMandatory: 'Y',
    width: 250,
    sorter: true,
  },
  {
    id: 8,
    dataIndex: 'hcpId',
    key: 'siteId',
    title: 'Stakeholder ID',
    isMandatory: 'N',
    width: 150,
    sorter: true,
  },
];

export const SITE_INFO_AFFILIATIONS_COLUMNS = [
  {
    id: 1,
    title: 'Stakeholder Name',
    dataIndex: 'hcpName',
    key: 'hcpNameSite',
    isMandatory: 'Y',
    width: 220,
    sorter: true,
    defaultSortOrder: 'ascend',
  },
  {
    id: 2,
    dataIndex: 'hcpPrimarySpeciality',
    key: 'hcpSpecialitySite',
    title: 'Primary Speciality',
    isMandatory: 'Y',
    width: 210,
    sorter: true,
  },
  {
    id: 3,
    dataIndex: 'hcpSecondarySpeciality',
    key: 'hcpSecondarySpecialitySite',
    title: 'Secondary Speciality',
    isMandatory: 'Y',
    width: 210,
    sorter: true,
  },
  {
    id: 4,
    dataIndex: 'hcpCity',
    key: 'hcpCitySite',
    title: 'City',
    isMandatory: 'Y',
    width: 160,
    sorter: true,
  },
  {
    id: 5,
    dataIndex: 'hcpState',
    key: 'hcpStateSite',
    title: 'State',
    isMandatory: 'Y',
    width: 100,
    sorter: true,
  },
  {
    id: 6,
    dataIndex: 'hcpZip',
    key: 'hcpZipSite',
    title: 'Zip',
    isMandatory: 'Y',
    width: 100,
    sorter: true,
  },
];

export const SITE_AFFILIATIONS_COLUMNS = [
  {
    id: 1,
    title: 'Stakeholder Name',
    dataIndex: 'hcpName',
    key: 'hcpNameSite',
    isMandatory: 'Y',
    width: 200,
    sorter: true,
    defaultSortOrder: 'ascend',
  },
  {
    id: 11,
    title: 'Stakeholder Type',
    dataIndex: 'stakeholderType',
    key: 'stakeholderType',
    isMandatory: 'Y',
    width: 200,
    sorter: true,
  },

  {
    id: 2,
    dataIndex: 'hcpSpeciality',
    key: 'hcpSpecialitySite',
    title: 'Speciality',
    isMandatory: 'Y',
    width: 200,
    sorter: true,
  },
  {
    id: 3,
    dataIndex: 'hcpAddress',
    key: 'hcpAddressSite',
    title: 'Stakeholder Address',
    width: 200,
    isMandatory: 'Y',
    sorter: true,
  },
  {
    id: 4,
    dataIndex: 'hcpCity',
    key: 'hcpCitySite',
    title: 'Stakeholder City',
    isMandatory: 'Y',
    width: 150,
    sorter: true,
  },
  {
    id: 5,
    dataIndex: 'hcpState',
    key: 'hcpStateSite',
    title: 'Stakeholder State',
    isMandatory: 'Y',
    width: 180,
    sorter: true,
  },
  {
    id: 6,
    dataIndex: 'hcpZip',
    key: 'hcpZipSite',
    title: 'Stakeholder Zip',
    isMandatory: 'Y',
    width: 150,
    sorter: true,
  },
  {
    id: 9,
    dataIndex: 'hcpPrimarySpeciality',
    key: 'hcpPrimarySpecialitySite',
    title: 'Primary Speciality',
    width: 200,
    isMandatory: 'Y',
    sorter: true,
  },
  {
    id: 10,
    dataIndex: 'hcpSecondarySpeciality',
    key: 'hcpSecondarySpecialitySite',
    title: 'Secondary Speciality',
    isMandatory: 'Y',
    width: 200,
    sorter: true,
  },
];

export const SITE_PERFOMANCE_COLUMNS = [
  {
    id: 2,
    dataIndex: 'siteName',
    key: 'siteName',
    title: 'Site Name',
    isMandatory: 'Y',
    width: 180,
    sorter: true,
  },
  {
    id: 3,
    dataIndex: 'siteAddress',
    key: 'siteAddress',
    title: 'Site Address',
    isMandatory: 'Y',
    width: 180,
    sorter: true,
  },
  {
    id: 4,
    dataIndex: 'siteCity',
    key: 'siteCity',
    title: 'Site City',
    isMandatory: 'Y',
    width: 110,
    sorter: true,
  },
  {
    id: 5,
    dataIndex: 'siteState',
    key: 'siteState',
    title: 'Site State',
    isMandatory: 'Y',
    width: 110,
    sorter: true,
  },
  {
    id: 9,
    dataIndex: 'ecosystem',
    key: 'ecosystem',
    title: 'Ecosystem',
    isMandatory: 'Y',
    width: 110,
    sorter: true,
  },
  {
    id: 7,
    dataIndex: 'contractSegment',
    key: 'contractSegment',
    title: 'Contract Segment',
    isMandatory: 'Y',
    width: 170,
    sorter: true,
  },
  {
    id: 6,
    dataIndex: 'classOfTrade',
    key: 'classOfTrade',
    title: 'Class of Trade',
    isMandatory: 'Y',
    width: 150,
    sorter: true,
  },
  {
    id: 11,
    dataIndex: 'percentOfCP',
    key: 'percentOfCP',
    title: '% Of CP',
    isMandatory: 'Y',
    width: 110,
    render: (value) => {
      const formattedNumber = Number(value).toFixed(2) + '%';
      return <span>{formattedNumber}</span>;
    },
    sorter: true,
  },
  {
    id: 12,
    dataIndex: 'grossSales',
    key: 'grossSales',
    title: 'Gross Sales($)',
    isMandatory: 'Y',
    width: 150,
    render: (value) => {
      const formattedNumber =
        '$' +
        Number(value).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      return <span>{formattedNumber}</span>;
    },
    sorter: true,
    defaultSortOrder: 'descend',
  },
  {
    id: 13,
    dataIndex: 'grossSalesGrowth',
    key: 'grossSalesGrowth',
    title: 'Gross Sales Growth %',
    isMandatory: 'Y',
    width: 150,
    render: (value) => {
      const formattedNumber = `${Number(value).toFixed(2)}%`;
      const numberColor = value < 0 ? '#d04715' : '#74AA50';
      return <span style={{ color: numberColor }}>{formattedNumber}</span>;
    },
    sorter: true,
  },
  {
    id: 1,
    title: 'Site ID',
    dataIndex: 'siteId',
    key: 'siteId',
    isMandatory: 'Y',
    width: 140,
    sorter: true,
  },
];

export const SITE_UTILIZATION_UTILIZATION = [
  {
    id: 1,
    dataIndex: 'ecosystem',
    key: 'ecosystem',
    title: 'Ecosystem',
    isMandatory: 'Y',
    width: 180,
    sorter: true,
    fixed: 'left',
    qFieldDefs: ['Ecosystem'],
  },
  {
    id: 2,
    dataIndex: 'site_name',
    key: 'site_name',
    title: 'Site Name',
    isMandatory: 'Y',
    width: 180,
    sorter: true,
    fixed: 'left',
    qFieldDefs: ['Site Name'],
  },
  {
    id: 3,
    dataIndex: 'site_address',
    key: 'siteAddress',
    title: 'Site Address',
    isMandatory: 'Y',
    width: 180,
    sorter: true,
    fixed: 'left',
    qFieldDefs: ['Site Address'],
  },
  {
    id: 4,
    dataIndex: 'site_city',
    key: 'siteCity',
    title: 'Site City',
    isMandatory: 'Y',
    sorter: true,
    width: 180,
    qFieldDefs: ['Site City'],
  },
  {
    id: 5,
    dataIndex: 'site_state',
    key: 'siteState',
    title: 'Site State',
    isMandatory: 'Y',
    sorter: true,
    width: 130,
    qFieldDefs: ['Site State'],
  },
  {
    id: 6,
    dataIndex: 'site_zip',
    key: 'siteZip',
    title: 'Site Zip',
    isMandatory: 'Y',
    sorter: true,
    width: 130,
    qFieldDefs: ['Site Zip'],
  },
  {
    id: 7,
    dataIndex: 'size',
    key: 'size',
    title: 'Size',
    isMandatory: 'Y',
    sorter: true,
    width: 130,
    qFieldDefs: ['Size'],
  },
  {
    id: 8,
    dataIndex: 'summary_score',
    key: 'summaryScore',
    title: 'Summary Score',
    isMandatory: 'Y',
    sorter: (a, b) => a?.summary_score - b?.summary_score,
    defaultSortOrder: 'descend',
    width: 160,
    qFieldDefs: ['Summary Score'],
  },
  {
    id: 9,
    dataIndex: 'actemra_iv',
    key: 'actemraIv',
    title: 'ACTEMRA IV',
    isMandatory: 'Y',
    sorter: true,
    qFieldDefs: ['ACTEMRA IV'],
  },
  {
    id: 10,
    dataIndex: 'actemra_sc',
    key: 'actemraSc',
    title: 'ACTEMRA SC',
    isMandatory: 'Y',
    sorter: true,
    qFieldDefs: ['ACTEMRA SC'],
  },
  {
    id: 11,
    dataIndex: 'alecensa',
    key: 'alecensa',
    title: 'ALECENSA',
    isMandatory: 'Y',
    sorter: true,
    qFieldDefs: ['ALECENSA'],
  },
  {
    id: 12,
    dataIndex: 'avastin',
    key: 'avastin',
    title: 'AVASTIN',
    isMandatory: 'Y',
    sorter: true,
    qFieldDefs: ['AVASTIN'],
  },
  {
    id: 13,
    dataIndex: 'columvi',
    key: 'columvi',
    title: 'COLUMVI',
    isMandatory: 'Y',
    sorter: true,
    qFieldDefs: ['COLUMVI'],
  },
  {
    id: 14,
    dataIndex: 'gazyva',
    key: 'gazyva',
    title: 'GAZYVA',
    isMandatory: 'Y',
    sorter: true,
    qFieldDefs: ['GAZYVA'],
  },
  {
    id: 15,
    dataIndex: 'hemlibra',
    key: 'hemlibra',
    title: 'HEMLIBRA',
    isMandatory: 'Y',
    sorter: true,
    qFieldDefs: ['HEMLIBRA'],
  },
  {
    id: 16,
    dataIndex: 'herceptin',
    key: 'herceptin',
    title: 'HERCEPTIN',
    isMandatory: 'Y',
    sorter: true,
    qFieldDefs: ['HERCEPTIN'],
  },
  {
    id: 17,
    dataIndex: 'herceptin_hylecTA',
    key: 'herceptinHylecta',
    title: 'HERCEPTIN HYLECTA',
    isMandatory: 'Y',
    sorter: true,
    width: 180,
    qFieldDefs: ['HERCEPTIN HYLECTA'],
  },
  {
    id: 18,
    dataIndex: 'kadcyla',
    key: 'kadcyla',
    title: 'KADCYLA',
    isMandatory: 'Y',
    sorter: true,
    qFieldDefs: ['KADCYLA'],
  },
  {
    id: 19,
    dataIndex: 'lunsumio',
    key: 'lunsumio',
    title: 'LUNSUMIO',
    isMandatory: 'Y',
    sorter: true,
    qFieldDefs: ['LUNSUMIO'],
  },
  {
    id: 20,
    dataIndex: 'ocrevus',
    key: 'ocrevus',
    title: 'OCREVUS',
    isMandatory: 'Y',
    sorter: true,
    qFieldDefs: ['OCREVUS'],
  },
  {
    id: 21,
    dataIndex: 'perjeta',
    key: 'perjeta',
    title: 'PERJETA',
    isMandatory: 'Y',
    sorter: true,
    qFieldDefs: ['PERJETA'],
  },
  {
    id: 22,
    dataIndex: 'phesgo',
    key: 'phesgo',
    title: 'PHESGO',
    isMandatory: 'Y',
    sorter: true,
    qFieldDefs: ['PHESGO'],
  },
  {
    id: 23,
    dataIndex: 'polivy',
    key: 'polivy',
    title: 'POLIVY',
    isMandatory: 'Y',
    sorter: true,
    qFieldDefs: ['POLIVY'],
  },
  {
    id: 24,
    dataIndex: 'rituxan',
    key: 'rituxan',
    title: 'RITUXAN',
    isMandatory: 'Y',
    sorter: true,
    qFieldDefs: ['RITUXAN'],
  },
  {
    id: 25,
    dataIndex: 'rituxan_hycela',
    key: 'rituxanHycela',
    title: 'RITUXAN HYCELA',
    isMandatory: 'Y',
    sorter: true,
    width: 150,
    qFieldDefs: ['RITUXAN HYCELA'],
  },
  {
    id: 26,
    dataIndex: 'rozlytrek',
    key: 'rozlytrek',
    title: 'ROZLYTREK',
    isMandatory: 'Y',
    sorter: true,
    qFieldDefs: ['ROZLYTREK'],
  },
  {
    id: 27,
    dataIndex: 'tecentriq',
    key: 'tecentriq',
    title: 'TECENTRIQ',
    isMandatory: 'Y',
    sorter: true,
    qFieldDefs: ['TECENTRIQ'],
  },
  {
    id: 28,
    dataIndex: 'venclexta',
    key: 'venclexta',
    title: 'VENCLEXTA',
    isMandatory: 'Y',
    sorter: true,
    qFieldDefs: ['VENCLEXTA'],
  },
  {
    id: 29,
    dataIndex: 'xolair',
    key: 'xolair',
    title: 'XOLAIR',
    isMandatory: 'Y',
    sorter: true,
    qFieldDefs: ['XOLAIR'],
  },
  {
    id: 1,
    title: 'Site ID',
    dataIndex: 'site_id',
    key: 'site_id',
    isMandatory: 'Y',
    sorter: true,
    width: 180,
    qFieldDefs: ['Site ID'],
  },
];

export const SITE_UTILIZATION_INCIDENCE = [
  {
    id: 1,
    dataIndex: 'ecosystem',
    key: 'ecosystem',
    title: 'Ecosystem',
    isMandatory: 'Y',
    width: 180,
    sorter: true,
    fixed: 'left',
    qFieldDefs: ['Ecosystem'],
  },
  {
    id: 2,
    dataIndex: 'site_name',
    key: 'site_name',
    title: 'Site Name',
    isMandatory: 'Y',
    width: 180,
    sorter: true,
    fixed: 'left',
    qFieldDefs: ['Site Name'],
  },
  {
    id: 3,
    dataIndex: 'site_address',
    key: 'siteAddress',
    title: 'Site Address',
    isMandatory: 'Y',
    width: 180,
    sorter: true,
    fixed: 'left',
    qFieldDefs: ['Site Address'],
  },
  {
    id: 4,
    dataIndex: 'site_city',
    key: 'siteCity',
    title: 'Site City',
    isMandatory: 'Y',
    sorter: true,
    width: 180,
    qFieldDefs: ['Site City'],
  },
  {
    id: 5,
    dataIndex: 'site_state',
    key: 'siteState',
    title: 'Site State',
    isMandatory: 'Y',
    sorter: true,
    width: 130,
    qFieldDefs: ['Site State'],
  },
  {
    id: 6,
    dataIndex: 'site_zip',
    key: 'siteZip',
    title: 'Site Zip',
    isMandatory: 'Y',
    sorter: true,
    width: 130,
    qFieldDefs: ['Site Zip'],
  },
  {
    id: 7,
    dataIndex: 'size',
    key: 'size',
    title: 'Size',
    isMandatory: 'Y',
    sorter: true,
    width: 130,
    qFieldDefs: ['Size'],
  },
  {
    id: 8,
    dataIndex: 'summary_score',
    key: 'summaryScore',
    title: 'Summary Score',
    isMandatory: 'Y',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a?.summary_score - b?.summary_score,
    width: 130,
    qFieldDefs: ['Summary Score'],
  },
  {
    id: 9,
    dataIndex: 'breast_category',
    key: 'actemraIv',
    title: 'Breast (Category)',
    isMandatory: 'Y',
    width: 130,
    sorter: true,
    qFieldDefs: ['Breast_Category'],
  },
  {
    id: 10,
    dataIndex: 'breast_per_100K',
    key: 'actemraSc',
    title: 'Breast (# per 100K)',
    isMandatory: 'Y',
    width: 130,
    sorter: true,
    qFieldDefs: ['Breast_per_100K'],
  },
  {
    id: 11,
    dataIndex: 'lung_category',
    key: 'alecensa',
    title: 'Lung (Category)',
    isMandatory: 'Y',
    width: 130,
    sorter: true,
    qFieldDefs: ['Lung_Category'],
  },
  {
    id: 12,
    dataIndex: 'lung_per_100K',
    key: 'avastin',
    title: 'Lung (# per 100K)',
    isMandatory: 'Y',
    width: 130,
    sorter: true,
    qFieldDefs: ['Lung_per_100K'],
  },
  {
    id: 13,
    dataIndex: 'leukemia_category',
    key: 'columvi',
    title: 'Leukemia (Category)',
    isMandatory: 'Y',
    width: 130,
    sorter: true,
    qFieldDefs: ['Leukemia_Category'],
  },
  {
    id: 14,
    dataIndex: 'leukemia_per_100K',
    key: 'gazyva',
    title: 'Leukemia (# per 100K)',
    isMandatory: 'Y',
    width: 130,
    sorter: true,
    qFieldDefs: ['Leukemia_per_100K'],
  },
  {
    id: 15,
    dataIndex: 'lymphoma_category',
    key: 'hemlibra',
    title: 'Lymphoma (Category)',
    isMandatory: 'Y',
    width: 130,
    sorter: true,
    qFieldDefs: ['Lymphoma_Category'],
  },
  {
    id: 16,
    dataIndex: 'lymphoma_per_100K',
    key: 'herceptin',
    title: 'Lymphoma (# per 100K)',
    isMandatory: 'Y',
    width: 130,
    sorter: true,
    qFieldDefs: ['Lymphoma_per_100K'],
  },
  {
    id: 1,
    width: 180,
    title: 'Site ID',
    dataIndex: 'site_id',
    key: 'site_id',
    isMandatory: 'Y',
    sorter: true,
    qFieldDefs: ['Site ID'],
  },
];

export const SITE_UTILIZATION_DEMOGRAPHICS = [
  {
    id: 1,
    dataIndex: 'ecosystem',
    key: 'ecosystem',
    title: 'Ecosystem',
    isMandatory: 'Y',
    width: 180,
    sorter: true,
    fixed: 'left',
    qFieldDefs: ['Ecosystem'],
  },
  {
    id: 2,
    dataIndex: 'site_name',
    key: 'site_name',
    title: 'Site Name',
    isMandatory: 'Y',
    width: 180,
    sorter: true,
    fixed: 'left',
    qFieldDefs: ['Site Name'],
  },
  {
    id: 3,
    dataIndex: 'site_address',
    key: 'siteAddress',
    title: 'Site Address',
    isMandatory: 'Y',
    sorter: true,
    fixed: 'left',
    width: 180,
    qFieldDefs: ['Site Address'],
  },
  {
    id: 4,
    dataIndex: 'site_city',
    key: 'siteCity',
    title: 'Site City',
    isMandatory: 'Y',
    sorter: true,
    width: 180,
    qFieldDefs: ['Site City'],
  },
  {
    id: 5,
    dataIndex: 'site_state',
    key: 'siteState',
    title: 'Site State',
    isMandatory: 'Y',
    sorter: true,
    width: 130,
    qFieldDefs: ['Site State'],
  },
  {
    id: 6,
    dataIndex: 'site_zip',
    key: 'siteZip',
    title: 'Site Zip',
    isMandatory: 'Y',
    sorter: true,
    width: 130,
    qFieldDefs: ['Site Zip'],
  },
  {
    id: 7,
    dataIndex: 'size',
    key: 'size',
    title: 'Size',
    isMandatory: 'Y',
    sorter: true,
    width: 130,
    qFieldDefs: ['Size'],
  },
  {
    id: 8,
    dataIndex: 'summary_score',
    key: 'summaryScore',
    title: 'Summary Score',
    isMandatory: 'Y',
    sorter: (a, b) => a?.summary_score - b?.summary_score,
    defaultSortOrder: 'descend',
    width: 130,
    qFieldDefs: ['Summary Score'],
  },
  {
    id: 9,
    dataIndex: 'zip_population',
    key: 'actemraIv',
    title: 'Zip Population',
    isMandatory: 'Y',
    sorter: true,
    width: 130,
    qFieldDefs: ['Zip Population'],
  },
  {
    id: 10,
    dataIndex: 'HHS_below_poverty_category',
    key: 'actemraSc',
    title: 'HHS Below Poverty (Category)',
    isMandatory: 'Y',
    width: 130,
    sorter: true,
    qFieldDefs: ['HHS_Below_Poverty_Category'],
  },
  {
    id: 11,
    dataIndex: 'HHS_below_poverty',
    key: 'alecensa',
    title: 'HHS Below Poverty (%)',
    isMandatory: 'Y',
    width: 130,
    sorter: true,
    qFieldDefs: ['HHS_Below_Poverty_Pct'],
  },
  {
    id: 12,
    dataIndex: 'no_insurance_category',
    key: 'avastin',
    title: 'No Insurance (Category)',
    isMandatory: 'Y',
    width: 130,
    sorter: true,
    qFieldDefs: ['No_Insurance_Category'],
  },
  {
    id: 13,
    dataIndex: 'no_insurance',
    key: 'columvi',
    title: 'No Insurance (%)',
    isMandatory: 'Y',
    width: 130,
    sorter: true,
    qFieldDefs: ['No_Insurance_Pct'],
  },
  {
    id: 14,
    dataIndex: 'commercial',
    key: 'gazyva',
    title: 'Commercial (%)',
    isMandatory: 'Y',
    width: 130,
    sorter: true,
    qFieldDefs: ['Commercial_Pct'],
  },
  {
    id: 15,
    dataIndex: 'medicare',
    key: 'hemlibra',
    title: 'Medicare (%)',
    isMandatory: 'Y',
    width: 130,
    sorter: true,
    qFieldDefs: ['Medicare_Pct'],
  },
  {
    id: 16,
    dataIndex: 'medicaid',
    key: 'herceptin',
    title: 'MedicaId (%)',
    isMandatory: 'Y',
    width: 130,
    sorter: true,
    qFieldDefs: ['Medicaid_Pct'],
  },
  {
    id: 31,
    title: 'Site ID',
    dataIndex: 'site_id',
    key: 'site_id',
    isMandatory: 'Y',
    sorter: true,
    width: 180,
    qFieldDefs: ['Site ID'],
  },
];
export const SITE_UTILIZATION_COLUMNS = Array.from(
  new Set(
    [
      ...SITE_UTILIZATION_UTILIZATION,
      ...SITE_UTILIZATION_INCIDENCE,
      ...SITE_UTILIZATION_DEMOGRAPHICS,
    ].map((item) => item.dataIndex)
  )
).map((dataIndex) => {
  const mergedObject = {};
  [
    SITE_UTILIZATION_UTILIZATION,
    SITE_UTILIZATION_INCIDENCE,
    SITE_UTILIZATION_DEMOGRAPHICS,
  ].forEach((array) => {
    const foundObject = array.find((item) => item.dataIndex === dataIndex);
    if (foundObject) {
      Object.assign(mergedObject, foundObject);
    }
  });
  return mergedObject;
});

export const updatedKpiNameLength = 250;
export const kpiTitleMessage = 'Title changed successfully.';
export const kpiTitleLimitExceedMessage =
  'Title name is exceeding the limit - 250 characters.';

export const PRODUCT_MIX_COLUMNS = [
  {
    id: 1,
    title: 'Product',
    dataIndex: 'product',
    key: 'product',
    isMandatory: 'Y',
    width: 280,
    sorter: true,
  },
  {
    id: 2,
    dataIndex: 'grossSales',
    key: 'grossSales',
    title: 'Sales ($)',
    isMandatory: 'Y',
    width: 270,
    render: (value) => {
      if (value === '-') {
        return '-';
      } else {
        const formattedNumber =
          '$' +
          Number(value).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        return <span>{formattedNumber}</span>;
      }
    },
    sorter: true,
    defaultSortOrder: 'descend',
  },
  {
    id: 4,
    dataIndex: 'grossSalesGrowthProduct',
    key: 'grossSalesGrowthProduct',
    title: 'Sales Growth %',
    isMandatory: 'Y',
    width: 280,
    render: (value) => {
      if (value === '-') {
        return '-';
      } else {
        const formattedNumber = `${Number(value).toFixed(2)}%`;
        const numberColor = value < 0 ? '#d04715' : '#74AA50';
        return <span style={{ color: numberColor }}>{formattedNumber}</span>;
      }
    },
    sorter: true,
  },
  {
    id: 3,
    dataIndex: 'percentOfCPProduct',
    key: 'percentOfCPProduct',
    title: '% Contribution',
    isMandatory: 'Y',
    width: 230,
    render: (value) => {
      if (value === '-') {
        return '-';
      } else {
        const formattedNumber = Number(value).toFixed(2) + '%';
        return <span>{formattedNumber}</span>;
      }
    },
    sorter: true,
  },
];

export const PAYER_MIX_COLUMNS = [
  {
    id: 1,
    title: 'Parent Payer',
    dataIndex: 'ParentPayer',
    key: 'ParentPayer',
    isMandatory: 'Y',
    width: 250,
    sorter: true,
  },
  {
    id: 2,
    dataIndex: 'Payer',
    key: 'Payer',
    title: 'Payer',
    isMandatory: 'Y',
    sorter: true,
  },
  {
    id: 3,
    dataIndex: 'BookOfBusiness',
    key: 'BookOfBusiness',
    title: 'Book of Business',
    isMandatory: 'Y',
    width: 250,
    sorter: true,
  },
  {
    id: 4,
    dataIndex: 'percentage_life_covered',
    key: 'percentage_life_covered',
    title: '% of Lives Covered',
    isMandatory: 'Y',
    width: 250,
    render: (value) => {
      if (value === '-') {
        return '-';
      } else {
        return value + '%';
      }
    },
    sorter: true,
    defaultSortOrder: 'descend',
  },
];

export const COPAY_ANALYTICS_FRM_COLUMNS = [
  {
    id: 1,
    title: 'Product',
    dataIndex: 'Product',
    key: 'Product',
    isMandatory: 'Y',
    width: 250,
    sorter: true,
  },

  {
    id: 2,
    dataIndex: 'Quarter',
    key: 'Product',
    title: 'Quarter',
    isMandatory: 'N',
    sorter: true,
    width: 150,
  },
  {
    id: 3,
    dataIndex: 'Site',
    key: 'Product',
    title: 'Site Name',
    isMandatory: 'N',
    width: 300,
    sorter: true,
  },
  {
    id: 4,
    dataIndex: 'Site Full Address',
    key: 'Product',
    title: 'Site Address',
    isMandatory: 'N',
    width: 300,
    sorter: true,
  },
  {
    id: 5,
    dataIndex: 'site_city',
    key: 'Product',
    title: 'Site City',
    isMandatory: 'N',
    width: 200,
    sorter: true,
  },
  {
    id: 6,
    dataIndex: 'site_state',
    key: 'Product',
    title: 'Site State',
    isMandatory: 'N',
    width: 150,
    sorter: true,
  },
  {
    id: 7,
    dataIndex: 'Site_INS_ID',
    key: 'Product',
    title: 'Site ID',
    isMandatory: 'N',
    width: 300,
    sorter: true,
  },
  {
    id: 8,
    dataIndex: 'New Enrollment',
    key: 'NewEnrollment',
    title: 'New Enrollment',
    isMandatory: 'Y',
    width: 200,
    sorter: true,
  },
  {
    id: 9,
    dataIndex: 'Total Enrollment',
    key: 'TotalEnrollment',
    title: 'Total Enrollment',
    isMandatory: 'Y',
    width: 200,
    sorter: true,
  },
  {
    id: 10,
    dataIndex: 'Total Paid Claims',
    key: 'TotalPaidClaims',
    title: 'Total Paid Claims',
    isMandatory: 'Y',
    width: 200,
    sorter: true,
    defaultSortOrder: 'descend',
  },
  {
    id: 11,
    dataIndex: 'Unique Utilizers',
    key: 'UniqueUtilizers',
    title: 'Unique Utilizers(Patients)',
    isMandatory: 'Y',
    width: 200,
    sorter: true,
  },
];

export const COPAY_ANALYTICS_HD_COLUMNS = [
  {
    id: 1,
    title: 'Product',
    dataIndex: 'Product',
    key: 'Product',
    isMandatory: 'Y',
    width: 250,
    sorter: true,
  },

  {
    id: 2,
    dataIndex: 'Quarter',
    key: 'Product',
    title: 'Quarter',
    isMandatory: 'N',
    sorter: true,
    width: 200,
  },
  {
    id: 3,
    dataIndex: 'Site',
    key: 'Product',
    title: 'Site Name',
    isMandatory: 'N',
    width: 300,
    sorter: true,
  },
  {
    id: 4,
    dataIndex: 'Site Full Address',
    key: 'Product',
    title: 'Site Address',
    isMandatory: 'N',
    width: 300,
    sorter: true,
  },
  {
    id: 5,
    dataIndex: 'site_city',
    key: 'Product',
    title: 'Site City',
    isMandatory: 'N',
    width: 200,
    sorter: true,
  },
  {
    id: 6,
    dataIndex: 'site_state',
    key: 'Product',
    title: 'Site State',
    isMandatory: 'N',
    width: 150,
    sorter: true,
  },
  {
    id: 7,
    dataIndex: 'Site_INS_ID',
    key: 'Product',
    title: 'Site ID',
    isMandatory: 'N',
    width: 300,
    sorter: true,
  },
  {
    id: 8,
    dataIndex: 'Total Paid Claims',
    key: 'TotalPaidClaims',
    title: 'Total Paid Claims',
    isMandatory: 'Y',
    width: 200,
    sorter: true,
    defaultSortOrder: 'descend',
  },
  {
    id: 9,
    dataIndex: 'Claims Utilization%',
    key: 'ClaimsUtilization',
    title: 'Claims Utilization % (selected account/ecosystem)',
    isMandatory: 'Y',
    width: 200,
    sorter: true,
  },
  {
    id: 10,
    dataIndex: 'Ecosystem%',
    key: 'EcosystemClaimsUtilization',
    title: 'Ecosystem Claims Utilization % (account agnostic)',
    isMandatory: 'Y',
    width: 200,
    sorter: true,
  },
  {
    id: 11,
    dataIndex: 'Nation%',
    key: 'NationalClaimsUtilization',
    title: 'National Claims Utilization %(account agnostic)',
    isMandatory: 'Y',
    width: 200,
    sorter: true,
  },
];

export const ACCOUNT_PAYER_MIX_COLUMNS = [
  {
    id: 1,
    title: 'Parent Payer',
    dataIndex: 'Parent_Payer',
    key: 'Parent_Payer',
    isMandatory: 'Y',
    width: 150,
    sorter: true,
  },
  {
    id: 2,
    dataIndex: 'Payer_Name',
    key: 'Payer_Name',
    title: 'Payer',
    isMandatory: 'Y',
    width: 200,
    sorter: true,
  },
  {
    id: 3,
    dataIndex: 'Book_of_Business',
    key: 'Book_of_Business',
    title: 'Book of Business',
    isMandatory: 'Y',
    width: 200,
    sorter: true,
  },
  {
    id: 4,
    dataIndex: 'Benefit_Type',
    key: 'Benefit_Type',
    title: 'Benefit Type',
    isMandatory: 'Y',
    width: 170,
    sorter: true,
  },

  {
    id: 5,
    dataIndex: 'Estimated_Payer_Sale',
    key: 'Estimated_Payer_Sale',
    title: 'Estimated Payer Sales ($)',
    isMandatory: 'Y',
    width: 205,
    render: (value) => {
      const formattedNumber =
        '$' +
        Number(value).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      return <span>{formattedNumber}</span>;
    },
    sorter: true,
    defaultSortOrder: 'descend',
  },
  {
    id: 6,
    dataIndex: 'Estimated_Contribution_to_Account',
    key: 'Estimated_Contribution_to_Account',
    title: 'Estimated Contribution to Account (%)',
    isMandatory: 'Y',
    width: 200,
    render: (value) => {
      if (value === '-' || value === undefined) {
        return '-';
      } else {
        const formattedNumber = Number(value).toFixed(2) + '%';
        return <span>{formattedNumber}</span>;
      }
    },
    sorter: true,
  },
];
export const DOCUMENT_COLUMNS = [
  {
    id: 1,
    dataIndex: 'name',
    key: 'name',
    title: 'Document Name',
    isMandatory: 'Y',
    width: 150,
    sorter: (a, b) => a.name.localeCompare(b.name),
    defaultSortOrder: 'ascend',
    render: (_, record) => (
      <OverlayTrigger
        overlay={documentTooltip(record?.description)}
        placement='right'
        style={{ minWidth: '300px' }}>
        <Link
          to={record.url}
          target='_blank'>
          {record.name}
        </Link>
      </OverlayTrigger>
    ),
  },
  {
    id: 2,
    dataIndex: 'refreshSchedule',
    key: 'refreshSchedule',
    title: 'Refresh Frequency',
    isMandatory: 'Y',
    width: 70,
    sorter: (a, b) => a.refreshSchedule.localeCompare(b.refreshSchedule),
  },
  {
    id: 3,
    dataIndex: 'pointOfContact',
    key: 'pointOfContact',
    title: 'Point of Contact',
    isMandatory: 'Y',
    width: 60,
    sorter: (a, b) => a.pointOfContact.localeCompare(b.pointOfContact),
  },
];
export const OTHER_TOOLS = 'Other Tools';
export const SITE_UTILIZATION_TIME_RANGE_EXP = `only([StartDate]) & ' - ' & Only([EndDate])`;
export const TIME_RANGE_EXP = new Map([
  [
    'R3',
    "Date(AddMonths(Date#(max({1<[Latest Month Flag]={1}>} Date((Date#([Month Year],'YYYYMMDD')),'MM''YYYY')),'MM''YYYY'),-(2)),'MMM''YY')& ' - ' & Date(Date#(max({1<[Latest Month Flag]={1}>} Date((Date#([Month Year],'YYYYMMDD')),'MM''YYYY')),'MM''YYYY'),'MMM''YY')",
  ],
  [
    'R6',
    "Date(AddMonths(Date#(max({1<[Latest Month Flag]={1}>} Date((Date#([Month Year],'YYYYMMDD')),'MM''YYYY')),'MM''YYYY'),-(5)),'MMM''YY')& ' - ' &  Date(Date#(max({1<[Latest Month Flag]={1}>} Date((Date#([Month Year],'YYYYMMDD')),'MM''YYYY')),'MM''YYYY'),'MMM''YY')",
  ],
  [
    'R12',
    "Date(AddMonths(Date#(max({1<[Latest Month Flag]={1}>} Date((Date#([Month Year],'YYYYMMDD')),'MM''YYYY')),'MM''YYYY'),-(11)),'MMM''YY')&' - ' & Date(Date#(max({1<[Latest Month Flag]={1}>} Date((Date#([Month Year],'YYYYMMDD')),'MM''YYYY')),'MM''YYYY'),'MMM''YY')",
  ],
  [
    'YTD',
    "Date(Date#(Min({<YTD={'C'}>} Date((Date#([Month Year],'YYYYMMDD')),'MM''YYYY')),'MM''YYYY'),'MMM''YY') & ' - ' &  Date(Date#(max({1<[Latest Month Flag]={1}>} Date((Date#([Month Year],'YYYYMMDD')),'MM''YYYY')),'MM''YYYY'),'MMM''YY')",
  ],
]);

export const orgHierarchyAllActions = [];
export const PRIORITY_TABLE_COLUMNS = [
  {
    id: 1,
    dataIndex: 'name',
    key: 'name',
    title: 'Name',
    isMandatory: 'Y',
    width: 350,
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    id: 2,
    dataIndex: 'type',
    key: 'type',
    title: 'Type',
    isMandatory: 'Y',
    width: 120,
  },
  {
    id: 3,
    dataIndex: 'address',
    key: 'address',
    title: 'Address',
    isMandatory: 'Y',
    width: 400,
  },
  {
    id: 4,
    dataIndex: 'primaryContact',
    key: 'primaryContact',
    title: 'Primary Contact',
    isMandatory: 'Y',
    width: 180,
  },
];

export const PAGINATION = {
  pageSizeOptions: ['5', '10', '15', '20', '25'],
  showSizeChanger: true,
  defaultPageSize: 5,
};

export const CUSTOMER_VIEW_DETAIL_PAGE = 'customerDetailPage';

export const VIEWLOGTYPEOPTIONS = [
  { label: 'User Log', value: 'user-log' },
  { label: 'HCOS Log', value: 'hcos-log' },
];

export const USERLOGACTIONOPTIONS = [
  { label: 'All', value: 'all' },
  { label: 'Add', value: 'add' },
  { label: 'Remove', value: 'remove' },
  { label: 'Move', value: 'move' },
  { label: 'Comments', value: 'comments' },
  { label: 'Labels', value: 'labels' },
];

export const HCOSLOGACTIONOPTIONS = [
  { label: 'Added', value: 'add' },
  { label: 'Moved', value: 'move' },
  { label: 'Deactivated', value: 'deactivate' },
];

export const LOGTYPE = {
  userlog: 'User Log',
  hcoslog: 'HCOS Log',
};

export const USERLOG = 'User Log';

export const CHANGEACTIONTYPE = {
  hierarchy: 'hierarchy',
  label: 'label',
};

export const CHANGEVALUETYPE = {
  parent: 'parent',
  label: 'label',
};

export const FILTERSCREEN = {
  main: 'main',
  action: 'action',
};

export const CHECKBOXCLASS = 'form-check-input-color';

export const TABLE_NAME_SITE_PERFORMANCE = 'SitePerformance';

export const TABLE_NAME_ENGAGEMENT = 'Engagement';

export const TABLE_NAME_SITE_UTILIZATION = 'SiteUtilization';

export const TABLE_NAME_AFFILIATION = 'Affiliation';

export const TABLE_NAME_PRODUCT_MIX = 'ProductMix';

export const TABLE_NAME_PAYER_MIX = 'PayerMix';

export const TABLE_NAME_COPAY_ANALYTICS = 'CopayAnalytics';

export const TABLE_NAME_SITE_AFFILIATION = 'SiteAffiliation';

export const SITE_UTILIZATION_HIGH_MEDIUM_LOW_COLUMN_NAME = [
  'size',
  'breast_category',
  'lung_category',
  'leukemia_category',
  'lymphoma_category',
  'HHS_below_poverty_category',
  'no_insurance_category',
];

export const COMPARE_BENCHMARK_NO_DIMENSION_CHARTTYPE = 'KPI';
export const DIMENSION = 'dimension';
export const COMPARE_TIME_PERIOD = 'time-period';
export const COMPARE_BENCHMARK = 'compare-benchmark';
export const BENCHMARK_LEGEND_FIRST = 'Selected Filters';
export const BENCHMARK_LEGEND_SECOND = 'Nation';
export const COMPARE_KPI = 'multi-kpi';
export const OS_FLAG = 4;

export const COPAY_CONSTANT = {
  COPAY_TIMESLOT: `'Q' & Ceil(month(date(addmonths(Date(Date#(min({1}date_year_month), 'YYYYMM'), 'YYYYMMDD'),-12),'YYYYMM'))/3)&' '&
  year(date((Date(Date#(min({1}date_year_month), 'YYYYMM'), 'YYYYMMDD')),'YYYYMM'))  & ' - ' &
 'Q' & ceil(month(date(addmonths(Date(Date#(max({1}date_year_month), 'YYYYMM'), 'YYYYMMDD'),-12),'YYYYMM'))/3)&' '&
  year(date((Date(Date#(max({1}date_year_month), 'YYYYMM'), 'YYYYMMDD')),'YYYYMM'))`,
  FRM_VIEW_MEASURE: [
    'New Enrollment',
    'Total Enrollment',
    'Total Paid Claims',
    'Unique Utilizers',
  ],
  HD_VIEW_MEASURE: [
    'Total Paid Claims',
    'Claims Utilization%',
    'Ecosystem%',
    'Nation%',
  ],
};
export const CONTRACT_SEGMENT = 'Contract Segment'
